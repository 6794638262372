<template>
  <button
    class="btn-icon btn-sm p-2 bg-interactive-bg-neutral-low-contrast"
    :class="{ active: enabled }"
    @click="toggle"
  >
    <icon-fluent name="star" size="16" :variant="enabled ? 'filled' : 'regular'"></icon-fluent>
  </button>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class FavoritedFilterToggle extends Vue {
  @Prop()
  value: number[];

  @Prop()
  enabled: boolean;

  toggle(): void {
    this.$emit("input", this.value.length ? [] : [this.$currentUser.id]);
  }
}
</script>

<style lang="scss" scoped>
button {
  &:hover svg,
  &.active svg {
    color: var(--ui-color-text-emphasis);
  }
}
</style>
