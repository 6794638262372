<template>
  <div class="logbook-entries-view">
    <card>
      <div class="heading">
        <h3>{{ $t("procedures.title") }}</h3>
        <button v-if="$hasRole('resident,solo')" class="btn-primary" @click="addProcedure">
          {{ $t("actions.add") }}
        </button>
      </div>
      <logbook-procedure-card
        v-for="entry in procedureEntries"
        :key="entry.id"
        class="logbook-entry-card"
        :entry="entry"
      ></logbook-procedure-card>
    </card>
    <card>
      <div class="heading">
        <h3>{{ $t("courses.title") }} {{ $t("actions.add") }}</h3>
        <button v-if="$hasRole('resident,solo')" class="btn-primary" @click="addCourse">{{ $t("actions.add") }}</button>
      </div>
      <logbook-course-card
        v-for="entry in courseEntries"
        :key="entry.id"
        class="logbook-entry-card"
        :entry="entry"
      ></logbook-course-card>
    </card>
    <card>
      <div class="heading">
        <h3>{{ $t("other_entries.title") }}</h3>
        <button v-if="$hasRole('resident,solo')" class="btn-primary" @click="addOtherEntry">
          {{ $t("actions.add") }}
        </button>
      </div>
      <logbook-other-entry-card
        v-for="entry in otherEntries"
        :key="entry.id"
        class="logbook-entry-card"
        :entry="entry"
      ></logbook-other-entry-card>
    </card>
    <card v-if="quizEntries && quizEntries.length > 0">
      <div class="heading">
        <h3>{{ $t("quizzes.title") }}</h3>
      </div>
      <logbook-quiz-card
        v-for="entry in quizEntries"
        :key="entry.id"
        class="logbook-quiz-card"
        :entry="entry"
      ></logbook-quiz-card>
    </card>
    <card v-if="usageRecords && usageRecords.length > 0">
      <div class="heading">
        <h3>{{ $t("vr_sessions.title") }}</h3>
      </div>
      <item-card v-for="record in usageRecords" :key="record.id">
        <template #title>{{ record.details.Operation || $t("vr_session.title") }}</template>
        <template #titleTrailing>
          <date :date="record.details.Started"></date>
        </template>
        <div class="duration">
          <strong>{{ $t("attributes.duration") }}: </strong>
          <date-diff :start="record.details.Started" :end="record.details.Ended"></date-diff>
        </div>
      </item-card>
    </card>
  </div>
</template>

<script lang="ts">
import LogbookEntry from "@/model/logbook-entry";
import Vue from "vue";
import Component from "vue-class-component";
import sortBy from "lodash/sortBy";
import { Prop } from "vue-property-decorator";
import { showDialog } from "@/utils/dialogs";
import ProcedureModal from "@/components/logbook-entry/procedure-modal.vue";
import CourseModal from "@/components/logbook-entry/course-modal.vue";
import OtherEntryModal from "@/components/logbook-entry/other-entry-modal.vue";
import UsageRecord from "@/model/usage-record";

@Component({})
export default class LogbookEntriesPage extends Vue {
  @Prop()
  entries: LogbookEntry<object>[];

  @Prop()
  usageRecords: UsageRecord[];

  get sortedEntries(): LogbookEntry<object>[] {
    return sortBy(this.entries, "date").reverse();
  }

  get procedureEntries(): LogbookEntry<object>[] {
    return this.sortedEntries.filter((entry) => entry.entry_type == "procedure");
  }

  get courseEntries(): LogbookEntry<object>[] {
    return this.sortedEntries.filter((entry) => entry.entry_type == "course");
  }

  get otherEntries(): LogbookEntry<object>[] {
    return this.sortedEntries.filter((entry) => entry.entry_type == "other");
  }

  get quizEntries(): LogbookEntry<object>[] {
    return this.sortedEntries.filter((entry) => entry.entry_type == "quiz");
  }

  addProcedure() {
    showDialog(ProcedureModal);
  }

  addCourse() {
    showDialog(CourseModal);
  }

  addOtherEntry() {
    showDialog(OtherEntryModal);
  }
}
</script>

<style lang="scss" scoped>
.card:not(:last-child) {
  margin-bottom: var(--spacing);
}

.heading {
  margin-bottom: var(--spacing);
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    margin: 0;
    flex: 1;
  }
}
</style>
