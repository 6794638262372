import { mergeAttributes, Node } from "@tiptap/core";
import MediaFile from "@/model/media-file";
import { createVideoDropHandler } from "@/editor/extensions/video/video-drop-handler";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import VideoNodeView from "@/editor/extensions/video/video-node-view.vue";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    video: {
      addVideo: (mediaFile: MediaFile) => ReturnType;
      updateVideo: (mediaFile: MediaFile) => ReturnType;
    };
  }
}

const VideoNode = Node.create({
  name: "video",

  inline: true,

  group: "inline",

  draggable: true,

  addAttributes() {
    return {
      blob: {},
    };
  },

  addCommands() {
    return {
      addVideo: ({ file: { key, filename, content_type, metadata } }: MediaFile) => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: {
            blob: { key, filename, content_type, metadata },
          },
        });
      },
      updateVideo: ({ file: { key, filename, content_type, metadata } }: MediaFile) => ({ commands }) => {
        return commands.updateAttributes(this.name, {
          blob: { key, filename, content_type, metadata },
        });
      },
    };
  },

  addProseMirrorPlugins() {
    if (this.editor.isEditable) {
      return [createVideoDropHandler(this.editor)];
    } else {
      return [];
    }
  },

  parseHTML() {
    return [
      {
        tag: "video-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["video-node", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(VideoNodeView);
  },
});

export default VideoNode;
