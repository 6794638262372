<template>
  <div class="invitation-list-page relative">
    <div>
      <div class="mb-md">
        <div class="flex flex-1">
          <search-input
            v-model="search"
            class="w-64 mr-md sm:grow-0"
            :placeholder="$t('actions.search')"
            @reset="onReset"
          ></search-input>
          <button class="btn-primary" @click="inviteUser">{{ $t("actions.invite_user") }}</button>
        </div>
      </div>
    </div>
    <scroll-detector v-if="loaded" class="mb-28" @end="loadMoreInvitations">
      <div>
        <list-item v-if="!invitations">
          <loader-big></loader-big>
        </list-item>
        <list-item v-for="invitation in invitations" v-else :key="invitation.id">
          <div class="flex-1 flex flex-col">
            <div class="text-body-large text-text-primary">
              {{ invitation.email }}
              <badge
                v-if="invitation.email_error"
                class="ml-xs"
                :label="invitation.email_error.error_code"
                variant="error"
              ></badge>
            </div>
            <div class="text-body-small">{{ roleTitle(invitation) }}</div>
          </div>
          <button @click="resendInvitation(invitation)">{{ $t("actions.resend") }}</button>
          <button @click="copyInvite(invitation.token)">{{ $t("actions.copy_invitation_link") }}</button>
          <button @click="onDelete(invitation.token)">{{ $t("actions.delete") }}</button>
        </list-item>
      </div>
    </scroll-detector>
    <loader-big v-if="loading" class="absolute top-full inset-x-0 mt-lg"></loader-big>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import Invitations from "@/services/invitations";
import Invitation from "@/model/invitation";
import { showDialog } from "@/utils/dialogs";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import InviteUserModal from "@/pages/users/invite-user-modal.vue";

@Component({})
export default class InvitationListPage extends Vue {
  @Prop()
  organizationId: number;

  @Prop({ default: 2 })
  minSearchLength: number;

  invitations: Invitation[] = null;
  loading: boolean = false;
  search = "";

  get loaded() {
    return !!this.invitations;
  }

  async created() {
    return this.fetchInvitations();
  }

  get invitationService() {
    return new Invitations();
  }

  roleTitle(invitation: Invitation) {
    const role = this.$t("user_roles." + invitation.role_type);
    return invitation.customer_organization ? `${role} (${invitation.customer_organization.name})` : role;
  }

  async onDelete(token: String) {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.are_you_sure") });
    if (confirmed) {
      return this.invitationService.delete(token).then(() => {
        this.invitations = this.invitations.filter((item) => {
          return !(item.token === token);
        });
      });
    }
  }

  copyInvite(token: String) {
    navigator.clipboard.writeText(window.location.origin + "/users/new/" + token);
  }

  loadMoreInvitations() {
    return this.fetchInvitations();
  }

  async inviteUser() {
    await showDialog(InviteUserModal, { organizationId: this.organizationId });
    return this.fetchInvitations(true);
  }

  async resendInvitation(invitation: Invitation) {
    await new Invitations().resendInvitation(invitation.token);
    this.$toast.info(this.$t("invitation.invitation_sent") as string);
  }

  async fetchInvitations(clear: boolean = false) {
    if (this.loading) {
      return;
    }
    if (clear) {
      this.invitations = null;
    }
    this.loading = true;
    const params: any = { limit: 20, offset: this.invitations?.length || 0 };
    if (this.organizationId) {
      params.customer_organization_id = this.organizationId;
    }
    if (this.search.length >= this.minSearchLength) {
      params.q = this.search;
    }
    const newInvitations = await new Invitations().getAll({ params });
    this.invitations = [...(this.invitations || []), ...newInvitations];
    this.loading = false;
  }

  @Watch("search")
  onSearch() {
    return this.fetchInvitations(true);
  }

  onReset() {
    this.search = "";
  }
}
</script>

<style lang="scss" scoped>
table {
  margin-top: 1em;

  button {
    margin-top: 1em;
  }
}

td {
  padding: 0 1em;
}

a {
  cursor: pointer;
  text-decoration: underline;

  &:visited {
    color: $grey-lighter;
  }

  &:link {
    color: $white;
  }
}
</style>
