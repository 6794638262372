<template>
  <modal>
    <form name="procedureModal" @submit.prevent="onSubmit">
      <h3 class="mb-4">{{ $t("procedure.log_procedure") }}</h3>
      <form-field :label="$t('attributes.hospital')">
        <resident-role-select v-model="model.residency_id"></resident-role-select>
      </form-field>
      <form-field
        v-if="selectedResidency && selectedResidency.curriculums && selectedResidency.curriculums.length > 0"
        :label="$t('logbook.title')"
      >
        <curriculum-select
          :value="curriculum && curriculum.id"
          :curriculums="selectedResidency.curriculums"
          @input="selectCurriculum"
        ></curriculum-select>
      </form-field>
      <form-field v-if="curriculum" :label="$t('procedure.title')">
        <curriculum-item-select
          v-model="model.curriculum_item_id"
          type="procedure"
          :curriculum="curriculum"
        ></curriculum-item-select>
      </form-field>
      <form-field :label="$t('attributes.date')" :error="$v.model.date.$error">
        <date-input v-model="model.date"></date-input>
      </form-field>
      <form-field :label="$t('attributes.duration')">
        <time-input v-model="model.data.duration" :placeholder="$t('attributes.duration') + '...'"></time-input>
      </form-field>
      <form-field :label="$t('procedure.diagnosis')">
        <disease-code-select v-model="model.data.disease_codes"></disease-code-select>
      </form-field>
      <form-field :label="$t('procedure.procedures_performed')">
        <procedure-type-select v-model="model.data.procedure_types"></procedure-type-select>
      </form-field>
      <form-field :label="$t('procedure.role')">
        <radio-select v-model="model.data.role" :options="roleOptions"></radio-select>
      </form-field>
      <form-field :label="$t('procedure.urgency')">
        <radio-select v-model="model.data.urgency" :options="surgeryUrgencyOptions"></radio-select>
      </form-field>
      <form-field :label="$t('attributes.comments')">
        <textarea v-model="model.data.comments" rows="4"></textarea>
      </form-field>
      <form-field :label="$t('attributes.attachments')">
        <attachments-input v-model="model.attachments"></attachments-input>
      </form-field>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { RadioSelectOption } from "@/components/form/radio-select.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import LogbookEntry from "@/model/logbook-entry";
import ProcedureData from "@/model/procedure-data";
import dayjs from "dayjs";
import { addLogbookEntry, updateLogbookEntry } from "@/actions";
import { Prop } from "vue-property-decorator";
import cloneDeep from "lodash/cloneDeep";
import Curriculum from "@/model/curriculum";
import { showDialog } from "@/utils/dialogs";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import Residency from "@/model/residency";
import Attachment from "@/model/attachment";
import MaybeUploading from "@/model/maybe-uploading";

@Component({})
export default class ProcedureModal extends Vue {
  @Prop()
  logbookEntry: LogbookEntry<ProcedureData>;

  model: Partial<LogbookEntry<ProcedureData>> = null;

  curriculum: Curriculum = null;

  @Validations()
  validations = {
    model: {
      date: { required },
      attachments: {
        ready: (attachments: Attachment[]) =>
          attachments.every((attachment: MaybeUploading<Attachment>) => !attachment.uploading),
      },
    },
  };

  get selectedResidency(): Residency {
    return this.model && this.model.residency_id
      ? this.$currentUser.residencies.find(({ id }) => id === this.model.residency_id)
      : null;
  }

  get roleOptions(): RadioSelectOption<string>[] {
    return ["surgeon", "supervised_surgeon", "assistant", "watched"].map((role) => ({
      value: role,
      label: this.$t(`procedure_role.${role}`).toString(),
    }));
  }

  get surgeryUrgencyOptions(): RadioSelectOption<string>[] {
    return ["elective", "emergency"].map((urgency) => ({
      value: urgency,
      label: this.$t(`procedure_urgency.${urgency}`).toString(),
    }));
  }

  created() {
    this.model = this.logbookEntry
      ? cloneDeep(this.logbookEntry)
      : {
          entry_type: "procedure",
          date: dayjs().format("YYYY-MM-DD"),
          attachments: [],
          data: {
            duration: null,
            urgency: "elective",
            role: "surgeon",
            procedure_types: [],
            disease_codes: [],
          },
          residency_id: this.$currentUser.currentResidency ? this.$currentUser.currentResidency.id : null,
        };

    this.curriculum = (this.logbookEntry && this.logbookEntry.curriculum) || null;
  }

  async onSubmit() {
    this.$v.$touch();
    if (!this.$v.$error) {
      const action = this.model.id ? updateLogbookEntry(this.model) : addLogbookEntry(this.model);
      await showDialog(LoadingIndicatorDialog, { action });
      this.$emit("ok");
    }
  }

  selectCurriculum(id: number) {
    this.curriculum = (this.selectedResidency && this.selectedResidency.curriculums)?.filter(
      (curriculum) => curriculum.id === id
    )[0];
  }
}
</script>
