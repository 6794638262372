import Vue from "vue";

Vue.directive("outside-click", {
  inserted: (el, binding, vnode) => {
    // assign event to the element
    (el as any).clickOutsideEvent = function(event) {
      // here we check if the click event is outside the element and it's children
      if (!(el == event.target || el.contains(event.target))) {
        // if clicked outside, call the provided method
        vnode.context[binding.expression](event);
      }
    };
    // register click and touch events
    document.body.addEventListener("click", (el as any).clickOutsideEvent);
    document.body.addEventListener("touchstart", (el as any).clickOutsideEvent);
  },
  unbind: function(el) {
    // unregister click and touch events before the element is unmounted
    document.body.removeEventListener("click", (el as any).clickOutsideEvent);
    document.body.removeEventListener("touchstart", (el as any).clickOutsideEvent);
  },
});
