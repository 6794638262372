<template>
  <div class="option" :class="answered" @click="$emit('select-option', option)">
    <div v-if="!answered" class="option-box">
      <div v-if="optionImageUrl" class="option-image"><img :key="optionImageUrl" :src="optionImageUrl" /></div>
      <div v-if="option.text && !answered" class="option-text">{{ option.text }}</div>
    </div>
    <div v-if="answered && option.explanation" class="explanation">{{ option.explanation }}</div>
    <div v-if="answered && explanationImageUrl" class="explanation-image">
      <img :key="explanationImageUrl" :src="explanationImageUrl" />
    </div>
    <div v-if="answered" class="answers-count-percentage">
      {{ $t("quiz.number_answered_this", { percentage: optionAnswerPercentage.toString() + "%" }) }}
    </div>
  </div>
</template>

<script lang="ts">
import QuizOption from "@/model/quiz-option";
import QuizQuestion from "@/model/quiz-question";
import { filePath } from "@/utils/paths";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class QuizOptionView extends Vue {
  @Prop()
  option: QuizOption;

  @Prop()
  question: QuizQuestion;

  @Prop()
  answered: boolean;

  get optionAnswerPercentage() {
    if (this.question.answers_count > 0) {
      return Math.round((this.option.answers_count / this.question.answers_count) * 100);
    } else {
      return 0;
    }
  }

  get optionImageUrl() {
    return filePath(this.option.image, "medium");
  }

  get explanationImage() {
    return this.option.explanation_image || this.option.image;
  }

  get explanationImageUrl() {
    return filePath(this.explanationImage, "medium");
  }
}
</script>

<style lang="scss" scoped>
.option {
  position: relative;
  width: 100%;
  margin: var(--spacing) 0;

  .explanation {
    color: var(--ui-color-text-primary);
    font-weight: bold;
    margin: var(--spacing-sm) 0;
  }

  .option-box {
    border: 2px solid var(--color-outline);
  }

  .option-image,
  .explanation-image {
    width: 100%;
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .option-text {
    text-align: center;
    padding: var(--spacing);
  }

  .answers-count-percentage {
    margin-top: var(--spacing-sm);
    color: var(--ui-color-text-primary);
    font-weight: bold;
  }

  &:not(.answered) {
    .option-box {
      &:hover {
        cursor: pointer;
        border: 2px solid var(--ui-color-border-emphasis);
      }
    }
  }
}
</style>
