<template>
  <a
    class="inline-block max-w-full border-l-4 border-background pl-sm mt-sm cursor-pointer overflow-hidden w-[400px]"
    :href="link.url"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div v-if="link.site_name" class="site_name">{{ link.site_name }}</div>
    <div class="text-label-large text-text-emphasis mt-xs">{{ link.title }}</div>
    <div v-if="link.image" class="mt-xs"><img :src="link.image" /></div>
    <div v-if="link.description" class="text-body-small mt-xs">{{ link.description }}</div>
    <div class="text-body-small mt-xs text-ellipsis whitespace-nowrap overflow-hidden">{{ link.url }}</div>
  </a>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import LinkData from "@/model/link-data";

@Component({})
export default class LinkCard extends Vue {
  @Prop()
  link: LinkData;
}
</script>
