<template>
  <a class="btn btn-secondary" :href="url" target="_blank">{{ $t("actions.launch_vr") }}: {{ title }}</a>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class VrButton extends Vue {
  @Prop()
  url: string;

  @Prop()
  title: string;
}
</script>
