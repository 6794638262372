<template>
  <node-view-wrapper as="span">
    <div class="relative group" data-drag-handle="">
      <div v-if="editor.isEditable" class="absolute top-4 right-4 hidden group-hover:flex">
        <button class="btn-primary btn-sm pl-4" type="button" @click.stop="changeVideo">
          <icon-fluent class="mr-xs" name="edit" :size="16"></icon-fluent><span>{{ $t("actions.change") }}</span>
        </button>
      </div>
      <video :key="url" class="video rounded-sm" controls="true">
        <source :src="url" :type="type" />
      </video>
    </div>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";
import { filePath } from "@/utils/paths";
import { showDialog } from "@/utils/dialogs";
import MediaFile from "@/model/media-file";
import MediaLibraryModal from "@/components/media-library/media-library-modal.vue";

@Component({
  components: { NodeViewWrapper },
  props: nodeViewProps,
})
export default class VideoNodeView extends Vue {
  @Prop()
  editor: Editor;

  @Prop()
  node: any;

  get url() {
    return filePath(this.node.attrs.blob);
  }

  get type() {
    return this.node.attrs.blob.content_type;
  }

  async changeVideo() {
    const mediaFiles = await showDialog<MediaFile[]>(MediaLibraryModal, {
      fileType: "video",
      showFileTypes: false,
      allowMultiselect: false,
    });
    if (mediaFiles && mediaFiles.length > 0) {
      const mediaFile = mediaFiles[0];
      this.editor.commands.updateVideo(mediaFile);
    }
  }
}
</script>
