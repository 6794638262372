<template>
  <div class="flex items-center">
    <slot name="left"></slot>
    <back-button v-if="backButtonRoute" class="cursor-pointer pointer-events-auto" :to="backButtonRoute"></back-button>
    <h3 class="text-text-emphasis m-0 truncate">{{ title }}</h3>
    <div v-if="status" class="ml-sm hidden sm:inline-block">
      <badge v-if="status != 'published'" :label="$t('status.' + status)" :variant="status"></badge>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { RawLocation } from "vue-router";

@Component({})
export default class PageHeaderTitle extends Vue {
  @Prop()
  title: string;

  @Prop()
  status?: string;

  @Prop()
  backButtonRoute?: RawLocation;
}
</script>
