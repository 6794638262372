import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import QuizLinkNodeView from "@/editor/extensions/quiz-link/quiz-link-node-view.vue";
import QuizLink from "@/editor/extensions/quiz-link/quiz-link";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    quizLink: {
      addQuizLink: (link: QuizLink) => ReturnType;
      updateQuizLink: (link: QuizLink) => ReturnType;
    };
  }
}

const QuizLinkNode = Node.create({
  name: "quizLink",

  inline: true,

  group: "inline",

  draggable: true,

  atom: true,

  addAttributes() {
    return {
      id: {},
      title: {},
    };
  },

  addCommands() {
    return {
      addQuizLink: (quizLink: QuizLink) => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: quizLink,
        });
      },
      updateQuizLink: (quizLink: QuizLink) => ({ commands }) => {
        console.log(quizLink);
        return commands.updateAttributes(this.name, quizLink);
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "quiz-link-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["quiz-link-node", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(QuizLinkNodeView);
  },
});

export default QuizLinkNode;
