<template>
  <button
    class="nav-link text-button-small group text-text-variant px-lg py-2 dark:text-text-variant hover:text-text-emphasis dark:hover:text-text-primary hover:brightness-95 dark:hover:brightness-120"
    type="button"
    @click="openModal"
  >
    <div class="icon-container mr-2">
      <icon-fluent class="group-hover:hidden" name="mail" :size="16"></icon-fluent>
      <icon-fluent class="hidden group-hover:inline-block" name="mail" :size="16" variant="filled"></icon-fluent>
    </div>
    <div class="title">{{ $t("feedback_view.title") }}</div>
  </button>
</template>

<script lang="ts">
import FeedbackModal from "@/components/feedback/feedback-modal.vue";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class FeedbackButton extends Vue {
  openModal() {
    showDialog(FeedbackModal);
  }
}
</script>

<style lang="scss" scoped>
button.nav-link {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  align-items: center;

  @media (hover: hover) {
    &:hover {
      // Set normal brightness for non-active links and exact links
      // (non-exact links have modified brightness declared with css class)
      &:not(.router-link-active),
      &.router-link-exact-active {
        filter: brightness(1);
      }
    }
  }

  &.router-link-active {
    background-color: var(--ui-color-surface-min-contrast);
    color: var(--ui-color-text-emphasis);

    @media (hover: hover) {
      // Specify hover color to be the same as non-hover for active links
      // Non active links get hover color from css class
      &:hover {
        color: var(--ui-color-text-emphasis);
      }
    }

    svg.regular {
      display: none;
    }
    svg.filled {
      display: inline-block;
    }
  }

  .icon-container {
    display: flex;
    justify-content: center;
    width: 20px;
    svg {
      height: 14px;
    }
  }
}
</style>
