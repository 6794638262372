<template>
  <img v-if="url" class="avatar" :src="url" />
  <div v-else-if="placeholderShowInitials" class="avatar avatar__initials">{{ initials }}</div>
  <div
    v-else
    class="avatar placeholder-icon flex flex-col items-center justify-center overflow-hidden bg-surface-mid-contrast"
  >
    <icon-fluent class="h-6 w-auto" name="person" variant="filled"></icon-fluent>
  </div>
</template>

<script lang="ts">
import User from "@/model/user";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class Avatar extends Vue {
  @Prop()
  user: User;

  @Prop()
  placeholderShowInitials: boolean;

  get url() {
    return this.user?.profile_picture?.url;
  }

  get initials() {
    return (
      this.user &&
      this.user.name
        .split(/\s+/)
        .map((s) => s[0]?.toUpperCase())
        .join("")
    );
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  color: var(--color-on-surface-variant);
  height: 32px;
  width: 32px;
  border-radius: 100%;

  &__initials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--ui-color-text-primary);
    font-size: var(--font-size-subtitle);
    font-weight: bold;
  }
}
</style>
