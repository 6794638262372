<template>
  <div class="youtube-player bg-surface-min-contrast rounded-sm">
    <div :id="elementId" class="player">
      <iframe
        :src="iframeSrc"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class YouTubePlayer extends Vue {
  @Prop()
  videoId: string;
  url: string;
  elementId: string;

  get iframeSrc() {
    return `https://www.youtube.com/embed/${this.videoId}`;
  }

  created() {
    this.elementId = `youtube-player-${this.videoId}`;
  }
}
</script>

<style lang="scss" scoped>
.youtube-player {
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;

  .player {
    aspect-ratio: 16 / 9;
    height: 100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
