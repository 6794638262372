<template><div></div></template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { showDialog } from "@/utils/dialogs";
import WelcomeDialog from "@/pages/welcome-dialog.vue";

@Component({})
export default class Welcome extends Vue {
  async created() {
    if (this.$currentUser) {
      this.$router.push("/");
      showDialog(WelcomeDialog);
    }
  }
}
</script>
