<template>
  <div class="indicator flex flex-col justify-between rounded-lg px-lg py-md bg-surface-min-contrast">
    <div class="leading">{{ label }}</div>
    <div class="flex-center text-4xl font-light text-tertiary">{{ value }}</div>
    <div class="trailing"></div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class Indicator extends Vue {
  @Prop()
  label: string;

  @Prop()
  value: any;
}
</script>

<style lang="scss" scoped>
.indicator {
  width: 200px;
  height: 200px;

  .leading,
  .trailing {
    height: 50px;
  }
}
</style>
