import MarkdownIt from "markdown-it";
import markdownLinkAttributes from "markdown-it-link-attributes";

const md = new MarkdownIt({
  html: false,
  breaks: true,
  linkify: true,
  typographer: true
})
.use(markdownLinkAttributes, {
  attrs: {
    target: "_blank",
    rel: "noopener noreferrer"
  }
});

// Add debug class to see what styles are being applied
md.renderer.rules.list_item_open = () => '<li class="mb-1">';
md.renderer.rules.paragraph_open = () => '<p class="mb-2">';

export const useMarkdown = () => {
  const render = (content: string): string => {
    return md.render(content);
  };

  return {
    render
  };
};
