<template>
  <page>
    <page-header v-if="contentItem" :title="page.name"></page-header>
    <page-toolbar>
      <div class="flex flex-col justify-between sm:flex-row">
        <tabs v-if="page.documents && page.documents.length > 1" class="page-tabs">
          <tab-link
            v-for="document in page.documents"
            :key="document.document_type"
            :active="currentDocument == document"
            @click="selectDocument(document)"
          >
            {{ getDocumentLabel(document) }}
          </tab-link>
        </tabs>
        <action-button
          v-if="contentItem.share_token"
          icon="share"
          :title="$t('actions.share')"
          @click="share"
        ></action-button>
      </div>
    </page-toolbar>
    <page-content>
      <div class="public-content">
        <document-view :key="currentDocument.id" :doc="currentDocument.content.doc"></document-view>
      </div>
    </page-content>
  </page>
</template>

<script lang="ts">
import ContentDocument, { documentLabel } from "@/model/content-document";
import ContentItem from "@/model/content-item";
import Page from "@/model/page";
import ContentItemsService from "@/services/content-items-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class PublicContentView extends Vue {
  @Prop()
  contentItem: ContentItem;

  currentDocument: ContentDocument = null;

  loading = false;

  get contentItemsService() {
    return new ContentItemsService();
  }

  get page(): Page {
    return this.contentItem.content.page;
  }

  async created() {
    this.currentDocument = this.page.documents ? this.page.documents[0] : null;
  }

  share() {
    const publicLink = `${location.origin}/public/content_items/${this.contentItem.share_token}`;
    navigator.clipboard.writeText(publicLink);
    const message = this.$t("share.link_copied_to_clipboard") as string;
    this.$toast.success(message, { position: "top-right", message });
  }

  selectDocument(document) {
    this.currentDocument = document;
  }

  getDocumentLabel(document: ContentDocument): string {
    return documentLabel(document);
  }
}
</script>
