<template>
  <div class="typeahead-select">
    <div class="relative">
      <input
        v-model="inputValue"
        type="text"
        :placeholder="placeholder"
        @input="$emit('input', inputValue)"
        @focus="onFocus"
        @blur="onBlur"
        @keyup.backspace="onKeyBackspace"
      />
      <fade-transition>
        <dropdown-content v-if="focused && matchingItems.length > 0" class="typeahead-items" :width-by-content="false">
          <div
            v-for="(item, i) in matchingItems"
            :key="i"
            class="typeahead-item hover:bg-surface-mid-contrast"
            @click="addItem(item)"
          >
            {{ item.label }}
          </div>
        </dropdown-content>
      </fade-transition>
    </div>
    <div v-if="showSelected === 'tags'" class="flex flex-1">
      <input-tag-item
        v-for="(item, i) in selectedItems"
        :key="i"
        :label="item.label"
        @remove="removeItem(item)"
      ></input-tag-item>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { TypeaheadItem } from "@/model/typeahead-item";

@Component({})
export default class TypeaheadSelect<Value> extends Vue {
  @Prop({ default: false })
  multiselect: boolean;

  @Prop({ default: "input" })
  showSelected: "input" | "tags";

  @Prop()
  placeholder: string;

  @Prop()
  selectedItems: TypeaheadItem<Value>[];

  @Prop()
  matchingItems: TypeaheadItem<Value>[];

  focused = false;

  inputValue = "";

  previousInputValue = "";

  mounted() {
    this.updateShownItems(this.selectedItems);
  }

  onKeyBackspace(e) {
    if (this.inputValue === "" && this.previousInputValue === "") {
      if (this.selectedItems.length > 0) {
        const removeIndex = this.selectedItems.length - 1;
        this.removeItem(this.selectedItems[removeIndex]);
      }
      e.target.blur();
    }
  }

  onFocus() {
    this.focused = true;
    this.inputValue = "";
    this.previousInputValue = "";
  }

  @Watch("selectedItems")
  onBlur(event) {
    this.previousInputValue = "";
    this.focused = false;
    this.$emit("input", "");
    this.$emit("blur", event);
    this.updateShownItems(this.selectedItems);
  }

  addItem(item) {
    this.$emit("input", "");

    const items = this.multiselect ? [...this.selectedItems, item] : [item];
    this.$emit("updated", items);
  }

  removeItem(item) {
    const items = this.selectedItems.filter(({ value }) => value != item.value);
    this.$emit("updated", items);
  }

  updateShownItems(items: TypeaheadItem<Value>[]) {
    if (this.showSelected === "input") {
      this.inputValue = items.map((item) => item.label).join(", ");
    } else {
      this.inputValue = "";
    }
  }
}
</script>

<style lang="scss" scoped></style>
