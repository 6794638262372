<template>
  <div v-if="!contentItems" class="flex flex-col">
    <div class="bg-surface-low-contrast rounded h-5 w-40"></div>
    <div class="grid grid-cols-2 gap-4 mt-4 mx-4 sm:grid-cols-3">
      <div v-for="i in 2" :key="'skeleton-' + i" class="rounded bg-surface-low-contrast h-20"></div>
    </div>
  </div>
  <div v-else-if="contentItems.length > 0" class="flex flex-col">
    <div class="flex items-center sm:mr-4">
      <div class="text-label-strong text-text-primary">{{ title }}</div>
      <div class="flex-1"></div>
      <div v-if="contentItems.length > 2" class="flex sm:hidden">
        <ui-button-icon-clear
          icon="chevronLeft"
          :disabled="!scrollState.left"
          @click="contentItemsScroller.scrollLeft()"
        ></ui-button-icon-clear>
        <ui-button-icon-clear
          icon="chevronRight"
          :disabled="!scrollState.right"
          @click="contentItemsScroller.scrollRight()"
        ></ui-button-icon-clear>
      </div>
      <a class="link text-button-small ml-sm" @click="goToContentsPage()">{{ $t("actions.show_more") }}</a>
    </div>
    <div class="gap-4 p-2 m-2 overflow-hidden hidden sm:flex">
      <content-item-card
        v-for="contentItem in contentItems"
        :key="contentItem.id"
        :content-item="contentItem"
      ></content-item-card>
    </div>
    <content-items-scroller ref="contentItemsScroller" class="sm:hidden" @scroll="scrollState = $event">
      <content-item-card
        v-for="contentItem in contentItems"
        :key="contentItem.id"
        :content-item="contentItem"
      ></content-item-card>
    </content-items-scroller>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";
import ContentItemCard from "@/pages/home/latest-releases-block/content-item-card.vue";
import { SET_CONTENT_FILTERS } from "@/store/mutations";
import ContentItemsScroller from "@/pages/home/latest-releases-block/content-items-scroller.vue";
import { ScrollState } from "@/pages/home/latest-releases-block/content-items-scroller.vue";
import { loadHomePageLatestReleases } from "@/actions";

@Component({
  components: {
    ContentItemCard,
    ContentItemsScroller,
  },
})
export default class LatestReleasesBlock extends Vue {
  @Ref("contentItemsScroller")
  contentItemsScroller: ContentItemsScroller;

  scrollState: ScrollState = {
    left: false,
    right: true,
  };

  get contentItems() {
    return this.$store.state.homePage.latestReleases;
  }

  get title() {
    return this.$t("contents.latest_releases");
  }

  goToContentsPage() {
    this.$store.commit(SET_CONTENT_FILTERS, { sort: "-created_at" });
    this.$router.push({ name: "Contents" });
  }

  async created() {
    return loadHomePageLatestReleases();
  }
}
</script>
