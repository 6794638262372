<template>
  <editor-content :editor="editor"></editor-content>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor, EditorContent, FocusPosition, JSONContent } from "@tiptap/vue-2";
import { buildEditor, findInvalidNodes, isValidContent } from "@/editor/editor";
import { v4 as uuidv4 } from "uuid";

@Component({
  components: { EditorContent },
})
export default class TipTapEditor extends Vue {
  @Prop()
  doc: JSONContent;

  @Prop({ default: true })
  editable: boolean;

  editor: Editor = null;

  save() {
    const doc = this.ensureUniqueUids(this.editor.getJSON());
    const html = this.editor.getHTML();
    this.$emit("save", { doc, html });
  }

  // This is to avoid duplicate ids caused by
  // Tiptap keeping same uid when block is split in the middle (even if keepOnSplit is false)
  ensureUniqueUids(doc: JSONContent) {
    const uids = new Set<string>();
    const ensureUniqueUid = (node: JSONContent) => {
      if (node.attrs?.uid) {
        const uid = node.attrs.uid;
        if (uids.has(uid)) {
          // Duplicate uid, generate a new one
          node.attrs.uid = uuidv4();
          uids.add(node.attrs.uid);
        } else {
          uids.add(uid);
        }
      }
      node.content?.forEach(ensureUniqueUid);
    };

    ensureUniqueUid(doc);
    return doc;
  }

  created() {
    let doc = this.doc;
    if (doc && !isValidContent(doc)) {
      const message = this.$t("errors.content_error") as string;
      this.$toast.error(message, { message, position: "top-right" });
      this.$rollbar.error("Invalid document content");
      const invalidNodes = findInvalidNodes(doc);
      if (invalidNodes) {
        console.error("Invalid nodes:", invalidNodes);
      }
      doc = null;
    }
    this.editor = buildEditor(doc, this.editable);
  }

  destroy() {
    this.editor.destroy();
  }

  focus(position: FocusPosition = "start") {
    this.editor.commands.focus(position);
  }
}
</script>

<style scoped></style>
