<template>
  <div :class="'force-' + theme + '-mode'">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { provide } from "vue";

const props = defineProps<{
  theme: string;
}>();

const themeComputed = computed(() => props.theme);

provide("theme", themeComputed);
</script>
