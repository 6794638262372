<template>
  <div class="group flex items-center mr-1 text-text-emphasis sidemenu:mr-2" @click="onClick">
    <icon-fluent class="h-5 w-auto sidemenu:h-7 group-hover:hidden" name="chevronLeft"></icon-fluent>
    <icon-fluent
      class="hidden h-5 w-auto sidemenu:h-7 group-hover:inline-block"
      name="chevronLeft"
      variant="filled"
    ></icon-fluent>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class BackButton extends Vue {
  @Prop()
  to: string;

  onClick() {
    if (this.to) {
      this.$router.push(this.to);
    } else {
      this.$router.back();
    }
  }
}
</script>
