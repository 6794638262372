<template>
  <modal>
    <form name="viewerLinkForm" novalidate @submit.prevent="submit()">
      <modal-header :title="$t('editor.edit_attachment')"></modal-header>
      <modal-body>
        <form-field :label="$t('attributes.title')">
          <input v-model="title" class="title-input" type="text" :placeholder="$t('attributes.title') + '...'" />
        </form-field>
        <form-field :label="$t('file_types.file')">
          <div class="flex justify-between overflow-hidden flex-wrap">
            <a class="link file" :href="url" target="_blank">{{ title }}</a>
            <a class="link" @click="changeFile">{{ $t("actions.change") }}</a>
          </div>
        </form-field>
      </modal-body>
      <modal-footer>
        <button class="btn-primary" type="submit" :disabled="!isValid">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import MediaLibraryModal from "@/components/media-library/media-library-modal.vue";
import ContentBlob from "@/model/content-blob";
import MediaFile from "@/model/media-file";
import { showDialog } from "@/utils/dialogs";
import { filePath } from "@/utils/paths";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class FileModal extends Vue {
  @Prop()
  attrs: any;

  title: string = "";
  blob: ContentBlob = null;

  get isValid() {
    return this.title.length > 0;
  }

  get url() {
    return filePath(this.blob);
  }

  created() {
    this.title = this.attrs.title;
    this.blob = this.attrs.blob;
  }

  submit() {
    const attrs = {
      title: this.title,
      blob: this.blob,
    };
    this.$emit("ok", attrs);
  }

  async changeFile() {
    const mediaFiles = await showDialog<MediaFile[]>(MediaLibraryModal, {
      fileType: "file",
      allowMultiselect: false,
    });
    if (!mediaFiles || mediaFiles.length === 0) {
      return;
    }
    const mediaFile = mediaFiles[0];
    const { key, filename, content_type, metadata } = mediaFile.file;
    this.blob = { key, filename, content_type, metadata };
  }
}
</script>

<style lang="scss" scoped>
.url-input {
  margin-bottom: $spacing;
}
</style>
