<template>
  <div
    class="fixed top-0 left-0 right-0 h-topbar z-30 pl-6 pr-12 pointer-events-none flex items-center sidemenu:ml-sidemenu sidemenu:pr-[250px]"
  >
    <div class="flex-grow-0 overflow-hidden">
      <slot>
        <page-header-title :title="title" :status="status" :back-button-route="backButtonRoute"></page-header-title>
      </slot>
    </div>
    <div v-if="$slots.right" class="flex-grow-1 ml-6 pointer-events-auto">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { RawLocation } from "vue-router";

@Component({})
export default class PageHeader extends Vue {
  @Prop()
  title?: string;

  @Prop()
  status?: string;

  @Prop()
  backButtonRoute?: RawLocation;
}
</script>
