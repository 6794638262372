import ApiService, { ApiServiceOpts } from "@/services/api-service";
import ContentDocument from "@/model/content-document";

export default class Documents extends ApiService<ContentDocument> {
  get baseUrl() {
    return "/api/v1/documents";
  }

  getDocument(params: Record<string, string>, opts: ApiServiceOpts = {}): Promise<ContentDocument> {
    return this.get("document", { ...opts, params });
  }
}
