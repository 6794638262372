<template>
  <page>
    <page-header :title="$t('organizations.title')"></page-header>
    <page-content>
      <div>
        <div class="mb-md">
          <div class="flex flex-1">
            <search-input
              v-model="search"
              class="w-64 mr-md sm:grow-0"
              :placeholder="$t('actions.search')"
              @reset="onReset"
            ></search-input>
            <router-link v-if="$hasRole('osgenic_admin')" class="btn btn-primary" :to="{ name: 'AddOrganization' }">
              {{ $t("actions.add") }}
            </router-link>
          </div>
        </div>
      </div>
      <scroll-detector v-if="loaded" class="mb-28" @end="loadMoreOrganizations">
        <div>
          <list-item v-if="!organizations">
            <loader-big></loader-big>
          </list-item>
          <list-item
            v-for="organization in organizations"
            :key="organization.id"
            @click="gotoOrganization(organization)"
          >
            <div class="text-body-large text-text-primary">{{ organization.name }}</div>
          </list-item>
        </div>
      </scroll-detector>
      <loader-big v-if="loading" class="absolute top-full inset-x-0 mt-lg"></loader-big>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import Organizations from "@/services/organizations";
import Organization from "@/model/organization";

@Component({})
export default class OrganizationsPage extends Vue {
  organizations: Organization[] = null;
  loading: boolean = false;

  @Prop({ default: 2 })
  minSearchLength: number;
  search = "";

  get loaded() {
    return !!this.organizations;
  }

  async created() {
    return this.queryOrganization();
  }

  queryOrganization() {
    this.organizations = null;
    return this.fetchOrganizations();
  }

  loadMoreOrganizations() {
    if (!this.loading) {
      return this.fetchOrganizations();
    }
  }

  async fetchOrganizations() {
    this.loading = true;
    const params: any = { limit: 20, offset: this.organizations?.length || 0 };
    if (this.search.length >= this.minSearchLength) {
      params.q = this.search;
    }
    const newOrganizations = await new Organizations().getAll({ params });
    this.organizations = [...(this.organizations || []), ...newOrganizations];
    this.loading = false;
  }

  gotoOrganization(organization: Organization) {
    this.$router.push({ name: "OrganizationGeneral", params: { id: organization.id.toString() } });
  }

  @Watch("search")
  async onSearch() {
    this.organizations = null;
    await this.fetchOrganizations();
  }

  onReset() {
    this.search = "";
  }
}
</script>

<style lang="scss" scoped>
li {
  list-style-type: none;
}
a:visited {
  color: $grey-lighter;
}
a:link {
  color: $white;
}
</style>
