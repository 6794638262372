<template>
  <logbook-entry-card :entry="entry" :label="label" @edit="edit">
    <section class="logbook-entry-card__section">
      <h4>{{ $t("item.details") }}</h4>
      <div class="name">
        <strong>{{ $t("attributes.name") }}: </strong>{{ entry.data.name }}
      </div>
      <div class="date">
        <strong>{{ $t("attributes.date") }}: </strong>{{ dateFilter(entry.date) }} {{ entry.end_date && "-" }}
        {{ dateFilter(entry.end_date) }}
      </div>
      <div v-if="entry.data.comments" class="comments">
        <strong>{{ $t("attributes.comments") }}: </strong>{{ entry.data.comments }}
      </div>
    </section>
    <section v-if="entry.attachments.length > 0" class="logbook-entry-card__section">
      <h4>{{ $t("attributes.attachments") }}</h4>
      <div v-for="attachment in entry.attachments" :key="attachment.url" class="attachment">
        <a class="link" :href="attachment.url" target="_blank">{{ attachment.filename }}</a>
      </div>
    </section>
  </logbook-entry-card>
</template>

<script lang="ts">
import CourseModal from "@/components/logbook-entry/course-modal.vue";
import { dateFilter } from "@/filters";
import CourseData from "@/model/course-data";
import LogbookEntry from "@/model/logbook-entry";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LogbookCourseCard extends Vue {
  @Prop()
  entry: LogbookEntry<CourseData>;

  get label() {
    return this.entry.data.name;
  }

  edit() {
    showDialog(CourseModal, { logbookEntry: this.entry });
  }

  dateFilter(value: string) {
    return dateFilter(value);
  }
}
</script>
