<template>
  <div v-if="!page" class="bg-surface-high-contrast rounded-md h-72"></div>
  <a v-else-if="viewerLinkNode" class="relative group" :href="viewerUrl" target="_blank" rel="noopener noreferrer">
    <ui-card>
      <div class="aspect-video w-full pt-2 pl-2 relative">
        <div class="h-full w-full rounded-sm bg-interactive-bg-emphasis-variant"></div>
        <div
          class="absolute inset-0 duration-300 pr-2 pb-2 hover-hover:pr-0 hover-hover:pb-0 group-hover:pr-2 group-hover:pb-2"
        >
          <ui-card-image class="relative z-10w w-full h-full" :url="imageUrl"></ui-card-image>
        </div>
        <three-d-badge class="absolute top-4 right-4"></three-d-badge>
        <open-link-badge class="absolute bottom-5 right-6"></open-link-badge>
      </div>
      <div class="px-4 py-5">
        <div class="text-label-large text-text-emphasis">Osgenic Invivo</div>
        <div class="mt-3 text-body-small">{{ $t("home.viewer_description") }}</div>
      </div>
    </ui-card>
  </a>
</template>

<script lang="ts">
import { findNodeOfType } from "@/editor/utils/nodes";
import Page from "@/model/page";
import { filePath } from "@/utils/paths";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class AnatomyCard extends Vue {
  @Prop()
  page: Page;

  get anatomyDocument() {
    return this.page?.documents?.find((d) => d.document_type === "anatomy");
  }

  get viewerLinkNode() {
    if (this.anatomyDocument) {
      return findNodeOfType(this.anatomyDocument.content.doc, "viewerLink");
    }
    return null;
  }

  get imageUrl() {
    return filePath(this.viewerLinkNode?.attrs?.blob, "medium");
  }

  get viewerUrl() {
    return `/viewer/${this.viewerLinkNode?.attrs?.slug}`;
  }
}
</script>
