import { defineStore } from "pinia";
import { ref } from "vue";

export type PaymentMethodType = "card" | "paypal_express_checkout";

export const useSignUpStore = defineStore("signUp", () => {
  const country = ref<string>();
  const period = ref<string>("month");
  const name = ref<string>(null);
  const email = ref<string>(null);
  const password = ref<string>(null);
  const verified_medical_professional = ref<boolean>(false);
  const accept_terms = ref<boolean>(false);
  const sign_up_code = ref<string>(null);
  const payment_intent_id = ref<string>(null);
  const token = ref<string>(null);
  const payment_method = ref<PaymentMethodType>(null);
  const authentication_token = ref<string>(null);

  return {
    country,
    period,
    name,
    email,
    password,
    verified_medical_professional,
    accept_terms,
    sign_up_code,
    payment_intent_id,
    token,
    payment_method,
    authentication_token,
  };
});
