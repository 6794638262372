<template>
  <div class="tab-link" :class="{ removable: removable, resetable: resetable, editable: editable }">
    <router-link v-if="to" class="text-label-small" :to="to" :replace="true">
      <slot></slot>
    </router-link>
    <form v-else-if="editable && editing" @submit.prevent="onChange">
      <input ref="editable_input" class="text-sm" type="text" :value="value" :maxlength="maxLength" @blur="onChange" />
    </form>
    <a
      v-else
      class="text-label-small"
      :class="{ active: active }"
      @click="editable && active ? setEditMode(true) : $emit('click', val)"
    >
      <slot></slot>
    </a>
    <button
      v-if="removable && !editing"
      class="remove-button inline-block p-0 absolute right-0 inset-y-0 hover:text-text-primary"
      type="button"
      @click="$emit('remove', val)"
    >
      <icon-fluent class="h-4 w-4" name="dismiss" variant="filled"></icon-fluent>
    </button>
    <button
      v-if="!removable && resetable && !editing"
      class="reset-button inline-block p-0 absolute right-0 inset-y-0 hover:text-text-primary"
      type="button"
      @click="$emit('reset')"
    >
      <icon-fluent class="h-4 w-4" name="arrowReset" variant="filled"></icon-fluent>
    </button>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

@Component({})
export default class TabLink extends Vue {
  @Ref("editable_input")
  editableInput: HTMLInputElement;

  @Prop()
  to: any;

  @Prop()
  value: any;

  @Prop()
  val: any;

  @Prop({ default: false })
  active: boolean;

  @Prop({ default: false })
  editable: boolean;

  @Prop({ default: false })
  resetable: boolean;

  @Prop({ default: false })
  removable: boolean;

  @Prop({ default: null })
  maxLength: number;

  editing: boolean = false;

  setEditMode(state: boolean) {
    this.editing = state;

    if (state == true) {
      Vue.nextTick(() => {
        this.editableInput.focus();
      });
    }
  }

  onChange() {
    const value = this.editableInput.value;
    this.editing = false;
    this.$emit("input", value);
  }
}
</script>

<style lang="scss" scoped>
.tab-link {
  &.editable {
    a.active {
      cursor: text;
    }

    input {
      padding-top: 0;
      padding-bottom: 0;
      height: 30px;
    }
  }

  &.removable,
  &.resetable {
    margin-right: var(--spacing-xs);

    .remove-button,
    .reset-button {
      display: none;
    }

    &:hover {
      .remove-button,
      .reset-button {
        display: block;
      }
    }
  }

  > a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--ui-color-text-primary);
    white-space: nowrap;
    cursor: pointer;
    border-radius: 9999px;
    background: var(--ui-color-interactive-bg-neutral-low-contrast);
    height: 32px;
    padding: 0 24px;
    transition-duration: 300ms;
    line-height: 1;

    &.router-link-exact-active {
      color: var(--ui-color-text-button-primary);
      background: var(--ui-color-interactive-bg-emphasis);
      font-weight: 700;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--ui-color-text-button-primary);
        background: var(--ui-color-interactive-bg-emphasis);
        font-weight: 700;
      }
    }
  }
}
</style>
