import { createDropHandler } from "@/editor/utils/create-drop-handler";
import { showDialog } from "@/utils/dialogs";
import { Editor } from "@tiptap/core";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import { uploadFileToMediaLibrary } from "@/actions";
import MediaFile from "@/model/media-file";
import FileModal from "@/editor/extensions/file/file-modal.vue";
import FileAttrs from "@/editor/extensions/file/file-attrs";

export const createFileDropHandler = (editor: Editor) => {
  return createDropHandler({
    key: "fileDropHandler",
    editor,
    filter: (file) => !/^(image|video)\//.test(file.type),
    callback: (files) => {
      uploadFiles(files, editor);
    },
  });
};

const uploadFiles = async (files: File[], editor: Editor) => {
  for (const file of files) {
    const action = uploadFileToMediaLibrary(file);
    const mediaFile = await showDialog<MediaFile>(LoadingIndicatorDialog, { action });
    if (mediaFile) {
      const { key, filename, content_type, metadata } = mediaFile.file;
      const attrs: FileAttrs = { title: mediaFile.file_name, blob: { key, filename, content_type, metadata } };
      const fileAttrs = await showDialog<FileAttrs>(FileModal, { attrs });
      if (fileAttrs) {
        editor.commands.addFile(fileAttrs);
      }
    }
  }
  editor.commands.focus();
};
