<template>
  <div class="attachments-input">
    <div v-for="attachment in value" :key="attachment.url" class="attachment">
      <progress-overlay :processing="attachment.uploading">
        <input-list-item icon="paperclip" @remove="remove(attachment)">{{ attachment.filename }}</input-list-item>
      </progress-overlay>
    </div>
    <div class="add-attachment">
      <input-list-item icon="plus-circle" label="Add attachment..."></input-list-item>
      <input ref="fileInput" type="file" @input="onInput" />
    </div>
  </div>
</template>

<script lang="ts">
import UploadService from "@/services/upload-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import Attachment from "@/model/attachment";
import MaybeUploading from "@/model/maybe-uploading";
import { v4 as uuidv4 } from "uuid";

@Component({})
export default class AttachmentsInput extends Vue {
  @Ref() readonly fileInput: HTMLInputElement;

  @Prop()
  value: MaybeUploading<Attachment>[];

  get uploadService() {
    return new UploadService();
  }

  remove(attachment) {
    const attachments = this.value.filter((each) => each !== attachment);
    this.$emit("input", attachments);
  }

  async onInput() {
    if (this.fileInput.files.length > 0) {
      await this.upload(this.fileInput.files[0]);
    }
  }

  async upload(file: File) {
    const tempId = uuidv4();
    const tempAttachment: MaybeUploading<Attachment> = { filename: file.name, tempId, uploading: true };
    this.$emit("input", [...this.value, tempAttachment]);

    let attachments;
    try {
      const { id, filename, content_type, url } = await this.uploadService.uploadFile(file);
      attachments = this.value.map((attachment) =>
        (attachment as MaybeUploading<Attachment>).tempId === tempId
          ? {
              id,
              filename,
              content_type,
              url,
            }
          : attachment
      );
    } catch (err) {
      console.error(err);
      attachments = this.value.filter((attachment) => (attachment as any).tempId !== tempId);
    }

    this.$emit("input", attachments);
  }
}
</script>

<style lang="scss" scoped>
.add-attachment {
  position: relative;
  cursor: pointer;

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
