<template>
  <div
    class="media-list-row h-12 flex items-center border-2"
    :class="selected ? 'border-border-emphasis' : 'border-transparent'"
    @click="$emit('click', $event)"
    @dblclick="$emit('dblclick', $event)"
  >
    <div class="flex-1 flex items-center overflow-hidden nowrap">
      <div
        class="h-8 w-8 mr-sm bg-gray-300 flex flex-row justify-center items-center relative overflow-hidden shrink-0"
      >
        <div v-if="mediaFile.uploading" class="shimmer h-full w-full"></div>
        <img v-else-if="isImage" class="max-w-full max-h-full" :src="thumbUrl" />
        <video v-else-if="isVideo" class="max-w-full max-h-full" :src="url" :type="mediaFile.file.content_type"></video>
      </div>
      <div class="file-name overflow-hidden text-ellipsis">{{ mediaFile.file_name }}</div>
    </div>
    <div class="w-32">{{ type }}</div>
    <div class="w-32">{{ dateFilter(mediaFile.created_at) }}</div>
  </div>
</template>

<script lang="ts">
import { dateFilter } from "@/filters";
import MaybeUploading from "@/model/maybe-uploading";
import MediaFile from "@/model/media-file";
import { filePath } from "@/utils/paths";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class MediaGridCard extends Vue {
  @Prop()
  mediaFile: MaybeUploading<MediaFile>;

  @Prop()
  selected: boolean;

  get isImage() {
    return this.mediaFile.file_type.startsWith("image");
  }

  get isVideo() {
    return this.mediaFile.file_type.startsWith("video");
  }

  get url() {
    return filePath(this.mediaFile.file);
  }

  get thumbUrl() {
    return filePath(this.mediaFile.file, "thumb");
  }

  get type() {
    if (this.isImage) {
      return this.$t("file_types.image");
    } else if (this.isVideo) {
      return this.$t("file_types.video");
    } else {
      return this.$t("file_types.file");
    }
  }

  dateFilter(value: string) {
    return dateFilter(value);
  }
}
</script>

<style lang="scss" scoped>
.shimmer {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1s infinite;
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
.file-name {
  max-width: 250px;

  @media (min-width: $screen-md-min) {
    max-width: 420px;
  }
}
</style>
