import Cookies from "js-cookie";
import i18n from "./i18n";
import placeholderDark from "@/assets/images/Placeholder_Dark_Asset.png";
import placeholderLight from "@/assets/images/Placeholder_Light_Asset.png";
import { Ref, computed, inject, ref, watch } from "vue";
import { useMediaQuery } from "@vueuse/core";

export type Theme = "light" | "dark";

// Here we preserve users theme selection
// light, dark or null (system default)
const currentTheme = ref<Theme>(null);

// Method to calculate active (effective) theme
export const getActiveTheme = (): Theme => {
  const prefersDark = useMediaQuery("(prefers-color-scheme: dark)").value;

  if (currentTheme.value === "dark" || (currentTheme.value === null && prefersDark)) {
    console.log(currentTheme.value, prefersDark);
    return "dark";
  } else {
    return "light";
  }
};

// Computed property to get active theme
export const activeTheme = computed(() => getActiveTheme());

// Update body class when active theme changes
watch(
  activeTheme,
  (theme) => {
    // set class to html element
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  },
  { immediate: true }
);

export const useTheme = () => inject("theme") as Ref<Theme>;

export const availableThemes = () => [
  [null, i18n.t("color_theme.system")],
  ["light", i18n.t("color_theme.light")],
  ["dark", i18n.t("color_theme.dark")],
];

export const setTheme = (theme: Theme) => {
  // set it null if undefined
  if (!theme || (theme as string) === "null") {
    theme = null;
  }

  currentTheme.value = theme;

  // Save theme setting to cookie also
  if (currentTheme.value) {
    Cookies.set("theme", currentTheme.value);
  } else {
    Cookies.remove("theme");
  }
};

export const setInitialTheme = () => {
  setTheme(Cookies.get("theme"));
};

export const getActiveThemePreviewPlaceholder = () => {
  const activeTheme = getActiveTheme();
  if (activeTheme === "dark") {
    return placeholderDark;
  } else {
    return placeholderLight;
  }
};
