<template>
  <div v-if="comments" class="comments-section">
    <div class="comments-section__add-comment">
      <avatar :user="$currentUser" :placeholder-show-initials="true"></avatar>
      <add-comment-form @add-comment="onAddComment"></add-comment-form>
    </div>
    <comment v-for="comment in comments" :key="comment.id" :comment="comment"></comment>
  </div>
  <div v-else class="comments-section">
    <loader-big :fill-color="'#b5b7bf'"></loader-big>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Comment from "@/model/comment";
import CommentsService from "@/services/comments-service";
import CommentNotification from "@/model/comment-notification";
import CommentNotificationsService from "@/services/comment-notifications-service";
import { SET_COMMENT_NOTIFICATIONS } from "@/store/mutations";
import AddCommentForm from "@/components/comments/add-comment-form.vue";
import { showDialog } from "@/utils/dialogs";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";

@Component({
  components: { AddCommentForm },
})
export default class CommentsSection extends Vue {
  @Prop()
  commentableType: string;

  @Prop()
  commentableId: number;

  comments: Comment[] = null;

  get commentsService() {
    return new CommentsService();
  }

  get commentableParams() {
    return {
      commentable_type: this.commentableType,
      commentable_id: this.commentableId.toString(),
    };
  }

  get notifications(): CommentNotification[] {
    return (this.$store.getters.commentNotifications || []).filter(
      (notification: CommentNotification) =>
        notification.comment.commentable_type === this.commentableType &&
        notification.comment.commentable_id === this.commentableId
    );
  }

  created() {
    this.fetchComments();
  }

  async onAddComment(attrs: Partial<Comment>) {
    const commentParams = { ...this.commentableParams, ...attrs };
    const action = this.commentsService.create(commentParams);
    const comment = await showDialog<Comment>(LoadingIndicatorDialog, { action });
    this.comments = [comment, ...this.comments];
  }

  @Watch("notifications")
  async fetchComments() {
    this.comments = await this.commentsService.getAll({ params: this.commentableParams });

    this.acknowledgeNotifications();
  }

  async acknowledgeNotifications() {
    const notificationIds = this.notifications.map((notification: CommentNotification) => notification.id);

    if (notificationIds.length > 0) {
      notificationIds.forEach((id) => {
        new CommentNotificationsService().update(id, { acknowledged: true });
      });
      const notifications = this.$store.getters.commentNotifications.filter(
        (notification) => !notificationIds.includes(notification.id)
      );
      this.$store.commit(SET_COMMENT_NOTIFICATIONS, notifications);
    }
  }
}
</script>

<style lang="scss" scoped>
.comments-section {
  &__add-comment {
    display: flex;
    margin: var(--spacing) 0;

    > *:nth-child(2) {
      flex: 1;
      margin-left: var(--spacing-sm);
    }
  }
}
</style>
