<template>
  <card v-if="organization" class="mb-md">
    <dl class="organization__details">
      <dt>{{ $t("organization.title") }} ID</dt>
      <dd>{{ organization.id }}</dd>
      <dt>{{ $t("organization.title") }}</dt>
      <dd>{{ organization.name }}</dd>
    </dl>

    <div class="organization__edit-menu">
      <dropdown :up="true" :right="true">
        <div slot="dropdownButton" class="organization__dropdown-button">
          <div class="cursor-pointer p-4">
            <icon icon="cogs" />
          </div>
        </div>
        <div class="organization__dropdown-content">
          <router-link :to="{ name: 'OrganizationEdit' }">
            <icon icon="edit" />
            <span>
              {{ $t("actions.edit") }}
            </span>
          </router-link>
        </div>
      </dropdown>
    </div>
  </card>
</template>

<script lang="ts" setup>
import { computedAsync } from "@vueuse/core";
import { defineProps } from "vue";
import Organizations from "@/services/organizations";
import Icon from "@/components/icon.vue";

const props = defineProps<{
  organizationId: string;
}>();

const organization = computedAsync(() => new Organizations().get(props.organizationId));
</script>
