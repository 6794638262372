<template>
  <node-view-wrapper as="span">
    <span v-if="editor.isEditable" data-drag-handle="">
      <resizeable v-if="node.attrs.blob" :enabled="selected" :value="node.attrs.image_width" @input="onWidthChanged">
        <viewer-buttons
          v-bind="viewerButtonAttrs"
          :is-editable="editor.isEditable"
          :is-selected="selected"
          @click="onEditClick"
          @click.native.prevent
        ></viewer-buttons>
      </resizeable>
      <viewer-buttons v-else v-bind="viewerButtonAttrs" @click.native.prevent="onEditClick"></viewer-buttons>
    </span>
    <viewer-buttons v-else v-bind="viewerButtonAttrs"></viewer-buttons>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";
import { showDialog } from "@/utils/dialogs";
import ViewerLinkModal from "@/editor/extensions/viewer-link/viewer-link-modal.vue";
import ViewerButtons from "@/editor/extensions/viewer-link/viewer-buttons.vue";
import ViewerLink from "@/editor/extensions/viewer-link/viewer-link";

@Component({
  components: { NodeViewWrapper, ViewerButtons },
  props: nodeViewProps,
})
export default class ViewerLinkNodeView extends Vue {
  @Prop()
  editor: Editor;

  @Prop()
  node: any;

  @Prop()
  selected: boolean;

  @Prop()
  getPos: () => number;

  @Prop()
  updateAttributes: (attrs: any) => void;

  get viewerButtonAttrs() {
    const { title, slug, blob, image_width } = this.node.attrs;
    const url = `/viewer/${slug}`;
    return { url, title, blob, image_width };
  }

  onWidthChanged(image_width) {
    this.updateAttributes({ image_width });

    // reselect this node (updateAttributes clear selection)
    this.editor.commands.setNodeSelection(this.getPos());
  }

  async onEditClick() {
    const viewerLink = await showDialog<ViewerLink>(ViewerLinkModal, { viewerLink: this.node.attrs });
    if (viewerLink) {
      this.editor
        .chain()
        .updateViewerLink({ ...this.node.attrs, ...viewerLink })
        .focus()
        .run();
    }
  }
}
</script>
