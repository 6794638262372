<template>
  <node-view-wrapper>
    <node-view-content class="image-row flex"></node-view-content>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { nodeViewProps, NodeViewWrapper, NodeViewContent } from "@tiptap/vue-2";

@Component({
  components: { NodeViewWrapper, NodeViewContent },
  props: nodeViewProps,
})
export default class ImageRowNodeView extends Vue {
  @Prop()
  node: any;
}
</script>

<style lang="scss" scoped>
.image-row::v-deep {
  > * {
    // Set default width to all elements in row to 0
    width: 0;
    flex-basis: 0;
    flex-grow: 0;
  }

  > .image-node-view {
    // Default flex-grow for images (overriden in image-node-view based on image aspect ratio)
    flex-grow: 1;

    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}
</style>
