<template>
  <page>
    <page-header :title="'Osgenic Oy - Privacy Statement'"> </page-header>
    <page-content class="py-8 px-9 max-w-3xl">
      <h3 class="my-8">1. Introduction</h3>
      <p class="my-4">
        Osgenic Oy (“Osgenic”, “we”) processes personal data of the end-users of Osgenic’s service made available at
        osgenic.com (“Service”).
      </p>
      <p class="my-4">
        In this Privacy Statement, the word “User” or “you” refers jointly to the representatives and other authorized
        users of our customer organizations using the Service, representatives of our potential customers and the
        visitors of the Website.
      </p>
      <p class="my-4">
        Our Privacy Statement explains, for example, the types of personal data we process, how we process the personal
        data and how you may exercise your rights as a data subject (e.g. right to object, right of access).
      </p>
      <p class="my-4">
        This Privacy Statement may be updated from time to time in order to reflect the changes in data processing
        practices or otherwise. You can find the current version on the Website. We will not make substantial changes to
        this Privacy Statement or reduce the rights of the Users under this Privacy Statement without providing a notice
        thereof.
      </p>
      <p class="my-4">
        This Privacy Statement applies to processing of personal data carried out by Osgenic as data controller.
      </p>
      <h3 class="my-8">2. Osgenic contact details</h3>
      <ul class="text-xs sm:text-sm">
        <li>Name: Osgenic Oy</li>
        <li>Business ID: 2851145-1</li>
        <li>Correspondence address: Lapinlahdenkatu 16, 00180 Helsinki</li>
        <li>Contact person: Arne Schlenzka, arne.schlenzka@osgenic.com</li>
      </ul>
      <h3 class="my-8">3. Personal data processed and sources of data</h3>
      <p class="my-4">
        The personal data collected and processed by us can be divided into two general data categories: User Data and
        Analytics Data.
      </p>
      <h4 class="my-4">User data</h4>
      <p class="my-4">
        User Data is personal data collected directly from you or from our customer organization on behalf of which you
        are using the Service (“Customer Organization”), as the case may be. We may collect User Data from our Users and
        Customer Organizations in a variety of ways, including, after conclusion of a service agreement with the
        Customer Organization or when Users register to or use the Service.
      </p>
      <h4 class="my-4">User Data that is necessary in order to use the Service</h4>
      <p class="my-4">
        The following personal data collected and processed by us is necessary for our legitimate interest whilst
        fulfilling our contractual obligations towards our Customer Organization and you and for the compliance with our
        legal obligations.
      </p>
      <p class="my-4">When User registers to the Service, we collect the following information:</p>
      <ul class="text-xs sm:text-sm">
        <li>full name</li>
        <li>role</li>
        <li>speciality</li>
        <li>email address</li>
        <li>organization</li>
      </ul>
      <h4 class="my-4">User Data you give us voluntarily</h4>
      <p class="my-4">Other Information. We may also process other information provided by you voluntarily such as:</p>
      <ul class="text-xs sm:text-sm">
        <li>marketing opt-ins and opt-outs</li>
        <li>profile image</li>
        <li>data on your use of and interaction with the Osgenic Services</li>
        <li>information you provide in correspondence with us</li>
      </ul>
      <h4 class="my-8">Analytics data</h4>
      <p class="my-4">
        Although we do not normally use Analytics Data to identify you as an individual, you can sometimes be recognized
        from it, either alone or when combined or linked with User Data. In such situations, Analytics Data can also be
        considered personal data under applicable laws and we will treat such data as personal data. We may
        automatically collect the following Analytics Data when you visit or interact with the Osgenic Services.
      </p>
      <h4 class="my-4">Device information</h4>
      <p class="my-4">
        We collect the following information relating to the technical device you use when using the Osgenic Services:
      </p>
      <ul class="text-xs sm:text-sm">
        <li>device and device identification number, device IMEI</li>
        <li>country</li>
        <li>IP address</li>
        <li>browser type and version</li>
        <li>operating system</li>
        <li>Internet service providers</li>
        <li>advertising identifier of your device</li>
        <li>visitor identifier</li>
      </ul>
      <h4 class="my-4">Usage information</h4>
      <p class="my-4">We collect information on your use of the Osgenic Services, such as:</p>
      <ul class="text-xs sm:text-sm">
        <li>time spent on the Osgenic Services</li>
        <li>interaction with the Osgenic Services</li>
        <li>the URL of the website you visited before and after visiting the Osgenic Services</li>
        <li>the time and date of your visits to the Osgenic Services</li>
        <li>the sections of the Osgenic Services you visited</li>
      </ul>
      <h4 class="my-4">Cookies</h4>
      <p class="my-4">
        We use various technologies to collect and store Analytics Data and other information when the Users visit the
        Osgenic Services, including cookies and web beacons.
      </p>
      <p class="my-4">
        Cookies are small text files sent and saved on your device that allows us to identify visitors of the Osgenic
        Services and facilitate the use of the Osgenic Services and to create aggregate information of our visitors.
        This helps us to improve the Osgenic Services and better serve our Users. The cookies will not harm your device
        or files. We use cookies to tailor the Osgenic Services and the information we provide in accordance with the
        individual interests of our Users.
      </p>
      <p class="my-4">
        The Users may choose to consent to cookies when opening the web page, or set their web browser to refuse
        cookies, or to alert when cookies are being sent. For example, the following links provide information on how to
        adjust the cookie settings on some popular browsers:
      </p>
      <ul class="text-xs sm:text-sm">
        <li>Safari</li>
        <li>Google Chrome</li>
        <li>Internet Explorer</li>
        <li>Mozilla Firefox</li>
      </ul>
      <p class="my-4">
        Please note that some parts of the Osgenic Services may not function properly if use of cookies is refused. A
        web beacon is a technology that allows identifying readers of websites and emails e.g. for identifying whether
        an email has been read. See Osgenic cookie list and policy here
      </p>
      <h3 class="my-8">4. Purposes and legitimate grounds for the processing of your personal data</h3>
      <h4 class="my-4">Purposes</h4>
      <p class="my-4">There are several purposes of the processing of your personal data by Osgenic:</p>
      <p class="my-4">To provide the Osgenic Services and carry out our contractual obligations</p>
      <p class="my-4">
        Osgenic processes your personal data to be able to offer the Service to you under the contract between Customer
        Organization and Osgenic.
      </p>
      <p class="my-4">
        We use the data for example to handle your payments or any refunds (where applicable). If you contact our
        customer service, we will use the information provided by you to answer your questions or solve your complaint.
      </p>
      <h4 class="my-4">For our legal obligations</h4>
      <p class="my-4">
        Osgenic processes data to enable us to administer and fulfil our obligations under law. This includes data
        processed for complying with our bookkeeping obligations and providing information to relevant authorities such
        as tax authorities.
      </p>
      <h4 class="my-4">For claims handling and legal processes</h4>
      <p class="my-4">
        Osgenic may process personal data in relation to claims handling, debt collection and legal processes. We may
        also process data for the prevention of fraud, misuse of our services and for data, system and network security.
      </p>
      <h4 class="my-4">For customer communication and marketing</h4>
      <p class="my-4">
        Osgenic processes your personal data to contact you regarding the Osgenic Services and to inform you of changes
        relating to them. Your personal data are also used for the purposes of marketing the Osgenic Services to you.
      </p>
      <h4 class="my-4">For quality improvement and trend analysis</h4>
      <p class="my-4">
        We may also process information about your use of the Osgenic Services to improve the quality of the Osgenic
        Services e.g. by analyzing any trends in the use of the Osgenic Services. In order to ensure that our services
        are in line with your needs, personal data can be used for things like customer satisfaction surveys. When
        possible, we will do this using only aggregated, non-personally identifiable data.
      </p>
      <h4 class="my-4">Legal grounds for processing</h4>
      <p class="my-4">
        Osgenic processes your personal data to comply with legal obligations. Furthermore, we process your personal
        data to pursue our legitimate interest whilst fulfilling our contractual obligations towards our Customer
        Organization as well as our legitimate interest to run, maintain and develop our business and to create and
        maintain customer relationships. When choosing to use your data on the basis of our legitimate interests, we
        weigh our own interests against your right to privacy and e.g. provide you with easy to use opt-out from our
        marketing communications and use pseudonymized or non-personally identifiable data when possible. In some parts
        of the Osgenic Services, you may be requested to grant your consent for the processing of personal data. In this
        event, you may withdraw your consent at any time.
      </p>
      <h3 class="my-8">5. Transfer to countries outside Europe</h3>
      <p class="my-4">
        Osgenic stores your personal data primarily within the European Economic Area. However, we have service
        providers and operations in several geographical locations. As such, we and our service providers may transfer
        your personal data to, or access it in, jurisdictions outside the European Economic Area or the User’s domicile.
      </p>
      <p class="my-4">
        We will take steps to ensure that the Users’ personal data receives an adequate level of protection in the
        jurisdictions in which they are processed. We provide adequate protection for the transfers of personal data to
        countries outside of the European Economic Area through a series of agreements with our service providers.
      </p>
      <p class="my-4">
        More information regarding the transfers of personal data may be obtained by contacting us on any of the
        addresses indicated above.
      </p>
      <h3 class="my-8">6. Recipients</h3>
      <p class="my-4">
        We only share your personal data within the organization of Osgenic if and as far as reasonably necessary for
        the purposes of this Privacy Statement. We do not share your personal data with third parties outside of
        Osgenic’s organization unless one of the following circumstances applies:
      </p>
      <h4 class="my-4">For the purposes set out in this Privacy Statement and to authorized service providers</h4>
      <p class="my-4">
        To the extent that third parties (such as the user administrators of the Service) need access to personal data
        in order for us to perform the Osgenic Services, we provide such third parties with your data. Furthermore, we
        may provide your personal data to authorized service providers who perform services for us (including data
        storage, accounting, sales and marketing) to process it for us and to payment service providers to process your
        payments to us.
      </p>
      <p class="my-4">
        When data is processed by third parties on behalf of Osgenic, Osgenic has taken the appropriate contractual and
        organizational measures to ensure that your data are processed exclusively for the purposes specified in this
        Privacy Statement and in accordance with all applicable laws and regulations and subject to our instructions and
        appropriate obligations of confidentiality and security measures.
      </p>
      <h4 class="my-4">For legal reasons and legal processes</h4>
      <p class="my-4">
        We may share your personal data with third parties outside Osgenic if we have a good-faith belief that access to
        and use of the personal data is reasonably necessary to: (i) meet any applicable law, regulation, and/or court
        order; (ii) detect, prevent, or otherwise address fraud, crime, security or technical issues; and/or (iii)
        protect the interests, properties or safety of Osgenic, the Users, Customer Organization or the public as far as
        in accordance with the law. When possible, we will inform you about such processing.
      </p>
      <h4 class="my-4">For other legitimate reasons</h4>
      <p class="my-4">
        If Osgenic is involved in a merger, acquisition or asset sale, we may transfer your personal data to the third
        party involved. However, we will continue to ensure the confidentiality of all personal data. We will give
        notice to all the Users concerned when the personal data are transferred or become subject to a different
        privacy statement.
      </p>
      <h4 class="my-4">With your explicit consent</h4>
      <p class="my-4">
        We may share your personal data with third parties outside Osgenic when we have your explicit consent to do so.
        You have the right to withdraw this consent at all times free of charge by contacting us.
      </p>
      <h3 class="my-8">7. Storage period</h3>
      <p class="my-4">
        Osgenic does not store your personal data longer than is legally permitted and necessary for the purposes of
        this Privacy Statement. The storage period depends on the nature of the information and on the purposes of
        processing. The maximum period may therefore vary per use.
      </p>
      <p class="my-4">
        Most personal data relating to a User’s user account with the Service will be deleted after a period of 180 days
        have lapsed after the User has deleted its user account with the Service. Thereafter, a part of the personal
        data relating to a User’s user account with the Service may be stored only as long as such processing is
        required by law or is reasonably necessary for our legal obligations or legitimate interests such as claims
        handling, bookkeeping, internal reporting and reconciliation purposes.
      </p>
      <p class="my-4">
        We will store Analytics Data of Users that do not have an account with the Service for a period of 180 days.
      </p>
      <h3 class="my-8">8. Your rights</h3>
      <h4 class="my-4">Right of access</h4>
      <p class="my-4">
        You have the right to access and be informed about your personal data processed by us. We give you the
        possibility to view certain data through your user account with the Service or request a copy of your personal
        data.
      </p>
      <h4 class="my-4">Right to withdraw consent</h4>
      <p class="my-4">
        In case the processing is based on a consent granted by the User, the User may withdraw the consent at any time
        free of charge. Withdrawing a consent may lead to fewer possibilities to use the Osgenic Services. The
        withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal.
      </p>
      <h4 class="my-4">Right to rectify</h4>
      <p class="my-4">
        You have the right to have incorrect or incomplete personal data we have stored about you corrected or completed
        by contacting us. You can correct or update some of your personal data through your user account in the Service.
      </p>
      <h4 class="my-4">Right to erasure</h4>
      <p class="my-4">
        You may also ask us to delete your personal data from our systems. We will comply with such request unless we
        have a legitimate ground to not delete the data.
      </p>
      <h4 class="my-4">Right to object</h4>
      <p class="my-4">
        You may have the right to object to certain use of your personal data if such data are processed for other
        purposes than necessary for the performance of the Osgenic Services or for compliance with a legal obligation.
        If you object to the further processing of your personal data, this may lead to fewer possibilities to use the
        Osgenic Services.
      </p>
      <h4 class="my-4">Right to restriction of processing</h4>
      <p class="my-4">
        You may request us to restrict processing of personal data for example when your data erasure, rectification or
        objection requests are pending and/or when we do not have legitimate grounds to process your data. This may
        however lead to fewer possibilities to use the Osgenic Services.
      </p>
      <h4 class="my-4">Right to data portability</h4>
      <p class="my-4">
        You have the right to receive the personal data you have provided to us yourself in a structured and commonly
        used format and to independently transmit those data to a third party.
      </p>
      <h4 class="my-4">How to use your rights</h4>
      <p class="my-4">
        The abovementioned rights may be used by sending a letter or an e-mail to us on the addresses set out above,
        including the following information: the full name, address, e-mail address and a phone number. We may request
        the provision of additional information necessary to confirm the identity of the User. We may reject or charge
        requests that are unreasonably repetitive, excessive or manifestly unfounded.
      </p>
      <h3 class="my-8">9. Direct marketing</h3>
      <p class="my-4">
        The User has the right to prohibit us from using the User’s personal data for direct marketing purposes, market
        research and profiling made for direct marketing purposes by contacting us on the addresses indicated above or
        by using the unsubscribe possibility offered in connection with any direct marketing messages.
      </p>
      <h3 class="my-8">10. Lodging a complaint</h3>
      <p class="my-4">
        In case the User considers our processing of personal data to be inconsistent with the applicable data
        protection laws, the User may lodge a complaint with the local supervisory authority for data protection in
        Finland, the Data Protection Ombudsman (https://www.tietosuoja.fi/). Alternatively, the User may lodge a
        complaint with the local supervisory authority for data protection of the User’s country of domicile.
      </p>
      <h3 class="my-8">11. Information security</h3>
      <p class="my-4">
        We use administrative, organizational, technical, and physical safeguards to protect the personal data we
        collect and process. Measures include for example, where appropriate, encryption, pseudonymization, firewalls,
        secure facilities and access right systems. Our security controls are designed to maintain an appropriate level
        of data confidentiality, integrity, availability, resilience and ability to restore the data. We regularly test
        the Osgenic Services, systems, and other assets for security vulnerabilities.
      </p>
      <p class="my-4">
        Should despite of the security measures, a security breach occur that is likely to have negative effects to the
        privacy of the Users, we will inform the relevant Users and other affected parties, as well as relevant
        authorities when required by applicable data protection laws, about the breach as soon as possible.
      </p>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class PrivacyStatementPage extends Vue {}
</script>
