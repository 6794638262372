<template>
  <theme-provider theme="dark" class="fixed inset-0 z-50">
    <div
      v-lazy:background-image="backgroundImage"
      class="h-full min-h-full flex flex-col items-center overflow-y-auto relative bg-cover px-5 opacity-0 duration-500"
      :class="{ 'opacity-100': show }"
    >
      <div class="w-full min-h-full flex flex-col sm:w-[620px]">
        <component
          :is="currentComponent"
          v-if="!isLoading"
          :period="period"
          :plans="plans"
          :sign-up-code="signUpCode"
          @select-period="selectPeriod"
          @back="back"
          @review="review"
          @done="done"
        />
      </div>
    </div>
  </theme-provider>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import ChoosePlanView from "./choose-plan-view.vue";
import ReviewPaymentMethodView from "./review-payment-method-view.vue";
import WelcomeBackView from "./welcome-back-view.vue";
import backgroundImage from "@/assets/images/signup-background.png";
import { useCurrentUser } from "@/composables/session";
import { usePlanPrices } from "@/api/plan-prices";
import { useSignUpCode } from "@/api/sign-up-codes";

const currentComponent = ref<any>(ChoosePlanView);

const period = ref<string>("month");

const show = ref(false);

const user = useCurrentUser();

const { data: plans, isLoading: isLoadingPlanPrices } = usePlanPrices();

const { data: signUpCode, isLoading: isLoadingSignUpCode } = useSignUpCode(user.value.sign_up_code);

const isLoading = computed(() => isLoadingPlanPrices.value || isLoadingSignUpCode.value);

const back = () => {
  currentComponent.value = ChoosePlanView;
};

const selectPeriod = (p: string) => {
  period.value = p;
};

const review = () => {
  currentComponent.value = ReviewPaymentMethodView;
};

const done = () => {
  currentComponent.value = WelcomeBackView;
};

onMounted(() => {
  show.value = true;
});
</script>
