<template>
  <div class="flex flex-row py-sm border-t">
    <div class="mr-md mt-sm">{{ optionNumber }}</div>
    <div class="flex-1">
      <image-text-input
        v-model="option.text"
        class="mb-sm"
        :auto-focus="true"
        :invalid="$v.option.text.$error"
        :placeholder="$t('question.enter_option')"
        :image-button-title="$t('question.add_option_image')"
        @upload="onImageUploaded"
      ></image-text-input>
      <div v-if="option.image" class="mb-sm">
        <removable-image :image="option.image" @remove="onRemoveImage"></removable-image>
      </div>
      <image-text-input
        v-model="option.explanation"
        class="mb-sm"
        :invalid="$v.option.explanation.$error"
        :placeholder="$t('question.enter_option_explanation')"
        @upload="onExplanationImageUploaded"
      ></image-text-input>
      <div v-if="option.explanation_image" class="mb-sm">
        <removable-image :image="option.explanation_image" @remove="onRemoveExplanationImage"></removable-image>
      </div>
      <div>
        <label>
          <input v-model="option.is_correct_answer" class="mr-sm" type="checkbox" :disabled="onlyContentEditable" />{{
            $t("question.is_correct_answer")
          }}
        </label>
      </div>
    </div>
    <div class="ml-2">
      <button
        v-if="!onlyContentEditable"
        class="btn-icon btn-tertiary"
        :title="$t('question.remove_option')"
        @click.stop="$emit('remove')"
      >
        <icon icon="times"></icon>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import Attachment from "@/model/attachment";
import QuizOption from "@/model/quiz-option";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";

const alpha = Array.from(Array(26)).map((e, i) => String.fromCharCode(i + 65));

@Component({})
export default class OptionCard extends Vue {
  @Prop()
  option: QuizOption;

  @Prop()
  optionIndex: number;

  @Prop({ default: false })
  onlyContentEditable: boolean;

  @Validations()
  validations = {
    option: {
      text: {
        required() {
          // require either text or image
          return this.option.image || this.option.text?.trim()?.length > 0;
        },
      },
      explanation: {
        required() {
          // require either explanation text or image
          return this.option.explanation_image || this.option.explanation?.trim()?.length > 0;
        },
      },
    },
  };

  get optionNumber() {
    return `${alpha[this.optionIndex]}.`;
  }

  validate() {
    this.$v.$touch();
    return !this.$v.$error;
  }

  onImageUploaded(image: Attachment) {
    this.option.image = image;
  }

  onRemoveImage() {
    this.option.image = null;
  }

  onExplanationImageUploaded(image: Attachment) {
    this.option.explanation_image = image;
  }

  onRemoveExplanationImage() {
    this.option.explanation_image = null;
  }
}
</script>
