<template>
  <span class="duration-diff">{{ durationDiff }} {{}}</span>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import dayjs from "dayjs";

@Component({})
export default class DateDiff extends Vue {
  @Prop()
  start: string;

  @Prop()
  end: string;

  get durationDiff() {
    const minutes = dayjs(this.end).diff(this.start, "minute");
    return this.$t("format.minutes", { minutes });
  }
}
</script>
