<template>
  <div class="typeahead-input">
    <input-list-item
      v-for="item in selectedItems"
      :key="item.label"
      :icon="itemIcon"
      :label="item.label"
      @remove="removeItem(item)"
    ></input-list-item>
    <input-icon slot="dropdownButton" icon="plus-circle">
      <input v-model="inputValue" type="text" :placeholder="placeholder" @input="$emit('input', inputValue)" />
      <div v-if="matchingItems" class="typeahead-items z-10">
        <div v-for="item in matchingItems" :key="item.label" class="typeahead-item" @click="addItem(item)">
          {{ item.label }}
        </div>
      </div>
    </input-icon>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TypeaheadItem } from "@/model/typeahead-item";

@Component({})
export default class TypeaheadInput<Value> extends Vue {
  @Prop()
  placeholder: string;

  @Prop()
  itemIcon: string;

  @Prop()
  selectedItems: TypeaheadItem<Value>[];

  @Prop()
  matchingItems: TypeaheadItem<Value>[];

  inputValue = "";

  addItem(item) {
    this.inputValue = "";
    this.$emit("input", "");
    this.$emit("updated", [...this.selectedItems, item]);
  }

  removeItem(item) {
    const items = this.selectedItems.filter(({ value }) => value != item.value);
    this.$emit("updated", items);
  }
}
</script>

<style lang="scss" scoped>
.typeahead-items {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: var(--ui-color-surface-high-contrast);
  border-radius: var(--border-radius-sm);
  margin-top: var(--spacing-sm);

  .typeahead-item {
    padding: $input-padding;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    &:hover {
      color: var(--ui-color-text-primary);
    }
  }
}
</style>
