<template>
  <div class="sm:mx-4">
    <div class="sm:mx-4">
      <h3>{{ $t("plan.change_your_plan") }}</h3>
      <div class="text-label-medium text-text-primary mt-2">
        {{
          subscription.scheduled_plan_price_id
            ? $t("plan.review_upcoming_plan_subtitle")
            : $t("plan.change_your_plan_subtitle")
        }}
      </div>
    </div>
    <div class="flex flex-row gap-2 sm:gap-6 mt-6">
      <plan-card
        class="flex-1 min-w-0"
        :plan-price="monthlyPlan"
        :subscription="subscription"
        :selected-plan-price-id="selectedPlanId"
        @click.native="selectPlan(monthlyPlan)"
      />
      <plan-card
        class="flex-1 min-w-0"
        :plan-price="yearlyPlan"
        :subscription="subscription"
        :selected-plan-price-id="selectedPlanId"
        :discount-percentage="yearlyPlanDiscount"
        @click.native="selectPlan(yearlyPlan)"
      />
    </div>
    <div class="w-full flex flex-col gap-2 bg-surface-min-contrast sm:bg-transparent rounded-sm mt-8 sm:px-12">
      <info-text :text="$t('signup.choose_your_plan.unlimited_access_to_content')" />
      <info-text :text="$t('signup.choose_your_plan.invivo_anatomy')" />
      <info-text :text="$t('signup.choose_your_plan.upload_content_and_take_notes')" />
      <info-text :text="$t('signup.choose_your_plan.create_own_anatomy_illustrations')" />
    </div>
    <div v-if="subscription.scheduled_plan_price_id" class="mt-12 flex justify-center gap-4">
      <button class="btn-secondary" @click="discardScheduledChange">{{ $t("plan.discard_upcoming_plan") }}</button>
      <button class="btn-primary" @click="close">
        {{ $t("plan.keep_upcoming_plan") }}
      </button>
    </div>
    <div v-else class="mt-12 flex justify-center gap-4">
      <button class="btn-secondary" @click="close">{{ $t("plan.keep_current_plan") }}</button>
      <button class="btn-primary" :disabled="!selectedPlanChanged" @click="checkout">
        {{ $t("plan.checkout") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useActiveSubscription } from "@/composables/session";
import { useArrayFind } from "@vueuse/core";
import { computed } from "vue";
import PlanPrice, { countDiscountPercentage } from "@/model/plan-price";
import PlanCard from "./plan-card.vue";

const props = defineProps<{
  selectedPlanId: string;
  plans: PlanPrice[];
}>();

const emit = defineEmits(["select-plan", "close", "checkout", "discard-scheduled-change"]);

const subscription = useActiveSubscription();

const plans = computed(() => props.plans);

const monthlyPlan = useArrayFind(plans, (p) => p.period === "month");

const yearlyPlan = useArrayFind(plans, (p) => p.period === "year");

const yearlyPlanDiscount = computed(() => countDiscountPercentage(yearlyPlan.value, monthlyPlan.value));

const selectedPlanChanged = computed(() => {
  return props.selectedPlanId !== subscription.value.plan_price_id;
});

const selectPlan = (plan: PlanPrice) => {
  emit("select-plan", plan);
};

const close = () => emit("close");

const checkout = () => emit("checkout");

const discardScheduledChange = () => emit("discard-scheduled-change");
</script>
