<template>
  <div v-if="curriculum && entries" class="logbook-curriculum-page">
    <clinical-block-card
      v-for="clinicalBlock in curriculum.clinical_blocks"
      :key="clinicalBlock.id"
      :clinical-block="clinicalBlock"
      :entries-by-curriculum-item="entriesByCurriculumItem"
      :user="user"
    ></clinical-block-card>
  </div>
</template>

<script lang="ts">
import Curriculum from "@/model/curriculum";
import LogbookEntry from "@/model/logbook-entry";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ClinicalBlockCard from "@/components/logbook-curriculum/clinical-block-card.vue";
import groupBy from "lodash/groupBy";
import User from "@/model/user";

@Component({
  components: {
    ClinicalBlockCard,
  },
})
export default class LogbookCurriculumPage extends Vue {
  @Prop()
  curriculum: Curriculum;

  @Prop()
  entries: LogbookEntry<object>[];

  @Prop()
  user: User;

  get entriesByCurriculumItem() {
    return groupBy(this.entries, "curriculum_item_id");
  }
}
</script>

<style lang="scss" scoped>
.card:not(:last-child) {
  margin-bottom: var(--spacing);
}
</style>
