<template>
  <div class="contents-grid">
    <div v-if="gridLabel" class="flex">
      <label>{{ gridLabel }}</label>
    </div>
    <div class="grid grid-cols-2 gap-5 sm:grid-cols-3 xl:grid-cols-4">
      <content-card
        v-for="contentItem in contentItems"
        :key="contentItem.id"
        class="cursor-pointer"
        :content-item="contentItem"
        @favoriteToggled="$emit('favoriteToggled', contentItem)"
      ></content-card>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ContentItem from "@/model/content-item";

@Component({})
export default class ContentsGrid extends Vue {
  @Prop()
  contentItems: ContentItem[];

  @Prop()
  gridLabel: string;
}
</script>
