<template>
  <modal class="media-library-modal" size="large" :closable="true" @close="$emit('cancel')">
    <div class="flex flex-col h-full">
      <h3 class="mb-4">{{ $t("media_library.title") }}</h3>
      <div class="flex-1 min-h-0">
        <media-library v-model="selectedFiles" v-bind="$props" @select="select"></media-library>
      </div>
      <modal-footer>
        <button class="btn-primary" :disabled="selectedFiles.length == 0" @click="select">
          {{ $t("actions.select") }}
        </button>
      </modal-footer>
    </div>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MediaFile from "@/model/media-file";

@Component({})
export default class MediaLibraryModal extends Vue {
  @Prop()
  fileType: string;

  @Prop({ default: true })
  showFileTypes: boolean;

  @Prop({ default: false })
  allowMultiselect: boolean;

  selectedFiles: MediaFile[] = [];

  select() {
    this.$emit("ok", this.selectedFiles);
  }
}
</script>

<style lang="scss">
.media-library-modal {
  > .content {
    max-height: calc(100% - 2 * var(--spacing));
  }
}
</style>
