<template>
  <div class="content-item-preview">
    <div class="flex items-center mt-2">
      <h3 class="text-text-emphasis mr-4 mb-0">{{ contentItem.name }}</h3>
      <router-link class="btn-primary btn-sm" :to="`/pages/${contentItem.content_id}`" tag="button">
        <div class="mr-1">{{ $t("actions.go") }}</div>
        <icon-fluent name="arrowRight" variant="filled" size="16"></icon-fluent>
      </router-link>
    </div>
    <div class="sm:mx-4">
      <div class="flex mt-4">
        <div class="basis-full xl:basis-1/2">
          <summary-text :page="page"></summary-text>
        </div>
      </div>
      <div v-if="tabs" class="grid grid-cols-1 gap-x-6 gap-y-4 mt-4 sm:grid-cols-2">
        <video-card :page="page"></video-card>
        <anatomy-card :page="page"></anatomy-card>
        <tab-card v-for="tab in tabs" :key="tab.title" :title="tab.title" :route="tab.route"></tab-card>
      </div>
      <div v-else class="grid grid-cols-1 gap-x-6 gap-y-4 mt-4 sm:grid-cols-2">
        <ui-card v-for="i in 4" :key="`skeleton-${i}`" class="h-24"></ui-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ContentItem from "@/model/content-item";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TabCard from "@/pages/home/content-item-preview/tab-card.vue";
import Page from "@/model/page";
import PagesService from "@/services/pages-service";
import VideoCard from "@/pages/home/content-item-preview/video-card.vue";
import AnatomyCard from "@/pages/home/content-item-preview/anatomy-card.vue";
import SummaryText from "@/pages/home/content-item-preview/summary-text.vue";
import { documentLabel } from "@/model/content-document";
import { routeForDocument } from "@/utils/routes";

@Component({
  components: {
    TabCard,
    VideoCard,
    AnatomyCard,
    SummaryText,
  },
})
export default class ContentItemPreview extends Vue {
  @Prop()
  contentItem: ContentItem;

  page: Page = null;

  created() {
    this.fetchPage();
  }

  async fetchPage() {
    this.page = await new PagesService().get(this.contentItem.content_id.toString(), {
      params: { update_recently_opened: false }
    });
  }

  get tabs() {
    if (this.page) {
      return this.page.documents
        .filter(
          (d) =>
            d.document_type !== "steps" &&
            d.document_type !== "training" &&
            this.page.available_document_types?.includes(d.document_type)
        )
        .map((document) => ({
          title: documentLabel(document),
          route: routeForDocument(this.page, document),
        }));
    }
    return null;
  }
}
</script>