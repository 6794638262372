<template>
  <modal>
    <form @submit="onSubmit">
      <h3 class="mb-4">Change user role</h3>
      <select v-model="role">
        <option value="osgenic_admin">{{ $t("user_roles.osgenic_admin") }}</option>
        <option value="content_admin">{{ $t("user_roles.content_admin") }}</option>
        <option value="content_creator">{{ $t("user_roles.content_creator") }}</option>
      </select>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import User from "@/model/user";
import Users from "@/services/users";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ChangeRoleModal extends Vue {
  @Prop()
  user: User;

  role: string = null;

  created() {
    this.role = this.user.role;
  }

  async onSubmit() {
    const action = new Users().update(this.user.id, { role: this.role });
    await showDialog(LoadingIndicatorDialog, { action });
    this.$emit("ok", this.role);
  }
}
</script>
