<template>
  <page>
    <page-header :title="title" :back-button-route="backButtonRoute"></page-header>
    <page-content>
      <card v-if="!quiz">
        <loader-big></loader-big>
      </card>
      <div v-else class="quiz">
        <div class="stats">
          <div class="flex flex-row mb-md indicators">
            <indicator :label="'Answers'" :value="quiz.answers_count"></indicator>
            <indicator :label="'Success rate'" :value="successPercentage(quiz.success_rate) + '%'"></indicator>
          </div>
          <h3 class="mb-4">Questions</h3>
          <list-subheader>
            <div class="w-2/3">Question</div>
            <div class="w-1/3">Success rate</div>
          </list-subheader>
          <list-item v-for="question in quiz.questions" :key="question.question">
            <div class="question__question w-2/3">{{ question.question }}</div>
            <div class="text-label-large text-tertiary w-1/3">{{ successPercentage(question.success_rate) }}%</div>
            <template #content>
              <div v-for="option in question.options" :key="option.id" class="option">
                {{ option.text }} ({{ option.answers_count }} answers)
              </div>
            </template>
          </list-item>
        </div>
        <div v-if="quiz.status == 'published'" class="take-quiz">
          <quiz-button :id="quiz.id"></quiz-button>
        </div>
      </div>
    </page-content>
  </page>
</template>

<script lang="ts">
import Quiz from "@/model/quiz";
import QuizzesService from "@/services/quizzes-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { RawLocation } from "vue-router";

@Component({})
export default class QuizPage extends Vue {
  @Prop()
  id: string;

  quiz: Quiz = null;

  get title() {
    return this.quiz ? this.quiz.name : "";
  }

  get backButtonRoute(): RawLocation {
    return { name: "Quizzes" };
  }

  successPercentage(successRate): number {
    return Math.round(successRate * 100);
  }

  async created() {
    return this.fetchQuiz().then(() => {
      this.$trackPageView(this.quiz.name);
    });
  }

  async fetchQuiz() {
    const quizzes = new QuizzesService();
    return quizzes.get(this.id).then((quiz) => (this.quiz = quiz));
  }
}
</script>

<style lang="scss" scoped>
.indicators {
  > *:not(:last-child) {
    margin-right: var(--spacing);
  }
}

.take-quiz {
  margin-top: var(--spacing);
}
</style>
