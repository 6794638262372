<template>
  <dropdown-select
    :value="value"
    :options="options"
    :allow-null="allowNull"
    :multiselect="multiselect"
    :placeholder="placeholder"
    :error="error"
    :width-by-content="widthByContent"
    button-class="rounded-xs"
    @input="onInput"
  ></dropdown-select>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DropdownSelectOption from "@/model/dropdown-select-option";
import i18n from "@/i18n";

@Component({})
export default class LanguageSelect extends Vue {
  @Prop()
  value: string | string[];

  @Prop({ default: () => i18n.t("attributes.language") })
  placeholder: string;

  @Prop({ default: false })
  useLocalizedNames: boolean;

  @Prop({ default: false })
  allowNull: boolean;

  @Prop({ default: false })
  multiselect: boolean;

  @Prop({ default: true })
  widthByContent: boolean;

  @Prop({ default: false })
  error: boolean;

  @Prop()
  availableLanguages: string[] | {};

  get options(): DropdownSelectOption[] {
    if (Array.isArray(this.availableLanguages)) {
      return this.availableLanguages.map((value) => ({
        value,
        label: this.$t(`languages.${value}`).toString(),
      }));
    }
    return Object.keys(this.availableLanguages).map((key) => ({
      value: key,
      label: this.useLocalizedNames ? this.$t(`languages.${key}`) : this.availableLanguages[key],
    }));
  }

  onInput(value) {
    this.$emit("input", value);
  }
}
</script>
