import { schema } from "@/editor/editor";
import { JSONContent } from "@tiptap/core";
import { Node } from "prosemirror-model";

export const findNode = (node: JSONContent, predicate) => {
  if (predicate(node)) {
    return node;
  } else if (node.content) {
    for (const child of node.content) {
      const found = findNode(child, predicate);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export const findNodeOfType = (node: JSONContent, type) => {
  return findNode(node, (node) => node.type === type);
};

export const textContent = (node: JSONContent) => {
  return Node.fromJSON(schema, node).textContent;
};
