<template>
  <div v-if="users" class="activity-table">
    <div class="header">
      <div class="name-col text-label-medium">{{ $t("attributes.name") }}</div>
      <div class="amount-col text-label-medium">{{ $t("attributes.amount") }}</div>
    </div>
    <div v-for="user in users" :key="user.id" class="item">
      <div class="name-col">{{ user.name }}</div>
      <div class="amount-col">{{ user.entry_count }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import User from "@/model/user";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ActivityTable extends Vue {
  @Prop()
  users: User[];
}
</script>

<style lang="scss" scoped>
.item,
.header {
  display: flex;
  flex-direction: row;
}
.item {
  background-color: var(--ui-color-surface-mid-contrast);
  border-radius: var(--border-radius-sm);
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: var(--spacing-sm);
  }
}
.name-col {
  flex: 1;
}
.amount-col {
  width: 80px;
}
.index-col,
.name-col,
.amount-col {
  padding: var(--spacing-sm);
}
</style>
