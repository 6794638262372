import ApiService, { ApiServiceOpts } from "@/services/api-service";
import User from "@/model/user";
import { NotFoundError } from "@/api/api-error";

export default class Users extends ApiService<User> {
  get baseUrl() {
    return "/api/v1/users";
  }

  search(query: string = null): Promise<User[]> {
    const url = this.buildUrl("search", { params: { query } });
    const headers = this.buildHeaders();
    return fetch(url, {
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  requestPasswordReset(email: String) {
    let url = this.baseUrl;
    url = `${url}/reset_password`;

    const headers = this.buildHeaders();

    let data = {
      email: email,
    };

    return fetch(url, {
      method: "post",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  sendVerificationEmail(user: User) {
    const url = this.buildUrl(user.id, { action: "send_verification_email" });
    const headers = this.buildHeaders();

    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  verifyEmail(token: String) {
    let url = this.baseUrl;
    url = `${url}/verify_email/${token}`;

    const headers = this.baseHeaders;

    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  checkResetTokenValidity(token: String) {
    let url = this.baseUrl;
    url = `${url}/reset_password/${token}`;

    const headers = this.buildHeaders();

    return fetch(url, {
      method: "get",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  resetPassword(token: String, password: String, passwordConfirmation: String) {
    let url = this.baseUrl;
    url = `${url}/reset_password/${token}`;

    const headers = this.buildHeaders();

    let data = {
      password: password,
      password_confirmation: passwordConfirmation,
    };

    return fetch(url, {
      method: "put",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  changePassword(id, oldPassword, newPassword, newPasswordConfirmation) {
    let url = this.baseUrl;
    url = `${url}/${id}/password`;

    const headers = this.buildHeaders();

    let data = {
      password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirmation,
    };

    return fetch(url, {
      method: "put",
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  getWaitingApproval(opts: ApiServiceOpts = {}) {
    const url = this.buildUrl("/waiting_approval", opts);

    const headers = this.baseHeaders;

    return fetch(url, {
      method: "get",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  validateSignUpCode(code: String) {
    const url = this.baseUrl + "/validate_sign_up_code/" + code;

    const headers = this.baseHeaders;

    return fetch(url, {
      method: "get",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => {
        if (error instanceof NotFoundError) {
          return false;
        } else {
          return this.handleError(error);
        }
      });
  }

  protected unserialize(data: any): User {
    return new User(data);
  }
}
