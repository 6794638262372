import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import VimeoNodeView from "@/editor/extensions/vimeo/vimeo-node-view.vue";

export type VimeoNodeAttributes = {
  id: string;
  preview_id: string | null;
  surgeon: string | null;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    vimeo: {
      addVimeo: (attrs: VimeoNodeAttributes) => ReturnType;
      updateVimeo: (attrs: VimeoNodeAttributes) => ReturnType;
    };
  }
}

const VimeoNode = Node.create({
  name: "vimeo",

  inline: true,

  group: "inline",

  draggable: true,

  addAttributes() {
    return {
      id: {},
      preview_id: { default: null },
      surgeon: { default: null },
    };
  },

  addCommands() {
    return {
      addVimeo:
        (attrs: VimeoNodeAttributes) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs,
          });
        },
      updateVimeo:
        (attrs: VimeoNodeAttributes) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, attrs);
        },
    };
  },

  parseHTML() {
    return [
      {
        tag: "vimeo-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["vimeo-node", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(VimeoNodeView);
  },
});

export default VimeoNode;
