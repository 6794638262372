<template>
  <theme-provider theme="dark" class="flex-1">
    <div
      v-lazy:background-image="backgroundImage"
      class="h-full flex flex-col items-center overflow-y-auto relative bg-cover px-5"
    >
      <router-view class="w-full max-w-2xl" @back="back" />
    </div>
  </theme-provider>
</template>
<script lang="ts" setup>
import { onMounted, onUnmounted } from "vue";
import { getLocale, setTemporaryLocale } from "@/i18n";
import { useSignUpStore } from "./signup-store";
import { useRouter } from "@/composables/router";
import backgroundImage from "@/assets/images/signup-background.png";

const props = defineProps<{ code?: string }>();
const store = useSignUpStore();
const initialLocale = getLocale();
const router = useRouter();

onMounted(() => {
  setTemporaryLocale("en");
});

onUnmounted(() => {
  setTemporaryLocale(initialLocale);
});

const back = () => router.back();

if (props.code) {
  store.sign_up_code = props.code.toUpperCase();
  router.replace({ name: "SignupCreateAccount" });
}
</script>
