<template>
  <div class="topbar flex z-30 sidemenu:pointer-events-none">
    <div class="w-sidemenu hidden sidemenu:block"></div>
    <div class="flex-1 flex items-stretch px-md justify-end bg-surface-min-contrast sidemenu:justify-between">
      <div v-if="impersonating" class="impersonating">
        <a class="link sidemenu:pointer-events-auto" @click="stopImpersonating">Stop impersonating</a>
      </div>
      <div class="flex items-center">
        <a class="text-lg text-text-variant sidemenu:hidden" @click="$emit('toggleSideMenu')">
          <icon icon="bars"></icon>
        </a>
      </div>
      <div class="items-center hidden sidemenu:flex">
        <dropdown v-if="$currentUser" class="pointer-events-auto" :right="true" :to-right="true">
          <template #dropdownButton>
            <div class="profile-button h-[50px]">
              <avatar :user="$currentUser"></avatar>
              <div class="text-label-small text-text-variant ml-2 tracking-[0.5px] group-hover:text-text-primary">
                {{ $currentUser.name }}
              </div>
              <icon-fluent
                class="text-text-variant w-7 h-auto group-hover:text-text-primary sm:w-5"
                name="chevronDown"
                variant="filled"
              ></icon-fluent>
            </div>
          </template>
          <router-link class="nav-link group" to="/profile">
            <div class="icon">
              <icon-fluent class="group-hover:hidden" name="person"></icon-fluent>
              <icon-fluent class="hidden group-hover:inline-block" name="person" variant="filled"></icon-fluent>
            </div>
            <div class="title group-hover:text-text-primary">{{ $t("profile.title") }}</div>
          </router-link>
          <a id="logoutButton" class="nav-link" @click.prevent="onLogoutClicked()">
            <div class="icon">
              <inline-svg class="svg-icon" :src="require('@/assets/images/logout.svg')"></inline-svg>
            </div>
            <div class="title">{{ $t("actions.log_out") }}</div>
          </a>
        </dropdown>
      </div>
    </div>
    <div class="fixed top-topbar left-sidemenu w-4 h-4 hidden bg-surface-min-contrast sidemenu:block">
      <div class="bg-surface-no-contrast w-full h-full rounded-tl"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { logout, stopImpersonating } from "@/actions";
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class Topbar extends Vue {
  get impersonating() {
    return !!this.$store.state.session.impersonating;
  }

  stopImpersonating() {
    stopImpersonating();
  }

  onLogoutClicked() {
    logout();
  }
}
</script>

<style lang="scss" scoped>
.topbar {
  height: var(--topbar-height);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  .profile-button {
    display: flex;
    align-items: center;
    cursor: pointer;

    > *:not(:last-child) {
      margin-right: var(--spacing-xs);
    }
  }

  a.nav-link {
    height: 60px;
    width: 200px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    padding: 0 var(--spacing);

    .icon {
      width: 20px;
      margin-right: var(--spacing-sm);
    }

    &.router-link-active {
      color: var(--ui-color-text-emphasis);

      svg.regular {
        display: none;
      }

      svg.filled {
        display: inline-block;
      }
    }
  }

  .impersonating {
    a {
      color: red;
    }
  }
}
</style>
