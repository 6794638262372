<template>
  <div ref="chartContainer" class="dougnut-chart">
    <canvas v-if="size" ref="canvas" :width="size" :height="size"></canvas>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Chart from "chart.js";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import Style from "@/style";

@Component({})
export default class DoughnutChart extends Vue {
  @Ref("chartContainer")
  chartContainer: HTMLDivElement;

  @Ref("canvas")
  canvas: HTMLCanvasElement;

  @Prop()
  data: number[];

  @Prop()
  colors: string[];

  chart: Chart = null;

  size: number = null;

  mounted() {
    this.size = this.chartContainer.clientWidth;

    this.$nextTick(() => {
      const barWidth = 20;
      const cutoutPercentage = (1 - barWidth / this.size) * 100;
      const ctx = this.canvas.getContext("2d");

      const config = {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: this.data,
              backgroundColor: this.colors,
              borderWidth: 4,
              borderColor: Style.colors.surface,
            },
          ],
        },
        options: {
          cutoutPercentage,
        },
      };
      this.chart = new Chart(ctx, config);
    });
  }

  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
</script>
