<template>
  <a :class="{ align }" @click="$emit('click')">
    <icon v-if="icon" :icon="icon"></icon><span class="title">{{ title }}</span>
  </a>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ActionLink extends Vue {
  @Prop()
  icon: string;

  @Prop()
  title: String;

  @Prop()
  align: Boolean;
}
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
  .icon {
    margin-right: var(--spacing-sm);
  }
  &.align {
    .icon {
      width: 18px;
    }
  }
}
</style>
