import { createDropHandler } from "@/editor/utils/create-drop-handler";
import { showDialog } from "@/utils/dialogs";
import { Editor } from "@tiptap/core";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import { uploadFileToMediaLibrary } from "@/actions";

export const createImageDropHandler = (editor: Editor) => {
  return createDropHandler({
    key: "imageDropHandler",
    editor,
    filter: (file) => /^image\//.test(file.type),
    callback: (images) => {
      const action = uploadImages(images, editor);
      showDialog(LoadingIndicatorDialog, { action });
    },
  });
};

const uploadImages = async (images: File[], editor: Editor) => {
  for (const image of images) {
    const mediaFile = await uploadFileToMediaLibrary(image);
    editor.commands.addImage(mediaFile);
  }
  editor.commands.focus();
};
