<template>
  <div class="flex flex-col items-center text-center m-4">
    <icon-fluent name="errorCircle" size="20" class="text-semantic-error" />
    <div v-if="subscription" class="text-semantic-error mt-2">
      {{ $t("membership_expired.your_membership_expired_on") }}:
      <br />
      {{ dateFilter(subscription.end_date) }}
    </div>
    <div v-else class="text-semantic-error mt-2">{{ $t("membership_expired.your_membership_has_expired") }}</div>
    <h2 class="mt-4 text-text-primary">{{ $t("membership_expired.title") }}</h2>
    <div class="mt-8 text-label-medium text-text-primary">{{ $t("membership_expired.info_text") }}</div>
    <button class="mt-6 btn-primary" @click="showRenewMembershipDialog">
      {{ $t("membership_expired.see_plans_and_prices") }}
      <icon-fluent name="arrowRight" size="16" variant="filled" class="ml-2" />
    </button>
    <button class="mt-4 btn-tertiary" @click="logout()">
      {{ $t("actions.log_out") }}
      <icon-fluent name="arrowRight" size="16" variant="filled" class="ml-2" />
    </button>
  </div>
</template>

<script setup lang="ts">
import RenewMembershipDialog from "@/pages/membership-expired/renew-membership-dialog.vue";
import { dateFilter } from "@/filters";
import { showDialog } from "@/utils/dialogs";
import { logout } from "@/actions";
import { useCurrentUser } from "@/composables/session";
import { computed } from "vue";
import _ from "lodash";

const user = useCurrentUser();

const subscription = computed(() => _.last(user.value.subscriptions));

const showRenewMembershipDialog = () => {
  showDialog(RenewMembershipDialog);
};
</script>
