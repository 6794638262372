<template>
  <div class="item-details">
    <div v-if="item.description">{{ $t("attributes.description") }}: {{ item.description }}</div>
    <div>{{ $t("attributes.required_count") }}: {{ item.min_count || 1 }}</div>
    <div v-if="item.quiz">{{ $t("quiz.title") }}: {{ item.quiz.name }}</div>
    <div v-if="item.procedure_types && item.procedure_types.length > 0">
      {{ $t("procedure.procedures_required") }}: {{ procedureTypes }}
    </div>
    <div v-if="quiz && quizAnswers">
      <div v-for="answer in quizAnswers" :key="answer.id" class="mt-xs pt-xs border-t border-background">
        <div class="text-text-variant">{{ $t("attributes.answer") }}: {{ dateTimeFilter(answer.created_at) }}</div>
        <div
          v-for="(question, index) in quiz.questions"
          :key="question.id"
          class="flex mt-xs pt-xs ml-md border-t border-background"
        >
          <div class="flex-1">{{ $t("question.title") }} {{ index + 1 }}: {{ question.question }}</div>
          <div class="basis-20">
            <div v-if="hasCorrectAnswer(question, answer)" class="correct">
              <icon class="text-tertiary mr-sm" icon="check-circle"></icon>{{ optionLabel(question, answer) }}
            </div>
            <div v-else class="incorrect">
              <icon class="text-error mr-sm" icon="times-circle"></icon>{{ optionLabel(question, answer) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { dateTimeFilter } from "@/filters";
import CurriculumItem from "@/model/curriculum-item";
import Quiz from "@/model/quiz";
import QuizAnswer from "@/model/quiz-answer";
import QuizQuestion from "@/model/quiz-question";
import User from "@/model/user";
import QuizzesService from "@/services/quizzes-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

const alpha = Array.from(Array(26)).map((e, i) => String.fromCharCode(i + 65));

@Component({})
export default class ItemDetails extends Vue {
  @Prop()
  item: CurriculumItem<object>;

  @Prop()
  user: User;

  quiz: Quiz = null;
  quizAnswers: QuizAnswer[] = null;

  get quizzesService() {
    return new QuizzesService();
  }
  get procedureTypes(): string {
    return (
      this.item.procedure_types && this.item.procedure_types.map(({ name, code }) => `${name} (${code})`).join(", ")
    );
  }

  async created() {
    if (this.item.quiz) {
      this.quiz = await this.quizzesService.get(this.item.quiz.id.toString());
      this.quizAnswers = await this.quizzesService.getAnswers(this.item.quiz.id, this.user.id);
    }
  }

  hasCorrectAnswer(question: QuizQuestion, answer: QuizAnswer) {
    const questionAnswer = answer.answers.find((a) => a.question_id === question.id);
    return questionAnswer.correct;
  }

  optionLabel(question: QuizQuestion, answer: QuizAnswer) {
    const questionAnswer = answer.answers.find((a) => a.question_id === question.id);
    const index = question.options.findIndex((opt) => opt.id === questionAnswer.option_id);

    return alpha[index];
  }

  dateTimeFilter(value: string) {
    return dateTimeFilter(value);
  }
}
</script>
