<template>
  <div class="page">
    <navigation v-if="showLayout" :open="sideMenuOpen" @hide="sideMenuOpen = false"></navigation>
    <div class="flex flex-1 flex-col" :class="{ 'sidemenu:pl-sidemenu': showLayout }">
      <topbar v-if="showLayout" @toggleSideMenu="toggleSideMenu"></topbar>
      <div class="mt-topbar">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Topbar from "@/topbar.vue";
import { Prop, Watch } from "vue-property-decorator";
import _ from "lodash";

@Component({
  components: {
    Topbar,
  },
})
export default class Page extends Vue {
  @Prop()
  layout?: boolean;

  sideMenuOpen = false;

  get showLayout() {
    return _.isBoolean(this.layout) ? this.layout : !this.$route.meta.noLayout;
  }

  @Watch("$route")
  onRouteChange() {
    document.body.classList.remove("overflow-hidden");
    this.sideMenuOpen = false;
  }

  toggleSideMenu() {
    this.sideMenuOpen = !this.sideMenuOpen;

    if (this.sideMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }
}
</script>
