import { Content, Editor, getSchema, JSONContent, Node } from "@tiptap/vue-2";
import { ListItem } from "@tiptap/extension-list-item";
import StarterKit from "@tiptap/starter-kit";
import ImageNode from "@/editor/extensions/image/image-node";
import VideoNode from "@/editor/extensions/video/video-node";
import FileNode from "@/editor/extensions/file/file-node";
import LinkNode from "@/editor/extensions/external-link/external-link-node";
import VimeoNode from "@/editor/extensions/vimeo/vimeo-node";
import YouTubeNode from "@/editor/extensions/youtube/youtube-node";
import LauncherLinkNode from "@/editor/extensions/launcher-link/launcher-link-node";
import ViewerLinkNode from "@/editor/extensions/viewer-link/viewer-link-node";
import QuizLinkNode from "@/editor/extensions/quiz-link/quiz-link-node";
import IndentListNode from "@/editor/extensions/indent-list/indent-list-node";
import StepNode from "@/editor/extensions/step/step-node";
import CustomLink from "@/editor/extensions/custom-link/custom-link";
import ImageRow from "@/editor/extensions/image-row/image-row-node";
import Uid from "@/editor/extensions/uid/uid";
import _ from "lodash";

const Document = Node.create({
  name: "doc",
  topNode: true,
  content: "(block | step)+",
});

const CustomListItem = ListItem.extend({
  content: "(paragraph | heading) block*",
});

export const buildEditor = (content: Content, editable = true) => {
  const editor = new Editor({
    content,
    extensions: buildExtensions(editable),
    autofocus: false,
    editable,
  });

  return editor;
};

const buildExtensions = (editable = true) => [
  Document,
  StarterKit.configure({
    document: false,
    listItem: false,
  }),
  CustomListItem,
  CustomLink.configure({
    HTMLAttributes: {
      class: "link",
    },
    openOnClick: !editable,
  }),
  ImageNode,
  VideoNode,
  FileNode,
  LinkNode,
  VimeoNode,
  YouTubeNode,
  LauncherLinkNode,
  ViewerLinkNode,
  QuizLinkNode,
  IndentListNode,
  StepNode,
  ImageRow,
  Uid,
];

export const schema = getSchema(buildExtensions(false));

export const isValidContent = (content: JSONContent) => {
  try {
    schema.nodeFromJSON(content).check();
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const findInvalidNodes = (node: JSONContent) => {
  return [!isValidContent(node) && node, ..._.flatMap(node?.content, findInvalidNodes)].filter(Boolean);
};
