import { Module } from "vuex";
import { SET_CONTENT_SEARCH_QUERY } from "@/store/mutations";
import { RootState } from "@/store/store";

export type ContentSearchState = {
  query: string;
};

export const buildInitialState: () => ContentSearchState = () => ({
  query: "",
});

export const mutations = {
  [SET_CONTENT_SEARCH_QUERY]: (state, query) => Object.assign(state, { query }),
};

export const contentSearchModule: Module<ContentSearchState, RootState> = {
  state: buildInitialState,
  mutations,
};

export default contentSearchModule;
