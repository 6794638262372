<template>
  <div v-if="token_invalid" class="reset-password-page">
    <card class="reset-password-page__card">
      <div class="reset-password-page__card-content">
        <h1>Password reset failed.</h1>
        <p>Password reset link is valid for only 30 minutes.</p>
        <div class="reset-password-page__actions">
          <button class="btn-primary" @click.prevent="onCancel">Continue</button>
        </div>
      </div>
    </card>
  </div>
  <div v-else class="reset-password-page">
    <card class="reset-password-page__card">
      <div class="reset-password-page__card-content">
        <h1>{{ $t("actions.reset_password") }}</h1>
        <form class="reset-password-page__form" @submit.prevent="onSubmit">
          <password-checker ref="new_password_form"></password-checker>
          <div class="reset-password-page__actions">
            <button class="btn-primary" @click.prevent="onSubmit">{{ $t("actions.reset_password") }}</button>
            <button class="btn-secondary" @click.prevent="onCancel">{{ $t("actions.cancel") }}</button>
          </div>
        </form>
      </div>
    </card>
  </div>
</template>

<script lang="ts">
import PasswordChecker from "@/components/form/password-checker.vue";
import ResultModal from "@/components/result-modal.vue";
import Users from "@/services/users";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";
import { logout } from "@/actions";
import store from "@/store/store";

@Component({})
export default class ResetPasswordPage extends Vue {
  @Ref("new_password_form")
  newPasswordForm: PasswordChecker;

  token: String = "";
  token_invalid: Boolean = false;

  get userService() {
    return new Users();
  }

  async mounted() {
    if (this.$route.params.token) {
      var param_token = this.$route.params.token;
      this.token = Array.isArray(param_token) ? param_token[0] : param_token;
      return (
        this.userService
          .checkResetTokenValidity(this.token)
          // eslint-disable-next-line no-unused-vars
          .then((valid) => {
            // console.log(validity);
          })
          // eslint-disable-next-line no-unused-vars
          .catch((e) => {
            this.token_invalid = true;
          })
      );
    }
  }

  async onSubmit() {
    this.newPasswordForm.$v.$touch();

    if (!this.newPasswordForm.$v.$error) {
      await this.userService.resetPassword(
        this.token,
        this.newPasswordForm.newPassword,
        this.newPasswordForm.confirmNewPassword
      );
      await showDialog(ResultModal, {
        title: "Password reset",
        content: "Your password was successfully reset. You can now log in with your new password.",
      });
      if (store.getters.currentUser) {
        logout();
      } else {
        this.$router.push({ name: "Login" });
      }
    }
  }

  onCancel() {
    this.$router.push({ name: "Login" });
  }
}
</script>

<style lang="scss" scoped>
.reset-password-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__card {
    width: 600px;
    padding: 50px 100px;
  }

  &__card-content {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;

    h1 {
      margin-bottom: 40px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--spacing-lg);

    > *:not(:last-child) {
      margin-right: var(--spacing-sm);
    }
  }
}
</style>
