<template>
  <div class="flex gap-4">
    <badge :label="badgeLabel" :variant="badgeVariant" />
    <badge v-if="cancelled" :label="cancelledLabel" variant="info" />
  </div>
</template>

<script setup lang="ts">
import { useActiveSubscription } from "@/composables/session";
import { dateFilter } from "@/filters";
import { computed } from "vue";
import { useI18n } from "vue-i18n-bridge";

const { t } = useI18n();

const subscription = useActiveSubscription();

const inTrial = computed(() => subscription.value.status == "in_trial");

const cancelled = computed(() => subscription.value.cancel_date != null);

const badgeLabel = computed(() => {
  if (inTrial.value) {
    return t("plan.free_trial");
  } else {
    return "Osgenic " + t("plan.period." + subscription.value.period);
  }
});

const badgeVariant = computed(() => {
  if (inTrial.value) {
    return "warning";
  } else {
    return "success";
  }
});

const cancelledLabel = computed(() => {
  const date = dateFilter(subscription.value.cancel_date);
  return t("plan.ends_on_date", { date });
});
</script>
