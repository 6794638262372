<template>
  <div
    ref="dropdown"
    v-outside-click="onOutsideClick"
    class="dropdown"
    :class="dropdownClasses"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click="toggleDropdown"
  >
    <slot name="dropdownButton">
      <div
        ref="button"
        class="dropdown__button w-full border-solid border-outline duration-300 px-3"
        :class="buttonClasses"
      >
        <div v-if="label" class="dropdown__button-label bg-background text-xs px-xs -ml-xs absolute -top-1 left-3">
          {{ label }}
        </div>
        <div class="dropdown__button-title flex-1 select-none whitespace-nowrap">
          <slot name="dropdownButtonTitle">{{ title }}</slot>
        </div>
        <div class="dropdown__button-icon">
          <icon-fluent
            :name="(visible && !up) || (!visible && up) ? 'chevronUp' : 'chevronDown'"
            variant="filled"
            :size="buttonSize === 'sm' ? 16 : 20"
          ></icon-fluent>
        </div>
      </div>
    </slot>
    <fade-transition>
      <div v-if="visible" class="dropdown__content z-10" :class="dropdownContentClasses">
        <slot></slot>
      </div>
    </fade-transition>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import _ from "lodash";
import { isElementOrAncestorOf } from "@/utils/dom";

type Hover = "click" | "hover";

@Component({})
export default class Dropdown extends Vue {
  @Ref("dropdown")
  dropdown: HTMLElement;

  @Ref("button")
  button: HTMLElement;

  @Prop()
  label: string;

  @Prop()
  title: string;

  @Prop({ default: "md" })
  buttonSize: "md" | "sm";

  @Prop({ default: "normal" })
  buttonStyle: "normal" | "clear" | "tertiary";

  @Prop({ default: "rounded-sm" })
  buttonClass: string;

  @Prop({ default: true })
  closeOnSelect: boolean;

  @Prop({ default: () => () => true })
  closeOnButtonClick: (element: Element) => boolean;

  @Prop()
  up: boolean;

  @Prop()
  right: boolean;

  @Prop()
  toRight: boolean;

  @Prop()
  bottom: boolean;

  @Prop()
  top: boolean;

  @Prop({ default: true })
  widthByContent: boolean;

  @Prop({ default: "click" })
  toggle: Hover;

  @Prop({ default: false })
  error: boolean;

  visible = false;

  rect: DOMRect = null;

  get onRight() {
    return this.rect && this.rect.left > window.innerWidth / 2;
  }

  get dropdownClasses() {
    return {
      open: this.visible,
    };
  }

  get dropdownContentClasses() {
    return {
      top: this.top,
      up: this.up,
      right: _.isBoolean(this.right) ? this.right : this.onRight,
      "to-right": _.isBoolean(this.toRight) ? this.toRight : this.onRight,
      bottom: this.bottom,
      "w-max": this.widthByContent,
    };
  }

  get buttonClasses() {
    const classes = [this.buttonClass];
    if (this.buttonStyle == "normal") {
      classes.push("border");
    }
    if (this.buttonStyle == "tertiary") {
      classes.push("text-text-emphasis");
    }
    if (this.error) {
      classes.push("border-error");
    }
    if (!this.visible) {
      classes.push("hover:bg-interactive-bg-neutral-low-contrast");
    }
    if (this.buttonSize === "sm") {
      classes.push("h-8");
    } else {
      classes.push("h-10");
    }
    classes.push(this.buttonSize == "sm" ? "text-button-small" : "text-button-regular");
    return classes;
  }

  mounted() {
    this.updateRect();
    window.addEventListener("resize", this.updateRect);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.updateRect);
  }

  updateRect() {
    this.rect = this.$el.getBoundingClientRect();
  }

  toggleDropdown(event: { target: HTMLElement }) {
    // Don't close if closeOnSelect is false (multiple selection dropdown
    // for example) and the click is not on the button
    if (this.visible && !this.closeOnSelect) {
      if (!isElementOrAncestorOf(this.button, event.target) || !this.closeOnButtonClick(event.target)) {
        return;
      }
    }

    if (this.toggle === "click") {
      this.visible = !this.visible;
    }
  }

  close() {
    this.visible = false;
  }

  onMouseEnter() {
    if (this.toggle === "hover") {
      this.visible = true;
    }
  }

  onMouseLeave() {
    if (this.toggle === "hover") {
      this.visible = false;
    }
  }

  // Hides dropdown when clicked outside of it
  onOutsideClick() {
    this.visible = false;
  }

  @Watch("visible")
  onVisibleChange(visible: any) {
    if (visible) {
      this.$emit("show");
    } else {
      this.$emit("blur");
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  position: relative;
  top: 0;
  font-size: var(--font-size-body);
  color: var(--ui-color-text-variant);

  .dropdown__button {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &.accent {
    .dropdown__button {
      border-color: var(--ui-color-border-emphasis);
    }
  }

  .dropdown__button-icon {
    margin-left: $spacing-sm;
    pointer-events: none;
  }

  .dropdown__content {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    background-color: var(--ui-color-surface-min-contrast);
    border-radius: var(--border-radius-xs);
    margin-top: var(--spacing-xs);
    transition: opacity 0.2s;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

    &.top {
      top: 0;
      margin-top: 0;

      &.right {
        left: unset;
        right: 0;
        margin-right: 0;
      }
    }

    &.up {
      top: unset;
      bottom: 100%;
      margin-top: 0;

      &.bottom {
        bottom: 0;
        margin-bottom: 0;
      }
    }

    &.to-right {
      left: unset;
      right: 100%;
      margin-right: $spacing-xs;

      &.right {
        right: 0;
        margin-right: 0;
      }
    }

    button {
      display: block;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: $spacing;
    }

    a {
      display: block;
      height: 40px;
      width: 100%;
      padding: 0 var(--spacing-sm);
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > .icon:not(:last-child) {
        margin-right: var(--spacing-sm);
      }
    }

    .option {
      color: var(--ui-color-text-primary);
      position: relative;

      &:hover {
        background-color: var(--ui-color-interactive-bg-neutral-low-contrast);
      }

      &[disabled] {
        color: var(--color-on-disabled);

        &:active {
          filter: brightness(100%);
        }

        &::before {
          display: none;
        }
      }
    }
  }
}
</style>
