<template>
  <modal class="light justify-center link-edit-modal" content-class="!mb-lg !px-lg">
    <form @submit.prevent="onSubmit">
      <modal-header :title="$t('editor.link')"></modal-header>
      <modal-body>
        <form-field :label="$t('editor.title')">
          <input v-model="_title" v-focus type="text" />
        </form-field>
        <form-field :label="$t('editor.link')">
          <input v-model="_href" type="text" />
        </form-field>
      </modal-body>
      <modal-footer>
        <div class="flex w-full">
          <div class="flex-1">
            <button class="btn-secondary" type="button" @click="onDelete">{{ $t("actions.deleteLink") }}</button>
          </div>
          <button class="btn-secondary" type="button" @click="onCancel">{{ $t("actions.cancel") }}</button>
          <div class="w-4"></div>
          <button class="btn-primary" type="submit">{{ $t("actions.save") }}</button>
        </div>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import { sanitizeUrl } from "@/utils/urls";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LinkEditModal extends Vue {
  @Prop()
  readonly title: string;

  @Prop()
  readonly href: string;

  _title: string = "";
  _href: string = "";

  created() {
    this._title = this.title || "";
    this._href = this.href || "";
  }

  async onSubmit() {
    this.$emit("ok", { href: sanitizeUrl(this._href), title: this._title });
  }

  async onDelete() {
    this.$emit("ok", { deleted: true });
  }

  onCancel() {
    this.$emit("cancel");
  }
}
</script>

<style lang="scss" scoped>
.link-edit-modal::v-deep {
  .content-wrapper {
    .content {
      width: 320px;
    }
  }
}
</style>
