<template>
  <sign-up-view
    :title="$t('signup.welcome.title', { name: store.name })"
    :subtitle="$t('signup.welcome.subtitle')"
    :subtitle2="$t('signup.welcome.info_text_1', { days: trialPeriodDays })"
    :back="false"
  >
    <template #top>
      <!-- <div
        class="border border-semantic-success py-2 px-4 rounded-sm text-left text-text-variant flex justify-center mb-2"
      >
        <icon-fluent name="checkmark" class="text-semantic-success mr-2" size="16" />
        <span class="text-label-medium text-text-primary leading-none">{{
          $t("signup.payment_method_added_successfully")
        }}</span>
      </div> -->
    </template>
    <div class="border border-border-emphasis rounded py-8 px-12 flex flex-col gap-8 text-left">
      <div class="flex flex-col gap-1">
        <div class="text-body-small text-text-variant uppercase">{{ $t("attributes.username") }}</div>
        <div class="text-label-small text-text-primary">{{ store.email }}</div>
      </div>
      <!--
      <div class="flex flex-col gap-1">
        <div class="text-body-small text-text-variant uppercase">{{ $t("plan.your_plan") }}</div>
        <div class="text-label-small text-text-primary">{{ $t("plan.period." + store.period) }}</div>
      </div>
      <div class="flex flex-col gap-1">
        <div class="text-body-small text-text-variant uppercase">{{ $t("plan.payment_method") }}</div>
        <div class="text-label-small text-text-primary">{{ $t("plan.payment_methods." + store.payment_method) }}</div>
      </div>
      <div class="flex flex-col gap-1">
        <div class="text-body-small text-text-variant uppercase">{{ $t("plan.next_billing_date") }}</div>
        <div class="text-label-small text-text-primary">{{ nextBillingDate }}</div>
      </div>
      -->
    </div>

    <div class="mt-8">
      <a href="https://www.osgenic.com" class="btn btn-secondary">
        {{ $t("actions.done") }}
      </a>
    </div>
  </sign-up-view>
</template>

<script setup lang="ts">
import dayjs from "dayjs";
import { useSignUpStore } from "./signup-store";
import SignUpView from "./sign-up-view.vue";
import { useRouter } from "@/composables/router";
import { storeToRefs } from "pinia";
import { useSignUpCode } from "@/api/sign-up-codes";
import { computed } from "vue";

const store = useSignUpStore();

const router = useRouter();

const storeRefs = storeToRefs(store);

const { data: signUpCode } = useSignUpCode(storeRefs.sign_up_code);

const trialPeriodDays = computed(() => signUpCode.value?.trial_period_days || 14);

const nextBillingDate = computed(() => dayjs().add(trialPeriodDays.value, "day").format("L"));

if (!store.payment_method) {
  router.replace({ name: "SignupCreateAccount" });
}
</script>
