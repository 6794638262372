<template>
  <div class="modal-header mb-4">
    <h3 v-if="title">{{ title }}</h3>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ModalHeader extends Vue {
  @Prop()
  title: string;
}
</script>
