<template>
  <modal class="preview-image-modal" size="auto">
    <div class="flex flex-col overflow-hidden h-full">
      <h3 class="mb-4">{{ $t("attributes.cover_image") }}</h3>
      <div class="flex flex-col justify-between flex-1 overflow-hidden sm:flex-row">
        <div class="flex-1 mr-md max-w-3xl overflow-hidden">
          <media-library v-model="selectedFiles" :show-file-types="false" file-type="image"></media-library>
        </div>
        <div class="preview flex-col hidden mb-md w-[255px] sm:flex">
          <label class="mb-2.5 tracking-[0.5px]">{{ $t("preview.title") }}</label>
          <content-card
            class="keep-hover !h-auto"
            :content-item="previewContentItem"
            @click.native.capture.prevent
          ></content-card>
        </div>
      </div>
      <modal-footer>
        <button class="btn-primary" @click="apply">{{ $t("actions.select") }}</button>
        <button class="btn-secondary" @click="clear">{{ $t("actions.clear") }}</button>
        <button class="btn-secondary" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </div>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ContentItem from "@/model/content-item";
import MediaFile from "@/model/media-file";

@Component({ components: {} })
export default class PreviewImageModal extends Vue {
  @Prop()
  contentItem: ContentItem;

  selectedFiles: MediaFile[] | false = null; // False empties set preview image

  get previewContentItem() {
    return this.selectedFiles !== null && this.selectedFiles[0] !== null
      ? {
          ...this.contentItem,
          preview_image: this.selectedFiles[0]
            ? {
                ...this.selectedFiles[0].file,
              }
            : null,
        }
      : this.contentItem;
  }

  apply() {
    if (this.selectedFiles !== null) {
      this.$emit("ok", this.selectedFiles[0] ?? false);
    } else {
      this.$emit("cancel");
    }
  }

  clear() {
    this.selectedFiles = false;
  }
}
</script>

<style lang="scss" scoped>
.select-button {
  input {
    display: none;
  }
}
</style>

<style lang="scss">
.modal.preview-image-modal {
  > .content {
    max-height: calc(100% - 2 * var(--spacing));
    overflow: hidden;
  }
}
</style>
