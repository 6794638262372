import { handleError, handleResponse } from "@/api/utils";
import _ from "lodash";

export function fetchHomePageSpecialities() {
  return fetch("/api/v1/home_page/specialities", {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));
}

export function fetchHomePageHighlightedContent(contentLanguages: string[]) {
  const params = {
    languages: contentLanguages,
  };

  var url = "/api/v1/home_page/content_items/highlighted";
  const urlSearchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (_.isArray(params[key])) {
      params[key].forEach((value) => urlSearchParams.append(`${key}[]`, value));
    } else {
      urlSearchParams.append(key, params[key]);
    }
  });
  url = `${url}?${urlSearchParams}`;

  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));
}

export function fetchHomePageLatestReleases() {
  return fetch("/api/v1/home_page/content_items/latest_releases", {
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));
}
