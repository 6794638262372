<template>
  <component :is="linkComponent" class="select-none hover:text-text-primary" v-bind="linkAttributes">
    <div
      class="text-label-small overflow-hidden text-ellipsis highlight sm:text-label-strong"
      v-html="highlightedName"
    ></div>
    <div class="flex mt-1 sm:mt-2">
      <div
        class="basis-10 h-10 bg-cover mr-sm rounded-xs sm:basis-20 sm:h-20"
        :style="{ backgroundImage: `url(${previewImage}` }"
      ></div>
      <div class="flex-1 flex flex-col justify-between overflow-hidden">
        <div
          class="highlight text-body-extra-small line-clamp-3 sm:text-body-small"
          v-html="item.search_highlight"
        ></div>
        <div class="hidden overflow-hidden items-center sm:flex">
          <div class="flex-1 flex gap-2 flex-wrap h-6 overflow-hidden">
            <badge
              v-for="specialty in item.specialities"
              :key="specialty.id"
              class="shrink-0"
              :label="specialty.name"
              variant="specialties"
            ></badge>
            <badge
              v-if="item.content_type == 'ExternalLink'"
              class="shrink-o"
              :label="$t('external_link.title')"
              variant="external-link"
            ></badge>
          </div>
          <div v-if="ownerName" class="shrink-0 ml-4 text-label-strong-small text-text-emphasis">{{ ownerName }}</div>
        </div>
      </div>
    </div>
  </component>
</template>

<script lang="ts">
import ContentItem from "@/model/content-item";
import { getActiveThemePreviewPlaceholder } from "@/theme";
import { filePath } from "@/utils/paths";
import { routeForContentItem } from "@/utils/routes";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ContentSearchItem extends Vue {
  @Prop()
  item: ContentItem;

  @Prop()
  searchQuery: string;

  get route() {
    return routeForContentItem(this.item);
  }

  get linkComponent() {
    if (this.item.content_type == "ExternalLink") {
      return "a";
    } else {
      return "router-link";
    }
  }

  get linkAttributes() {
    if (this.item.content_type == "ExternalLink") {
      return {
        href: `/external_links/${this.item.content_id}`,
        target: "_blank",
        rel: "noopener noreferrer",
      };
    } else {
      return {
        to: this.route,
      };
    }
  }

  get previewImage() {
    if (this.item.preview_image) {
      return filePath(this.item.preview_image, "small");
    } else {
      return getActiveThemePreviewPlaceholder();
    }
  }

  get highlightedName() {
    const index = this.item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase());
    if (index >= 0) {
      return (
        this.item.name.substring(0, index) +
        "<b>" +
        this.item.name.substring(index, index + this.searchQuery.length) +
        "</b>" +
        this.item.name.substring(index + this.searchQuery.length)
      );
    } else {
      return this.item.name;
    }
  }

  get categoryTitle() {
    const parts = [];
    if (this.item.specialities?.length > 0) {
      parts.push(this.item.specialities[0].name);
    }
    if (this.item.locations?.length > 0) {
      parts.push(this.item.locations[0].name);
    }
    if (this.item.customer_organization) {
      parts.push(this.item.customer_organization.name);
    } else if (this.item.user) {
      parts.push(this.item.user.name);
    } else {
      parts.push("Osgenic");
    }
    return parts.join(" - ");
  }

  get ownerName() {
    if (this.item.customer_organization) {
      return this.item.customer_organization.name;
    } else if (!this.item.user) {
      return "Osgenic";
    }
    return null;
  }
}
</script>

<style lang="scss" scoped>
.highlight::v-deep {
  b {
    color: var(--ui-color-text-emphasis);
    font-weight: 900;
  }
}
</style>
