<template>
  <div v-if="user">
    <div class="flex gap-4 items-end sm:bg-surface-min-contrast sm:rounded sm:gap-16 sm:p-6">
      <profile-photo
        v-model="user.profile_picture"
        class="w-16 h-16 sm:w-24 sm:h-24"
        :upload-on-change="true"
        @input="saveProfilePicture"
      ></profile-photo>
      <div>
        <confirmable-input
          v-model="user.name"
          :edit-label="$t('actions.change_name')"
          :on-save="(value) => saveConfirmable('name', value)"
        >
          <template #value>
            <div class="text-label-large text-text-primary">{{ user.name }}</div>
          </template>
          <text-input v-model="user.name" :floating-label="true" :placeholder="$t('attributes.name')" />
        </confirmable-input>
        <div class="hidden sm:block">
          <subscription-status v-if="$currentUser.isSolo()" class="mt-4" />
        </div>
      </div>
    </div>
    <div class="sm:hidden">
      <subscription-status v-if="$currentUser.isSolo()" class="mt-4" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import User from "@/model/user";
import { updateProfile } from "@/actions";
import ConfirmableInput from "@/components/form/confirmable-input.vue";
import store from "@/store";
import SubscriptionStatus from "./subscription-status.vue";
import _ from "lodash";

const user = ref<User>(null);
const isMounted = ref(false);

onMounted(() => {
  user.value = _.cloneDeep(store.getters.currentUser);
  isMounted.value = true;
});

const saveProfilePicture = async (value) => {
  return updateProfile({
    profile_picture: value,
  });
};

const saveConfirmable = async (key, value) => {
  return updateProfile({
    [key]: value,
  });
};
</script>
