import SignUpCode from "@/model/sign-up-code";
import { handleError, handleResponse } from "./utils";
import { NotFoundError } from "./api-error";
import { Ref, computed, ref } from "vue";
import { useQuery } from "@tanstack/vue-query";

export const useSignUpCode = (code: string | Ref<string>) => {
  const codeRef = ref(code);
  return useQuery<SignUpCode>({
    queryKey: ["signUpCode", code],
    queryFn: ({ signal }) => {
      return fetch(`/api/v1/sign_up_codes/${codeRef.value}`, {
        signal,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) =>
          handleResponse(response).catch((error) => {
            if (error instanceof NotFoundError) {
              return null;
            }
            throw error;
          })
        )
        .catch((error) => handleError(error));
    },
    enabled: computed(() => (codeRef.value ? codeRef.value.trim().length > 0 : false)),
  });
};
