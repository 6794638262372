<template>
  <page>
    <page-content>
      <h1>{{ $t("add_organization.title") }}</h1>
      <form @submit.prevent="onSubmit">
        <div class="field">
          <label>{{ $t("attributes.name") }}</label>
          <input v-model="name" type="text" required maxlength="70" />
        </div>
        <div class="actions">
          <button class="btn-primary-variant" type="submit" :disabled="!isValid">{{ $t("actions.save") }}</button>
          <button @click="onCancel">{{ $t("actions.cancel") }}</button>
        </div>
      </form>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Organizations from "@/services/organizations";

@Component({})
export default class AddOrganizationPage extends Vue {
  name = "";

  get organizationService() {
    return new Organizations();
  }

  get isValid() {
    return this.name.length > 0;
  }

  async onSubmit() {
    const { id } = await this.organizationService.create({
      name: this.name,
    });

    this.$router.replace({ name: "Organization", params: { id: String(id) } });
  }

  onCancel() {
    this.$router.back();
  }
}
</script>

<style lang="scss" scoped>
.actions {
  margin-top: $spacing-xl;

  button:not(:last-child) {
    margin-right: $spacing;
  }
}
</style>
