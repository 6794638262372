<template>
  <page>
    <div v-if="invitation_invalid" class="invitation-page">
      <h1>{{ $t("invitation.invitation_invalid") }}</h1>
    </div>
    <div v-if="invitation" class="invitation-page">
      <h1 class="hidden">{{ $t("invitation.invitation_to_join") }}</h1>
      <div class="invitation-page__logo text-text-emphasis-20 dark:text-text-primary">
        <inline-svg class="mx-auto" :src="require('@/assets/images/osgenic-logo.svg')" alt="Osgenic"></inline-svg>
      </div>
      <card v-if="$currentUser">
        <div>{{ $t("invitation.you_have_been_invited", { organization: invitation.customer_organization.name }) }}</div>
        <div class="actions">
          <button class="btn-primary" @click="onAccept">{{ $t("invitation.accept_invitation") }}</button>
          <button class="btn-secondary" @click="onCancel">{{ $t("actions.cancel") }}</button>
        </div>
      </card>
    </div>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Invitation from "@/model/invitation";
import Invitations from "@/services/invitations";
import { showDialog } from "@/utils/dialogs";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import { Prop } from "vue-property-decorator";
import { loadProfile } from "@/actions";

@Component({})
export default class InvitationJoinPage extends Vue {
  @Prop()
  token: string;

  invitation: Invitation = null;
  invitation_invalid: Boolean = false;

  get invitationService() {
    return new Invitations();
  }

  mounted() {
    this.invitationService
      .getInvitation(this.token)
      .then((invitation) => {
        this.invitation = invitation;
      })
      .catch(() => {
        this.invitation_invalid = true;
      });
  }

  async onAccept() {
    const action = this.invitationService.acceptInvitation(this.token);
    await showDialog<boolean>(LoadingIndicatorDialog, { action });
    loadProfile().finally(() => {
      this.$router.replace("/");
    });
  }

  onCancel() {
    this.$router.back();
  }
}
</script>

<style lang="scss" scoped>
.invitation-page {
  width: 440px;
  margin: auto;

  &__logo {
    margin-bottom: 50px;

    svg {
      width: 200px;
      height: auto;
    }
  }
}
.inline-field {
  label {
    display: inline;
  }
  label:not(:first-child) {
    padding-left: 1em;
  }
  input {
    display: inline;
    width: 10px; // This shouldn't have effect, but it does
  }
}
.details {
  padding: 1em;
  margin-bottom: 1em;
  p:first-child {
    margin-top: 0;
  }
  width: max-content;
  border-radius: 5px;
}
input:read-only {
  color: var(--ui-color-text-variant);
}
.actions {
  margin-top: var(--spacing-lg);
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: var(--spacing-sm);
  }
}
</style>
