<template>
  <page>
    <page-content>
      <loader-big></loader-big>
    </page-content>
  </page>
</template>

<script lang="ts">
import SharesService from "@/services/shares-service";
import { routeForContentItem } from "@/utils/routes";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class SharePage extends Vue {
  @Prop()
  token: string;

  created() {
    new SharesService()
      .accept(this.token)
      .then((share) => this.$router.push(routeForContentItem(share.content_item)))
      .catch(() => this.$router.push("/"));
  }
}
</script>
