<template>
  <input-icon icon="clock">
    <flat-pickr
      :value="value"
      :config="config"
      :placeholder="placeholder || $t('attributes.time') + '...'"
      @input="onInput"
    ></flat-pickr>
  </input-icon>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import flatPickr from "vue-flatpickr-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: { flatPickr },
})
export default class TimeInput extends Vue {
  @Prop()
  value: string;

  @Prop()
  placeholder: string;

  get config() {
    return {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      defaultHour: 0,
    };
  }

  onInput(value) {
    this.$emit("input", value);
  }
}
</script>
