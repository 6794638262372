import DynamicScene from "@/model/dynamic-scene";
import { useApiInfinitely } from "./utils";
import { Ref, computed } from "vue";

export const useDynamicScenesInfinitely = (search: Ref<string>) => {
  const urlRef = computed(() => {
    const url = new URL("/api/v1/dynamic_scenes", window.location.origin);
    if (search.value && search.value.trim().length > 0) {
      url.searchParams.append("q", search.value);
    }
    return url.toString();
  });
  return useApiInfinitely<DynamicScene[]>(urlRef);
};
