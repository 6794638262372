<template>
  <page>
    <page-header :title="'Osgenic End-User License Agreement'"> </page-header>
    <page-content class="py-8 px-9 max-w-3xl">
      <h3 class="my-8">Acceptance</h3>
      <p class="my-4">
        This Osgenic End-User License Agreement (“EULA”) governs the use of the service made available at osgenic.com
        (“Service”) by Osgenic Oy, a Finnish limited liability company, business ID 2851145-1 (“Osgenic”) to you
        (“Customer” or “you”). This EULA together with Appendix 1 “Data Processing Addendum” and any separate customer
        agreement (“Service Agreement”), form the complete agreement (“Agreement”) between Osgenic and the Customer. By
        using the Service, you accept the terms of the said Agreement. Osgenic reserves the right, at its sole
        discretion, to change, modify, add or remove portions of this EULA and Appendix 1, at any time. It is your
        responsibility to periodically review this EULA and Appendix 1 for changes. You will be notified of any material
        changes on the front page of the Service. Continuing to use the Service following the posting of changes will
        imply that you accept and agree to the changes.
      </p>
      <h3 class="my-8">The Service</h3>
      <p class="my-4">
        The Service is intended for healthcare professionals and is available to individuals who either sign up
        individually (“Individual User”) or in their capacity as an employee or representative of a business entity or
        other organization (“Customer Organization”) that has executed a (“Service Agreement”) with Osgenic with respect
        to the Service. In case you access the Service as a representative of a Customer Organization, you represent and
        warrant that you have the authority to bind the Customer Organization to this Agreement. This Agreement is also
        binding on the Customer Organization as a representative of which you are signing into and using the Service.
        You must provide accurate and complete information and keep your information updated. You must notify Osgenic
        immediately of any change in your eligibility to use the Service. If there is any discrepancy between this EULA
        and the Service Agreement, the Service Agreement shall, however, prevail.
      </p>
      <p class="my-4">
        BY USING THIS SITE, YOU WARRANT YOU ARE OF LEGAL AGE TO FORM A BINDING AGREEMENT AND FURTHER AGREE TO BE BOUND
        BY THIS EULA. IF YOU ARE NOT OF LEGAL AGE TO FORM A BINDING AGREEMENT OR DO NOT WISH TO BE BOUND BY THIS EULA,
        PLEASE EXIT THIS SITE NOW. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THIS SITE, OR ANY SERVICES, CONTENT, OR
        OTHER INFORMATION AVAILABLE ON OR THROUGH THIS SITE, IS TO STOP USING THE SITE AND THE SERVICE. YOUR AGREEMENT
        WITH US REGARDING COMPLIANCE WITH THIS EULA BECOMES EFFECTIVE IMMEDIATELY UPON COMMENCEMENT OF YOUR ACCESS TO
        THIS SITE.
      </p>
      <p class="my-4">
        THIS SITE AND THE SERVICE ARE FOR INFORMATIONAL PURPOSES ONLY. THEY ARE DESIGNED TO SERVE AS A SOURCE OF
        INFORMATION FOR HEALTHCARE PROFESSIONALS, NOT AS A SUBSTITUTE FOR PROFESSIONAL MEDICAL CARE. IN NO WAY DOES THE
        COMPANY REPRESENT THAT THIS SITE, THE SERVICE OR THE INFORMATION ON THIS SITE OR THE SERVICE CAN OR SHOULD BE
        USED TO MAKE MEDICAL, CLINICAL OR OTHER DECISIONS RELATED TO PATIENT CARE. INDIVIDUALS IN NEED OF MEDICAL CARE
        SHOULD CONSULT HEALTHCARE PROFESSIONALS. THE COMPANY IS NOT LIABLE IF THIS SITE, THE SERVICE OR ANY INFORMATION
        ON THIS SITE IS USED FOR PATIENT CARE.
      </p>
      <p class="my-4">
        You shall use the Service in compliance with this EULA. Any misuse of the Service may lead to termination of the
        EULA or suspension and/or denial of access to the Service.
      </p>
      <p class="my-4">
        This EULA only covers the Service and the use thereof and any and all linked third party services and platforms
        are provided by the relevant third parties and covered by their terms of service or other agreement or license.
        Osgenic shall not be liable to you for any direct or indirect damages arising from your use of third-party
        services. In addition, Osgenic shall not be liable for interruptions to the availability of the services
        provided by third parties.
      </p>
      <p class="my-4">
        Osgenic reserves the right to update pricing and payment terms at the end of your then-current agreement term.
        You will be notified in advance of any such changes, and you will have the right to terminate your subscription
        prior to the changes taking effect.
      </p>
      <h3 class="my-8">Intellectual Property Rights</h3>
      <p class="my-4">
        “Intellectual Property Rights” shall mean copyrights and related rights (including database and catalogue rights
        and photography rights), patents, utility models, design rights, trademarks, tradenames, trade secrets, know-how
        and any other form of registered or unregistered intellectual property rights.
      </p>
      <p class="my-4">
        All Intellectual Property Rights to and in the Service and all content on the Service and available through the
        Service, including, but not limited to, designs, text, graphics, images, video, information, applications,
        software, sound, designs, models and other files, and their selection and arrangement (the "Content"), as well
        as Intellectual Property Rights pertaining thereto, are exclusive property of Osgenic or its licensors with all
        rights reserved.
      </p>
      <p class="my-4">
        Except as expressly stated herein, this EULA does not grant you any Intellectual Property Rights in the Service
        and Content and all rights not expressly granted hereunder are reserved by Osgenic and its subcontractors or
        licensors, as the case may be.
      </p>
      <p class="my-4">
        Osgenic shall have the right to collect and generate anonymous data and statistics from the User Data and your
        use of the Service ("Aggregate Data") for updates and development of the Service. The Intellectual Property
        Rights and title to Aggregate Data shall belong to Osgenic.
      </p>
      <p class="my-4">
        At any time, if Osgenic reasonably deems that any part of the Service infringes the Intellectual Property Rights
        of any third party, Osgenic has the right at its own expense to (i) modify/replace the Service to eliminate the
        infringement in such a manner that the modified Service complies with this EULA, or (ii) procure to you a right
        to use the Service. If none of the alternatives are reasonable possible, Osgenic shall have the right to
        terminate the EULA.
      </p>
      <h3 class="my-8">Your obligations and rights</h3>
      <p class="my-4">
        Subject to your timely payment of all fees due under the Agreement and compliance with the terms agreed herein,
        Osgenic grants and you hereby accept a limited, non-exclusive, non-transferable, and non-sublicensable right to
        access and use the Service during the term of this EULA for the purposes set out herein. The Service may be used
        only in accordance with the documentation provided by Osgenic and in an unmodified form, and solely for your
        business purposes. Your access and use of the Service shall comply with all other conditions set forth in the
        EULA.
      </p>
      <p class="my-4">You are not permitted and not entitled to do any of the following:</p>
      <ul class="text-xs sm:text-sm">
        <li>
          share, distribute or transfer your sign-in credentials to anyone or allow anyone else to use the Service with
          your credentials, as the Service is intended for your personal use only;
        </li>
        <li>
          copy, redistribute, reproduce, record, transfer, perform or display to the public, broadcast, or make
          available to the public any part of the Service or the Content, or otherwise make any use of the Service or
          the Content which is not expressly permitted under the EULA or applicable law or which otherwise infringes the
          Intellectual Property Rights (such as copyright) in the Service or the Content or any part thereof or any
          other Intellectual Property Rights of third parties;
        </li>
        <li>
          use the Service and the Content in any manner that could damage, disable, overburden or impair the Service or
          the Content;
        </li>
        <li>use any data mining, robots, scraping, or similar data gathering or extraction methods;</li>
        <li>
          use, sell, rent, transfer, license or otherwise provide anybody with the Service and/or the Content, except as
          provided herein;
        </li>
        <li>interfere with other Users’ use and enjoyment of the Service;</li>
        <li>circumvent or try to circumvent any usage control or anti-copy functionalities of the Service;</li>
        <li>
          reverse engineer or decompile the Service or access the source code thereof, except as permitted by law;
        </li>
        <li>
          use the Service for transmitting any unauthorised advertising, promotional materials, junk mail, spam, chain
          letters, contests, pyramid schemes, or any other form of solicitation or mass messaging;
        </li>
        <li>use the Service in violation of applicable law;</li>
        <li>
          use the Service in ways that violate Intellectual Property Rights, business secrets or privacy of third
          parties; and
        </li>
        <li>
          use the Service to transmit any material that contains adware, malware, spyware, software viruses, worms or
          any other computer code designed to interrupt, destroy, or limit the functionality of computer software or
          equipment.
        </li>
      </ul>
      <p class="my-4">
        Notwithstanding the foregoing, you may use images available in the Service and images you have created using the
        Service for non-commercial training purposes. In such cases, Osgenic copyright must be presented alongside any
        such image(s).
      </p>
      <h3 class="my-8">User data</h3>
      <p class="my-4">
        “User Data” shall mean data submitted to the Service by you except for personal data provided by you in
        connection with the registration to and use of the Service that Osgenic processes as a data controller.
      </p>
      <p class="my-4">
        The Intellectual Property Rights and the title to the User Data shall belong to the You or the Customer
        Organization as the case may be. Osgenic and its subcontractors (subject to applicable data protection laws) may
        use, copy, store, and modify User Data during the term of this EULA for the purposes of providing the Service to
        you as well as analyze the use of the Service.
      </p>
      <p class="my-4">
        The Service shall not be used as a storage service. You shall be solely responsible for storing appropriate
        backup copies of the User Data. You shall be responsible for the User Data and shall be liable for ensuring that
        User Data does not infringe any third party rights or violate applicable legislation, and that you possess such
        necessary licences and permissions from third parties as may be required in order to use the User Data as set
        out herein. The Service may not be used to store patient data or documents or any personal data identifying any
        natural person. User Administrators are able to access certain User Data and certain data relating to your use
        of the Service.
      </p>
      <p class="my-4">
        With the consent of the User Administrator, it may be possible to export data relating to your use of the
        Service to Osgenic’s third party customer organization’s user account with the Service when you no longer are an
        employee or a representative of the Customer Organization and there is a valid customer agreement between
        Osgenic and such third party customer organization with respect to the Service.
      </p>
      <h3 class="my-8">Feedback</h3>
      <p class="my-4">
        Should you provide any suggestion regarding the Service or any of Osgenic’s products or services to Osgenic
        (“Feedback”), you hereby grant to Osgenic a perpetual, unrestricted, free, irrevocable, worldwide license to
        use, modify, assign and grant licenses to any such Feedback. Notwithstanding what is stated below in section
        titled “Confidentiality”, Feedback will not be considered Customer Organization’s confidential information.
      </p>
      <h3 class="my-8">Personal data</h3>
      <p class="my-4">
        Osgenic may collect and process data, including personal data, in relation to your registration and use of the
        Service, such as contact information and identification data. Osgenic processes such personal data in accordance
        with its Privacy Policy in force from time to time. For clarity, such personal data is not considered User Data.
      </p>
      <h3 class="my-8">Limited Warranty</h3>
      <p class="my-4">
        Except as specifically provided under this EULA, the Service is provided "as is" and with the functionalities
        available at each time without warranty of any kind, either express or implied, including but not limited to the
        warranties of merchantability and fitness for a particular purpose.
      </p>
      <p class="my-4">
        The Service may include images of or references to instruments, implants or equipment manufactured by medical
        product manufacturers. Any such elements must be used in accordance with the instructions provided by the
        manufacturer thereof and Osgenic assumes no liability for such instruments and equipment or use thereof.
      </p>
      <p class="my-4">
        The Service and any text, image and video contained therein is for illustrative purposes only. The
        circumstances, lighting and setting in which the video has been recorded may differ from situations in real life
        and therefore the user must not rely on the accuracy or medical correctness of the content. Osgenic assumes no
        responsibility for any medical decisions made regarding the treatment of an individual patient. You hereby
        acknowledge and agree that Osgenic shall have no liability whatsoever in connection with or arising from your
        use of the Service or other services of Osgenic, as set forth herein.
      </p>
      <p class="my-4">
        Osgenic will make reasonable efforts to keep the Service operational. However, certain technical difficulties or
        maintenance may, from time to time, result in temporary interruptions. To the extent permissible under
        applicable law, Osgenic reserves the right, periodically and at any time, to modify or discontinue, temporarily
        or permanently, functions and features of the Service, with or without notice, all without liability to you,
        except where prohibited by law, for any interruption, modification, or discontinuation of the Service or any
        function or feature thereof.
      </p>
      <p class="my-4">
        You understand, agree, and accept that Osgenic has no obligation to maintain, support, upgrade, or update the
        Service, or to provide all or any specific Content through the Service. This section will be enforced to the
        extent permissible by applicable law. Osgenic and/or the owners of any Content may, from time to time, remove
        any such Content without notice to the extent permitted by applicable law.
      </p>
      <h3 class="my-8">Indemnity</h3>
      <p class="my-4">
        You agree that you shall indemnify and hold Osgenic harmless from and against any and all liabilities, losses,
        damages, costs, and expenses (including reasonable legal fees and expenses) associated with any claim or action
        brought against Osgenic that may arise from your use of the Service in breach of this Agreement, including
        claims that the User Data infringes the Intellectual Property Rights or privacy rights of third parties.
      </p>
      <h3 class="my-8">Term and termination</h3>
      <p class="my-4">
        You agree that we may, at our sole discretion, terminate or suspend your access to all of part of this site with
        or without notice and for any reason, including, without limitation, breach of this EULA. Any suspected
        fraudulent, abusive or illegal activity may be grounds for terminating your relationship and may be referred to
        appropriate law enforcement authorities.
      </p>
      <p class="my-4">
        Upon any expiration or termination of this EULA, all rights, obligations and licenses of the Parties shall
        cease, and we may immediately deactivate or delete your account and bar any further access to such files or this
        site. In case of expiration or termination, the following shall survive: (a) all obligations that accrued prior
        to the effective date of termination; and (b) all remedies for any breach of this EULA.
      </p>
      <h3 class="my-8">Force Majeure</h3>
      <p class="my-4">
        Osgenic shall not be liable for any delay or failure in performing its obligations hereunder that arises out of
        any cause, condition or circumstance beyond its reasonable control.
      </p>
      <h3 class="my-8">Confidentiality</h3>
      <p class="my-4">
        You shall not disclose to third parties any material or information received from Osgenic and marked as
        confidential or which should be understood to be confidential, and you shall not use such material or
        information for any other purposes than those stated in this EULA.
      </p>
      <p class="my-4">
        The confidentiality obligation shall, however, not be applied to material or information, (a) which is generally
        available or otherwise public; or (b) which you have received from a third party without any obligation of
        confidentiality; or (c) which was in your possession prior to receipt of the same from Osgenic without any
        obligation of confidentiality related thereto; or (d) which you have independently developed without using
        material or information received from Osgenic.
      </p>
      <h3 class="my-8">Limitation of liability</h3>
      <p class="my-4">
        Osgenic has no other obligations or liabilities than those that have expressly been agreed upon in this EULA.
        Neither Party shall be liable for any indirect or consequential damages. Nothing contained herein shall be
        deemed to limit each Party’s liability towards the other Party in the event of and to the extent that the
        damages are caused by wilful misconduct or gross negligence of the breaching Party.
      </p>
      <h3 class="my-8">Severance</h3>
      <p class="my-4">
        If any provision of this EULA is declared by any judicial or other competent authority to be void, illegal or
        otherwise unenforceable, the remaining provisions of this EULA shall remain in full force and effect.
      </p>
      <h3 class="my-8">Assignment</h3>
      <p class="my-4">
        Osgenic shall be entitled to assign all or any of its rights or obligations hereunder in whole or part to an
        affiliate or successor or to a purchaser or acquirer of its business assets without your prior consent. You
        shall not be entitled to assign any of your rights or obligations hereunder in any extent.
      </p>
      <h3 class="my-8">Export restrictions</h3>
      <p class="my-4">
        You agree to comply with any export restrictions in force in any jurisdiction that may be applied to the
        provision of the Service hereunder.
      </p>
      <h3 class="my-8">Entire Agreement</h3>
      <p class="my-4">
        This EULA supersedes all prior agreements, arrangements, and understandings between the Parties relating to the
        subject matter hereof between the Parties relating to the subject matter hereof.
      </p>
      <h3 class="my-8">Applicable law and dispute resolution</h3>
      <p class="my-4">
        ARBITRATION NOTICE AND CLASS ACTION WAIVER FOR CUSTOMERS LOCATED IN THE UNITED STATES: EXCEPT FOR CERTAIN TYPES
        OF DISPUTES DESCRIBED IN THIS SECTION (APPLICABLE LAW AND DISPUTE RESOLUTION), YOU AGREE THAT DISPUTES BETWEEN
        YOU AND OSGENIC WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A
        CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION
      </p>
      <p class="my-4">
        This EULA is executed in accordance with and shall be governed by the laws of Finland, excluding its choice of
        law provisions.
      </p>
      <p class="my-4">
        Any dispute, controversy or claim arising out of or relating to this EULA, or a breach, termination or validity
        thereof shall be settled primarily by amicable negotiations between the Parties.
      </p>
      <p class="my-4">
        Should negotiations not lead to a settlement between the Parties, any dispute, controversy or claim arising out
        of or relating to this EULA, or the breach, termination or validity thereof, shall be finally settled by
        arbitration in accordance with the Arbitration Rules of the Finland Chamber of Commerce. The number of
        arbitrators shall be one. The seat of arbitration shall be Helsinki, Finland. The language of arbitration shall
        be English.
      </p>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class EULAPage extends Vue {}
</script>
