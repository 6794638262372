<template>
  <div v-if="!contentItem" class="w-full h-full flex justify-center items-center">
    <loader-big></loader-big>
  </div>
  <public-procedure-view v-else-if="isProcedure" :content-item="contentItem"></public-procedure-view>
  <public-content-view v-else :content-item="contentItem"></public-content-view>
</template>

<script lang="ts">
import ContentItem from "@/model/content-item";
import Page from "@/model/page";
import ContentItemsService from "@/services/content-items-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PublicContentView from "./public-content-view.vue";
import PublicProcedureView from "./public-procedure-view.vue";

@Component({
  components: {
    PublicContentView,
    PublicProcedureView,
  },
})
export default class PublicContentPage extends Vue {
  @Prop()
  token: string;

  contentItem: ContentItem = null;

  get contentItemsService() {
    return new ContentItemsService();
  }

  get page(): Page {
    return this.contentItem.content.page;
  }

  get isProcedure() {
    return this.page.page_type === "procedure";
  }

  async created() {
    this.contentItem = await this.contentItemsService.getPublic(this.token);
  }
}
</script>
