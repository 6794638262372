import dayjs from "dayjs";
import "dayjs/locale/fi";

import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

(dayjs as any).updateLocale("fi", {
  relativeTime: {
    past: "%s sitten",
    s: "muutama sekunti",
    m: "Minuutti",
    mm: "%d minuuttia",
    h: "Tunti",
    hh: "%d tuntia",
    d: "Päivä",
    dd: "%d päivää",
    M: "Kuukausi",
    MM: "%d kuukautta",
    y: "Vuosi",
    yy: "%d vuotta",
  },
});

export default dayjs;
