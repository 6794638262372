import ApiService from "@/services/api-service";

interface ChatHistory {
  role: string;
  content: string;
}

interface SpecialtySearchParams {
  specialty_area: string;
  chat_history: ChatHistory[];
}

interface PubmedSearchParams {
  chat_history: ChatHistory[];
}

export default class AiSearchService extends ApiService<SpecialtySearchParams | PubmedSearchParams> {
  get baseUrl(): string {
    return "/api/v1";
  }

  // Custom fetch method for AI endpoints that need POST but aren't creating resources
  protected fetchWithPost<T>(action: string, data: T): Promise<any> {
    const url = this.buildUrl(null, { action });
    const headers = this.buildHeaders();

    return fetch(url, {
      method: "post",
      headers,
      body: this.buildBody(data),
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  specialtySearch(params: SpecialtySearchParams): Promise<any> {
    return this.fetchWithPost("specialty_search", params);
  }

  pubmedSearch(params: PubmedSearchParams): Promise<any> {
    return this.fetchWithPost("pubmed_search", params);
  }
}
