<template>
  <router-link ref="navFolder" class="nav-folder px-5" :to="path" :event="[]">
    <div
      class="nav-folder-title flex items-center text-label-strong group text-text-primary h-[60px] dark:text-text-variant hover:text-text-emphasis dark:hover:text-text-emphasis"
      @click="handleClick"
    >
      <div class="w-5 h-5 flex items-center justify-center mr-2">
        <icon-fluent class="group-hover:hidden" :name="icon"></icon-fluent>
        <icon-fluent class="hidden group-hover:inline-block" :name="icon" variant="filled"></icon-fluent>
      </div>
      <div class="title flex-1">{{ title }}</div>
      <div class="open-icon">
        <icon-fluent :name="open ? 'chevronUp' : 'chevronDown'" variant="filled"></icon-fluent>
      </div>
    </div>
    <div v-if="open" class="sublinks">
      <slot></slot>
    </div>
  </router-link>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

@Component({})
export default class NavFolder extends Vue {
  @Ref("navFolder")
  navFolder: any;

  @Prop()
  title: string;

  @Prop()
  icon: string;

  @Prop()
  path: string;

  open = false;

  handleClick() {
    this.open = !this.open;
  }

  created() {
    this.$nextTick(() => {
      if (this.navFolder && this.navFolder.$el.classList.contains("router-link-active")) {
        this.open = true;
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.nav-folder {
  cursor: pointer;
  text-decoration: none;
  border-radius: 30px;

  @media (hover: hover) {
    &:hover {
      // Set normal brightness for non-active links and exact links
      // (non-exact links have modified brightness declared with css class)
      &:not(.router-link-active),
      &.router-link-exact-active {
        filter: brightness(1);
      }
    }
  }

  &.router-link-active {
    background-color: var(--ui-color-surface-low-contrast);
    color: var(--ui-color-text-emphasis);

    @media (hover: hover) {
      // Specify hover color to be the same as non-hover for active links
      // Non active links get hover color from css class
      &:hover {
        color: var(--ui-color-text-emphasis);
      }
    }

    svg.regular {
      display: none;
    }
    svg.filled {
      display: inline-block;
    }
  }

  .sublinks::v-deep {
    a {
      display: block;
      margin-bottom: 20px;

      &.router-link-active {
        color: var(--ui-color-text-emphasis);
      }
    }
  }
}
</style>
