<template>
  <div class="bg-surface-min-contrast rounded sm:bg-transparent">
    <div class="h-10 flex items-center pl-4 pr-2 sm:hidden">
      <icon-fluent class="mr-1" name="filter" size="16" variant="filled"></icon-fluent>
      <div class="text-label-medium">{{ $t("contents.filters") }}</div>
      <div class="flex-1"></div>
      <button class="btn-icon" type="button" @click="open = !open">
        <icon-fluent :name="open ? 'chevronUp' : 'chevronDown'" size="16" variant="filled"></icon-fluent>
      </button>
    </div>
    <div class="flex-1 flex-col gap-y-sm items-center p-4 sm:p-0 sm:flex sm:flex-row" :class="open ? 'flex' : 'hidden'">
      <div class="flex gap-y-sm flex-col w-full sm:flex-1 sm:flex-row sm:w-auto">
        <speciality-select
          v-model="value.speciality_ids"
          class="w-full mr-md sm:max-w-60 sm:max-w-[240px]"
          :allow-null="true"
          :multiselect="true"
          :floating-label="true"
          :used-values-only="true"
        ></speciality-select>
        <location-select
          v-model="value.location_ids"
          class="w-full mr-md sm:max-w-60 sm:max-w-[240px]"
          :allow-null="true"
          :multiselect="true"
          :floating-label="true"
          :used-values-only="true"
          :user="user"
          :customer-organization="customerOrganization"
        ></location-select>
        <content-by-select
          class="w-full mr-md sm:max-w-60 sm:max-w-[240px]"
          :filters="value"
          :floating-label="true"
          @change="onUpdateFilters"
        ></content-by-select>
      </div>
      <div class="flex gap-4 w-full items-center justify-between flex-row-reverse sm:w-auto sm:justify-end sm:flex-row">
        <favorited-filter-toggle
          v-model="value.favorited_by_ids"
          :enabled="value.favorited_by_ids.includes($currentUser.id)"
        ></favorited-filter-toggle>
        <button class="btn-tertiary btn-sm gap-2" :disabled="!hasActiveFilters" @click="resetFilters">
          <icon-fluent class="hidden sm:inline-block" name="dismiss" size="16" variant="filled"></icon-fluent>
          <span>{{ $t("actions.reset_filters") }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import User from "@/model/user";
import Organization from "@/model/organization";
import ContentItemFilters, { buildEmptyFilters } from "@/model/content-item-filters";
import { isEqual } from "lodash";

@Component({})
export default class ContentsPrimaryFilters extends Vue {
  @Prop()
  value: any;

  @Prop()
  user: User;

  @Prop()
  customerOrganization: Organization;

  open = false;

  get primaryFilters(): string[] {
    return [
      "owner_user_ids",
      "organization_ids",
      "favorited_by_ids",
      "speciality_ids",
      "location_ids",
      "content_types",
    ];
  }

  get defaultFilters() {
    return {
      ...buildEmptyFilters(),
      owner_user_ids: [this.$currentUser.id],
      organization_ids: this.$currentUser.organizations.map((org: Organization) => org.id),
      shared: true,
    };
  }

  get hasActiveFilters(): boolean {
    return !isEqual(this.value, this.defaultFilters);
  }

  resetFilters(): void {
    this.$emit("input", buildEmptyFilters());
  }

  onUpdateFilters(value: ContentItemFilters) {
    this.$emit("input", value);
  }
}
</script>

<style lang="scss" scoped>
.dropdown-select::v-deep {
  .dropdown__button-title {
    @apply text-base font-regular;
    letter-spacing: 0.5px;
  }
}
</style>
