<template>
  <router-link :to="route">
    <ui-card>
      <div class="px-4 py-2">
        <div class="text-label-large text-text-emphasis overflow-hidden text-ellipsis whitespace-nowrap">
          {{ title }}
        </div>
        <div class="text-body-small mt-2">{{ description }}</div>
      </div>
    </ui-card>
  </router-link>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class TabCard extends Vue {
  @Prop()
  title: string;

  @Prop()
  route: any;

  get description() {
    // TODO: don't show the card if there is no description?
    return this.$t(`home.${this.route.params["documentType"]}_description`);
  }
}
</script>
