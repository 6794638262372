<template>
  <node-view-wrapper as="span">
    <span v-if="editor.isEditable" data-drag-handle="">
      <quiz-button v-bind="node.attrs" @click="onEditorClick"></quiz-button>
    </span>
    <quiz-button v-else v-bind="node.attrs"></quiz-button>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";
import { showDialog } from "@/utils/dialogs";
import QuizLink from "@/editor/extensions/quiz-link/quiz-link";
import QuizLinkModal from "@/editor/extensions/quiz-link/quiz-link-modal.vue";

@Component({
  components: { NodeViewWrapper },
  props: nodeViewProps,
})
export default class QuizLinkNodeView extends Vue {
  @Prop()
  editor: Editor;

  @Prop()
  node: any;

  @Prop()
  getPos: () => number;

  async onEditorClick() {
    const quizLink = await showDialog<QuizLink>(QuizLinkModal, { quizLink: this.node.attrs });
    if (quizLink) {
      this.editor
        .chain()
        .setNodeSelection(this.getPos())
        .updateQuizLink({ ...this.node.attrs, ...quizLink })
        .focus()
        .run();
    }
  }
}
</script>
