<template>
  <modal>
    <h3 class="mb-4">{{ $t("clinical_block.title") }}</h3>
    <form @submit.prevent="onSubmit">
      <form-field :label="$t('attributes.name')" :error="$v.model.name.$error">
        <input v-model="model.name" type="text" :placeholder="$t('attributes.name') + '...'" />
      </form-field>
      <form-field :label="$t('attributes.duration_months')" :error="$v.model.duration_months.$error">
        <input v-model="model.duration_months" type="number" :placeholder="$t('attributes.duration_months') + '...'" />
      </form-field>
      <form-field :label="$t('attributes.speciality')">
        <speciality-select v-model="model.speciality_id"></speciality-select>
      </form-field>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.save") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { Prop } from "vue-property-decorator";
import cloneDeep from "lodash/cloneDeep";
import ClinicalBlock from "@/model/clinical-block";

@Component({
  components: {},
})
export default class LogbookClinicalBlockModal extends Vue {
  @Prop()
  clinicalBlock: ClinicalBlock;

  model: Partial<ClinicalBlock> = null;

  @Validations()
  validations = {
    model: {
      name: { required },
      duration_months: { required },
    },
  };

  created() {
    this.model = this.clinicalBlock
      ? cloneDeep(this.clinicalBlock)
      : {
          name: null,
          duration_months: null,
          curriculum_items: [],
        };
  }

  async onSubmit() {
    this.$v.$touch();
    if (!this.$v.$error) {
      this.$emit("ok", this.model);
    }
  }
}
</script>
