<template>
  <logbook-entry-card :entry="entry" :label="label">
    <section class="logbook-entry-card__section">
      <h4>{{ $t("item.details") }}</h4>
      <div class="correct">
        <strong>{{ $t("attributes.date") }}: </strong>{{ dateFilter(entry.date) }} {{ entry.end_date && "-" }}
        {{ dateFilter(entry.end_date) }}
      </div>
      <div class="date">
        <strong>{{ $t("quiz.correct_answers") }}: </strong>{{ entry.data.quiz_answer.correct_answers_count }} /
        {{ entry.data.quiz_answer.answers.length }}
      </div>
    </section>
  </logbook-entry-card>
</template>

<script lang="ts">
import { dateFilter } from "@/filters";
import LogbookEntry from "@/model/logbook-entry";
import QuizData from "@/model/quiz-data";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LogbookQuizCard extends Vue {
  @Prop()
  entry: LogbookEntry<QuizData>;

  get label() {
    return this.entry.data.quiz_answer.quiz.name;
  }

  dateFilter(value: string) {
    return dateFilter(value);
  }
}
</script>
