<template>
  <div class="language-selection right-5 top-5 text-text-emphasis-20 dark:text-text-primary">
    <button
      class="rounded-none rounded-l-sm text-sm"
      type="button"
      :class="isCurrentLocale('fi') ? 'btn-primary' : 'btn-clear'"
      @click="changeLocale('fi')"
    >
      FI
    </button>
    <button
      class="rounded-none rounded-r-sm text-sm"
      type="button"
      :class="isCurrentLocale('en') ? 'btn-primary' : 'btn-clear'"
      @click="changeLocale('en')"
    >
      EN
    </button>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { setLocale, getLocale } from "@/i18n";

export default class LanguageSelection extends Vue {
  changeLocale(locale) {
    setLocale(locale);
  }

  isCurrentLocale(locale) {
    return getLocale() === locale;
  }
}
</script>

<style lang="scss" scoped>
.language-selection {
  svg {
    width: 150px;
    height: auto;
  }
}
</style>
