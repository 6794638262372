<template>
  <card>
    <template slot="title">
      {{ clinicalBlock.name }} ({{ $t("format.months", { months: clinicalBlock.duration_months }) }})
    </template>
    <logbook-node v-if="procedures.length > 0" :title="$t('procedures.title')">
      <template #titleTrailing>
        <logbook-number-of-completed :entry-count="totalCount(procedures)"></logbook-number-of-completed>
      </template>
      <logbook-node v-for="procedure in procedures" :key="procedure.id" :title="procedure.name">
        <template #titleTrailing>
          <logbook-number-of-completed :entry-count="entryCount(procedure)"></logbook-number-of-completed>
        </template>
        <template #details>
          <item-details :item="procedure"></item-details>
        </template>
      </logbook-node>
    </logbook-node>
    <logbook-node v-if="courses.length > 0" :title="$t('courses.title')">
      <template #titleTrailing>
        <logbook-number-of-completed :entry-count="totalCount(courses)"></logbook-number-of-completed>
      </template>
      <logbook-node v-for="course in courses" :key="course.id" :title="course.name">
        <template #titleTrailing>
          <logbook-number-of-completed :entry-count="entryCount(course)"></logbook-number-of-completed>
        </template>
        <template #details>
          <item-details :item="course"></item-details>
        </template>
      </logbook-node>
    </logbook-node>
    <logbook-node v-if="quizzes.length > 0" :title="$t('quizzes.title')">
      <template #titleTrailing>
        <logbook-number-of-completed :entry-count="totalCount(quizzes)"></logbook-number-of-completed>
      </template>
      <logbook-node v-for="quiz in quizzes" :key="quiz.id">
        <template #title>
          <div class="flex flex-row">
            <div class="mr-sm">{{ quiz.name }}</div>
            <a class="link" @click="takeQuiz(quiz)">{{ $t("actions.take_quiz") }}</a>
          </div>
        </template>
        <template #titleTrailing>
          <logbook-number-of-completed :entry-count="entryCount(quiz)"></logbook-number-of-completed>
        </template>
        <template #details>
          <item-details :item="quiz" :user="user"></item-details>
        </template>
      </logbook-node>
    </logbook-node>
    <logbook-node v-if="otherRequirements.length > 0" :title="$t('other_requirements.title')">
      <template #titleTrailing>
        <logbook-number-of-completed :entry-count="totalCount(otherRequirements)"></logbook-number-of-completed>
      </template>
      <logbook-node v-for="other in otherRequirements" :key="other.id" :title="other.name">
        <template #titleTrailing>
          <logbook-number-of-completed :entry-count="entryCount(other)"></logbook-number-of-completed>
        </template>
        <template #details>
          <item-details :item="other"></item-details>
        </template>
      </logbook-node>
    </logbook-node>
  </card>
</template>

<script lang="ts">
import LogbookNumberOfCompleted, { EntryCount } from "@/components/logbook-curriculum/logbook-number-of-completed.vue";
import ClinicalBlock from "@/model/clinical-block";
import CurriculumItem from "@/model/curriculum-item";
import LogbookEntry from "@/model/logbook-entry";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ItemDetails from "@/components/logbook-curriculum/item-details.vue";
import QuizCurriculumItemData from "@/model/curriculum-item/quiz-curriculum-item-data";
import { showDialog } from "@/utils/dialogs";
import QuizModal from "@/components/quiz/quiz-modal.vue";
import User from "@/model/user";

@Component({
  components: {
    LogbookNumberOfCompleted,
    ItemDetails,
  },
})
export default class ClinicalBlockCard extends Vue {
  @Prop()
  clinicalBlock: ClinicalBlock;

  @Prop()
  entriesByCurriculumItem: Record<number, LogbookEntry<object>[]>;

  @Prop()
  user: User;

  get procedures() {
    return this.curriculumItems.filter(({ item_type }) => item_type === "procedure");
  }

  get courses() {
    return this.curriculumItems.filter(({ item_type }) => item_type === "course");
  }

  get quizzes() {
    return this.curriculumItems.filter(({ item_type }) => item_type === "quiz");
  }

  get otherRequirements() {
    return this.curriculumItems.filter(({ item_type }) => item_type === "other");
  }

  get curriculumItems(): CurriculumItem<any>[] {
    return this.clinicalBlock.curriculum_items || [];
  }

  entryCount(curriculumItem: CurriculumItem<object>): EntryCount {
    const done = (this.entriesByCurriculumItem[curriculumItem.id] || []).length;
    const required = curriculumItem.min_count || 1;
    return {
      done,
      required,
    };
  }

  totalCount(items: CurriculumItem<object>[]): EntryCount {
    return items
      .map((item) => this.entryCount(item))
      .reduce((c1, c2) => ({
        done: c1.done + c2.done,
        required: c1.required + c2.required,
      }));
  }

  takeQuiz(quizItem: CurriculumItem<QuizCurriculumItemData>) {
    showDialog(QuizModal, { id: quizItem.data.quiz_id });
  }
}
</script>
