import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import fi from "i18n-iso-countries/langs/fi.json";
import countriesAndTimezones from "countries-and-timezones";
import { getLocale } from "./i18n";
import _ from "lodash";

countries.registerLocale(en);
countries.registerLocale(fi);

export const getAllCountries = () => {
  return _.map(countries.getNames(getLocale(), { select: "official" }), (name, code) => {
    return { code, name };
  });
};

export const getTimezoneCountries = (tz: string) => {
  return countriesAndTimezones.getTimezone(tz)?.countries || [];
};

export const getCurrentTzCountry = () => {
  const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return getTimezoneCountries(currentTz)[0];
};

export default countries;
