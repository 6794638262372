<template>
  <div
    class="flex flex-col rounded sm:bg-surface-min-contrast sm:p-12 md:self-stretch"
    :class="{ 'bg-surface-min-contrast': !open }"
  >
    <div
      class="flex flex-row items-center p-4 sm:p-0 text-label-medium text-text-variant cursor-pointer sm:text-label-large"
      @click="open = !open"
    >
      <div class="flex-1">
        {{ $t("membership_and_billing.title") }}
      </div>
      <icon-fluent class="sm:hidden" :name="open ? 'chevronUp' : 'chevronDown'" size="16" variant="filled" />
    </div>
    <div class="flex-1 sm:flex flex-col rounded p-6 sm:p-0 sm:mt-12 bg-surface-min-contrast" :class="{ hidden: !open }">
      <div class="flex flex-col gap-2">
        <div class="text-label-small uppercase">{{ $t("plan.your_plan") }}</div>
        <div class="text-label-medium text-text-primary">{{ yourPlan }}</div>
        <a v-if="!cancelled" class="text-label-small text-text-emphasis cursor-pointer" @click="changePlan">{{
          $t("plan.review_or_change")
        }}</a>
      </div>
      <div class="flex flex-col gap-2 mt-12">
        <div class="text-label-small uppercase">{{ $t("plan.starting_date") }}</div>
        <div class="text-label-medium text-text-primary">{{ dateFilter(subscription.start_date) }}</div>
      </div>
      <div class="flex flex-col gap-2 mt-12">
        <div class="text-label-small uppercase">{{ $t("plan.payment_method") }}</div>
        <div class="text-label-medium text-text-primary">
          {{ $t("plan.payment_methods." + subscription.payment_method) }}
        </div>
        <a class="text-label-small text-text-emphasis cursor-pointer" @click="reviewPaymentMethod">{{
          $t("plan.review_or_change")
        }}</a>
      </div>
      <div v-if="!cancelled" class="flex flex-col gap-2 mt-12">
        <div class="text-label-small uppercase">{{ $t("plan.next_billing_date") }}</div>
        <div class="text-label-medium text-text-primary">{{ dateFilter(subscription.next_billing_date) }}</div>
      </div>
      <div class="flex-1"></div>
      <div class="mt-12">
        <button v-if="!cancelled" class="btn btn-primary" @click="cancelMembership">
          {{ $t("membership_and_billing.cancel_membership") }}
        </button>
        <button v-else class="btn btn-primary" @click="restartMembership">
          {{ $t("plan.restart_membership") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { dateFilter } from "@/filters";
import { showDialog } from "@/utils/dialogs";
import ChangePlanDialog from "./change-plan-dialog.vue";
import ReviewPaymentMethodDialog from "./review-payment-method-dialog.vue";
import CancelMembershipDialog from "./cancel-membership-dialog.vue";
import { useActiveSubscription } from "@/composables/session";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n-bridge";
import SubscriptionsService from "@/services/subscriptions-service";
import { loadProfile } from "@/actions";
import LoadingIndicatorView from "@/pages/loading-indicator-view.vue";
import { useToaster } from "@/composables/toaster";

const open = ref(false);

const { t } = useI18n();

const toaster = useToaster();

const subscription = useActiveSubscription();

const cancelled = computed(() => subscription.value.cancel_date);

const yourPlan = computed(() => {
  if (cancelled.value) {
    return t("plan.no_active_plan");
  } else if (subscription.value.scheduled_plan_period) {
    return "Osgenic " + t("plan.period." + subscription.value.scheduled_plan_period) + ` (${t("plan.upcoming")})`;
  } else {
    return "Osgenic " + t("plan.period." + subscription.value.period);
  }
});

const changePlan = () => {
  showDialog(ChangePlanDialog);
};

const reviewPaymentMethod = () => {
  showDialog(ReviewPaymentMethodDialog);
};

const cancelMembership = () => {
  showDialog(CancelMembershipDialog);
};

const uncancelSubscription = async () => {
  await new SubscriptionsService().uncancelSubscription(subscription.value);
  await loadProfile();
};

const restartMembership = async () => {
  const title = t("plan.updating_subscription");
  const action = uncancelSubscription();
  await showDialog(LoadingIndicatorView, { title, action });
  toaster.success(t("plan.membership_restarted"));
};
</script>

<style scoped></style>
