<template>
  <div
    class="inline-flex items-center justify-center"
    :class="{ group: !disabled, 'cursor-pointer': !disabled }"
    @click="onClick"
  >
    <icon-fluent
      class="group-hover:hidden"
      :name="icon"
      variant="regular"
      :class="disabled ? 'text-text-variant' : 'text-text-primary'"
    ></icon-fluent>
    <icon-fluent class="hidden text-text-emphasis group-hover:inline-block" :name="icon" variant="filled"></icon-fluent>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Icon extends Vue {
  @Prop()
  icon: string;

  @Prop()
  disabled: boolean;

  onClick() {
    if (!this.disabled) {
      this.$emit("click");
    }
  }
}
</script>
