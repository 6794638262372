<template>
  <form class="add-comment-form">
    <input ref="fileInput" class="hidden" type="file" @input="onFileInput" />
    <textarea ref="textarea" v-model="message" :placeholder="$t('comments.input_placeholder')"></textarea>
    <div class="flex">
      <div v-for="attachment in attachments" :key="attachment.url" class="flex flex-row items-center mr-sm">
        <a class="link" :href="attachment.url" target="_blank">{{ attachment.filename }}</a>
        <a @click="removeAttachment(attachment)"> <icon class="ml-xs" icon="times"></icon></a>
      </div>
    </div>
    <div class="flex justify-between mt-sm">
      <a class="inline-block px-sm py-xs" @click="addAttachment">
        <icon class="mr-xs" icon="paperclip"></icon>{{ $t("actions.add_attachment") }}
      </a>
      <button class="btn-primary py-xs px-sm" :disabled="!isValid" @click="addComment">{{ $t("actions.send") }}</button>
    </div>
  </form>
</template>

<script lang="ts">
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import Attachment from "@/model/attachment";
import Upload from "@/model/upload";
import UploadService from "@/services/upload-service";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";

@Component({})
export default class AddCommentForm extends Vue {
  @Ref()
  textarea: HTMLTextAreaElement;

  @Ref()
  fileInput: HTMLInputElement;

  message = "";
  attachments: Attachment[] = [];

  get uploadService() {
    return new UploadService();
  }

  get isValid() {
    return this.message.trim().length > 0;
  }

  addAttachment() {
    this.fileInput.click();
  }

  onFileInput() {
    if (this.fileInput.files.length > 0) {
      Array.from(this.fileInput.files).forEach(async (file) => {
        const action = this.uploadService.uploadFile(file);
        const upload = await showDialog<Upload>(LoadingIndicatorDialog, { action });
        this.attachments.push(upload as Attachment);
      });
    }
  }

  removeAttachment(attachment: Attachment) {
    this.attachments = this.attachments.filter((it) => it !== attachment);
  }

  async addComment() {
    if (!this.isValid) {
      return;
    }
    this.textarea.blur();
    this.$emit("add-comment", {
      message: this.message,
      attachments: this.attachments,
    });
    this.message = "";
    this.attachments = [];
  }
}
</script>
