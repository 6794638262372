export default interface Speciality {
  id: number;
  parent_id?: number;
  code: string;
  name: string;
  resident_count: number;
}

export const SOFT_LAUNCH_SPECIALITY_CODES = [
  "gastrointestinal_surgery",
  "hand_surgery",
  "pediatric_surgery",
  "orthopedics",
  "plastic_surgery",
  "thoracic_surgery",
  "urology",
  "vascular_surgery",
  "general_surgery",
  "or_foot_and_ankle",
  "or_joint_replacement",
  "or_orthopedic_oncology",
  "or_pediatric_orthopedics",
  "or_shoulder_and_elbow",
  "or_spine",
  "or_trauma",
];
