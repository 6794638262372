<template>
  <list>
    <list-header>
      <h3>{{ title }}</h3>
      <template #trailing>
        <dropdown :title="$t('actions.add_requirement')" :right="true" :to-right="true">
          <action-link :title="$t('procedures.add_procedure')" @click="addItem('procedure')"></action-link>
          <action-link :title="$t('courses.add_course')" @click="addItem('course')"></action-link>
          <action-link :title="$t('quizzes.add_quiz')" @click="addItem('quiz')"></action-link>
          <action-link :title="$t('other_requirements.add_other_requirement')" @click="addItem('other')"></action-link>
        </dropdown>
      </template>
    </list-header>
    <list-subheader v-if="procedures.length > 0">{{ $t("procedures.title") }}</list-subheader>
    <curriculum-item-view
      v-for="procedure in procedures"
      :key="procedure.id"
      :item="procedure"
      @edit="editItem(procedure)"
      @delete="deleteItem(procedure)"
    ></curriculum-item-view>
    <list-subheader v-if="courses.length > 0">{{ $t("courses.title") }}</list-subheader>
    <curriculum-item-view
      v-for="course in courses"
      :key="course.id"
      :item="course"
      @edit="editItem(course)"
      @delete="deleteItem(course)"
    ></curriculum-item-view>
    <list-subheader v-if="quizzes.length > 0">{{ $t("quizzes.title") }}</list-subheader>
    <curriculum-item-view
      v-for="quiz in quizzes"
      :key="quiz.id"
      :item="quiz"
      @edit="editItem(quiz)"
      @delete="deleteItem(quiz)"
    ></curriculum-item-view>
    <list-subheader v-if="otherRequirements.length > 0">{{ $t("other_requirements.title") }}</list-subheader>
    <curriculum-item-view
      v-for="other in otherRequirements"
      :key="other.id"
      :item="other"
      @edit="editItem(other)"
      @delete="deleteItem(other)"
    ></curriculum-item-view>
  </list>
</template>

<script lang="ts">
import LogbookItemModal from "@/components/logbook/logbook-item-modal.vue";
import ClinicalBlock from "@/model/clinical-block";
import CurriculumItem, { CurriculumItemType } from "@/model/curriculum-item";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CurriculumItemsService from "@/services/curriculum-items-service";
import ProcedureItemData from "@/model/procedure-item-data";
import CurriculumItemView from "@/components/logbook/curriculum-item-view.vue";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import { MethodNotAllowedError } from "@/api/api-error";
import AlertDialog from "@/components/alert-dialog.vue";

@Component({
  components: {
    CurriculumItemView,
  },
})
export default class ClinicalBlockCard extends Vue {
  @Prop()
  clinicalBlock: ClinicalBlock;

  get curriculumItemsService() {
    return new CurriculumItemsService();
  }

  get procedures() {
    return this.curriculumItems.filter(({ item_type }) => item_type === "procedure");
  }

  get courses() {
    return this.curriculumItems.filter(({ item_type }) => item_type === "course");
  }

  get quizzes() {
    return this.curriculumItems.filter(({ item_type }) => item_type === "quiz");
  }

  get otherRequirements() {
    return this.curriculumItems.filter(({ item_type }) => item_type === "other");
  }

  get curriculumItems(): CurriculumItem<any>[] {
    return this.clinicalBlock.curriculum_items || [];
  }

  get title(): string {
    const details =
      (this.clinicalBlock.speciality ? `${this.clinicalBlock.speciality.name}, ` : "") +
      this.$t("format.months", { months: this.clinicalBlock.duration_months });
    const title = `${this.clinicalBlock.name} (${details})`;
    return title;
  }

  async addItem(item_type) {
    const curriculumItem = await this.showItemModal(item_type);

    if (curriculumItem) {
      await this.curriculumItemsService.create(curriculumItem);

      const updated: ClinicalBlock = {
        ...this.clinicalBlock,
        curriculum_items: [...this.curriculumItems, curriculumItem],
      };

      this.$emit("updated", updated);
    }
  }

  async editItem(curriculumItem) {
    curriculumItem = await showDialog<CurriculumItem<object>>(LogbookItemModal, { curriculumItem });

    if (curriculumItem) {
      curriculumItem = await this.curriculumItemsService.update(curriculumItem.id, curriculumItem);

      const updated: ClinicalBlock = {
        ...this.clinicalBlock,
        curriculum_items: this.curriculumItems.map((each) => (each.id === curriculumItem.id ? curriculumItem : each)),
      };

      this.$emit("updated", updated);
    }
  }

  async deleteItem(curriculumItem) {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.delete_permanently") });

    if (confirmed) {
      try {
        await this.curriculumItemsService.delete(curriculumItem.id);

        const updated: ClinicalBlock = {
          ...this.clinicalBlock,
          curriculum_items: this.curriculumItems.filter(({ id }) => id !== curriculumItem.id),
        };
        this.$emit("updated", updated);
      } catch (error) {
        if (error instanceof MethodNotAllowedError) {
          showDialog(AlertDialog, { message: this.$t("logbook.delete_not_allowed") });
        } else {
          throw error;
        }
      }
    }
  }
  private showItemModal(item_type: CurriculumItemType) {
    return showDialog<CurriculumItem<object>>(LogbookItemModal, {
      curriculumItem: {
        item_type,
        name: null,
        description: null,
        clinical_block_id: this.clinicalBlock.id,
        min_count: null,
        data: this.getInitialData(item_type),
      },
    });
  }

  private getInitialData(item_type: CurriculumItemType): object {
    if (item_type == "procedure") {
      return this.getInitialProcedureData();
    } else {
      return {};
    }
  }

  private getInitialProcedureData(): ProcedureItemData {
    return {
      procedure_types: [],
    };
  }
}
</script>
