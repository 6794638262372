<template>
  <div class="quiz-question" :class="{ answered }">
    <div class="flex flex-row">
      <h4 class="flex-1">{{ question.question }}</h4>
      <div class="ml-sm">{{ questionCounter }}</div>
    </div>
    <div v-if="questionImageUrl" class="image"><img :key="questionImageUrl" :src="questionImageUrl" /></div>
    <hr />
    <div class="options">
      <div v-if="answered" class="result">
        <div v-if="isCorrect" class="correct"><icon icon="check"></icon>{{ $t("quiz.correct_message") }}</div>
        <div v-if="!isCorrect" class="incorrect">
          <icon icon="times"></icon>{{ $t("quiz.incorrect_message") }}<span v-if="answered"> {{ answer.text }}</span>
        </div>
      </div>
      <quiz-option-view
        v-for="option in questionOptions"
        :key="option.id"
        :option="option"
        :question="question"
        :answered="answered"
        @select-option="selectOption"
      ></quiz-option-view>
      <div v-if="answered" class="continue">
        <button class="btn-primary" @click="submitAnswer()">{{ $t("actions.continue") }}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Quiz from "@/model/quiz";
import QuizOption from "@/model/quiz-option";
import QuizQuestion from "@/model/quiz-question";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import QuizOptionView from "@/components/quiz/quiz-option-view.vue";
import { filePath } from "@/utils/paths";

@Component({
  components: {
    QuizOptionView,
  },
})
export default class QuizQuestionView extends Vue {
  @Prop()
  question: QuizQuestion;

  @Prop()
  questionNumber: number;

  @Prop()
  quiz: Quiz;

  answer: QuizOption = null;

  get questionCounter() {
    return `${this.questionNumber}/${this.quiz.questions.length}`;
  }

  get answered() {
    return !!this.answer;
  }

  get isCorrect() {
    return !!this.answer?.is_correct_answer;
  }

  get questionImageUrl() {
    return filePath(this.question.image, "medium");
  }

  get questionOptions() {
    return this.question.options.filter((option) => !this.answered || option == this.answer);
  }

  selectOption(option: QuizOption) {
    if (!this.answered) {
      this.answer = option;

      this.question.answers_count += 1;
      option.answers_count += 1;
    }
  }

  submitAnswer() {
    const answer = { question_id: this.question.id, option_id: this.answer.id };
    this.$emit("answer", answer);
  }

  @Watch("question")
  clear() {
    this.answer = null;
  }
}
</script>

<style lang="scss" scoped>
.quiz-question {
  .image {
    margin-bottom: var(--spacing);
    width: 100%;
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .result {
    margin-top: var(--spacing);
  }

  .correct {
    font-size: var(--font-size-large);

    .icon {
      color: var(--color-tertiary);
      margin-right: var(--spacing-sm);
    }
  }

  .incorrect {
    font-size: var(--font-size-large);

    .icon {
      color: var(--color-error);
      margin-right: var(--spacing-sm);
    }
  }

  .continue {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
