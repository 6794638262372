<template>
  <input-icon v-if="showIcon" icon="calendar">
    <flat-pickr :value="value" :config="config" :placeholder="placeholder" @input="onInput"></flat-pickr>
  </input-icon>
  <flat-pickr v-else :value="value" :config="config" :placeholder="placeholder" @input="onInput"></flat-pickr>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import i18n from "@/i18n";
import flatPickr from "vue-flatpickr-component";
import { Prop } from "vue-property-decorator";

@Component({
  components: { flatPickr },
})
export default class DateInput extends Vue {
  @Prop()
  value: string;

  @Prop({ default: () => `${i18n.t("attributes.date")}...` })
  placeholder: string;

  @Prop({ default: true })
  showIcon: boolean;

  get config() {
    return {
      altInput: true,
      altInputClass: "date-input__input",
      altFormat: this.$t("format.flatpickr_date"),
    };
  }

  onInput(value) {
    this.$emit("input", value);
  }
}
</script>
