<template>
  <div class="mobile-actions relative z-40" :class="{ open: visible }" @click.stop="toggleDropdown">
    <fade-transition>
      <div v-if="visible" class="mobile-actions-overlay fixed inset-0"></div>
    </fade-transition>
    <slot name="button">
      <div
        ref="button"
        class="mobile-actions__button flex w-14 h-14 rounded items-center justify-center"
        :class="{ active: visible }"
      >
        <icon-fluent
          class="mobile-actions__button-icon text-on-primary"
          :name="visible ? 'dismiss' : 'add'"
          variant="filled"
        ></icon-fluent>
      </div>
    </slot>
    <fade-transition>
      <div v-if="visible" class="mobile-actions__content flex flex-col-reverse items-end">
        <slot></slot>
      </div>
    </fade-transition>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class MobileActions extends Vue {
  @Prop()
  iconName: string;

  visible = false;

  toggleDropdown(event) {
    if (event.target !== this.$refs["button"] && event.target.parentElement !== this.$refs["button"] && this.visible)
      return;

    this.visible = !this.visible;
  }

  // Hides dropdown when clicked outside of it
  onDocumentClick() {
    this.visible = false;
  }

  mounted() {
    document.addEventListener("click", this.onDocumentClick);
  }

  beforeDestroy() {
    document.removeEventListener("click", this.onDocumentClick);
  }

  @Watch("visible")
  onVisibleChange(visible) {
    if (visible) {
      this.$emit("show");
    } else {
      this.$emit("blur");
    }
  }
}
</script>

<style lang="scss">
.mobile-actions-overlay {
  background-color: rgba(86, 97, 113, 0.6);
  backdrop-filter: blur(4px);
}

.mobile-actions__button {
  position: fixed;
  right: 2.375rem;
  bottom: 2.375rem;
  cursor: pointer;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
  background-color: var(--ui-color-interactive-bg-emphasis);

  &.active {
    background-color: var(--ui-color-interactive-bg-emphasis-variant);
  }
}

.mobile-actions__button-icon {
  width: 23px;
  height: 23px;
  pointer-events: none;
}

.mobile-actions__content {
  position: fixed;
  right: 2.375rem;
  bottom: 7.375rem;

  button {
    // box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    @apply text-xs;
    position: absolute;
    bottom: 100%;
    color: var(--ui-color-text-primary);
    letter-spacing: 0.5px;
    margin-bottom: 0.25rem;
  }
}
</style>
