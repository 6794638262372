import ContentDocument from "@/model/content-document";
import ContentItem from "@/model/content-item";
import Page from "@/model/page";

export const routeForContentItem = (contentItem: ContentItem) => {
  if (contentItem.content_type === "Page") {
    if (contentItem.matching_document_type) {
      return {
        name: "PageContent",
        params: { id: contentItem.content_id.toString(), documentType: contentItem.matching_document_type },
      };
    } else {
      return {
        name: "Page",
        params: { id: contentItem.content_id.toString() },
      };
    }
  } else {
    throw `Unknown content type ${contentItem.content_type}`;
  }
};

export const routeForPage = (page: Page) => {
  return {
    name: "PageContent",
    params: {
      id: String(page.id),
      documentType: page.available_document_types[0] ?? page.documents[0]?.document_type ?? "null",
    },
  };
};

export const routeForDocument = (page: Page, document: ContentDocument) => {
  return {
    name: "PageContent",
    params: {
      id: String(page.id),
      documentType: document.document_type,
    },
  };
};
