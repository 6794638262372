<template>
  <div v-if="user" class="flex-1 rounded sm:bg-transparent" :class="{ 'bg-surface-min-contrast': !open }">
    <div
      class="flex flex-row items-center p-4 sm:hidden text-label-medium text-text-variant cursor-pointer"
      @click="open = !open"
    >
      <div class="flex-1">
        {{ $t("profile.your_profile") }}
      </div>
      <icon-fluent :name="open ? 'chevronUp' : 'chevronDown'" size="16" variant="filled" />
    </div>
    <div
      class="sm:flex flex-col gap-12 sm:gap-6 rounded p-6 sm:p-0 bg-surface-min-contrast sm:bg-transparent"
      :class="open ? 'flex' : 'hidden'"
    >
      <div>
        <div class="text-label-small uppercase">{{ $t("attributes.email") }}</div>
        <confirmable-input
          v-model="user.email"
          :edit-label="$t('actions.change_email')"
          :on-save="(value) => saveConfirmable('email', value)"
        >
          <template #value>
            <div>{{ user.email }}</div>
          </template>
          <text-input v-model="user.email" type="email" />
        </confirmable-input>
      </div>
      <div id="changePassword">
        <div class="text-label-small uppercase">{{ $t("attributes.password") }}</div>
        <div class="h-10 pb-2 flex items-end">*********</div>
        <a class="link text-xs" @click="changePassword">{{ $t("actions.change_password") }}</a>
      </div>
      <div>
        <div class="text-label-small uppercase">{{ $t("attributes.speciality") }}</div>
        <confirmable-input
          v-model="user.speciality_id"
          :edit-label="$t('actions.change_speciality')"
          :on-save="(value) => saveConfirmable('speciality_id', value)"
        >
          <template #value>
            <div>
              <span v-if="specialityLabel">{{ specialityLabel || "-" }}</span>
              <loader-big v-else class="loader w-12"></loader-big>
            </div>
          </template>
          <speciality-select ref="specialitySelect" v-model="user.speciality_id"></speciality-select>
        </confirmable-input>
      </div>
      <div>
        <div class="text-label-small uppercase">{{ $t("attributes.position") }}</div>
        <confirmable-input
          v-model="user.position"
          :edit-label="$t('actions.change_position')"
          :on-save="(value) => saveConfirmable('position', value)"
        >
          <template #value>
            <div>{{ $t(`positions.${user.position}`) }}</div>
          </template>
          <position-select v-model="user.position" required></position-select>
        </confirmable-input>
      </div>
      <div>
        <div class="text-label-small uppercase">{{ $t("attributes.hospital") }}</div>
        <confirmable-input
          v-model="user.organization"
          :edit-label="$t('actions.change_hospital')"
          :on-save="(value) => saveConfirmable('organization', value)"
        >
          <template #value>
            <div>{{ user.organization || "-" }}</div>
          </template>
          <text-input v-model="user.organization" />
        </confirmable-input>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SpecialitySelect from "@/components/specialities/speciality-select.vue";
import User from "@/model/user";
import { computed, ref, onMounted } from "vue";
import store from "@/store";
import { showDialog } from "@/utils/dialogs";
import ChangePasswordModal from "@/components/change-password-modal.vue";
import ResultModal from "@/components/result-modal.vue";
import { updateProfile } from "@/actions";
import _ from "lodash";

const specialitySelect = ref<SpecialitySelect>(null);
const user = ref<User>(null);
const isMounted = ref(false);
const open = ref(false);

onMounted(() => {
  user.value = _.cloneDeep(store.getters.currentUser);
  isMounted.value = true;
});

const changePassword = async () => {
  let dialogResult = await showDialog(ChangePasswordModal);
  if (dialogResult === 200) {
    showDialog(ResultModal, { title: "OK", content: "Your password has been changed." });
  }
};

const saveConfirmable = async (key, value) => {
  return updateProfile({
    [key]: value,
  });
};

const specialityLabel = computed(() => {
  if (!user.value.speciality_id) return "-";
  return isMounted.value
    ? specialitySelect.value?.specialities?.find((s) => s.id === user.value.speciality_id)?.name
    : null;
});
</script>
