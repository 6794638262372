import { getActiveTheme } from "@/theme";

const lightThemeColors = {
  surface: "#f7f9fe",
  tertiary: "#006c51",
  tertiarycontainer: "#45FEC9",
  custom0: "#6622ff",
  custom1: "#006875",
  custom0container: "#e8ddff",
  custom1container: "#95f0ff",
};

const darkThemeColors = {
  surface: "#001a4a",
  tertiary: "#00E0AE",
  tertiarycontainer: "#45FEC9",
  custom0: "#6622FF",
  custom1: "#02DAF4",
  custom0container: "#4C00D4",
  custom1container: "#004E59",
};

const Style = {
  get colors() {
    return getActiveTheme() === "dark" ? darkThemeColors : lightThemeColors;
  },
  get chartColors() {
    return [
      this.colors.tertiary,
      this.colors.tertiarycontainer,
      this.colors.custom0,
      this.colors.custom1,
      this.colors.custom0container,
      this.colors.custom1container,
    ];
  },
};

export default Style;
