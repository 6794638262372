import CommentNotification from "@/model/comment-notification";
import { SET_COMMENT_NOTIFICATIONS } from "@/store/mutations";
import { RootState } from "@/store/store";
import { Module } from "vuex";

export type CommentNotificationsState = {
  notifications: CommentNotification[];
};

const commentNotificationsModule: Module<CommentNotificationsState, RootState> = {
  state: () => ({ notifications: [] }),
  mutations: {
    [SET_COMMENT_NOTIFICATIONS]: (state, notifications) => Object.assign(state, { notifications }),
  },
  getters: {
    commentNotifications: (state) => {
      const commentNotifications = state.notifications || [];
      return commentNotifications.filter((notification) => !notification.acknowledged);
    },
  },
};

export default commentNotificationsModule;
