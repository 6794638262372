<template>
  <select :value="value" @change="onChange">
    <option :value="null">{{ $t("attributes.not_selected") }}</option>
    <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
  </select>
</template>

<script lang="ts">
import User from "@/model/user";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ResidentRoleSelect extends Vue {
  @Prop()
  value: number;

  get options() {
    const residencies = (this.$store.getters.currentUser as User).activeResidencies;
    return residencies.map((residency) => {
      return {
        value: residency.id,
        label: residency.customer_organization.name,
      };
    });
  }

  onChange(event) {
    const value: number = Number.parseInt(event.target.value) || null;
    this.$emit("input", value);
  }
}
</script>
