<template>
  <div class="user-list-page relative">
    <div>
      <div class="mb-md">
        <div class="flex flex-1">
          <search-input
            v-model="search"
            class="w-64 sm:grow-0"
            :placeholder="$t('actions.search')"
            @reset="onReset"
          ></search-input>
        </div>
      </div>
    </div>
    <scroll-detector v-if="loaded" class="mb-28" @end="loadMoreUsers">
      <div>
        <list-item v-for="user in users" :key="user.id">
          <div class="mr-md">
            <avatar :user="user"></avatar>
          </div>
          <div class="flex-1">
            <div class="flex flex-row items-center">
              <div class="text-body-large text-text-primary">
                {{ user.name }}<span v-if="user.id == $currentUser.id" class="ml-sm">(You)</span>
              </div>
            </div>
            <div class="text-body-small text-text-variant">{{ roleTitle(user) }}</div>
            <div class="text-body-small text-text-variant">
              {{ `id: ${user.id} email: ${user.email}` }}
              <badge v-if="user.email_error" class="ml-xs" :label="user.email_error.error_code" variant="error"></badge>
            </div>
          </div>
          <context-menu v-if="user.id != $currentUser.id">
            <action-link
              :align="true"
              icon="trash"
              :title="$t('actions.delete')"
              @click="deleteUser(user)"
            ></action-link>
            <action-link
              v-if="!user.email_verified"
              icon="envelope"
              :align="true"
              :title="$t('actions.send_verification_email')"
              @click="sendVerificationEmail(user)"
            ></action-link>
            <action-link
              v-if="user.isOsgenicUser()"
              icon="user-cog"
              :align="true"
              title="Change role"
              @click="changeRole(user)"
            ></action-link>
            <action-link
              v-if="!user.isAdmin()"
              :align="true"
              icon="user-secret"
              title="Impersonate"
              @click="impersonate(user)"
            ></action-link>
          </context-menu>
        </list-item>
      </div>
    </scroll-detector>
    <loader-big v-if="loading" class="absolute top-full inset-x-0 mt-lg"></loader-big>
  </div>
</template>

<script lang="ts">
import { Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import Component from "vue-class-component";
import Users from "@/services/users";
import User from "@/model/user";
import Subscription from "@/model/subscription";
import { impersonate } from "@/actions";
import { showDialog } from "@/utils/dialogs";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import ChangeRoleModal from "@/pages/users/change-role-modal.vue";

@Component({})
export default class UserListPage extends Vue {
  @Prop()
  organizationId: number;

  @Prop({ default: 2 })
  minSearchLength: number;
  search = "";

  users: User[] = null;
  loading: boolean = false;

  get loaded() {
    return !!this.users;
  }

  async created() {
    return this.queryUsers();
  }

  queryUsers() {
    this.users = null;
    return this.fetchUsers();
  }

  loadMoreUsers() {
    if (!this.loading) {
      this.fetchUsers();
    }
  }

  async fetchUsers() {
    this.loading = true;

    const params: any = { limit: 20, offset: this.users?.length || 0 };
    if (this.organizationId) {
      params.customer_organization_id = this.organizationId;
    }
    if (this.search.length >= this.minSearchLength) {
      params.q = this.search;
    }
    const newUsers = await new Users().getAll({ params });

    this.users = [...(this.users || []), ...newUsers];
    this.loading = false;
  }

  roleTitle(user: User): string {
    const role = this.$t(`user_roles.${user.role}`) as string;

    if (user.isResident() && user.currentResidency) {
      return `${role} (${user.currentResidency.customer_organization.name})`;
    }

    if (user.isSolo() && user.subscriptions) {
      const subscriptionDetails = user.subscriptions
        .map(
          (subscription: Subscription) =>
            `${subscription.status}, ${subscription.start_date} - ${subscription.end_date}`
        )
        .join(", ");

      return `${role}, ${subscriptionDetails}, ${this.$t("attributes.sign_up_code")}: ${user.sign_up_code}`;
    }

    return user.customer_organization ? `${role} (${user.customer_organization.name})` : role;
  }

  impersonate(user: User) {
    impersonate(user);
  }

  async deleteUser(user: User) {
    const confirmed = await showDialog(ConfirmationDialog, {
      title: "Are you sure? Deleting a user cannot be undone!",
    });
    if (confirmed) {
      new Users().delete(user.id);
      this.users = this.users.filter(({ id }) => id !== user.id);
    }
  }

  async sendVerificationEmail(user: User) {
    await new Users().sendVerificationEmail(user);
    this.$toast.info("Verification email sent");
  }

  async changeRole(user: User) {
    const role = await showDialog(ChangeRoleModal, { user });
    this.users = this.users.map((each) => (each.id === user.id ? new User({ ...user, role }) : each));
    this.$toast.info("User role changed.");
  }

  @Watch("search")
  async onSearch() {
    this.users = null;
    await this.fetchUsers();
  }

  onReset() {
    this.search = "";
  }
}
</script>

<style lang="scss" scoped>
.invitations-link {
  margin-top: 2em;
}

a:visited {
  color: $grey-lighter;
}

a:link {
  color: $white;
}
</style>
