<template>
  <div class="tabs overflow-x-scroll no-scrollbar md:overflow-x-auto" @click="onClick">
    <div
      class="inline-flex bg-interactive-bg-neutral-low-contrast rounded-full mr-5 md:mr-0 md:gap-2 md:flex-wrap md:bg-transparent"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class Tabs extends Vue {
  onClick(evt: PointerEvent) {
    const el = evt.target as HTMLElement;
    el.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
  }
}
</script>
