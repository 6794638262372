<template>
  <modal>
    <h3 class="mb-4">{{ $t("invite_user.title") }}</h3>
    <form @submit.prevent="onSubmit">
      <form-field :label="$t('attributes.email')">
        <text-input
          key="email"
          v-model="model.email"
          class="mb-4"
          type="email"
          :floating-label="false"
          :error="$v.model.email.$error"
          :validation-message="vuelidateExternalResults.email"
          @input="vuelidateExternalResults.email = null"
        ></text-input>
      </form-field>
      <form-field :label="$t('attributes.role_type')">
        <radio-select v-model="model.role_type" :options="roleOptions"></radio-select>
      </form-field>
      <form-field
        v-if="model.role_type == 'resident' && organization && organization.curriculums"
        :label="$t('logbook.title')"
      >
        <select v-model="model.curriculum_id">
          <option :value="null">{{ $t("attributes.not_selected") }}</option>
          <option v-for="curriculum in organization.curriculums" :key="curriculum.id" :value="curriculum.id">
            {{ curriculum.name }},&nbsp;
            <date-range :start-date="curriculum.start_date" :end-date="curriculum.end_date"></date-range>
          </option>
        </select>
      </form-field>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.invite") }}</button>
        <button class="btn-secondary" @click="onCancel">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import { RadioSelectOption } from "@/components/form/radio-select.vue";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import Invitation from "@/model/invitation";
import Organization from "@/model/organization";
import Invitations from "@/services/invitations";
import Organizations from "@/services/organizations";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { UnprocessableEntityError } from "@/api/api-error";

@Component({})
export default class CustomerOrganizationInviteUserModal extends Vue {
  model: Partial<Invitation> = null;

  organization: Organization = null;

  vuelidateExternalResults: Record<string, string> = {
    email: null,
  };

  @Validations()
  validations = {
    model: {
      email: {
        required,
        validFormat: () => !this.vuelidateExternalResults.email,
      },
    },
  };

  get organizationService(): Organizations {
    return new Organizations();
  }

  get roleOptions(): RadioSelectOption<string>[] {
    return [
      { value: "resident", label: this.$t("user_roles.resident") },
      { value: "hospital_admin", label: this.$t("user_roles.hospital_admin") },
    ];
  }

  async onSubmit() {
    this.$v.$touch();
    if (!this.$v.$error) {
      const action = new Invitations().create(this.model).catch((e: UnprocessableEntityError) => {
        for (let field in this.vuelidateExternalResults) {
          this.vuelidateExternalResults[field] = e.details[field] || null;
        }
        this.$v.$touch();
      });
      const invitation = await showDialog(LoadingIndicatorDialog, { action });
      if (invitation) {
        this.$emit("ok", invitation);
      }
    }
  }

  onCancel() {
    this.$emit("cancel");
  }

  async created() {
    this.model = {
      email: null,
      role_type: "resident",
      curriculum_id: null,
      customer_organization_id: this.$currentUser.customer_organization_id,
    };
    this.organization = await this.organizationService.get(this.$currentUser.customer_organization_id.toString());
  }
}
</script>
