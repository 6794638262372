<template>
  <div class="dropdown__content z-10" :class="{ top, up, right, 'to-right': toRight, bottom, 'w-max': widthByContent }">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class DropdownContent extends Vue {
  @Prop({ default: false })
  up: boolean;

  @Prop({ default: false })
  right: boolean;

  @Prop({ default: false })
  toRight: boolean;

  @Prop({ default: false })
  bottom: boolean;

  @Prop({ default: false })
  top: boolean;

  @Prop({ default: true })
  widthByContent: boolean;
}
</script>

<style lang="scss" scoped>
.dropdown__content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--ui-color-surface-min-contrast);
  border-radius: var(--border-radius-xs);
  transition: opacity 0.2s;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  padding: var(--spacing-sm) 0;
  overflow-y: auto;
  max-height: 350px;

  /* For Firefox Browser */
  scrollbar-width: thin;
  /* For Chrome, EDGE, Opera, Others */
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 6px 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-clip: content-box;
    border: 4px solid transparent;
  }

  &.top {
    top: 0;
    margin-top: 0;

    &.right {
      left: unset;
      right: 0;
      margin-right: 0;
    }
  }

  &.up {
    top: unset;
    bottom: 100%;
    margin-top: 0;
    &.bottom {
      bottom: 0;
      margin-bottom: 0;
    }
  }

  &.to-right {
    left: unset;
    right: 100%;
    margin-right: $spacing-xs;
    &.right {
      right: 0;
      margin-right: 0;
    }
  }

  button {
    display: block;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 !important; // Remove !important when dropdown.vue button style has been refactored
  }

  & > * {
    @apply px-4 leading-4;
    display: block;
    height: 32px;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--ui-color-text-primary);

    &:hover {
      background-color: var(--ui-color-surface-mid-contrast);
    }

    > .icon:not(:last-child) {
      margin-right: var(--spacing-sm);
    }

    svg.filled {
      color: var(--ui-color-text-emphasis);
    }
  }
}
</style>
