<template>
  <node-view-wrapper>
    <div v-if="editor.isEditable" class="relative group" data-drag-handle="">
      <vimeo-player class="pointer-events-none" :video-id="node.attrs.id"></vimeo-player>
      <div class="absolute top-4 left-4 hidden group-hover:flex">
        <button class="btn-primary btn-sm pl-4" type="button" @click.stop="editVideo">
          <icon-fluent class="mr-xs" name="edit" :size="16"></icon-fluent><span>{{ $t("actions.change") }}</span>
        </button>
      </div>
    </div>
    <vimeo-player v-else :video-id="node.attrs.id"></vimeo-player>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";
import { showDialog } from "@/utils/dialogs";
import EmbedVimeoModal from "./embed-vimeo-modal.vue";
import { VimeoNodeAttributes } from "./vimeo-node";

@Component({
  components: { NodeViewWrapper },
  props: nodeViewProps,
})
export default class VimeoNodeView extends Vue {
  @Prop()
  editor: Editor;

  @Prop()
  node: any;

  async editVideo() {
    const attrs = await showDialog<VimeoNodeAttributes>(EmbedVimeoModal, {
      attrs: this.node.attrs,
    });
    if (attrs) {
      this.editor.chain().updateVimeo(attrs).focus().run();
    }
  }
}
</script>
