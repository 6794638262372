import ApiService from "@/services/api-service";
import Invitation from "@/model/invitation";

export default class Invitations extends ApiService<Invitation> {
  get baseUrl() {
    return "/api/v1/invitations";
  }

  acceptInvitation(token: String) {
    const url = this.buildUrl(`${token}/accept`, {});
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  resendInvitation(token: String) {
    const url = this.buildUrl(`${token}/resend`, {});
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  getInvitation(token: String): Promise<Invitation> {
    const url = this.buildUrl(token, {});
    const headers = this.buildHeaders();
    return fetch(url, {
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }
}
