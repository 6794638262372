<template>
  <page>
    <page-content v-if="!curriculum">
      <card>
        <loader-big></loader-big>
      </card>
    </page-content>
    <page-content v-else>
      <page-title class="flex flex-row">
        <div class="flex-1">{{ curriculum.name }}</div>
        <div class="actions">
          <button class="btn-primary leading-6" @click="addClinicalBlock">
            {{ $t("clinical_blocks.add_clinical_block") }}
          </button>
        </div>
      </page-title>
      <div class="details">
        <div v-if="curriculum.speciality" class="item">
          <strong>{{ $t("attributes.speciality") }}: </strong><span>{{ curriculum.speciality.name }}</span>
        </div>
        <div class="item">
          <strong>{{ $t("attributes.period") }}: </strong>
          <date-range :start-date="curriculum.start_date" :end-date="curriculum.end_date"></date-range>
        </div>
      </div>
      <div class="content">
        <clinical-block-card
          v-for="clinicalBlock in curriculum.clinical_blocks"
          :key="clinicalBlock.id"
          class="clinical-block-card"
          :clinical-block="clinicalBlock"
          @updated="onClinicalBlockUpdated"
        ></clinical-block-card>
      </div>
    </page-content>
  </page>
</template>

<script lang="ts">
import Curriculum from "@/model/curriculum";
import CurriculumsService from "@/services/curriculums-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { showDialog } from "@/utils/dialogs";
import LogbookClinicalBlockModal from "@/components/logbook/logbook-clinical-block-modal.vue";
import ClinicalBlocksService from "@/services/clinical-blocks-service";
import ClinicalBlock from "@/model/clinical-block";
import ClinicalBlockCard from "@/components/logbook/clinical-block-card.vue";

@Component({
  components: {
    ClinicalBlockCard,
  },
})
export default class EditLogbookPage extends Vue {
  @Prop()
  id: string;

  curriculum: Curriculum = null;

  get curriculumsService(): CurriculumsService {
    return new CurriculumsService();
  }

  get clinicalBlocksService(): ClinicalBlocksService {
    return new ClinicalBlocksService();
  }

  get title() {
    return this.$t("logbook.title");
  }

  async addClinicalBlock() {
    return showDialog<ClinicalBlock>(LogbookClinicalBlockModal)
      .then(this.setLogbookId)
      .then(this.createClinicalBlock)
      .then(this.addToList);
  }

  setLogbookId(clinicalBlock: ClinicalBlock): ClinicalBlock {
    return clinicalBlock
      ? {
          ...clinicalBlock,
          curriculum_id: this.curriculum.id,
        }
      : null;
  }

  async createClinicalBlock(clinicalBlock: ClinicalBlock): Promise<ClinicalBlock> {
    return clinicalBlock ? this.clinicalBlocksService.create(clinicalBlock) : null;
  }

  addToList(clinicalBlock: ClinicalBlock) {
    if (clinicalBlock) {
      this.curriculum.clinical_blocks.push(clinicalBlock);
    }
  }

  async created() {
    return this.curriculumsService.get(this.id).then((curriculum) => (this.curriculum = curriculum));
  }

  onClinicalBlockUpdated(clinicalBlock: ClinicalBlock) {
    this.curriculum.clinical_blocks = this.curriculum.clinical_blocks.map((each) =>
      each.id === clinicalBlock.id ? clinicalBlock : each
    );
  }
}
</script>

<style lang="scss" scoped>
.details {
  margin-bottom: var(--spacing);
}

.clinical-block-card:not(:last-child) {
  margin-bottom: var(--spacing);
}
</style>
