<template>
  <page>
    <page-header class="mb-4" :title="$t('logbooks.title')"></page-header>
    <page-content>
      <div>
        <div class="mb-md">
          <div class="flex flex-1">
            <search-input
              v-model="search"
              class="mr-md sm:grow-0"
              :placeholder="$t('actions.search')"
              @reset="onReset"
            ></search-input>
            <button class="btn-primary" @click="addCurriculum">{{ $t("actions.add") }}</button>
          </div>
        </div>
      </div>
      <scroll-detector v-if="loaded" class="mb-28" @end="loadMoreCurriculumns">
        <div v-if="!curriculums">
          <list-item>
            <loader-big></loader-big>
          </list-item>
        </div>
        <div v-else>
          <list-item
            v-for="curriculum in sortedLogbooks"
            :key="curriculum.id"
            @click="editCurriculumContents(curriculum)"
          >
            <div class="main-col">
              <span class="main-col">
                <span class="text-body-large text-text-primary mr-xs">{{ curriculum.name }}</span>
                <icon class="text-text-variant" icon="chevron-right"></icon>
              </span>
              <div v-if="curriculum.speciality" class="text-body-small">{{ curriculum.speciality.name }}</div>
            </div>
            <div class="date-col">
              <date-range :start-date="curriculum.start_date" :end-date="curriculum.end_date"></date-range>
            </div>
            <div class="actions-col" @click.stop>
              <context-menu>
                <a @click="editCurriculum(curriculum)">
                  <icon icon="pen"></icon><span class="text">{{ $t("actions.edit") }}</span>
                </a>
                <a @click="deleteCurriculum(curriculum)">
                  <icon icon="trash"></icon><span class="text">{{ $t("actions.delete") }}</span>
                </a>
              </context-menu>
            </div>
          </list-item>
        </div>
      </scroll-detector>
      <loader-big v-if="loading" class="absolute top-full inset-x-0 mt-lg"></loader-big>
    </page-content>
  </page>
</template>

<script lang="ts">
import LogbookModal from "@/components/logbook/logbook-modal.vue";
import CurriculumsService from "@/services/curriculums-service";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import sortBy from "lodash/sortBy";
import Curriculum from "@/model/curriculum";
import { MethodNotAllowedError } from "@/api/api-error";
import AlertDialog from "@/components/alert-dialog.vue";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";

@Component({})
export default class LogbooksPage extends Vue {
  curriculums: Curriculum[] = null;
  loading: boolean = false;

  @Prop({ default: 2 })
  minSearchLength: number;
  search = "";

  get loaded() {
    return !!this.curriculums;
  }

  get sortedLogbooks() {
    return sortBy(this.curriculums, "start_date").reverse();
  }

  get curriculumsService(): CurriculumsService {
    return new CurriculumsService();
  }

  async addCurriculum() {
    const curriculum = await showDialog<Curriculum>(LogbookModal);
    if (curriculum) {
      this.curriculums.push(curriculum);
    }
  }

  async editCurriculum(curriculum) {
    curriculum = await showDialog<Curriculum>(LogbookModal, { curriculum });
    if (curriculum) {
      this.curriculums = this.curriculums.map((each) => (each.id === curriculum.id ? curriculum : each));
    }
  }

  async editCurriculumContents(curriculum) {
    this.$router.push({ name: "EditLogbook", params: { id: curriculum.id } });
  }

  async deleteCurriculum(curriculum) {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.delete_permanently") });
    if (confirmed) {
      this.curriculums = this.curriculums.filter((each) => each !== curriculum);
      try {
        await this.curriculumsService.delete(curriculum.id);
      } catch (error) {
        this.curriculums.push(curriculum);
        if (error instanceof MethodNotAllowedError) {
          showDialog(AlertDialog, { message: this.$t("logbook.delete_not_allowed") });
        } else {
          throw error;
        }
      }
    }
  }

  async created() {
    return this.fetchCurriculumns();
  }

  loadMoreCurriculumns() {
    if (!this.loading) {
      return this.fetchCurriculumns();
    }
  }

  async fetchCurriculumns() {
    this.loading = true;
    const params: any = { limit: 20, offset: this.curriculums?.length || 0 };
    if (this.search.length >= this.minSearchLength) {
      params.q = this.search;
    }
    const newCurriculum = await this.curriculumsService.getAll({ params });
    this.curriculums = [...(this.curriculums || []), ...newCurriculum];
    this.loading = false;
  }

  @Watch("search")
  async onSearch() {
    this.curriculums = null;
    await this.fetchCurriculumns();
  }

  onReset() {
    this.search = "";
  }
}
</script>

<style lang="scss" scoped>
.main-col {
  flex-grow: 1;
}
.date-col {
  width: 200px;

  @media (max-width: $screen-sm-max) {
    display: none;
  }
}
</style>
