<template>
  <input-icon class="input-icon" icon-type="fluent-icon" icon="globe" variant="filled" @click.stop="openModal">
    <input
      v-focus="focus"
      type="text"
      size="2"
      :placeholder="placeholder"
      :value="currentLocaleValue"
      @input="onCurrentLocaleInput"
    />
  </input-icon>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Translations from "@/model/translations";
import { showDialog } from "@/utils/dialogs";
import TranslationsModal from "@/components/form/translations-modal.vue";
import { getLocale } from "@/i18n";

@Component({})
export default class TranslationsInput extends Vue {
  @Prop()
  placeholder: string;

  @Prop()
  label: string;

  @Prop()
  modalTitle: string;

  @Prop()
  value: Translations;

  @Prop()
  error: any;

  @Prop()
  focus: boolean;

  // current locale value is shown in input field
  get currentLocaleValue() {
    return this.value && this.value[getLocale()];
  }

  // update only current locale value
  onCurrentLocaleInput(evt) {
    const value = { ...(this.value || {}), [getLocale()]: evt.target.value };
    this.$emit("input", value);
  }

  // open modal for updating any locale value
  async openModal() {
    const value = await showDialog(TranslationsModal, { title: this.modalTitle, value: this.value });
    if (value) {
      this.$emit("input", value);
    }
  }
}
</script>
