<template>
  <div v-lazy:background-image="backgroundImage" class="flex w-full h-full items-center justify-center bg-cover">
    <card v-if="approvalProcessPending" class="w-full max-w-[24rem]">
      <div class="my-lg">
        <div class="text-body-large text-center text-text-primary mb-lg">
          {{ $t("signup.added_to_approval_process_text") }}
        </div>
        <h3 class="text-center text-text-primary">{{ $t("signup.please_wait_for_approval_text") }}</h3>
      </div>
    </card>
    <div v-else>
      <card v-if="error">
        {{ $t("errors.verify_email_error") }}
        <div class="mt-sm">
          <router-link class="link" to="/sessions/login">{{ $t("actions.login") }}</router-link>
        </div>
      </card>
      <loader-big v-else fill-color="currentColor"></loader-big>
    </div>
  </div>
</template>

<script lang="ts">
import Users from "@/services/users";
import { SET_CURRENT_USER } from "@/store/mutations";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { showDialog } from "@/utils/dialogs";
import backgroundImage from "@/assets/images/background.png";
import WelcomeDialog from "@/pages/welcome-dialog.vue";

@Component({})
export default class VerifyEmailPage extends Vue {
  @Prop()
  token: String;

  error = false;

  approvalProcessPending = false;

  get userService() {
    return new Users();
  }

  get backgroundImage() {
    return backgroundImage;
  }

  async created() {
    this.userService
      .verifyEmail(this.token)
      .then((user) => {
        if ("name" in user) {
          this.$store.commit(SET_CURRENT_USER, user);
          this.$router.push("/");

          if (this.$currentUser) {
            showDialog(WelcomeDialog);
          }
        } else {
          this.approvalProcessPending = true;
        }
      })
      .catch(() => (this.error = true));
  }
}
</script>
