<template>
  <component :is="linkComponent" class="row flex" v-bind="linkAttributes">
    <div class="cell flex p-2">
      <favorite-toggle
        class="favorite-toggle"
        :toggled="row.favorited_by_current_user"
        @click="onFavoriteToggled"
      ></favorite-toggle>
    </div>
    <div class="cell flex p-2">
      <micro-image :src="row.preview_image_url"></micro-image>
    </div>
    <div class="cell flex p-2">
      <content-name-and-state :name="row.name" :state="row.item_state"></content-name-and-state>
    </div>
    <div class="cell flex p-2">
      <div>{{ row.speciality_labels }}</div>
    </div>
    <div class="cell flex p-2">
      <div>{{ row.owner }}</div>
    </div>
    <div class="cell flex p-2">
      <content-type-icon :type="row.content_type"></content-type-icon>
    </div>
    <div class="cell flex p-2">
      <div>{{ row.language_name }}</div>
    </div>
    <div class="cell flex p-2">
      <date-string :date="row.updated_at"></date-string>
    </div>
  </component>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ContentItem from "@/model/content-item";
import { routeForContentItem } from "@/utils/routes";

@Component({})
export default class ContentsTableRow extends Vue {
  @Prop()
  row: ContentItem;

  get route() {
    return routeForContentItem(this.row);
  }

  get linkComponent() {
    if (this.row.content_type == "ExternalLink") {
      return "a";
    } else {
      return "router-link";
    }
  }

  get linkAttributes() {
    if (this.row.content_type == "ExternalLink") {
      return {
        href: `/external_links/${this.row.content_id}`,
        target: "_blank",
        rel: "noopener noreferrer",
      };
    } else {
      return {
        to: this.route,
      };
    }
  }

  onFavoriteToggled() {
    this.$emit("favoriteToggled", this.row);
  }
}
</script>
