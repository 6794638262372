<template>
  <div class="contents-table flex flex-col flex-1 bg-surface-min-contrast -mx-md sm:rounded-lg sm:mx-0">
    <div class="sorted-table">
      <div class="table-head">
        <div class="row flex">
          <div v-for="column in columns" :key="column.key" class="cell head flex p-2">
            {{ column.label ? $t(column.label) : "" }}
          </div>
        </div>
      </div>
      <div class="table-body pb-12">
        <contents-table-row
          v-for="row in contentItems"
          :key="row.id"
          class="row flex clickable"
          :row="row"
          @favoriteToggled="$emit('favoriteToggled', row)"
        ></contents-table-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ContentItem from "@/model/content-item";
import SortedTableColumn from "@/model/sorted-table-column";
import ContentsTableRow from "./contents-table-row.vue";

@Component({
  components: {
    ContentsTableRow,
  },
})
export default class ContentsTable extends Vue {
  @Prop()
  contentItems: ContentItem[];

  columns: SortedTableColumn[] = [
    { key: "favorited_by_current_user" },
    { key: "preview_image_url" },
    { key: "name", label: "attributes.name", sortable: true },
    { key: "speciality_labels", label: "attributes.speciality" },
    { key: "owner", label: "attributes.owner" },
    { key: "content_type", label: "attributes.type" },
    { key: "language_name", label: "language" },
    { key: "updated_at", label: "attributes.last_modified", sortable: true },
  ];
}
</script>

<style lang="scss" scoped>
// Sorted-table
.row::v-deep {
  flex-direction: column;
  padding: 0.875rem;
  position: relative;
  margin-bottom: 1px;
  border-bottom: 1px solid var(--ui-color-surface-no-contrast);

  @media (min-width: $screen-sm-min) {
    flex-direction: row;
    padding: 0;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 0px 1px var(--ui-color-text-emphasis) inset;
    }
  }

  &:hover {
    .favorite-toggle {
      visibility: visible;
    }
  }

  .cell {
    @media (max-width: $screen-xs-max) {
      &:nth-child(3) {
        word-break: break-all;
        white-space: normal;
      }
    }

    @media (min-width: $screen-sm-min) {
      flex-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
    }

    > div,
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:nth-child(1) {
      display: none;
      flex-shrink: 0;
    }

    &:nth-child(2) {
      padding-top: 0.25rem;
      padding-bottom: 0;
      flex-basis: 48px;
      flex-shrink: 0;
      position: absolute;

      img {
        border-radius: 0.375rem;
      }
    }

    &:nth-child(3) {
      flex: 1 1;
      margin-left: 52px;
      padding-top: 0;
      padding-bottom: 0;
      letter-spacing: 0.01785em;
      max-width: 520px;
    }

    &:nth-child(4) {
      display: none;
    }

    &:nth-child(5) {
      display: none;
    }

    &:nth-child(6) {
      margin-top: 0.375rem;
      margin-left: 52px;
      padding-top: 0;
      padding-bottom: 0;

      :not(.head) {
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &:nth-child(7) {
      display: none;
    }

    &:nth-child(8) {
      display: none;
    }

    @media (min-width: $screen-sm-min) {
      &:nth-child(1) {
        display: flex;
      }

      &:nth-child(2) {
        position: static;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;

        &:not(.head) {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }

      &:nth-child(3) {
        margin-left: 0;
        letter-spacing: 0;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
        flex-shrink: 0;

        &:not(.head) {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }
      }

      &:nth-child(4) {
        display: flex;
        max-width: 215px;
        width: 100%;

        > div {
          &:empty {
            &::before {
              content: "-";
            }
          }
        }
      }

      &:nth-child(5) {
        display: flex;
        flex-basis: 170px;
        flex-shrink: 1;
      }

      &:nth-child(6) {
        flex-basis: 110px;
        flex-shrink: 1;
        margin-top: 0;
        margin-left: 0;
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;

        &:not(.head) {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }

        :not(.head) {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }

      &:nth-child(7) {
        display: flex;
        flex-basis: 110px;
        flex-shrink: 1;
      }

      &:nth-child(8) {
        display: flex;
        flex-basis: 165px;
        flex-shrink: 0;
      }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      &:nth-child(3) {
        flex-basis: 175px;
      }

      &:nth-child(6) {
        display: none;
      }

      &:nth-child(7) {
        display: none;
      }
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-lg-max) {
      &:nth-child(3) {
        flex-basis: 300px;
      }

      &:nth-child(6) {
        display: none;
      }

      &:nth-child(7) {
        display: none;
      }
    }

    @media (min-width: $screen-xl-min) {
      &:nth-child(3) {
        flex-basis: 300px;
      }
    }
  }

  width: 100%;
  flex-wrap: wrap;

  @media (min-width: $screen-sm-min) {
    flex-wrap: nowrap;
  }
}

// Contents -table
.header {
  @apply bg-background;
}

.table-head {
  display: none;
  flex-direction: column;

  @media (min-width: $screen-sm-min) {
    display: flex;
  }

  .cell {
    align-items: flex-end;
    user-select: none;
    text-align: left;
    min-height: 44px;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;

    &:nth-child(3) {
      margin-left: -48px;
      margin-right: 48px;
    }

    &.sortable {
      cursor: pointer;
    }
  }
}

.table-body {
  display: flex;
  flex-direction: column;
}

.contents-table::v-deep {
  display: flex;
  flex-direction: column;

  .favorite-toggle {
    visibility: hidden;
  }

  &.narrow {
    .row {
      .cell {
        @media (min-width: $screen-sm-min) {
          &:nth-child(3) {
            flex-shrink: 1;
          }

          &:nth-child(6) {
            display: none;
          }

          &:nth-child(7) {
            display: none;
          }
        }

        @media (min-width: $screen-sm-min) and (max-width: $screen-lg-max) {
          &:nth-child(4) {
            display: none;
          }
        }
      }
    }
  }
}
</style>
