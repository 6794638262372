<template>
  <modal>
    <h3 class="mb-4">{{ $t("editor.embed_video") }}</h3>
    <form name="linkForm" @submit.prevent="onSubmit">
      <form-field :label="$t('attributes.vimeo_video_id')">
        <input
          ref="idInput"
          v-model="model.id"
          type="text"
          required
          :placeholder="$t('attributes.vimeo_video_id') + '...'"
        />
      </form-field>
      <form-field :label="$t('attributes.vimeo_preview_id')">
        <input v-model="model.preview_id" type="text" :placeholder="$t('attributes.vimeo_preview_id') + '...'" />
      </form-field>
      <form-field :label="$t('attributes.surgeon')">
        <input v-model="model.surgeon" type="text" :placeholder="$t('attributes.surgeon') + '...'" />
      </form-field>
      <modal-footer>
        <button class="btn-primary" type="submit" :disabled="!isValid">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import { VimeoNodeAttributes } from "./vimeo-node";

@Component({})
export default class EmbedVimeoModal extends Vue {
  @Ref()
  readonly idInput: HTMLInputElement;

  @Prop({ required: false })
  attrs: VimeoNodeAttributes;

  model: VimeoNodeAttributes = null;

  get isValid() {
    return this.model.id.length > 0;
  }

  created() {
    this.model = {
      id: this.attrs?.id || "",
      preview_id: this.attrs?.preview_id || "",
      surgeon: this.attrs?.surgeon || "",
    };
  }

  mounted() {
    this.$nextTick(() => this.idInput.focus());
  }

  onSubmit() {
    this.$emit("ok", this.model);
  }
}
</script>
