<template>
  <div class="profile-picture-cropper">
    <div class="header">
      <div
        v-if="currentPicture != null && !removeImage"
        class="current-profile-picture"
        :style="profilePictureStyle(currentPicture)"
      ></div>
      <p v-else>Profile picture not set!</p>
      <div class="actions">
        <div class="add-image">
          <input-list-item icon="plus-circle" label="Upload new profile picture" :removable="false"></input-list-item>
          <input ref="fileInput" type="file" accept="image/*" @change="setImage" />
        </div>
        <div class="remove-image">
          <input-list-item
            icon="times-circle"
            label="Remove profile picture"
            :removable="false"
            @click="removeProfilePicture"
          ></input-list-item>
        </div>
      </div>
    </div>
    <div class="collapser">
      <div class="images">
        <div class="cropper-wrapper">
          <vue-cropper
            ref="cropper"
            :guides="true"
            :view-mode="2"
            :drag-mode="'none'"
            :auto-crop-area="0.8"
            :background="false"
            :modal="true"
            :highlight="true"
            :movable="false"
            :rotatable="true"
            :scalable="false"
            :zoomable="false"
            :src="sourceImage"
            alt="Source image"
            :img-style="{ width: '400px', height: '400px' }"
            :aspect-ratio="1"
            @cropend="cropImage"
            @ready="cropImage"
          ></vue-cropper>
        </div>
        <img class="preview" :src="croppedImage" alt="Cropped image" />
      </div>
      <button @click="rotate">Rotate</button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { Prop, Ref } from "vue-property-decorator";
import Attachment from "@/model/attachment";
import UploadService from "@/services/upload-service";

@Component({
  components: {
    VueCropper,
  },
})
export default class ProfilePictureCropper extends Vue {
  sourceImage = ""; // base64
  croppedImage = ""; // base64
  removeImage = false; // tearing my hair out w/ vue, so a dirty hack to skirt around directly mutating currentPicture prop

  @Ref() readonly cropper: VueCropper;
  @Ref() readonly fileInput: HTMLInputElement;

  @Prop()
  currentPicture: Attachment;

  get uploadService() {
    return new UploadService();
  }

  profilePictureStyle(image: Attachment) {
    if (image != null) {
      return `
      background: url(${image.url}) center center;
      background-size: cover;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      `;
    }
  }

  setImage(e) {
    const file = e.target.files[0];
    if (!file.type.includes("image/")) {
      // Not an image, show error
      return;
    }
    if (typeof FileReader === "function") {
      const reader = new FileReader();
      reader.onload = (event) => {
        document.querySelector<HTMLElement>(".collapser").style.display = "block";
        this.sourceImage = event.target.result.toString();
        this.cropper.replace(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      // FileReader API not supported
    }
  }

  cropImage() {
    this.croppedImage = this.cropper.getCroppedCanvas().toDataURL();
  }

  centerCropBox() {
    const cropBoxData = this.cropper.getCropBoxData();
    const containerData = this.cropper.getContainerData();
    this.cropper.setCropBoxData({
      left: containerData.width / 2 - cropBoxData.width / 2,
      top: containerData.height / 2 - cropBoxData.height / 2,
      width: cropBoxData.width,
      height: cropBoxData.height,
    });
  }

  rotate() {
    this.centerCropBox();
    this.cropper.rotate(90);
    this.cropImage();
  }

  srcToFile(src, fileName, mimeType): Promise<File> {
    return fetch(src)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], fileName, { type: mimeType });
      });
  }

  getMimeTypeFromDataURL(dataUrl: String): String {
    let base64ContentArray = dataUrl.split(",");
    return base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
  }

  async saveProfilePicture(): Promise<Attachment> {
    if (this.croppedImage != null) {
      let mimeType = this.getMimeTypeFromDataURL(this.croppedImage);
      const f: File = await this.srcToFile(this.croppedImage, "profile_pic_cropped", mimeType);
      return this.upload(f);
    }
  }

  async upload(file: File): Promise<Attachment> {
    let attachment;
    try {
      const upload = await this.uploadService.uploadFile(file);
      attachment = upload;
    } catch (err) {
      console.error(err);
    }
    this.fileInput.value = null;

    return attachment;
    // this.$emit("input", attachment);
  }

  toDataURL(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  removeProfilePicture() {
    // this.currentPicture = null;
    this.removeImage = true;
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
}
.current-profile-picture {
  //
}
.collapser {
  display: none;
  margin-top: $spacing-xl;
  padding: $spacing;
  background-color: $color-surface;
  color: $color-on-surface;
  border-radius: $border-radius;
  margin-bottom: 1em;
  width: calc(2 * 400px + 4 * #{$spacing-sm} + 2 * #{$spacing});
  button {
    margin-top: $spacing-sm;
  }
}
.images {
  display: flex;
  width: calc(2 * 400px + 4 * #{$spacing-sm});
  .cropper-wrapper {
    min-width: 400px;
    width: 400px;
    height: 400px;
    margin: $spacing-sm;
    flex-shrink: 0;
  }
  .preview {
    min-width: 400px;
    width: 400px;
    height: 400px;
    margin: $spacing-sm;
    flex-shrink: 0;
  }
}
.actions {
  align-self: center;
  margin-left: 1em;
  position: relative;
}
.add-image {
  cursor: pointer;

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.remove-image {
  cursor: pointer;
}
</style>
