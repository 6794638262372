<template>
  <sign-up-view
    :step="1"
    :total-steps="2"
    :back="false"
    :title="$t('signup.choose_your_plan.title')"
    :subtitle="$t('membership_expired.choose_your_plan_subtitle')"
    v-on="$listeners"
  >
    <plan-period-toggle :period="period" @select="onSelect" />
    <div class="w-full sm:max-w-xs mt-4">
      <plan-card
        v-if="period == 'month'"
        :plan-price="monthlyPlan"
        :discount-percentage="discountPercentage"
        @select="review"
      />
      <plan-card
        v-else
        :plan-price="yearlyPlan"
        :discount-percentage="discountPercentage"
        :yearly-discount-percentage="yearlyPlanDiscount"
        @select="review"
      />
    </div>
    <div class="w-full flex flex-col gap-2 bg-surface-min-contrast sm:bg-transparent rounded-sm mt-4 p-4 sm:px-8">
      <info-text :text="$t('signup.choose_your_plan.unlimited_access_to_content')" />
      <info-text :text="$t('signup.choose_your_plan.invivo_anatomy')" />
      <info-text :text="$t('signup.choose_your_plan.upload_content_and_take_notes')" />
      <info-text :text="$t('signup.choose_your_plan.create_own_anatomy_illustrations')" />
    </div>
  </sign-up-view>
</template>

<script setup lang="ts">
import SignUpView from "@/pages/sign-up/sign-up-view.vue";
import PlanCard from "@/pages/sign-up/plan-card.vue";
import PlanPrice, { countDiscountPercentage } from "@/model/plan-price";
import { computed } from "vue";
import PlanPeriodToggle from "../sign-up/plan-period-toggle.vue";
import SignUpCode from "@/model/sign-up-code";

const props = defineProps<{
  period: string;
  plans: PlanPrice[];
  signUpCode: SignUpCode;
}>();

const emit = defineEmits(["select-period", "review"]);

const discountPercentage = computed(() => props.signUpCode?.discount_percentage);

const monthlyPlan = props.plans.find((p) => p.period === "month");

const yearlyPlan = props.plans.find((p) => p.period === "year");

const yearlyPlanDiscount = computed(() => countDiscountPercentage(yearlyPlan, monthlyPlan));

const onSelect = (period: string) => {
  emit("select-period", period);
};

const review = () => {
  emit("review");
};
</script>
