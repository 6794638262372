<template>
  <button
    class="bg-interactive-bg-neutral-low-contrast w-8 h-8 rounded flex justify-center items-center p-0"
    type="button"
    :class="{ toggled }"
    @click="toggle"
  >
    <icon-fluent class="flex-shrink-0 text-text-emphasis hidden" name="star" size="16" variant="filled"></icon-fluent>
    <icon-fluent class="flex-shrink-0 text-text-emphasis" name="star" size="16" variant="regular"></icon-fluent>
  </button>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class FavoriteToggle extends Vue {
  @Prop()
  toggled: boolean;

  toggle(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.$emit("click", e);
  }
}
</script>

<style lang="scss" scoped>
button {
  &.toggled,
  &:hover {
    svg.filled {
      display: block;
    }

    svg.regular {
      display: none;
    }
  }

  &.toggled:hover {
    @apply bg-interactive-bg-neutral-high-contrast;
  }
}
</style>
