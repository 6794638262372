<template>
  <div class="invitation-list-page relative">
    <form class="mb-4">
      <form-field class="w-1/3" :label="$t('medical_professional_approval_states.approval_state')">
        <select v-model="approval_state" @change="onSubmit">
          <option v-for="option in approvalStateOptions" :value="option.value">{{ option.label }}</option>
        </select>
      </form-field>
    </form>
    <scroll-detector v-if="loaded" class="mb-28" @end="loadMoreUsers">
      <list-item v-if="!users">
        <loader-big></loader-big>
      </list-item>
      <list-item v-for="user in users" v-else :key="user.id">
        <div class="flex-1 flex flex-col">
          <div class="text-body-large text-text-primary">
            {{ user.name }}
            <div class="text-body-small">{{ user.email }}</div>
            <div class="text-body-small">{{ user.approval_state }}</div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row">
          <button @click="onApprovalStateChange(user.id, 'confirmed')">{{ $t("actions.confirm") }}</button>
          <button v-if="approval_state != 'postponed'" @click="onApprovalStateChange(user.id, 'postponed')">
            {{ $t("actions.postpone") }}
          </button>
          <button v-if="approval_state != 'rejected'" @click="onApprovalStateChange(user.id, 'rejected')">
            {{ $t("actions.reject") }}
          </button>
          <button v-if="approval_state != 'pending'" @click="onApprovalStateChange(user.id, 'pending')">
            {{ $t("actions.pend") }}
          </button>
        </div>
      </list-item>
    </scroll-detector>
    <loader-big v-if="loading" class="absolute top-full inset-x-0 mt-lg"></loader-big>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { showDialog } from "@/utils/dialogs";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import Users from "@/services/users";
import User from "@/model/user";
import MedicalProfessionalApprovalStateService from "@/services/medical-professional-approval-state-service";

@Component({})
export default class WaitingApprovalListPage extends Vue {
  users: User[] = null;
  loading: boolean = false;
  approval_state = "pending";

  get loaded() {
    return !!this.users;
  }

  async created() {
    return this.queryUsers();
  }

  get medicalProfessionalApprovalService() {
    return new MedicalProfessionalApprovalStateService();
  }

  queryUsers() {
    this.users = null;
    return this.fetchUsers();
  }

  loadMoreUsers() {
    if (!this.loading) {
      this.fetchUsers();
    }
  }

  async fetchUsers() {
    this.loading = true;
    const newUsers = await new Users().getWaitingApproval({
      params: { approval_state: this.approval_state, limit: 20, offset: this.users?.length || 0 },
    });
    this.users = [...(this.users || []), ...newUsers];
    this.loading = false;
  }

  async onApprovalStateChange(userId: number, approvalState: String) {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.are_you_sure") });
    if (confirmed) {
      const attrs = { user_id: userId, approval_state: approvalState };
      return this.medicalProfessionalApprovalService.create(attrs).then(() => {
        this.users = this.users.filter((user) => {
          return !(user.id === userId);
        });
      });
    }
  }

  async onSubmit() {
    this.queryUsers();
  }

  get approvalStateOptions() {
    return [
      { value: "pending", label: this.$t("medical_professional_approval_states.pending") },
      { value: "postponed", label: this.$t("medical_professional_approval_states.postponed") },
      { value: "rejected", label: this.$t("medical_professional_approval_states.rejected") },
    ];
  }
}
</script>
