<template>
  <a
    v-if="blob"
    class="relative inline-block max-w-full"
    :href="url"
    target="_blank"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <image-view :blob="blob" :width="image_width" :detail-view="false"></image-view>
    <div class="absolute top-4 flex space-x-2 right-[-8px]">
      <button v-if="(hover || isSelected) && isEditable" class="btn-primary pl-4 h-8" @click="$emit('click')">
        <icon-fluent class="inline-block w-4 h-4 mr-sm text-white align-middle" name="edit"></icon-fluent>
        <span class="text align-middle">{{ $t("actions.edit_link") }}</span>
      </button>
      <div
        class="text-label-large text-white bg-interactive-bg-emphasis h-8 w-12 rounded-xs flex items-center justify-center"
      >
        3D
      </div>
    </div>
  </a>
  <a v-else class="btn btn-primary" :href="url" target="_blank">
    {{ $t("actions.launch_3d_viewer") }}
    <span class="hidden sm:inline">: {{ title }}</span>
  </a>
</template>

<script lang="ts">
import { filePath } from "@/utils/paths";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ContentBlob from "@/model/content-blob";
import ImageView from "@/editor/extensions/image/image-view.vue";

@Component({
  components: {
    ImageView,
  },
})
export default class ViewerButtons extends Vue {
  @Prop()
  isEditable: boolean;

  @Prop()
  isSelected: boolean;

  @Prop()
  url: string;

  @Prop()
  title: string;

  @Prop()
  image_width: number;

  hover = false;

  @Prop()
  blob: ContentBlob;

  get imageUrl() {
    return this.blob && filePath(this.blob);
  }

  get imageStyle() {
    if (this.image_width) {
      return {
        width: this.image_width + "px",
      };
    }
    return null;
  }
}
</script>

<style lang="scss" scoped>
img {
  cursor: pointer;
}
</style>
