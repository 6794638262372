<template>
  <div class="form-field mb-4" :class="{ invalid: error }">
    <label v-if="label" class="text-body-small text-text-variant mb-xs">
      <slot v-if="checkbox"></slot>{{ label }}<span v-if="required" class="ml-xs font-bold">＊</span>
    </label>
    <slot v-if="!checkbox"></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class FormField extends Vue {
  @Prop()
  label: string;

  @Prop()
  error: any;

  @Prop({ default: false })
  checkbox: Boolean;

  @Prop()
  required: Boolean;
}
</script>

<style lang="scss" scoped>
.form-field {
  width: 100%;
  max-width: 400px;

  > label {
    display: flex;
    align-items: center;

    > input {
      margin-right: var(--spacing-xs);
    }
  }

  * label {
    display: inline;
  }
}
</style>

<style lang="scss">
// Not scoped because some inputs could be inside inner components in different scope
.form-field {
  &.invalid {
    input[type="email"],
    input[type="password"],
    input[type="text"],
    input[type="url"],
    input[type="number"],
    input[type="file"],
    select,
    textarea {
      border-color: var(--color-error);
    }
  }
}
</style>
