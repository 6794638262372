<template>
  <form v-if="location" @submit.prevent="submit">
    <div class="mt-sm">
      <form-field :label="$t('attributes.name')" :error="$v.location.name_translations.$error">
        <translations-input
          v-model="location.name_translations"
          :focus="true"
          :label="$t('attributes.name')"
        ></translations-input>
      </form-field>
    </div>
    <div v-if="parentOptions" class="flex flex-col mt-md">
      <form-field :label="$t('actions.add_as_subcategory_to')">
        <dropdown-select
          v-model.number="location.parent_id"
          class="mt-1"
          :options="parentOptions"
          :allow-null="true"
          :up="true"
          :width-by-content="false"
          :placeholder="$t('attributes.not_selected')"
        ></dropdown-select>
      </form-field>
      <div class="actions mt-3.5">
        <button class="btn-primary text-sm font-regular" type="submit">{{ $t("actions.add") }}</button>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import LocationsService from "@/services/locations-service";
import Location from "@/model/location";
import DropdownSelectOption from "@/model/dropdown-select-option";
import User from "@/model/user";
import Organization from "@/model/organization";
import Translations from "@/model/translations";
import _ from "lodash";

@Component({})
export default class LocationForm extends Vue {
  @Validations()
  validations = {
    location: {
      name_translations: {
        required(value: Translations) {
          // ensure that at least one translation has value, and all defined translations are non empty
          if (!value) {
            return false;
          }
          const keys = Object.keys(value);
          return keys.length > 0 && _.every(keys, (key) => value[key]?.length > 0 && value[key].length < 35);
        },
      },
    },
  };

  @Prop()
  parentOptions: DropdownSelectOption[];

  @Prop()
  user: User;

  @Prop()
  customerOrganization: Organization;

  location: Omit<Location, "name"> = null;

  locationsService = new LocationsService();

  async created() {
    this.initLocation();
  }

  initLocation() {
    this.location = {
      name_translations: {},
      parent_id: null,
    };
    this.$v.$reset();
  }

  async submit() {
    this.$v.$touch();

    if (!this.$v.$error) {
      this.$emit("submit", this.location);
      this.createLocation();
    } else {
      this.$emit("error", this.$v.$error);
    }
  }

  private async createLocation(): Promise<Location> {
    const attrs: Partial<Location> = {
      ...this.location,
      user_id: this.user?.id ?? null,
      customer_organization_id: this.customerOrganization?.id ?? null,
    };

    const location = await this.locationsService.create(attrs);

    this.$emit("locationCreated", location);

    this.initLocation();

    return location;
  }
}
</script>

<style lang="scss" scoped></style>
