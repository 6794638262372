<template>
  <button class="btn-primary" type="button" @click.stop="onClick()">{{ buttonLabel }}</button>
</template>

<script lang="ts">
import QuizModal from "@/components/quiz/quiz-modal.vue";
import i18n from "@/i18n";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class QuizButton extends Vue {
  @Prop()
  id: number;

  @Prop()
  title: string;

  get buttonLabel() {
    let label = i18n.t("actions.take_quiz");
    if (this.title) {
      label += `: ${this.title}`;
    }
    return label;
  }

  onClick() {
    if (this.$listeners.click) {
      this.$emit("click", this.id);
    } else {
      showDialog(QuizModal, { id: this.id });
    }
  }
}
</script>
