<template>
  <modal>
    <div class="text">{{ title || defaultTitle }}</div>
    <modal-footer>
      <button class="btn-secondary" type="button" @click="$emit('cancel')">
        {{ cancelText || defaultCancelText }}
      </button>
      <button class="btn-primary" type="button" @click="$emit('ok', true)">{{ okText || defaultOkText }}</button>
    </modal-footer>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ConfirmationDialog extends Vue {
  @Prop()
  title: string;

  @Prop()
  okText: string;

  @Prop()
  cancelText: string;

  get defaultTitle() {
    return this.$t("confirmation.are_you_sure");
  }

  get defaultOkText() {
    return this.$t("actions.ok");
  }

  get defaultCancelText() {
    return this.$t("actions.cancel");
  }
}
</script>
