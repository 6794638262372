import { uploadFileToMediaLibrary } from "@/actions";
import { createDropHandler } from "@/editor/utils/create-drop-handler";
import { showDialog } from "@/utils/dialogs";
import { Editor } from "@tiptap/core";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";

export const createVideoDropHandler = (editor: Editor) => {
  return createDropHandler({
    key: "videoDropHandler",
    editor,
    filter: (file) => /^video\//.test(file.type),
    callback: (videos) => {
      const action = uploadVideos(videos, editor);
      showDialog(LoadingIndicatorDialog, { action });
    },
  });
};

const uploadVideos = async (videos: File[], editor: Editor) => {
  for (const video of videos) {
    const mediaFile = await uploadFileToMediaLibrary(video);
    editor.commands.addVideo(mediaFile);
  }
  editor.commands.focus();
};
