<template>
  <div class="image-view-container">
    <div class="instruction-text" v-if="hasHoverImage">
      <p v-if="isMobile">{{ $t('media.tap_instruction') }}</p>
      <p v-else>{{ $t('media.hover_instruction') }}</p>
    </div>
    <span
      class="image-view inline-block align-bottom bg-surface-min-contrast rounded-sm relative"
      :class="{ 'cursor-zoom-in': detailView }"
      @mouseover="hovering = true"
      @mouseleave="hovering = false"
      @click="toggleHover"
    >
      <img
        v-lazy="hovering && hoverUrl ? hoverUrl : mediumUrl"
        class="image inline rounded-sm"
        :style="imageStyle"
        @click.stop="!hasHoverImage && (detailOpen = true)"
      />
      <transition v-if="detailView" name="fade">
        <div v-if="detailOpen" class="detail-view z-50" @click.stop="toggleHover">
          <img :src="url" @click.stop="toggleHover" />
        </div>
      </transition>
    </span>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { filePath } from "@/utils/paths";
import ContentBlob from "@/model/content-blob";

@Component({})
export default class ImageView extends Vue {
  @Prop()
  blob: ContentBlob;

  @Prop()
  width: number;

  @Prop({ default: true })
  detailView: boolean;

  @Prop({ default: null })
  hoverBlob: ContentBlob;

  detailOpen = false;
  hovering = false;
  hoverToggled = false;

  get imageStyle() {
    const style: any = {};

    if (this.blob.metadata?.width) {
      style.width = this.blob.metadata.width + "px";
      if (this.blob.metadata.height) {
        style.aspectRatio = this.blob.metadata.width / this.blob.metadata.height;
      }
    }

    if (this.width) {
      style.width = this.width + "px";
    }

    return style;
  }

  get url() {
    return filePath(this.blob);
  }

  get mediumUrl() {
    return filePath(this.blob, "medium");
  }

  get hoverUrl() {
    return this.hoverBlob ? filePath(this.hoverBlob) : null;
  }

  get hasHoverImage() {
    return !!this.hoverBlob;
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  toggleHover() {
    if (this.hasHoverImage) {
      this.hoverToggled = !this.hoverToggled;
      this.hovering = this.hoverToggled;
    } else {
      this.detailOpen = !this.detailOpen;
    }
  }
}
</script>

<style lang="scss" scoped>
.image-view-container {
  // Max width = content width - 1px (so that it will not automatically break to new line in editor)
  max-width: calc(100% - 1px);
}

.instruction-text {
  font-size: 14px;
  color: var(--ui-color-text-variant);
  margin-bottom: 8px;
}

.image-view {
  > img {
    opacity: 0;
    transition: opacity 0.3s ease;

    &[lazy="loaded"] {
      opacity: 1;
      background-color: var(--ui-color-surface-min-contrast);
    }
  }

  .detail-view {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    padding: var(--spacing);
    cursor: zoom-out;

    img {
      max-width: 100%;
      max-height: 100%;
      background-color: var(--ui-color-surface-no-contrast);
    }

    transition: opacity 0.5s ease;
  }
}
</style>
