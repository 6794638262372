import i18n from "@/i18n";
import { JSONContent } from "@tiptap/core";

const DOCUMENT_STATUS_PUBLISHED = "published";
const DOCUMENT_STATUS_DRAFT = "draft";
const DOCUMENT_STATUS_EDITED = "edited";

interface ContentDocument {
  id?: number;
  order?: number;
  documentable_type: string;
  documentable_id: number;
  document_type: string;
  document_name?: string; // User inputted name. Used if not a preset document.
  content: JSONContent;
  html: string;
  draft?: boolean;
  published?: boolean;
  _destroy?: boolean;
}

const documentStatus = (document: ContentDocument) => {
  if (document.published) {
    if (document.draft) {
      return DOCUMENT_STATUS_EDITED;
    } else {
      return DOCUMENT_STATUS_PUBLISHED;
    }
  } else {
    return DOCUMENT_STATUS_DRAFT;
  }
};

const documentLabel = (document: ContentDocument): string => {
  if (document.document_name) return document.document_name;
  if (document.document_type) return i18n.t(`documents.types.${document.document_type}`).toString();
  return "[Missing label]";
};

export { documentStatus, documentLabel, DOCUMENT_STATUS_DRAFT, DOCUMENT_STATUS_PUBLISHED, DOCUMENT_STATUS_EDITED };

export default ContentDocument;
