<template>
  <page>
    <page-header class="flex-1" :title="$t('logbook.title')"></page-header>
    <page-toolbar>
      <div class="mt-4">
        <dropdown
          v-if="residencies.length > 0"
          class="inline-block"
          :title="dropdownTitle"
          :to-right="true"
          :right="true"
        >
          <router-link :to="{ name: 'MyLogbook' }">{{ $t("logbook.all_entries") }}</router-link>
          <router-link
            v-for="residency in residencies"
            :key="residency.id"
            :to="{ name: 'MyLogbook', query: { residency_id: residency.id } }"
          >
            {{ residencyTitle(residency) }}
          </router-link>
        </dropdown>
      </div>
      <tabs class="mt-4">
        <tab-link :to="{ name: 'MyLogbookEntries', query: residency_id ? { residency_id } : null }">
          {{ $t("entries.title") }}
        </tab-link>
        <tab-link
          v-for="curriculum in curriculums"
          :key="curriculum.id"
          :to="{ name: 'MyLogbookCurriculum', query: { residency_id, curriculum_id: curriculum.id } }"
        >
          {{ curriculum.name }}
        </tab-link>
      </tabs>
    </page-toolbar>
    <page-content>
      <router-view :entries="entries" :usage-records="usageRecords" :curriculum="curriculum"></router-view>
    </page-content>
  </page>
</template>

<script lang="ts">
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import ProcedureModal from "@/components/logbook-entry/procedure-modal.vue";
import CourseModal from "@/components/logbook-entry/course-modal.vue";
import OtherEntryModal from "@/components/logbook-entry/other-entry-modal.vue";
import { Prop, Watch } from "vue-property-decorator";
import Residency from "@/model/residency";
import Curriculum from "@/model/curriculum";
import CurriculumsService from "@/services/curriculums-service";
import { loadLogbookEntries } from "@/actions";
import LogbookEntry from "@/model/logbook-entry";
import { localizedDate } from "@/utils/formatting";
import UsageRecord from "@/model/usage-record";
import UsageRecordsService from "@/services/usage-records-service";

@Component({})
export default class MyLogbookPage extends Vue {
  @Prop()
  residency_id: string;

  @Prop()
  curriculum_id: string;

  curriculum: Curriculum = null;
  usageRecords: UsageRecord[] = null;

  get curriculumsService(): CurriculumsService {
    return new CurriculumsService();
  }

  get usageRecordsService() {
    return new UsageRecordsService();
  }

  get dropdownTitle() {
    return this.selectedResidency ? this.residencyTitle(this.selectedResidency) : this.$t("logbook.all_entries");
  }

  get selectedResidency(): Residency {
    return this.residency_id
      ? this.$store.getters.currentUser.residencies.find(({ id }) => id.toString() === this.residency_id.toString())
      : null;
  }

  get curriculums(): Curriculum[] {
    return this.selectedResidency && !this.selectedResidency.end_date && this.selectedResidency.curriculums;
  }

  get residencies(): Residency[] {
    return this.$currentUser.residencies || [];
  }

  get entries() {
    let entries = this.$store.getters.logbookEntries as LogbookEntry<any>[];
    if (this.residency_id) {
      const id = Number.parseInt(this.residency_id);
      entries = entries.filter(({ residency_id }) => residency_id === id);
    }
    return entries;
  }

  addProcedure() {
    showDialog(ProcedureModal);
  }

  addCourse() {
    showDialog(CourseModal);
  }

  addOtherEntry() {
    showDialog(OtherEntryModal);
  }

  residencyTitle(residency: Residency): string {
    let title = residency.customer_organization.name;

    if (residency.end_date) {
      title += ` (${localizedDate(residency.start_date)} - ${localizedDate(residency.end_date)})`;
    }

    return title;
  }

  async created() {
    return Promise.all([loadLogbookEntries(), this.fetchCurriculum(), this.fetchUsageRecords()]);
  }

  @Watch("curriculum_id")
  async fetchCurriculum() {
    this.curriculum = null;
    if (this.curriculum_id) {
      return this.curriculumsService.get(this.curriculum_id).then((curriculum) => (this.curriculum = curriculum));
    }
  }

  @Watch("residency_id")
  async fetchUsageRecords() {
    this.usageRecords = null;
    const params = this.residency_id ? { residency_id: this.residency_id } : {};
    return this.usageRecordsService.getAll({ params }).then((usageRecords) => (this.usageRecords = usageRecords));
  }
}
</script>

<style lang="scss" scoped>
.logbook-entry-card {
  margin-left: -$spacing;
  margin-right: -$spacing;
}
</style>
