<template>
  <page>
    <page-header :title="$t('dashboard.overview')"></page-header>
    <page-content>
      <div class="cols">
        <div class="left">
          <residents-card></residents-card>
          <most-performed-card></most-performed-card>
          <least-performed-card></least-performed-card>
        </div>
        <div class="right">
          <recent-activity-card></recent-activity-card>
        </div>
      </div>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import RecentActivityCard from "@/pages/dashboard/recent-activity-card.vue";
import MostPerformedCard from "@/pages/dashboard/most-performed-card.vue";
import LeastPerformedCard from "@/pages/dashboard/least-performed-card.vue";
import ResidentsCard from "@/pages/dashboard/residents-card.vue";

@Component({
  components: {
    RecentActivityCard,
    MostPerformedCard,
    LeastPerformedCard,
    ResidentsCard,
  },
})
export default class DashboardPage extends Vue {}
</script>
<style lang="scss" scoped>
.cols {
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-gap: var(--spacing);
  margin-top: var(--spacing);

  @media (max-width: $screen-sm-max) {
    grid-template-columns: 1fr;
  }

  .left {
    grid-column: 1;
  }

  .right {
    grid-column: 2;
  }

  @media (max-width: $screen-sm-max) {
    grid-template-columns: 1fr;
    .left,
    .right {
      grid-column: 1;
    }
  }

  .left,
  .right {
    > *:not(:last-child) {
      margin-bottom: var(--spacing);
    }
  }
}
</style>
