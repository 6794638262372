import ContentType from "@/model/content-type";
import User from "@/model/user";
import Organization from "@/model/organization";
import Speciality from "@/model/speciality";
import Attachment from "./attachment";
import Location from "@/model/location";

const CONTENT_ITEM_STATUS_PUBLISHED = "published";
const CONTENT_ITEM_STATUS_DRAFT = "draft";
const CONTENT_ITEM_STATUS_EDITED = "edited";

interface ContentItem {
  id?: number;
  name: string;
  content_id: number;
  content?: any;
  content_type: ContentType;
  preview_image?: Attachment;
  language: string;
  share_token?: string;
  user_id?: number;
  user?: User;
  specialities?: Speciality[];
  speciality_ids?: number[];
  locations?: Location[];
  location_ids?: number[];
  customer_organization?: Organization;
  customer_organization_id?: number;
  item_state: "published" | "draft" | "edited";
  created_at?: Date;
  updated_at?: Date;
  permissions?: Record<string, boolean>;
  search_rank?: number;
  search_highlight?: string;
  matching_document_type?: string;
  favorited_by_current_user?: boolean;
  has_shares?: boolean;
  latest_content_item_release?: any;
  preview_video_id?: number;
  video_surgeon?: string;
  invivo_preview_image_url?: string;
}

const getSpecialityLabels = (contentItem: ContentItem) => contentItem.specialities.map((s) => s.name.trim()).join(", ");

const getContentItemOwnerName = (contentItem: ContentItem): string => {
  return contentItem.user?.name || contentItem.customer_organization?.name || "Osgenic";
};

const isContentItemPublished = (contentItem: ContentItem): boolean =>
  contentItem.item_state === CONTENT_ITEM_STATUS_PUBLISHED || contentItem.item_state === CONTENT_ITEM_STATUS_EDITED;

const isContentItemDraft = (contentItem: ContentItem): boolean =>
  contentItem.item_state === CONTENT_ITEM_STATUS_DRAFT || contentItem.item_state === CONTENT_ITEM_STATUS_EDITED;

export { isContentItemPublished, isContentItemDraft, getSpecialityLabels, getContentItemOwnerName };

export default ContentItem;
