<template>
  <div class="tab-button text-label-strong">
    <button type="button" @click="$emit('click')">
      <slot></slot>
    </button>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class TabButton extends Vue {}
</script>

<style lang="scss" scoped>
.tab-button {
  display: inline-block;

  > button {
    display: inline-block;
    text-decoration: none;
    color: var(--ui-color-text-variant);
    white-space: nowrap;
    padding: var(--spacing-xs) 0;

    &:hover {
      color: var(--ui-color-text-primary);
    }

    &.active {
      color: var(--ui-color-text-primary);
      border-bottom: 2px solid var(--ui-color-border-emphasis);
    }
  }
}
</style>
