<template>
  <modal>
    <form name="quizLinkForm" novalidate @submit.prevent="submit()">
      <modal-header :title="$t('editor.add_quiz_link')"></modal-header>
      <modal-body v-if="visibleQuizzes">
        <form-field :label="$t('quiz.title')">
          <select v-model="selectedId">
            <option :value="null">{{ $t("attributes.not_selected") }}</option>
            <option v-for="quiz in visibleQuizzes" :key="quiz.id" :value="quiz.id">{{ quiz.name }}</option>
          </select>
        </form-field>
      </modal-body>
      <modal-body v-else>
        <loader-big></loader-big>
      </modal-body>
      <modal-footer>
        <button class="btn-primary" type="submit" :disabled="!isValid">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import QuizLink from "@/editor/extensions/quiz-link/quiz-link";
import Organization from "@/model/organization";
import Quiz from "@/model/quiz";
import QuizzesService from "@/services/quizzes-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class QuizLinkModal extends Vue {
  @Prop()
  customerOrganization: Organization;

  @Prop()
  quizLink: QuizLink;

  selectedId: number = null;

  quizzes: Quiz[] = null;

  get isValid() {
    return this.selectedId;
  }

  get visibleQuizzes() {
    if (!this.quizzes) {
      return null;
    }
    return this.quizzes.filter((quiz) =>
      this.customerOrganization
        ? quiz.customer_organization_id === this.customerOrganization.id || !quiz.customer_organization_id
        : !quiz.customer_organization_id
    );
  }

  submit() {
    const quiz = this.visibleQuizzes?.find((quiz) => quiz.id === this.selectedId);
    if (quiz) {
      this.$emit("ok", { title: quiz.name, id: quiz.id });
    }
  }

  created() {
    if (this.quizLink) {
      this.selectedId = this.quizLink.id;
    }
    this.fetchQuizzes();
  }

  fetchQuizzes() {
    this.quizzes = null;
    new QuizzesService().getAll().then((quizzes) => (this.quizzes = quizzes));
  }
}
</script>
