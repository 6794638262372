import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import ViewerLinkNodeView from "@/editor/extensions/viewer-link/viewer-link-node-view.vue";
import ViewerLink from "@/editor/extensions/viewer-link/viewer-link";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    viewerLink: {
      addViewerLink: (viewerLink: ViewerLink) => ReturnType;
      updateViewerLink: (viewerLink: ViewerLink) => ReturnType;
    };
  }
}

const ViewerLinkNode = Node.create({
  name: "viewerLink",

  inline: true,

  group: "inline",

  draggable: true,

  atom: true,

  addAttributes() {
    return {
      title: {},
      slug: {},
      image_width: { default: null },
      blob: { default: null },
    };
  },

  addCommands() {
    return {
      addViewerLink: (viewerLink: ViewerLink) => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: viewerLink,
        });
      },
      updateViewerLink: (viewerLink: ViewerLink) => ({ commands }) => {
        return commands.updateAttributes(this.name, viewerLink);
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "viewer-link-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["viewer-link-node", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(ViewerLinkNodeView);
  },
});

export default ViewerLinkNode;
