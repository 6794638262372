<template>
  <dropdown
    ref="dropdown"
    class="dropdown-select text-left"
    v-bind="dropdownProps"
    :title="title"
    :close-on-select="!multiselect"
    :close-on-button-click="closeOnButtonClick"
    :class="{ 'has-value': hasValue, multiselect: multiselect }"
    :width-by-content="false"
    :error="error"
    @show="onShow"
    @blur="onBlur"
  >
    <template #dropdownButtonTitle>
      <div v-if="allowTextSearch">
        <input
          v-if="visible"
          ref="searchInput"
          v-focus
          class="peer border-0 !p-0"
          type="text"
          :placeholder="inputPlaceholder"
          @input="onSearchInput"
        />
        <div v-show="(!floatingLabel || hasValue) && !visible" class="text-body-large">{{ title }}</div>
        <floating-label v-if="floatingLabel" :floating="visible || hasValue">{{ placeholder }}</floating-label>
      </div>
      <div v-else>
        <div class="text-body-large">{{ title }}</div>
        <floating-label v-if="floatingLabel && hasValue" :floating="true" :primary="visible">
          {{ placeholder }}
        </floating-label>
      </div>
    </template>
    <div class="overflow-y-auto max-h-[22rem]">
      <div class="select-options">
        <dropdown-select-item
          v-if="allowNull"
          :option="nullOption"
          :on-select="select"
          :is-selected-check="isSelected"
          :is-partially-selected="isPartiallySelected"
        ></dropdown-select-item>
        <div v-for="(optionGroup, key) in filteredOptions" :key="key" class="option-group">
          <div
            v-if="key && optionGroup.length > 0"
            class="flex items-center h-10 text-lebal-medium text-text-variant px-3.5 tracking-[0.5px]"
          >
            {{ key }}
          </div>
          <dropdown-select-item
            v-for="option in optionGroup"
            :key="option.value"
            :option="option"
            :multiselect="multiselect"
            :on-select="select"
            :is-selected-check="isSelected"
            :is-partially-selected="isPartiallySelected"
            @update-option="$emit('update-option', $event)"
            @delete="$emit('delete', $event)"
          ></dropdown-select-item>
        </div>
        <a v-if="addLabel" class="option" @click="onAddClick">
          <icon-fluent class="mr-sm" name="add"></icon-fluent><span>{{ addLabel }}</span>
        </a>
      </div>
      <div v-if="editing" class="edit-area pt-xs px-3.5 pb-3.5">
        <slot name="add" :add-callback="onAdd"></slot>
      </div>
    </div>
  </dropdown>
</template>

<script lang="ts">
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import DropdownSelectOption from "@/model/dropdown-select-option";
import Dropdown from "@/components/dropdown/dropdown.vue";

@Component({})
export default class DropdownSelect extends Vue {
  @Ref("dropdown")
  dropdown: Dropdown;

  @Prop()
  options: DropdownSelectOption[] | Map<string, DropdownSelectOption[]>;

  @Prop()
  value: any;

  @Prop()
  placeholder: string;

  @Prop({ default: false })
  allowNull: boolean;

  @Prop({ default: false })
  multiselect: boolean;

  @Prop()
  addLabel: string;

  @Prop({ default: false })
  error: boolean;

  @Prop({ default: false })
  allowTextSearch: boolean;

  @Prop()
  floatingLabel: boolean;

  editing: boolean = false;
  visible: boolean = false;
  searchValue: string = "";

  get nullOption(): DropdownSelectOption {
    return {
      value: null,
      label: this.$t("attributes.not_selected"),
    };
  }

  get filteredOptions(): Record<string, DropdownSelectOption[]> {
    return this.getOptions(this.searchValue);
  }

  getOptions(searchValue: string): Record<string, DropdownSelectOption[]> {
    searchValue = searchValue.toLowerCase();
    const options =
      searchValue.length > 0
        ? (this.options as DropdownSelectOption[]).filter((opt) =>
            opt.label.toString().toLowerCase().includes(searchValue)
          )
        : this.options;
    return _.isMap(options) ? Object.fromEntries(options) : { "": options };
  }

  recursiveLabelFind(inputArray, option) {
    if (this.isSelected(option)) {
      inputArray.push(option.label);
    } else {
      if (option.children?.length > 0) {
        option.children.reduce(this.recursiveLabelFind, inputArray);
      }
    }
    return inputArray;
  }

  get title(): string {
    if (!this.options) return this.placeholder;

    const labels = _.flatten(Object.values(this.getOptions(""))).reduce(this.recursiveLabelFind, []);
    return labels.length > 0 ? labels.join(", ") : this.placeholder;
  }

  get inputPlaceholder() {
    return this.floatingLabel ? this.$t("actions.search") : this.placeholder;
  }

  get hasValue(): boolean {
    if (Array.isArray(this.value)) return this.value.length > 0;
    return !!this.value;
  }

  get dropdownProps() {
    return _.omit(this.$attrs, Object.keys(this.$props));
  }

  setEditMode(state: boolean) {
    this.editing = state;
  }

  isSelected(option: DropdownSelectOption): boolean {
    return option.value == this.value || (Array.isArray(this.value) && this.value.includes(option.value));
  }

  isPartiallySelected(option: DropdownSelectOption): boolean {
    return _.some(
      option.children,
      (child) => child.value == this.value || (Array.isArray(this.value) && this.value.includes(child.value))
    );
  }

  select(option: DropdownSelectOption): void {
    this.setEditMode(false);

    if (this.multiselect) {
      // If null value selected, clear the array
      if (!option.value) {
        this.$emit("input", []);
        return;
      }

      if (option.excludeOthers) {
        this.$emit("input", [option.value]);
        return;
      }

      // If value already exists in value array, remove it. If not, add it.
      let newValue = [...(this.value || [])];
      const existingValueIndex = newValue.indexOf(option.value);

      if (existingValueIndex >= 0) {
        newValue.splice(existingValueIndex, 1);
        if (option.parent) {
          const parentIndex = newValue.indexOf(option.parent.value);
          if (parentIndex >= 0) {
            newValue.splice(parentIndex, 1);
          }
        } else if (option.children) {
          option.children.forEach((child) => {
            const childIndex = newValue.indexOf(child.value);
            if (childIndex >= 0) {
              newValue.splice(childIndex, 1);
            }
          });
        }
      } else {
        newValue.push(option.value);
        if (option.parent) {
          const everyChildSelected = _.every(option.parent.children, (child) => newValue.indexOf(child.value) >= 0);
          if (everyChildSelected) {
            newValue.push(option.parent.value);
          }
        } else if (option.children) {
          option.children.forEach((child) => {
            const childIndex = newValue.indexOf(child.value);
            if (childIndex < 0) {
              newValue.push(child.value);
            }
          });
        }
      }
      this.$emit("input", newValue);
    }
    // If this is a single value select
    else {
      this.dropdown.close();
      this.$emit("input", option.value);
    }
  }

  onAddClick(): void {
    this.setEditMode(true);
  }

  onAdd(): void {
    this.setEditMode(false);
  }

  onShow(): void {
    this.visible = true;
    this.$emit("show");
    this.$parent.$emit("show");
  }

  onBlur(): void {
    this.visible = false;
    if (this.allowTextSearch) {
      this.searchValue = "";
    }
    this.setEditMode(false);
    this.$emit("blur");
    this.$parent.$emit("blur");
  }

  onSearchInput(event: { target: { value: string } }) {
    this.searchValue = event.target.value;
  }

  closeOnButtonClick(el: Element) {
    return el !== this.$refs.searchInput;
  }
}
</script>

<style lang="scss">
:root.dark {
  .dropdown-select {
    &.open {
      & > .dropdown__button {
        border-color: var(--ui-color-text-primary);
      }
    }

    .dropdown__content {
      border: 1px solid var(--ui-color-text-primary);
    }
  }
}
</style>

<style lang="scss" scoped>
.dropdown-select::v-deep {
  &.open {
    & > .dropdown__button {
      border-color: var(--ui-color-border-primary);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-width: 1.5px;
      border-bottom-color: var(--color-outline) !important;
      border-bottom-width: 1px;
    }

    &.up {
      & > .dropdown__button {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;

        &::after {
          top: -1px;
          bottom: unset;
        }
      }
    }
  }

  .dropdown__button-title {
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      white-space: nowrap;
      pointer-events: none;
    }
  }

  &.multiselect {
    .dropdown__button-title {
      width: 0; // Does not allow overflowing title text to alter size of the dropdown element
    }
  }

  &.has-value {
    & > .dropdown__button {
      @apply border-border-primary;

      .dropdown__button-title {
        @apply text-text-primary;
      }
    }
  }

  .dropdown__content {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1.5px solid var(--ui-color-border-primary);
    border-top-width: 0;
    box-shadow: none;
  }
}

.dropdown-select {
  .option {
    @apply px-3.5;
    height: 32px;
  }
}

.edit-area::v-deep {
  @apply bg-surface-no-contrast dark:bg-surface-min-contrast;
  height: auto;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
  top: calc(100% - 42px);
  bottom: unset;

  > input,
  *:not(.input-icon) > input {
    @apply text-sm;
    height: 20px;
    padding: 0;
    border-radius: 0;
    border-color: transparent;

    &:hover {
      border-color: var(--ui-color-text-variant);

      &:focus {
        border-color: transparent;
      }
    }

    &[error] {
      border-color: var(--color-error);
    }
  }

  .dropdown-select {
    .dropdown__button {
      @apply rounded-xs;
    }
  }

  .actions {
    button {
      width: auto;
      padding: var(--spacing-sm) var(--spacing);
    }
  }
}
</style>
