<template>
  <card :title="$t('dashboard.least_procedures_performed')">
    <loader-big v-if="!users"></loader-big>
    <activity-table v-else :users="users"></activity-table>
  </card>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import DashboardService from "@/services/dashboard-service";
import User from "@/model/user";
import ActivityTable from "@/pages/dashboard/activity-table.vue";

@Component({
  components: {
    ActivityTable,
  },
})
export default class LeastPerformedCard extends Vue {
  users: User[] = null;

  get dashboardService(): DashboardService {
    return new DashboardService();
  }

  async mounted() {
    return this.fetchUsers();
  }

  async fetchUsers() {
    this.users = await this.dashboardService.getUsersWithLeastProceduresPerformed();
  }
}
</script>
