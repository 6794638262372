<template>
  <dropdown-select
    :value="value"
    :options="options"
    :placeholder="$t('attributes.system_language')"
    :error="error"
    :width-by-content="false"
    button-class="rounded-xs"
    @input="onInput"
  ></dropdown-select>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DropdownSelectOption from "@/model/dropdown-select-option";
import { availableLanguages } from "@/i18n";

@Component({})
export default class SystemLanguageSelect extends Vue {
  @Prop()
  value: string;

  @Prop({ default: false })
  error: boolean;

  get options(): DropdownSelectOption[] {
    return Object.keys(availableLanguages).map((value) => ({
      value,
      label: this.$t(`languages.${value}`).toString(),
    }));
  }

  onInput(value) {
    this.$emit("input", value);
  }
}
</script>
