<template>
  <div class="collapsible" :class="{ collapsed }">
    <div v-if="!collapsed" class="collapsible__content">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Collapsible extends Vue {
  @Prop({ default: true })
  collapsed: boolean;
}
</script>

<style lang="scss" scoped>
.collapsible {
  overflow: hidden;

  &.collapsed {
    height: 0px;
  }
}
</style>
