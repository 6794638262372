<template>
  <div class="sm:mx-4">
    <div class="sm:mx-4">
      <h3>{{ $t("plan.your_new_plan") }}</h3>
      <div class="text-label-medium text-text-primary mt-2">
        {{ $t("plan.your_new_plan_subtitle") }}
      </div>
    </div>
    <div class="sm:mx-8 my-8">
      <div class="text-body-small uppercase">{{ $t("plan.your_new_plan") }}</div>
      <div class="flex justify-between mt-4 px-4 py-2 rounded-sm bg-surface-mid-contrast border border-border-emphasis">
        <div class="label-large">{{ planName }}</div>
        <div class="label-large whitespace-nowrap">{{ totalPrice }}</div>
      </div>
      <div class="mt-8 flex justify-between">
        <div class="flex flex-col gap-4">
          <div class="text-body-small uppercase">{{ $t("plan.billed_on") }}</div>
          <div class="label-strong text-text-primary">{{ dateFilter(newPlanStartingDate) }}</div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="text-body-small uppercase">{{ $t("plan.starting_date") }}</div>
          <div class="label-strong text-text-primary">{{ dateFilter(newPlanStartingDate) }}</div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="text-body-small uppercase">{{ $t("plan.valid_until") }}</div>
          <div class="label-strong text-text-primary">{{ dateFilter(newPlanEndDate) }}</div>
        </div>
      </div>
      <div class="mt-8">
        <div class="text-body-small uppercase">{{ $t("plan.payment_method") }}</div>
        <div class="flex justify-between mt-4 px-4 py-2 rounded-sm bg-surface-mid-contrast">
          {{ $t("plan.payment_methods." + subscription.payment_method) }}
        </div>
      </div>
      <div class="mt-8 rounded-sm border border-semantic-warning p-4 flex flex-col gap-4 text-label-medium">
        <div class="text-text-variant">
          {{ selectedPlan.period === "month" ? $t("plan.auto_renew_info_monthly") : $t("plan.auto_renew_info_yearly") }}
        </div>
        <div class="text-text-variant">{{ $t("plan.cancel_membership_info_text") }}</div>
        <div class="text-text-primary">{{ $t("plan.confirm_change_info_text") }}</div>
      </div>
    </div>
    <div class="mt-12 flex justify-center gap-4">
      <button class="btn-secondary" @click="back">
        <icon-fluent class="mr-2" name="arrowLeft" variant="filled" size="16" />
        {{ $t("actions.back") }}
      </button>
      <button class="btn-primary" @click="startNewPlan">
        {{ $t("plan.start_new_plan") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import PlanPrice, { priceToDisplay } from "@/model/plan-price";
import dayjs from "dayjs";
import { computed } from "vue";
import { useI18n } from "vue-i18n-bridge";
import { dateFilter } from "@/filters";
import { useActiveSubscription } from "@/composables/session";

const { t } = useI18n();

const props = defineProps<{
  selectedPlan: PlanPrice;
}>();

const emit = defineEmits(["back", "update-subscription"]);

const subscription = useActiveSubscription();

const planName = computed(() => {
  return props.selectedPlan.period === "month" ? t("plan.one_month_subscription") : t("plan.one_year_subscription");
});

const totalPrice = computed(() => {
  const price = priceToDisplay(props.selectedPlan);
  return t("plan.total_price", { price });
});

const newPlanStartingDate = computed(() => {
  return subscription.value.next_billing_date;
});

const newPlanEndDate = computed(() => {
  return dayjs(subscription.value.next_billing_date).add(1, "year").toDate();
});
const back = () => emit("back");

const startNewPlan = () => emit("update-subscription");
</script>
