import Translations from "@/model/translations";
import _ from "lodash";
import { TranslateResult } from "vue-i18n";

export default interface DropdownSelectOption {
  value: any;
  parent?: DropdownSelectOption;
  label: string | TranslateResult;
  label_translations?: Translations;
  children?: DropdownSelectOption[];
  editable?: boolean;
  forceOpen?: boolean;
  noCheckbox?: boolean;
  noIndent?: boolean;
  separator?: boolean;
  excludeOthers?: boolean;
}

export interface TreeNode {
  id?: number | string;
  name: string;
  name_translations?: Translations;
  parent_id?: number | string;
}

/**
 * Builds node hierarchy from a flat array of nodes with id and parent_id
 */
export function BuildDropdownSelectOptionHierarchy(
  nodes: TreeNode[],
  { editable } = { editable: false }
): DropdownSelectOption[] {
  if (nodes.length == 0) {
    return [];
  }

  function sortOptions(options: DropdownSelectOption[]) {
    return _.sortBy(options, (option) => option.label.toString().toLowerCase());
  }

  function BuildOption(node: TreeNode, parent: DropdownSelectOption = null): DropdownSelectOption {
    const value = node.id;
    const children = groupedByParents[value] ? Object.values(groupedByParents[value]) : [];
    const option: DropdownSelectOption = {
      value: value,
      label: node.name,
      label_translations: node.name_translations,
      parent,
      editable,
    };
    option.children = sortOptions(children.map((child) => BuildOption(child, option)));
    return option;
  }

  const groupedByParents = _.groupBy(nodes, "parent_id");
  const options = Object.values(groupedByParents["null"]).map((node) => BuildOption(node));
  return sortOptions(options);
}
