<template>
  <div class="comment flex my-md">
    <avatar :user="comment.author" :placeholder-show-initials="true"></avatar>
    <div class="flex-1 ml-md">
      <div class="text-text-primary">
        {{ comment.author.name
        }}<span class="text-body-small text-text-variant">&nbsp;&nbsp;{{ dateFilter(comment.created_at) }}</span>
      </div>
      <div>{{ comment.message }}</div>
      <div class="flex">
        <div v-for="attachment in comment.attachments" :key="attachment.url">
          <a class="link mr-sm" :href="attachment.url" target="_blank">{{ attachment.filename }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { dateFilter } from "@/filters";
import Comment from "@/model/comment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class VueComment extends Vue {
  @Prop()
  comment: Comment;

  dateFilter(value: string) {
    return dateFilter(value);
  }
}
</script>
