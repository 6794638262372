<template>
  <form class="quiz-edit-form" @submit.prevent>
    <page v-if="loaded">
      <page-content>
        <card>
          <div class="flex flex-row mb-sm flex-wrap sm:flex-nowrap">
            <div class="flex-1 mr-md min-w-[40%]" :class="{ invalid: $v.quiz.name.$error }">
              <input
                v-model="quiz.name"
                v-focus
                class="text-text-primary wysiwyg"
                type="text"
                :class="`text-lg sm:text-2xl${$v.quiz.name.$error ? ' invalid' : ''}`"
                :placeholder="$t('quiz.enter_name')"
                maxlength="50"
              />
            </div>
            <div class="preview-modes flex items-center sm:order-4">
              <label class="mr-xs text-xs">{{ $t("preview.title") }}</label>
              <button class="btn-icon btn-tertiary" :title="$t('actions.preview_desktop')" @click="preview('large')">
                <icon icon="desktop"></icon>
              </button>
              <button class="btn-icon btn-tertiary" :title="$t('actions.preview_mobile')" @click="preview('small')">
                <icon icon="mobile-alt"></icon>
              </button>
            </div>
            <div
              v-if="organizations && organizations.length > 0"
              class="w-full mt-sm sm:w-auto sm:order-2 sm:mt-0 sm:mr-md"
            >
              <select v-model="quiz.customer_organization_id" :disabled="hasAnswers">
                <option :value="null">{{ $t("quiz.select_organization") }}</option>
                <option v-for="organization in organizations" :key="organization.id" :value="organization.id">
                  {{ organization.name }}
                </option>
              </select>
            </div>
            <div class="w-full mt-sm sm:w-auto sm:order-3 sm:mt-0 sm:mr-md">
              <language-select
                v-model="quiz.language"
                :available-languages="languages"
                :use-localized-names="true"
                :placeholder="$t('attributes.language')"
                :allow-null="true"
              ></language-select>
            </div>
          </div>
          <div class="mb-sm">
            <content-editable
              v-model="quiz.description"
              class="wysiwyg"
              :placeholder="$t('quiz.enter_description')"
            ></content-editable>
          </div>
          <div v-if="$v.quiz.questions.$error" class="form-error-message">{{ $t("questions.required_message") }}</div>
          <question-card
            v-for="(question, index) in quiz.questions"
            v-if="!question._destroy"
            :key="index"
            :ref="'question-' + index"
            :question="question"
            :question-number="index + 1"
            :only-content-editable="hasAnswers"
            @remove="removeQuestion(question)"
          ></question-card>
          <button v-if="!hasAnswers" class="btn-primary" @click="addQuestion">
            {{ $t("questions.add_question") }}
          </button>
        </card>
      </page-content>
      <page-footer>
        <footer-actions>
          <button v-if="isDraft && canPublish" class="btn-primary-variant" @click="publish">
            {{ $t("actions.publish") }}
          </button>
          <button class="btn-primary" @click="save">
            {{ isDraft ? $t("actions.save_draft") : $t("actions.save") }}
          </button>
          <button class="btn-secondary" type="button" @click="onCancel">{{ $t("actions.cancel") }}</button>
        </footer-actions>
      </page-footer>
    </page>
  </form>
</template>

<script lang="ts">
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import QuizModal from "@/components/quiz/quiz-modal.vue";
import Organization from "@/model/organization";
import Quiz, { isDraftQuiz } from "@/model/quiz";
import QuizQuestion from "@/model/quiz-question";
import Organizations from "@/services/organizations";
import QuizzesService from "@/services/quizzes-service";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, maxLength } from "vuelidate/lib/validators";
import QuestionCard from "./question-card.vue";
import { availableContentLanguages } from "@/i18n";

@Component({
  components: {
    QuestionCard,
  },
})
export default class QuizEditPage extends Vue {
  @Prop()
  id: string;

  @Prop({ required: true })
  action: string;

  quiz: Partial<Quiz> = null;

  organizations: Organization[] = null;

  @Validations()
  validations = {
    quiz: {
      name: { required, maxLength: maxLength(50) },
      questions: { required },
    },
  };

  get loaded() {
    return this.quiz;
  }

  get title() {
    if (this.action == "edit") {
      return this.$t("quizzes.edit_quiz");
    }

    return this.$t("quizzes.add_quiz");
  }

  get isDraft() {
    return isDraftQuiz(this.quiz as Quiz);
  }

  get hasAnswers() {
    return this.quiz.answers_count > 0;
  }

  get languages() {
    return availableContentLanguages;
  }

  get canPublish() {
    return this.$currentUser.canPublishQuiz(this.quiz as Quiz);
  }

  created() {
    if (this.id) {
      this.fetchQuiz();
    } else {
      this.initQuiz();
    }

    this.fetchCustomerOrganizations();
  }

  async addQuestion() {
    const question: Partial<QuizQuestion> = {
      question: "",
      image: null,
      explanation: "",
      options: [],
    };
    this.quiz.questions.push(question as QuizQuestion);
  }

  removeQuestion(question: QuizQuestion) {
    const removeIndex = this.quiz.questions.indexOf(question);
    if (removeIndex >= 0) {
      Vue.set(this.quiz.questions, removeIndex, { ...question, _destroy: true });
    }
  }

  questionCards() {
    return Object.keys(this.$refs)
      .filter((key) => key.startsWith("question-"))
      .map((key) => this.$refs[key][0] as QuestionCard)
      .filter((obj) => obj);
  }

  validateQuestions() {
    return this.questionCards()
      .map((el) => {
        el.$v.$touch();
        return el.validate();
      })
      .every((valid) => valid);
  }

  validate() {
    this.$v.$touch();
    return this.validateQuestions() && !this.$v.$error;
  }

  async publish() {
    if (this.validate()) {
      const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.publish_quiz") });
      if (confirmed) {
        const action = this._save().then((quiz) => new QuizzesService().publish(quiz.id));
        await showDialog(LoadingIndicatorDialog, { action });
        this.$router.back();
      }
    }
  }

  async save() {
    if (this.validate()) {
      const action = this._save();
      await showDialog(LoadingIndicatorDialog, { action });
      this.$router.back();
    }
  }

  private _save() {
    return this.action === "edit"
      ? new QuizzesService().update(this.id, this.quiz)
      : new QuizzesService().create(this.quiz);
  }

  preview(size: string) {
    if (!this.validate()) {
      return;
    }
    showDialog(QuizModal, { preview: this.quiz, size });
  }

  onCancel() {
    this.$router.back();
  }

  private initQuiz() {
    this.quiz = {
      name: "",
      description: "",
      questions: [],
      customer_organization_id: null,
    };

    if (this.$currentUser.isHospitalAdmin()) {
      this.quiz.customer_organization_id = this.$currentUser.customer_organization_id;
    } else if (this.$currentUser.isResident()) {
      const organizations = this.$currentUser.activeResidencies
        .filter((residency) => residency.permissions.includes("edit_content"))
        .map((residency) => residency.customer_organization);
      if (organizations.length > 0) {
        this.quiz.customer_organization_id = organizations[0].id;
        if (organizations.length > 1) {
          this.organizations = organizations;
        }
      } else {
        this.$router.back();
      }
    }
  }

  async fetchQuiz() {
    this.quiz = await new QuizzesService().get(this.id);
    delete this.quiz.id;
    if (this.action === "duplicate") {
      this.quiz.name = `${this.quiz.name} (${this.$t("terms.copy")})`;
      // Remove id’s from questions and options
      this.quiz.questions = this.quiz.questions.map(({ id, ...question }) => ({
        ...question,
        options: question.options.map(({ id, ...option }) => option),
      })) as QuizQuestion[];
    }
  }

  fetchCustomerOrganizations() {
    if (this.$currentUser.isAdmin() || this.$currentUser.isContentAdmin()) {
      new Organizations().getAll().then((organizations) => (this.organizations = organizations));
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  flex: 1;
  display: flex;
  overflow: hidden;
}
</style>
