export default class Subscription {
  id: number;
  user_id: number;
  start_date: string;
  end_date: string;
  next_billing_date: string;
  cancel_date: string;
  status: string;
  period: string;
  currency: string;
  payment_method: string;
  payment_method_details: any;
  plan_price_id: string;
  scheduled_plan_price_id: string;
  scheduled_plan_period: string;
  coupon: string;
  discount_percentage: number;
  price: number;
}

const activeStatuses = ["in_trial", "active", "non_renewing"];

export const isActiveSubscription = (subscription: Subscription) => {
  return activeStatuses.includes(subscription.status);
};
