<template>
  <inline-svg v-if="iconSrc" class="icon" :class="variant" :src="iconSrc" fill="currentColor" @click="$emit('click')" />
</template>

<script lang="ts" setup>
import { computedAsync } from "@vueuse/core";

const icons = {
  regular: {
    16: {
      add: import("@fluentui/svg-icons/icons/add_16_regular.svg"),
      arrowLeft: import("@fluentui/svg-icons/icons/arrow_left_16_regular.svg"),
      arrowRight: import("@fluentui/svg-icons/icons/arrow_right_16_regular.svg"),
      arrowUp: import("@fluentui/svg-icons/icons/arrow_up_16_regular.svg"),
      arrowUpLeft: import("@fluentui/svg-icons/icons/arrow_up_left_16_regular.svg"),
      arrowDown: import("@fluentui/svg-icons/icons/arrow_down_16_regular.svg"),
      arrowFit: import("@fluentui/svg-icons/icons/arrow_fit_16_regular.svg"),
      arrowUpload: import("@fluentui/svg-icons/icons/arrow_upload_16_regular.svg"),
      attach: import("@fluentui/svg-icons/icons/attach_16_regular.svg"),
      checkmark: import("@fluentui/svg-icons/icons/checkmark_16_regular.svg"),
      checkboxChecked: import("@fluentui/svg-icons/icons/checkbox_checked_16_regular.svg"),
      checkboxUnchecked: import("@fluentui/svg-icons/icons/checkbox_unchecked_16_regular.svg"),
      caretLeft: import("@fluentui/svg-icons/icons/caret_left_16_regular.svg"),
      caretRight: import("@fluentui/svg-icons/icons/caret_right_16_regular.svg"),
      caretUp: import("@fluentui/svg-icons/icons/caret_up_16_regular.svg"),
      caretDown: import("@fluentui/svg-icons/icons/caret_down_16_regular.svg"),
      chevronLeft: import("@fluentui/svg-icons/icons/chevron_left_16_regular.svg"),
      chevronRight: import("@fluentui/svg-icons/icons/chevron_right_16_regular.svg"),
      chevronUp: import("@fluentui/svg-icons/icons/chevron_up_16_regular.svg"),
      chevronDown: import("@fluentui/svg-icons/icons/chevron_down_16_regular.svg"),
      copy: import("@fluentui/svg-icons/icons/copy_16_regular.svg"),
      delete: import("@fluentui/svg-icons/icons/delete_16_regular.svg"),
      document: import("@fluentui/svg-icons/icons/document_16_regular.svg"),
      documentPdf: import("@fluentui/svg-icons/icons/document_pdf_16_regular.svg"),
      dismiss: import("@fluentui/svg-icons/icons/dismiss_16_regular.svg"),
      documentQuestionMark: import("@fluentui/svg-icons/icons/document_question_mark_16_regular.svg"),
      edit: import("@fluentui/svg-icons/icons/edit_16_regular.svg"),
      errorCircle: import("@fluentui/svg-icons/icons/error_circle_16_regular.svg"),
      eye: import("@fluentui/svg-icons/icons/eye_16_regular.svg"),
      eyeOff: import("@fluentui/svg-icons/icons/eye_off_16_regular.svg"),
      excel: import("@/assets/images/content_types/excel_16_regular.svg"),
      filter: import("@fluentui/svg-icons/icons/filter_16_regular.svg"),
      folderAdd: import("@fluentui/svg-icons/icons/folder_add_16_regular.svg"),
      folderZip: import("@fluentui/svg-icons/icons/folder_zip_16_regular.svg"),
      globe: import("@fluentui/svg-icons/icons/globe_16_regular.svg"),
      grid: import("@fluentui/svg-icons/icons/grid_16_regular.svg"),
      image: import("@fluentui/svg-icons/icons/image_16_regular.svg"),
      iot: import("@/assets/images/content_types/iot_16_regular.svg"),
      link: import("@fluentui/svg-icons/icons/link_16_regular.svg"),
      mail: import("@fluentui/svg-icons/icons/mail_16_regular.svg"),
      news: import("@fluentui/svg-icons/icons/news_16_regular.svg"),
      open: import("@fluentui/svg-icons/icons/open_16_regular.svg"),
      question: import("@fluentui/svg-icons/icons/question_16_regular.svg"),
      peopleTeam: import("@fluentui/svg-icons/icons/people_team_16_regular.svg"),
      person: import("@fluentui/svg-icons/icons/person_16_regular.svg"),
      ppt: import("@/assets/images/content_types/ppt_16_regular.svg"),
      premium: import("@fluentui/svg-icons/icons/premium_16_regular.svg"),
      previewLink: import("@fluentui/svg-icons/icons/preview_link_16_regular.svg"),
      questionCircle: import("@fluentui/svg-icons/icons/question_circle_16_regular.svg"),
      search: import("@fluentui/svg-icons/icons/search_16_regular.svg"),
      share: import("@fluentui/svg-icons/icons/share_16_regular.svg"),
      star: import("@fluentui/svg-icons/icons/star_16_regular.svg"),
      textBold: import("@fluentui/svg-icons/icons/text_bold_16_regular.svg"),
      textBulletList: import("@fluentui/svg-icons/icons/text_bullet_list_ltr_16_regular.svg"),
      textIndentIncrease: import("@fluentui/svg-icons/icons/text_indent_increase_ltr_16_regular.svg"),
      textIndentDecrease: import("@fluentui/svg-icons/icons/text_indent_decrease_ltr_16_regular.svg"),
      textItalic: import("@fluentui/svg-icons/icons/text_italic_16_regular.svg"),
      textNumberList: import("@fluentui/svg-icons/icons/text_number_list_ltr_16_regular.svg"),
      textUnderline: import("@fluentui/svg-icons/icons/text_underline_16_regular.svg"),
      videoClip: import("@fluentui/svg-icons/icons/video_clip_16_regular.svg"),
      warning: import("@fluentui/svg-icons/icons/warning_16_regular.svg"),
      word: import("@/assets/images/content_types/word_16_regular.svg"),
    },
    20: {
      add: import("@fluentui/svg-icons/icons/add_20_regular.svg"),
      appGeneric: import("@fluentui/svg-icons/icons/app_generic_20_regular.svg"),
      appsList: import("@fluentui/svg-icons/icons/apps_list_20_regular.svg"),
      arrowLeft: import("@fluentui/svg-icons/icons/arrow_left_20_regular.svg"),
      arrowRight: import("@fluentui/svg-icons/icons/arrow_right_20_regular.svg"),
      arrowUp: import("@fluentui/svg-icons/icons/arrow_up_20_regular.svg"),
      arrowUpLeft: import("@fluentui/svg-icons/icons/arrow_up_left_20_regular.svg"),
      arrowDown: import("@fluentui/svg-icons/icons/arrow_down_20_regular.svg"),
      arrowFit: import("@fluentui/svg-icons/icons/arrow_fit_20_regular.svg"),
      arrowReset: import("@fluentui/svg-icons/icons/arrow_reset_20_regular.svg"),
      arrowUpload: import("@fluentui/svg-icons/icons/arrow_upload_20_regular.svg"),
      attach: import("@fluentui/svg-icons/icons/attach_20_regular.svg"),
      buildingMultiple: import("@fluentui/svg-icons/icons/building_multiple_20_regular.svg"),
      cameraAdd: import("@fluentui/svg-icons/icons/camera_add_20_regular.svg"),
      chartMultiple: import("@fluentui/svg-icons/icons/chart_multiple_20_regular.svg"),
      checkmark: import("@fluentui/svg-icons/icons/checkmark_20_regular.svg"),
      checkboxChecked: import("@fluentui/svg-icons/icons/checkbox_checked_20_regular.svg"),
      checkboxUnchecked: import("@fluentui/svg-icons/icons/checkbox_unchecked_20_regular.svg"),
      caretLeft: import("@fluentui/svg-icons/icons/caret_left_20_regular.svg"),
      caretRight: import("@fluentui/svg-icons/icons/caret_right_20_regular.svg"),
      caretUp: import("@fluentui/svg-icons/icons/caret_up_20_regular.svg"),
      caretDown: import("@fluentui/svg-icons/icons/caret_down_20_regular.svg"),
      chevronLeft: import("@fluentui/svg-icons/icons/chevron_left_20_regular.svg"),
      chevronRight: import("@fluentui/svg-icons/icons/chevron_right_20_regular.svg"),
      chevronUp: import("@fluentui/svg-icons/icons/chevron_up_20_regular.svg"),
      chevronDown: import("@fluentui/svg-icons/icons/chevron_down_20_regular.svg"),
      clipboardText: import("@fluentui/svg-icons/icons/clipboard_text_ltr_20_regular.svg"),
      copy: import("@fluentui/svg-icons/icons/copy_20_regular.svg"),
      delete: import("@fluentui/svg-icons/icons/delete_20_regular.svg"),
      document: import("@fluentui/svg-icons/icons/document_20_regular.svg"),
      documentPdf: import("@fluentui/svg-icons/icons/document_pdf_20_regular.svg"),
      dismiss: import("@fluentui/svg-icons/icons/dismiss_20_regular.svg"),
      documentQuestionMark: import("@fluentui/svg-icons/icons/document_question_mark_20_regular.svg"),
      edit: import("@fluentui/svg-icons/icons/edit_20_regular.svg"),
      errorCircle: import("@fluentui/svg-icons/icons/error_circle_20_regular.svg"),
      eye: import("@fluentui/svg-icons/icons/eye_20_regular.svg"),
      eyeOff: import("@fluentui/svg-icons/icons/eye_off_20_regular.svg"),
      excel: import("@/assets/images/content_types/excel_20_regular.svg"),
      filter: import("@fluentui/svg-icons/icons/filter_20_regular.svg"),
      folderAdd: import("@fluentui/svg-icons/icons/folder_add_20_regular.svg"),
      folderZip: import("@fluentui/svg-icons/icons/folder_zip_20_regular.svg"),
      globe: import("@fluentui/svg-icons/icons/globe_20_regular.svg"),
      grid: import("@fluentui/svg-icons/icons/grid_20_regular.svg"),
      gridKanban: import("@fluentui/svg-icons/icons/grid_kanban_20_regular.svg"),
      home: import("@fluentui/svg-icons/icons/home_20_regular.svg"),
      hatGraduation: import("@fluentui/svg-icons/icons/hat_graduation_20_regular.svg"),
      headsetVR: import("@fluentui/svg-icons/icons/headset_vr_20_regular.svg"),
      image: import("@fluentui/svg-icons/icons/image_20_regular.svg"),
      iot: import("@fluentui/svg-icons/icons/iot_20_regular.svg"),
      link: import("@fluentui/svg-icons/icons/link_20_regular.svg"),
      lockClosed: import("@fluentui/svg-icons/icons/lock_closed_20_regular.svg"),
      mail: import("@fluentui/svg-icons/icons/mail_20_regular.svg"),
      moreHorizontal: import("@fluentui/svg-icons/icons/more_horizontal_20_regular.svg"),
      moreVertical: import("@fluentui/svg-icons/icons/more_vertical_20_regular.svg"),
      news: import("@fluentui/svg-icons/icons/news_20_regular.svg"),
      open: import("@fluentui/svg-icons/icons/open_20_regular.svg"),
      question: import("@fluentui/svg-icons/icons/question_20_regular.svg"),
      peopleSettings: import("@fluentui/svg-icons/icons/people_settings_20_regular.svg"),
      peopleTeam: import("@fluentui/svg-icons/icons/people_team_20_regular.svg"),
      person: import("@fluentui/svg-icons/icons/person_20_regular.svg"),
      ppt: import("@/assets/images/content_types/ppt_20_regular.svg"),
      premium: import("@fluentui/svg-icons/icons/premium_20_regular.svg"),
      previewLink: import("@fluentui/svg-icons/icons/preview_link_20_regular.svg"),
      questionCircle: import("@fluentui/svg-icons/icons/question_circle_20_regular.svg"),
      radioButton: import("@fluentui/svg-icons/icons/radio_button_20_regular.svg"),
      search: import("@fluentui/svg-icons/icons/search_20_regular.svg"),
      share: import("@fluentui/svg-icons/icons/share_20_regular.svg"),
      shareScreenStop: import("@fluentui/svg-icons/icons/share_screen_stop_20_regular.svg"),
      signOut: import("@fluentui/svg-icons/icons/sign_out_20_regular.svg"),
      star: import("@fluentui/svg-icons/icons/star_20_regular.svg"),
      textBold: import("@fluentui/svg-icons/icons/text_bold_20_regular.svg"),
      textBulletList: import("@fluentui/svg-icons/icons/text_bullet_list_ltr_20_regular.svg"),
      textHeader1: import("@fluentui/svg-icons/icons/text_header_1_20_regular.svg"),
      textHeader2: import("@fluentui/svg-icons/icons/text_header_2_20_regular.svg"),
      textHeader3: import("@fluentui/svg-icons/icons/text_header_3_20_regular.svg"),
      textIndentIncrease: import("@fluentui/svg-icons/icons/text_indent_increase_ltr_20_regular.svg"),
      textIndentDecrease: import("@fluentui/svg-icons/icons/text_indent_decrease_ltr_20_regular.svg"),
      textItalic: import("@fluentui/svg-icons/icons/text_italic_20_regular.svg"),
      textNumberList: import("@fluentui/svg-icons/icons/text_number_list_ltr_20_regular.svg"),
      textParagraph: import("@fluentui/svg-icons/icons/text_paragraph_20_regular.svg"),
      textUnderline: import("@fluentui/svg-icons/icons/text_underline_20_regular.svg"),
      videoClip: import("@fluentui/svg-icons/icons/video_clip_20_regular.svg"),
      viewDesktop: import("@fluentui/svg-icons/icons/view_desktop_20_regular.svg"),
      warning: import("@fluentui/svg-icons/icons/warning_20_regular.svg"),
      word: import("@/assets/images/content_types/word_20_regular.svg"),
    },
  },
  filled: {
    16: {
      add: import("@fluentui/svg-icons/icons/add_16_filled.svg"),
      arrowLeft: import("@fluentui/svg-icons/icons/arrow_left_16_filled.svg"),
      arrowRight: import("@fluentui/svg-icons/icons/arrow_right_16_filled.svg"),
      arrowUp: import("@fluentui/svg-icons/icons/arrow_up_16_filled.svg"),
      arrowUpLeft: import("@fluentui/svg-icons/icons/arrow_up_left_16_filled.svg"),
      arrowDown: import("@fluentui/svg-icons/icons/arrow_down_16_filled.svg"),
      arrowFit: import("@fluentui/svg-icons/icons/arrow_fit_16_filled.svg"),
      arrowUpload: import("@fluentui/svg-icons/icons/arrow_upload_16_filled.svg"),
      attach: import("@fluentui/svg-icons/icons/attach_16_filled.svg"),
      checkmark: import("@fluentui/svg-icons/icons/checkmark_16_filled.svg"),
      checkboxChecked: import("@fluentui/svg-icons/icons/checkbox_checked_16_filled.svg"),
      checkboxUnchecked: import("@fluentui/svg-icons/icons/checkbox_unchecked_16_filled.svg"),
      caretLeft: import("@fluentui/svg-icons/icons/caret_left_16_filled.svg"),
      caretRight: import("@fluentui/svg-icons/icons/caret_right_16_filled.svg"),
      caretUp: import("@fluentui/svg-icons/icons/caret_up_16_filled.svg"),
      caretDown: import("@fluentui/svg-icons/icons/caret_down_16_filled.svg"),
      chevronLeft: import("@fluentui/svg-icons/icons/chevron_left_16_filled.svg"),
      chevronRight: import("@fluentui/svg-icons/icons/chevron_right_16_filled.svg"),
      chevronUp: import("@fluentui/svg-icons/icons/chevron_up_16_filled.svg"),
      chevronDown: import("@fluentui/svg-icons/icons/chevron_down_16_filled.svg"),
      copy: import("@fluentui/svg-icons/icons/copy_16_filled.svg"),
      delete: import("@fluentui/svg-icons/icons/delete_16_filled.svg"),
      document: import("@fluentui/svg-icons/icons/document_16_filled.svg"),
      documentPdf: import("@fluentui/svg-icons/icons/document_pdf_16_filled.svg"),
      dismiss: import("@fluentui/svg-icons/icons/dismiss_16_filled.svg"),
      documentQuestionMark: import("@fluentui/svg-icons/icons/document_question_mark_16_filled.svg"),
      edit: import("@fluentui/svg-icons/icons/edit_16_filled.svg"),
      errorCircle: import("@fluentui/svg-icons/icons/error_circle_16_filled.svg"),
      eye: import("@fluentui/svg-icons/icons/eye_16_filled.svg"),
      eyeOff: import("@fluentui/svg-icons/icons/eye_off_16_filled.svg"),
      excel: import("@/assets/images/content_types/excel_16_regular.svg"),
      filter: import("@fluentui/svg-icons/icons/filter_16_filled.svg"),
      folderAdd: import("@fluentui/svg-icons/icons/folder_add_16_filled.svg"),
      folderZip: import("@fluentui/svg-icons/icons/folder_zip_16_filled.svg"),
      globe: import("@fluentui/svg-icons/icons/globe_16_filled.svg"),
      grid: import("@fluentui/svg-icons/icons/grid_16_filled.svg"),
      image: import("@fluentui/svg-icons/icons/image_16_filled.svg"),
      iot: import("@/assets/images/content_types/iot_16_regular.svg"),
      link: import("@fluentui/svg-icons/icons/link_16_filled.svg"),
      mail: import("@fluentui/svg-icons/icons/mail_16_filled.svg"),
      news: import("@fluentui/svg-icons/icons/news_16_filled.svg"),
      open: import("@fluentui/svg-icons/icons/open_16_filled.svg"),
      question: import("@fluentui/svg-icons/icons/question_16_filled.svg"),
      peopleTeam: import("@fluentui/svg-icons/icons/people_team_16_filled.svg"),
      person: import("@fluentui/svg-icons/icons/person_16_filled.svg"),
      ppt: import("@/assets/images/content_types/ppt_16_regular.svg"),
      premium: import("@fluentui/svg-icons/icons/premium_16_filled.svg"),
      previewLink: import("@fluentui/svg-icons/icons/preview_link_16_filled.svg"),
      questionCircle: import("@fluentui/svg-icons/icons/question_circle_16_filled.svg"),
      search: import("@fluentui/svg-icons/icons/search_16_filled.svg"),
      share: import("@fluentui/svg-icons/icons/share_16_filled.svg"),
      star: import("@fluentui/svg-icons/icons/star_16_filled.svg"),
      textBold: import("@fluentui/svg-icons/icons/text_bold_16_filled.svg"),
      textBulletList: import("@fluentui/svg-icons/icons/text_bullet_list_ltr_16_filled.svg"),
      textIndentIncrease: import("@fluentui/svg-icons/icons/text_indent_increase_ltr_16_filled.svg"),
      textIndentDecrease: import("@fluentui/svg-icons/icons/text_indent_decrease_ltr_16_filled.svg"),
      textItalic: import("@fluentui/svg-icons/icons/text_italic_16_filled.svg"),
      textNumberList: import("@fluentui/svg-icons/icons/text_number_list_ltr_16_filled.svg"),
      textUnderline: import("@fluentui/svg-icons/icons/text_underline_16_filled.svg"),
      videoClip: import("@fluentui/svg-icons/icons/video_clip_16_filled.svg"),
      warning: import("@fluentui/svg-icons/icons/warning_16_filled.svg"),
      word: import("@/assets/images/content_types/word_16_regular.svg"),
    },
    20: {
      add: import("@fluentui/svg-icons/icons/add_20_filled.svg"),
      appGeneric: import("@fluentui/svg-icons/icons/app_generic_20_filled.svg"),
      appsList: import("@fluentui/svg-icons/icons/apps_list_20_filled.svg"),
      arrowUp: import("@fluentui/svg-icons/icons/arrow_up_20_filled.svg"),
      arrowUpLeft: import("@fluentui/svg-icons/icons/arrow_up_left_20_filled.svg"),
      arrowDown: import("@fluentui/svg-icons/icons/arrow_down_20_filled.svg"),
      arrowFit: import("@fluentui/svg-icons/icons/arrow_fit_20_filled.svg"),
      arrowReset: import("@fluentui/svg-icons/icons/arrow_reset_20_filled.svg"),
      arrowUpload: import("@fluentui/svg-icons/icons/arrow_upload_20_filled.svg"),
      attach: import("@fluentui/svg-icons/icons/attach_20_filled.svg"),
      buildingMultiple: import("@fluentui/svg-icons/icons/building_multiple_20_filled.svg"),
      cameraAdd: import("@fluentui/svg-icons/icons/camera_add_20_filled.svg"),
      chartMultiple: import("@fluentui/svg-icons/icons/chart_multiple_20_filled.svg"),
      checkmark: import("@fluentui/svg-icons/icons/checkmark_20_filled.svg"),
      checkboxChecked: import("@fluentui/svg-icons/icons/checkbox_checked_20_filled.svg"),
      checkboxUnchecked: import("@fluentui/svg-icons/icons/checkbox_unchecked_20_filled.svg"),
      checkboxIndeterminate: import("@fluentui/svg-icons/icons/checkbox_indeterminate_20_filled.svg"),
      caretLeft: import("@fluentui/svg-icons/icons/caret_left_20_filled.svg"),
      caretRight: import("@fluentui/svg-icons/icons/caret_right_20_filled.svg"),
      caretUp: import("@fluentui/svg-icons/icons/caret_up_20_filled.svg"),
      caretDown: import("@fluentui/svg-icons/icons/caret_down_20_filled.svg"),
      chevronLeft: import("@fluentui/svg-icons/icons/chevron_left_20_filled.svg"),
      chevronRight: import("@fluentui/svg-icons/icons/chevron_right_20_filled.svg"),
      chevronUp: import("@fluentui/svg-icons/icons/chevron_up_20_filled.svg"),
      chevronDown: import("@fluentui/svg-icons/icons/chevron_down_20_filled.svg"),
      clipboardText: import("@fluentui/svg-icons/icons/clipboard_text_ltr_20_filled.svg"),
      copy: import("@fluentui/svg-icons/icons/copy_20_filled.svg"),
      delete: import("@fluentui/svg-icons/icons/delete_20_filled.svg"),
      document: import("@fluentui/svg-icons/icons/document_20_filled.svg"),
      documentPdf: import("@fluentui/svg-icons/icons/document_pdf_20_filled.svg"),
      dismiss: import("@fluentui/svg-icons/icons/dismiss_20_filled.svg"),
      documentQuestionMark: import("@fluentui/svg-icons/icons/document_question_mark_20_filled.svg"),
      edit: import("@fluentui/svg-icons/icons/edit_20_filled.svg"),
      errorCircle: import("@fluentui/svg-icons/icons/error_circle_20_filled.svg"),
      eye: import("@fluentui/svg-icons/icons/eye_20_filled.svg"),
      excel: import("@/assets/images/content_types/excel_20_regular.svg"),
      filter: import("@fluentui/svg-icons/icons/filter_20_filled.svg"),
      folderAdd: import("@fluentui/svg-icons/icons/folder_add_20_filled.svg"),
      folderZip: import("@fluentui/svg-icons/icons/folder_zip_20_filled.svg"),
      globe: import("@fluentui/svg-icons/icons/globe_20_filled.svg"),
      grid: import("@fluentui/svg-icons/icons/grid_20_filled.svg"),
      gridKanban: import("@fluentui/svg-icons/icons/grid_kanban_20_filled.svg"),
      home: import("@fluentui/svg-icons/icons/home_20_filled.svg"),
      hatGraduation: import("@fluentui/svg-icons/icons/hat_graduation_20_filled.svg"),
      headsetVR: import("@fluentui/svg-icons/icons/headset_vr_20_filled.svg"),
      image: import("@fluentui/svg-icons/icons/image_20_filled.svg"),
      iot: import("@fluentui/svg-icons/icons/iot_20_filled.svg"),
      link: import("@fluentui/svg-icons/icons/link_20_filled.svg"),
      mail: import("@fluentui/svg-icons/icons/mail_20_filled.svg"),
      moreHorizontal: import("@fluentui/svg-icons/icons/more_horizontal_20_filled.svg"),
      moreVertical: import("@fluentui/svg-icons/icons/more_vertical_20_filled.svg"),
      news: import("@fluentui/svg-icons/icons/news_20_filled.svg"),
      open: import("@fluentui/svg-icons/icons/open_20_filled.svg"),
      question: import("@fluentui/svg-icons/icons/question_20_filled.svg"),
      peopleSettings: import("@fluentui/svg-icons/icons/people_settings_20_filled.svg"),
      peopleTeam: import("@fluentui/svg-icons/icons/people_team_20_filled.svg"),
      person: import("@fluentui/svg-icons/icons/person_20_filled.svg"),
      ppt: import("@/assets/images/content_types/ppt_20_regular.svg"),
      premium: import("@fluentui/svg-icons/icons/premium_20_filled.svg"),
      previewLink: import("@fluentui/svg-icons/icons/preview_link_20_filled.svg"),
      questionCircle: import("@fluentui/svg-icons/icons/question_circle_20_filled.svg"),
      radioButton: import("@fluentui/svg-icons/icons/radio_button_20_filled.svg"),
      search: import("@fluentui/svg-icons/icons/search_20_filled.svg"),
      share: import("@fluentui/svg-icons/icons/share_20_filled.svg"),
      shareScreenStop: import("@fluentui/svg-icons/icons/share_screen_stop_20_filled.svg"),
      signOut: import("@fluentui/svg-icons/icons/sign_out_20_filled.svg"),
      star: import("@fluentui/svg-icons/icons/star_20_filled.svg"),
      textBold: import("@fluentui/svg-icons/icons/text_bold_20_filled.svg"),
      textBulletList: import("@fluentui/svg-icons/icons/text_bullet_list_ltr_20_filled.svg"),
      textHeader1: import("@fluentui/svg-icons/icons/text_header_1_20_filled.svg"),
      textHeader2: import("@fluentui/svg-icons/icons/text_header_2_20_filled.svg"),
      textHeader3: import("@fluentui/svg-icons/icons/text_header_3_20_filled.svg"),
      textIndentIncrease: import("@fluentui/svg-icons/icons/text_indent_increase_ltr_20_filled.svg"),
      textIndentDecrease: import("@fluentui/svg-icons/icons/text_indent_decrease_ltr_20_filled.svg"),
      textItalic: import("@fluentui/svg-icons/icons/text_italic_20_filled.svg"),
      textNumberList: import("@fluentui/svg-icons/icons/text_number_list_ltr_20_filled.svg"),
      textParagraph: import("@fluentui/svg-icons/icons/text_paragraph_20_filled.svg"),
      textUnderline: import("@fluentui/svg-icons/icons/text_underline_20_filled.svg"),
      videoClip: import("@fluentui/svg-icons/icons/video_clip_20_filled.svg"),
      viewDesktop: import("@fluentui/svg-icons/icons/view_desktop_20_filled.svg"),
      warning: import("@fluentui/svg-icons/icons/warning_20_filled.svg"),
      word: import("@/assets/images/content_types/word_20_regular.svg"),
    },
  },
};

const props = withDefaults(defineProps<{ name: string; size?: number | string; variant?: string }>(), {
  size: 20,
  variant: "regular",
});

const iconSrc = computedAsync(async () => {
  let src = (await icons[props.variant][props.size][props.name]).default;
  if (!src) {
    console.error(`Icon not found: name=${props.name} size=${props.size} variant=${props.variant}`);
  }
  return src;
});
</script>
