<template>
  <component
    :is="tag"
    class="inline-flex items-center text-label-small group py-1 px-2"
    :class="classes"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <icon-fluent v-if="icon" class="group-hover:hidden" size="16" :name="icon"></icon-fluent>
    <icon-fluent v-if="icon" class="hidden group-hover:block" size="16" :name="icon" variant="filled"></icon-fluent>
    <div class="ml-2 whitespace-nowrap leading-none">{{ title }}</div>
  </component>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ActionButton extends Vue {
  @Prop({ default: "default" })
  type: string;

  @Prop()
  icon: string;

  @Prop()
  title: string;

  @Prop({ default: "button" })
  tag: string;

  get classes() {
    if (this.type === "delete") {
      return ["text-semantic-error", "hover:bg-semantic-error-container"];
    } else if (this.type === "emphasis") {
      return ["text-text-emphasis", "hover:text-text-variant"];
    } else {
      return ["text-text-variant", "hover:text-text-emphasis"];
    }
  }
}
</script>
