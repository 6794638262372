import { createLinkEditClickHandler } from "@/editor/extensions/custom-link/link-edit-click-handler";
import Link from "@tiptap/extension-link";

/* Extends Tiptap Link extension. Adds click handler for editor.  */
const CustomLink = Link.extend({
  addProseMirrorPlugins() {
    if (this.editor.isEditable) {
      return [
        // Return default plugins defined by Link extension
        ...this.parent?.(),

        // Add custom plugin for handling clicks
        createLinkEditClickHandler({ type: this.name, editor: this.editor }),
      ];
    } else {
      // Return default plugins
      return this.parent?.();
    }
  },
});

export default CustomLink;
