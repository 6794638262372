import ContentBlob from "@/model/content-blob";

export const filePath = (blob: ContentBlob, variant = null) => {
  if (!blob) {
    return null;
  }
  if (blob.presigned_url) {
    return blob.presigned_url;
  }
  let path = `/files/${blob.key}`;
  if (variant) {
    path += "/" + variant;
  }
  return path;
};
