<template>
  <img :src="src" />
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class MicroImage extends Vue {
  @Prop()
  src: string;
}
</script>

<style lang="scss" scoped>
img {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  object-fit: cover;
}
</style>
