<template>
  <div class="profile-photo">
    <div
      class="photo-container w-full h-full rounded-sm bg-surface-no-contrast max-w-[128px] max-h-[128px]"
      :class="{ empty: !(tempDataURL || value) }"
    >
      <img v-if="tempDataURL" :src="tempDataURL" /><img v-else-if="value" :src="url" />
      <div v-else class="img-placeholder p-4">
        <icon-fluent name="person" variant="filled"></icon-fluent>
      </div>
    </div>
    <button class="absolute btn-primary btn-icon btn-sm -bottom-1 -right-1">
      <icon-fluent name="edit" variant="filled" size="16"></icon-fluent>
      <input ref="fileInput" class="absolute inset-0 opacity-0" type="file" accept="image/*" @change="cropImage" />
    </button>
  </div>
</template>

<script lang="ts">
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import Attachment from "@/model/attachment";
import ProfilePhotoModal from "@/components/profile-photo/profile-photo-modal.vue";
import UploadService from "@/services/upload-service";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import { Component, Prop, Ref } from "vue-property-decorator";

@Component({})
export default class ProfilePhoto extends Vue {
  @Ref()
  fileInput: HTMLInputElement;

  @Prop()
  value: Attachment;

  @Prop({ default: true })
  uploadOnChange: boolean;

  tempDataURL: string = "";

  get uploadService() {
    return new UploadService();
  }

  get url() {
    return this.value?.url ?? `/files/${this.value.key}`;
  }

  cropImage(e) {
    const file = e.target.files[0];
    if (!file || !file.type.includes("image/")) {
      this.fileInput.value = null;
      this.tempDataURL = "";
      return;
    }
    if (typeof FileReader === "function") {
      const reader = new FileReader();
      reader.onload = async (event) => {
        this.tempDataURL = await showDialog<string>(ProfilePhotoModal, { photo: event.target.result.toString() });

        if (this.uploadOnChange) {
          const attachment = await showDialog(LoadingIndicatorDialog, { action: this.saveProfilePicture() });
          if (attachment) {
            this.$emit("input", attachment);
          }
        }
      };
      reader.onerror = (error) => {
        console.log("ERROR", error);
      };
      reader.readAsDataURL(file);
    } else {
      // FileReader API not supported
    }

    // Clear fileInput value after read or error
    this.fileInput.value = null;
  }

  async saveProfilePicture(): Promise<Attachment> {
    if (this.tempDataURL) {
      let mimeType = this.getMimeTypeFromDataURL(this.tempDataURL);
      const f: File = await this.srcToFile(this.tempDataURL, "profile", mimeType);
      return this.upload(f);
    }
  }

  srcToFile(src, fileName, mimeType): Promise<File> {
    return fetch(src)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], fileName, { type: mimeType });
      });
  }

  getMimeTypeFromDataURL(dataUrl: String): String {
    let base64ContentArray = dataUrl.split(",");
    return base64ContentArray[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
  }

  async upload(file: File): Promise<Attachment> {
    let attachment;
    try {
      const upload = await this.uploadService.uploadFile(file);
      attachment = upload;
    } catch (err) {
      console.error(err);
    }
    this.tempDataURL = "";

    return attachment;
  }
}
</script>

<style lang="scss" scoped>
.profile-photo {
  position: relative;

  .photo-container {
    overflow: hidden;

    &.empty {
      border: 1px dashed var(--ui-color-text-variant);
    }

    img {
      width: 100%;
    }

    .img-placeholder {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        width: auto;
        height: 100%;
      }
    }
  }

  .edit-button {
    position: absolute;
    bottom: -5px;
    right: -5px;
    background-color: var(--ui-color-surface-min-contrast);
    border: 1px solid var(--ui-color-border-primary);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border-color: var(--ui-color-text-primary);

      .icon {
        color: var(--ui-color-text-primary);
      }
    }

    .icon {
      font-size: 10px;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    input[type="file"]::-webkit-file-upload-button {
      /* chromes and blink button */
      cursor: pointer;
    }
  }
}
</style>
