<template>
  <div class="inline-block">
    <ui-button-icon class="hidden sm:flex" :icon="icon" variant="secondary" @click="toggleViewMode"></ui-button-icon>
    <ui-button-icon
      class="flex sm:hidden"
      :icon="icon"
      size="sm"
      variant="secondary"
      @click="toggleViewMode"
    ></ui-button-icon>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ViewModeToggle extends Vue {
  @Prop()
  value: "contents-grid" | "contents-table";

  get icon(): string {
    return this.value === "contents-table" ? "appsList" : "gridKanban";
  }

  toggleViewMode() {
    this.$emit("input", this.value === "contents-table" ? "contents-grid" : "contents-table");
  }
}
</script>

<style lang="scss" scoped>
button {
  height: 40px;

  .filled,
  &:hover {
    color: var(--ui-color-text-emphasis);
  }
}
</style>
