<template>
  <div
    class="media-grid-card border-2 group relative"
    :class="selected ? 'border-border-emphasis' : 'border-transparent'"
    @click="$emit('click', $event)"
    @dblclick="$emit('dblclick', $event)"
  >
    <div class="h-24 bg-gray-300 flex flex-row justify-center items-center relative overflow-hidden">
      <div v-if="mediaFile.uploading" class="shimmer h-full w-full"></div>
      <img v-else-if="isImage" class="max-w-full max-h-full" :src="thumbUrl" />
      <video v-else-if="isVideo" class="max-w-full max-h-full" :src="url" :type="mediaFile.file.content_type"></video>
    </div>
    <div class="flex flex-row items-center mt-xs">
      <div class="mx-sm text-text-primary">
        <icon-fluent class="w-4 h-4" :name="iconName"></icon-fluent>
      </div>
      <div class="flex flex-col overflow-hidden">
        <div class="text-label-small text-text-primary overflow-hidden overflow-ellipsis whitespace-nowrap">
          {{ mediaFile.file_name }}
        </div>
        <div class="text-label-small text-text-variant">{{ dateFilter(mediaFile.created_at) }}</div>
      </div>
    </div>
    <div
      v-if="canDelete"
      class="absolute top-1 right-1 z-10 cursor-pointer text-text-variant hover-hover:hidden group-hover:block hover:text-text-primary"
      @click.stop="$emit('delete', mediaFile)"
    >
      <icon-fluent name="dismiss" variant="filled"></icon-fluent>
    </div>
  </div>
</template>

<script lang="ts">
import { dateFilter } from "@/filters";
import MaybeUploading from "@/model/maybe-uploading";
import MediaFile from "@/model/media-file";
import { filePath } from "@/utils/paths";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class MediaGridCard extends Vue {
  @Prop()
  mediaFile: MaybeUploading<MediaFile>;

  @Prop()
  selected: boolean;

  get isImage() {
    return this.mediaFile.file_type.startsWith("image");
  }

  get isVideo() {
    return this.mediaFile.file_type.startsWith("video");
  }

  get url() {
    return filePath(this.mediaFile.file);
  }

  get thumbUrl() {
    return filePath(this.mediaFile.file, "thumb");
  }

  get iconName() {
    if (this.isImage) {
      return "image";
    } else if (this.isVideo) {
      return "videoClip";
    } else {
      return "document";
    }
  }

  get canDelete() {
    if (this.mediaFile.user_id === this.$currentUser.id) {
      return true;
    } else {
      return false;
    }
  }

  dateFilter(value: string) {
    return dateFilter(value);
  }
}
</script>

<style lang="scss" scoped>
.shimmer {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1s infinite;
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
