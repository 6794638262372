<template>
  <div class="add-content-button">
    <dropdown>
      <template #dropdownButton>
        <button class="btn-primary pl-4 hidden sm:flex">
          <icon-fluent class="inline-block w-4 h-4 text-white align-middle" name="add" variant="filled"></icon-fluent>
          <div class="ml-2">{{ $t("actions.add") }}</div>
        </button>
        <ui-button-icon class="sm:hidden" icon="add"></ui-button-icon>
      </template>
      <div v-for="addAction in addActions" :key="addAction.label" @click.stop>
        <a class="option" @click="addAction.action">
          <icon-fluent
            class="inline-block mr-sm"
            :name="addAction.icon"
            :variant="addAction.iconVariant ? addAction.iconVariant : 'regular'"
          ></icon-fluent>
          <div class="title mr-sm tracking-[.5px]">{{ $t(addAction.label) }}</div>
        </a>
        <hr v-if="addAction.separatorAfter" class="border-surface-variant dark:border-background" />
      </div>
    </dropdown>
    <input ref="imageInput" class="hidden" type="file" multiple accept="image/*" @input="onFileInput('image')" />
    <input ref="videoInput" class="hidden" type="file" multiple accept="video/*" @input="onFileInput('video')" />
    <input ref="fileInput" class="hidden" type="file" multiple @input="onFileInput('file')" />
  </div>
</template>

<script lang="ts">
import ContentAddAction from "@/model/content-add-action";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";
import _ from "lodash";
import { uploadFileToMediaLibrary } from "@/actions";
import { showDialog } from "@/utils/dialogs";
import MediaFile from "@/model/media-file";
import LoadingIndicatorDialog from "../loading-indicator-dialog.vue";

@Component({})
export default class AddContentButton extends Vue {
  @Ref()
  imageInput: HTMLInputElement;

  @Ref()
  videoInput: HTMLInputElement;

  @Ref()
  fileInput: HTMLInputElement;

  get addActions(): ContentAddAction[] {
    return _.compact([
      {
        label: "actions.add_page",
        mobileLabel: "page.title",
        icon: "add",
        mobileIcon: "appGeneric",
        action: () => {
          this.$router.push({ name: "AddPage", params: { pageType: "general" } });
        },
        separatorAfter: true,
      },
      this.$currentUser.isOsgenicUser()
        ? {
            label: "procedures.add_procedure",
            mobileLabel: "procedure.title",
            icon: "add",
            mobileIcon: "appGeneric",
            action: () => {
              this.$router.push({ name: "AddPage", params: { pageType: "procedure" } });
            },
            separatorAfter: true,
          }
        : null,
      {
        label: "contents.photo",
        mobileLabel: "contents.photo",
        icon: "cameraAdd",
        mobileIcon: "image",
        action: () => this.imageInput.click(),
      },
      {
        label: "file_types.video",
        mobileLabel: "file_types.video",
        icon: "videoClip",
        mobileIcon: "videoClip",
        action: () => this.videoInput.click(),
      },
      {
        label: "contents.file_upload",
        mobileLabel: "contents.file_upload",
        icon: "arrowUpload",
        mobileIcon: "arrowUpload",
        action: () => this.fileInput.click(),
      },
      this.$currentUser.isOsgenicUser() || this.$currentUser.isHospitalAdmin()
        ? {
            label: "editor.link",
            mobileLabel: "editor.link",
            icon: "link",
            mobileIcon: "link",
            action: () => {
              this.$router.push({ name: "AddExternalLink" });
            },
          }
        : null,
    ]);
  }

  async onFileInput(type) {
    const files =
      type === "image" ? this.imageInput.files : type === "video" ? this.videoInput.files : this.fileInput.files;
    const action = Promise.all(Array.from(files).map((file) => uploadFileToMediaLibrary(file)));
    const mediaFiles = await showDialog<MediaFile[]>(LoadingIndicatorDialog, { action });
    this.$router.push({
      name: "AddPage",
      params: { pageType: "general", mediaFiles: JSON.stringify(mediaFiles), mediaFilesType: type },
    });
  }
}
</script>
