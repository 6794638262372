<template>
  <div class="flex items-center justify-between mb-sm">
    <div v-if="share.user" class="flex items-center">
      <div class="flex items-center justify-center h-10 w-10 mr-sm">
        <icon-fluent name="person"></icon-fluent>
      </div>
      <div class="text-label-strong">{{ share.user.name }}</div>
    </div>
    <div v-else-if="share.customer_organization" class="flex items-center">
      <div class="flex items-center justify-center h-10 w-10 mr-sm">
        <icon-fluent name="buildingMultiple"></icon-fluent>
      </div>
      <div class="text-label-strong">{{ share.customer_organization.name }}</div>
    </div>
    <div v-else-if="share.email" class="flex items-center">
      <div class="flex items-center justify-center h-10 w-10 mr-sm">
        <icon-fluent name="person"></icon-fluent>
      </div>
      <div class="text-label-strong">{{ share.email }}</div>
    </div>
    <slot name="trailing">
      <dropdown :title="dropdownLabel" :button-style="'clear'">
        <a v-if="share.permission != 'view'" @click="setPermission('view')">{{ $t("share.permission.view") }}</a>
        <a v-if="share.permission != 'edit'" @click="setPermission('edit')">{{ $t("share.permission.edit") }}</a>
        <a v-if="share.id" @click="remove()">{{ $t("share.remove_access") }}</a>
      </dropdown>
    </slot>
  </div>
</template>

<script lang="ts">
import Share from "@/model/share";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ShareDialogRow extends Vue {
  @Prop()
  share: Share;

  get dropdownLabel() {
    if (this.share.permission) {
      return this.$t("share.permission." + this.share.permission);
    } else {
      return this.$t("share.not_shared");
    }
  }

  transferOwnership() {}

  async remove() {
    this.$emit("remove");
  }

  async setPermission(permission: string) {
    this.$emit("set-permission", permission);
  }
}
</script>
