<template>
  <page>
    <page-header :title="title"></page-header>
    <page-content>
      <div>
        <div class="mb-md">
          <div class="flex flex-1">
            <search-input
              v-model="search"
              class="w-64 mr-md sm:grow-0"
              :placeholder="$t('actions.search')"
              @reset="onReset"
            ></search-input>
            <button v-if="$currentUser.canCreateQuiz()" class="btn-primary" @click="addQuiz()">
              {{ $t("actions.add") }}
            </button>
          </div>
        </div>
      </div>
      <scroll-detector v-if="loaded" class="mb-28" @end="loadMoreQuizes">
        <div v-if="!loaded">
          <list-item>
            <loader-big></loader-big>
          </list-item>
        </div>
        <div v-else>
          <list-item v-for="quiz in quizzes" :key="quiz.id" @click="gotoQuiz(quiz)">
            <div class="flex-1">
              <div class="name">
                <span class="text-body-large text-text-primary mr-xs">
                  {{ quiz.name }}
                  <span v-if="quiz.customer_organization"> ({{ quiz.customer_organization.name }})</span>
                </span>
                <icon class="text-text-variant mr-sm" icon="chevron-right"></icon>
                <badge v-if="quiz.status !== 'published'" :label="quizStatus(quiz)" :variant="quiz.status"></badge>
              </div>
            </div>
            <div class="actions" @click.stop="">
              <context-menu v-if="$currentUser.canCreateQuiz()">
                <dropdown-button
                  v-if="canEdit(quiz)"
                  class="option"
                  icon="edit"
                  :title="$t('actions.edit')"
                  @click="editQuiz(quiz)"
                ></dropdown-button>
                <dropdown-button
                  class="option"
                  icon="copy"
                  :title="$t('actions.duplicate')"
                  @click="duplicateQuiz(quiz)"
                >
                </dropdown-button>
                <dropdown-button
                  v-if="showDelete(quiz)"
                  class="option"
                  icon="delete"
                  :title="$t('actions.delete')"
                  @click="deleteQuiz(quiz)"
                >
                </dropdown-button>
                <dropdown-button
                  v-if="showPublish(quiz)"
                  class="option"
                  icon="globe"
                  :title="$t('actions.publish')"
                  @click="publishQuiz(quiz)"
                >
                </dropdown-button>
              </context-menu>
              <quiz-button v-else :id="quiz.id"></quiz-button>
            </div>
          </list-item>
        </div>
      </scroll-detector>
      <loader-big v-if="loading" class="absolute top-full inset-x-0 mt-lg"></loader-big>
    </page-content>
  </page>
</template>

<script lang="ts">
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import Quiz from "@/model/quiz";
import QuizzesService from "@/services/quizzes-service";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";

@Component({})
export default class QuizzesPage extends Vue {
  quizzes: Quiz[] = null;
  loading: boolean = false;

  @Prop({ default: 2 })
  minSearchLength: number;
  search = "";

  get loaded() {
    return !!this.quizzes;
  }

  get title() {
    return this.$t("quizzes.title");
  }

  quizStatus(quiz: Quiz) {
    return this.$t("status." + quiz.status);
  }

  gotoQuiz(quiz: Quiz) {
    if (this.$currentUser.canEditQuiz(quiz)) {
      this.$router.push({ name: "Quiz", params: { id: quiz.id.toString() } });
    }
  }

  addQuiz() {
    this.$router.push({ name: "AddQuiz" });
  }

  editQuiz(quiz: Quiz) {
    this.$router.push({ name: "EditQuiz", params: { id: quiz.id.toString() } });
  }

  duplicateQuiz(quiz: Quiz) {
    this.$router.push({ name: "DuplicateQuiz", params: { id: quiz.id.toString() } });
  }

  canEdit(quiz: Quiz) {
    return this.$currentUser.canEditQuiz(quiz);
  }

  showDelete(quiz: Quiz) {
    return this.$currentUser.canEditQuiz(quiz) && quiz.answers_count === 0;
  }

  showPublish(quiz: Quiz) {
    return quiz.status === "draft" && this.$currentUser.canPublishQuiz(quiz);
  }

  async deleteQuiz(quiz: Quiz) {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.delete_permanently") });
    if (confirmed) {
      const action = new QuizzesService().delete(quiz.id);
      await showDialog(LoadingIndicatorDialog, { action });
      this.quizzes = this.quizzes.filter((each) => each !== quiz);
    }
  }

  async publishQuiz(quiz: Quiz) {
    const confirmed = await showDialog(ConfirmationDialog);
    if (confirmed) {
      const action = new QuizzesService().publish(quiz.id);
      const updated = await showDialog<Quiz>(LoadingIndicatorDialog, { action });
      this.quizzes = this.quizzes.map((each) => (each === quiz ? updated : each));
    }
  }

  async created() {
    return this.fetchQuizzes();
  }

  loadMoreQuizes() {
    if (!this.loading) {
      return this.fetchQuizzes();
    }
  }

  async fetchQuizzes() {
    this.loading = true;
    const params: any = {
      limit: 20,
      offset: this.quizzes?.length || 0,
    };
    if (this.search.length >= this.minSearchLength) {
      params.q = this.search;
    }
    if (this.$currentUser.content_languages.length > 0) {
      params.languages = this.$currentUser.content_languages;
    }
    const newQuizItems = await new QuizzesService().getAll({ params });
    this.quizzes = [...(this.quizzes || []), ...newQuizItems];
    this.loading = false;
  }

  @Watch("search")
  async onSearch() {
    this.quizzes = null;
    await this.fetchQuizzes();
  }

  onReset() {
    this.search = "";
  }
}
</script>
