import { handleError, handleResponse } from "@/api/utils";
import _ from "lodash";

export interface ApiServiceOpts {
  params?: Record<string, string | string[] | number | number[] | boolean> | string;
  action?: string;
  format?: string;
  signal?: AbortSignal;
  authenticationToken?: string;
}

export default abstract class ApiService<T> {
  abstract get baseUrl(): string;

  getAll<X = T>(opts: ApiServiceOpts = {}): Promise<X[]> {
    const url = this.buildUrl(null, opts);
    const headers = this.buildHeaders();
    return fetch(url, {
      headers,
      signal: opts.signal,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  get<X = T>(id: string, opts: ApiServiceOpts = {}): Promise<X> {
    const url = this.buildUrl(id, opts);
    const headers = this.buildHeaders();
    return fetch(url, {
      headers,
      signal: opts.signal,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  create(data, opts: ApiServiceOpts = {}): Promise<T> {
    const url = this.buildUrl(null, opts);
    const headers = this.buildHeaders(opts);

    return fetch(url, {
      method: "post",
      headers,
      body: this.buildBody(data, opts),
      signal: opts.signal,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  update(id, data, opts: ApiServiceOpts = {}): Promise<T> {
    const url = this.buildUrl(id, opts);
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "put",
      headers,
      body: JSON.stringify(data),
      signal: opts.signal,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  delete(id, opts: ApiServiceOpts = {}): Promise<T> {
    const url = this.buildUrl(id, opts);
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "delete",
      headers,
      signal: opts.signal,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  protected buildUrl(id, opts: ApiServiceOpts) {
    const { action, params } = opts;
    let url = this.baseUrl;

    if (id) {
      url = `${url}/${id}`;
    }
    if (action) {
      url = `${url}/${action}`;
    }
    if (params) {
      const urlSearchParams = new URLSearchParams();
      Object.keys(params).forEach((key) => {
        if (_.isArray(params[key])) {
          params[key].forEach((value) => urlSearchParams.append(`${key}[]`, value));
        } else {
          urlSearchParams.append(key, params[key]);
        }
      });
      url = `${url}?${urlSearchParams}`;
    }
    return url;
  }

  protected async handleResponse(response: Response, raw = false): Promise<any> {
    const data = await handleResponse(response);
    if (raw) {
      return data;
    } else if (Array.isArray(data)) {
      return data.map((item) => this.unserialize(item));
    } else {
      return this.unserialize(data);
    }
  }

  protected handleError(error) {
    return handleError(error);
  }

  protected unserialize(data: any): any {
    return data;
  }

  protected buildHeaders(opts: ApiServiceOpts = {}) {
    const headers = { ...this.baseHeaders };
    if (opts.format === "multipart") {
      delete headers["Content-Type"];
    }
    if (opts.authenticationToken) {
      headers["Authorization"] = `Bearer ${opts.authenticationToken}`;
    }

    return headers;
  }

  protected buildBody(data, opts: ApiServiceOpts = {}) {
    if (opts.format === "multipart") {
      const formData = new FormData();

      for (const name in data) {
        if (_.isArray(data[name])) {
          data[name].forEach((item) => {
            formData.append(name + "[]", item);
          });
        } else {
          if (data[name]) {
            formData.append(name, data[name]);
          }
        }
      }

      return formData;
    }
    return JSON.stringify(data);
  }

  protected get baseHeaders() {
    return {
      "Content-Type": "application/json",
    };
  }
}
