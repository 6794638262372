<template>
  <div class="relative flex flex-col sm:flex-row">
    <div class="sm:hidden bg-surface-min-contrast h-12 px-6 flex items-center">
      <inline-svg
        class="text-text-logo w-auto h-4 dark:text-text-primary"
        :src="require('@/assets/images/osgenic-logo.svg')"
      />
      <div class="flex-1"></div>
      <router-link tag="button" class="btn btn-tertiary btn-sm" :to="{ name: 'Login' }">
        {{ $t("actions.login") }}
      </router-link>
      <router-link tag="button" class="btn btn-primary btn-sm" :to="{ name: 'SignupChoosePlan' }">
        {{ $t("actions.signup") }}
      </router-link>
    </div>
    <div class="sm:w-[50%] md:w-[496px] px-4 sm:px-12 sm:pt-16 pb-8 sm:fixed flex flex-col items-center text-center">
      <div class="p-4 hidden sm:block">
        <inline-svg
          class="text-text-logo w-auto h-4 dark:text-text-primary"
          :src="require('@/assets/images/osgenic-logo.svg')"
        />
      </div>
      <div class="mt-6">
        <inline-svg class="text-semantic-warning-on-surface" :src="require('@/assets/images/warning-circle.svg')" />
      </div>
      <div class="mt-2 text-label-medium text-semantic-warning-on-surface sm:whitespace-pre-wrap">
        <div class="hidden sm:block">
          {{ $t("public_page.login_signup_info_text") }}
        </div>
        <div class="info-text-2 sm:hidden" v-html="$t('public_page.login_info_text_2')" />
      </div>
      <div class="mt-8 hidden sm:block">
        <router-link tag="button" class="btn btn-primary" :to="{ name: 'Login' }">
          {{ $t("actions.login") }}
        </router-link>
      </div>
      <div class="mt-4 hidden sm:block">
        <router-link tag="button" class="btn btn-secondary" :to="{ name: 'SignupChoosePlan' }">
          {{ $t("actions.signup") }}
        </router-link>
      </div>
    </div>
    <div class="sm:flex-1 px-4 sm:p-8 sm:pl-[50%] md:pl-[496px]">
      <div class="sm:bg-surface-min-contrast rounded sm:p-4">
        <div class="max-w-[900px]">
          <div class="sm:px-3">
            <div class="text-label-medium text-text-emphasis">{{ $t("actions.preview") }}</div>
            <h2 class="mt-2">{{ contentItem.name }}</h2>
          </div>
          <div
            class="info-text-2 mt-4 mx-3 p-4 border-l-[3px] border-semantic-warning bg-surface-no-contrast text-label-medium text-semantic-warning-on-surface hidden lg:block"
            v-html="$t('public_page.login_info_text_2')"
          ></div>
          <div v-if="hasDocument('steps')" class="py-4 sm:px-4">
            <div class="text-label-large text-text-primary">
              {{ $t("documents.types.steps") }}
            </div>
            <div class="mt-2 text-body-small">
              {{ $t("public_page.tab_info.steps") }}
            </div>
            <div v-if="contentItem.preview_video_id" class="my-4">
              <vimeo-player :video-id="contentItem.preview_video_id" :toc="false" />
              <div v-if="contentItem.video_surgeon" class="mt-2">
                <span class="text-text-variant">{{ $t("attributes.surgeon") }}: </span>
                <span class="text-text-primary">{{ contentItem.video_surgeon }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row gap-4">
            <div class="basis-1/2 bg-surface-low-contrast rounded py-4 px-6 flex flex-col gap-8">
              <div v-if="hasDocument('general')">
                <div class="text-label-large text-text-primary">
                  {{ $t("documents.types.general") }}
                </div>
                <div class="mt-2 text-body-small">
                  {{ $t("public_page.tab_info.general") }}
                </div>
              </div>
              <div v-if="hasDocument('anatomy')">
                <div class="text-label-large text-text-primary">
                  {{ $t("documents.types.anatomy") }}
                </div>
                <div class="mt-2 text-body-small">
                  {{ $t("public_page.tab_info.anatomy") }}
                </div>
              </div>
              <div v-if="hasDocument('external')">
                <div class="text-label-large text-text-primary">
                  {{ $t("documents.types.external") }}
                </div>
                <div class="mt-2 text-body-small">
                  {{ $t("public_page.tab_info.external") }}
                </div>
              </div>
            </div>
            <div v-if="contentItem.invivo_preview_image_url" class="basis-1/2 sm:px-2 py-2">
              <div class="mb-6 relative">
                <ui-card-image :url="contentItem.invivo_preview_image_url" />
                <div
                  class="flex justify-center items-center absolute top-4 right-4 w-6 h-6 rounded-xs bg-interactive-bg-emphasis z-10"
                >
                  <div class="text-label-small text-text-button-primary">3D</div>
                </div>
              </div>
              <div class="px-4">
                <div class="text-label-large text-text-primary">Osgenic Invivo</div>
                <div class="mt-2 text-body-small">
                  {{ $t("public_page.tab_info.invivo") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ContentItem from "@/model/content-item";

const props = defineProps<{ contentItem: ContentItem }>();

const hasDocument = (documentType: string) => {
  return props.contentItem.content.page.documents.some((document) => document.document_type === documentType);
};
</script>

<style lang="scss" scoped>
.info-text-2::v-deep {
  a {
    color: var(--ui-color-semantic-warning-on-surface);
    border-bottom: 1px solid var(--ui-color-semantic-warning-on-surface);
  }
}
</style>
