import Vue from "vue";
import i18n from "@/i18n";
import store from "@/store/store";
import router from "@/router";
import { activeTheme } from "@/theme";

/**
 * Renders dialog component and returns dialog return value as promise.
 * Expects component to emit @ok and @cancel events.
 *
 * @param component
 * @param props
 */
export function showDialog<T>(component, props = {}): Promise<T> {
  return new Promise((resolve, reject) => {
    const vm = new Vue({
      i18n,
      store,
      router,
      provide: {
        theme: activeTheme,
      },
      methods: {
        destroy() {
          vm.$destroy();
          vm.$el.remove();
        },
      },
      render(createElement) {
        return createElement(component, {
          props,
          on: {
            ok: (data) => {
              resolve(data);
              this.destroy();
            },
            cancel: () => {
              resolve(undefined);
              this.destroy();
            },
            close: () => {
              resolve(undefined);
              this.destroy();
            },
            error: (err) => {
              reject(err);
              this.destroy();
            },
          },
        });
      },
    }).$mount();
    document.body.appendChild(vm.$el);
  });
}
