import ContentItemFilters, { buildEmptyFilters } from "@/model/content-item-filters";
import { SET_CONTENTS_VIEW_MODE, SET_CONTENT_FILTERS } from "@/store/mutations";
import { RootState } from "@/store/store";
import { Module } from "vuex";

export type ContentsViewState = {
  filters: ContentItemFilters;
  viewMode: "contents-grid" | "contents-table";
};

export const buildInitialState: () => ContentsViewState = () => ({
  filters: buildEmptyFilters(),
  viewMode: "contents-grid",
});

export const mutations = {
  [SET_CONTENTS_VIEW_MODE]: (state, viewMode) => Object.assign(state, { viewMode }),
  [SET_CONTENT_FILTERS]: (state, filters) => Object.assign(state, { filters }),
};

export const contentsViewModule: Module<ContentsViewState, RootState> = {
  state: buildInitialState,
  mutations,
};

export default contentsViewModule;
