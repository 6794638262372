import { SET_LOADING_LOGBOOK_ENTRIES, SET_LOGBOOK_ENTRY_IDS } from "@/store/mutations";
import { RootState } from "@/store/store";
import { Module } from "vuex";

export type LogbookEntriesState = {
  loading: boolean;
  ids: number[];
};

const logbookEntriesModule: Module<LogbookEntriesState, RootState> = {
  state: () => ({ loading: false, ids: [] }),
  mutations: {
    [SET_LOADING_LOGBOOK_ENTRIES]: (state, loading) => Object.assign(state, { loading }),
    [SET_LOGBOOK_ENTRY_IDS]: (state, ids) => Object.assign(state, { ids }),
  },
  getters: {
    logbookEntries: ({ ids }, getters) => getters.getEntities({ logbookEntries: ids }).logbookEntries,
  },
};

export default logbookEntriesModule;
