import { v4 as uuidv4 } from "uuid";

type MaybeUploading<T> = {
  [P in keyof T]?: T[P];
} & {
  tempId?: string;
  uploading?: boolean;
};

export function makeMaybeUploading<T>(object: T, uploading = true): MaybeUploading<T> {
  return {
    ...object,
    tempId: uuidv4(),
    uploading,
  };
}

export default MaybeUploading;
