<template>
  <modal size="large">
    <h3 class="mb-4">{{ $t("profile.edit_photo") }}</h3>
    <div class="flex flex-row">
      <div class="cropper-wrapper">
        <vue-cropper
          ref="cropper"
          :guides="true"
          :view-mode="2"
          :drag-mode="'none'"
          :auto-crop-area="0.8"
          :background="false"
          :modal="true"
          :highlight="true"
          :movable="false"
          :rotatable="true"
          :scalable="false"
          :zoomable="false"
          :src="photo"
          alt="Source image"
          :aspect-ratio="1"
          @cropend="cropImage"
          @ready="cropImage"
        ></vue-cropper>
      </div>
      <div class="preview"><img v-if="croppedImage" :src="croppedImage" alt="Cropped image" /></div>
    </div>
    <modal-footer>
      <button class="btn-primary" @click="save">{{ $t("actions.save") }}</button>
      <button class="btn-secondary" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
    </modal-footer>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import VueCropper from "vue-cropperjs";

const CANVAS_SIZE = 200 / (3 / 4);

@Component({ components: { VueCropper } })
export default class ProfilePhotoModal extends Vue {
  @Ref()
  cropper: VueCropper;

  @Prop()
  photo: string;

  croppedImage: string = null;

  cropImage() {
    this.croppedImage = this.cropper
      .getCroppedCanvas({
        maxWidth: CANVAS_SIZE,
        maxHeight: CANVAS_SIZE,
      })
      .toDataURL();
  }

  save() {
    this.$emit("ok", this.croppedImage);
  }
}
</script>

<style lang="scss" scoped>
.cropper-wrapper {
  flex: 1;
  margin-right: var(--spacing);
}
.preview {
  width: 204px;
  position: relative;
  img {
    border: 2px solid var(--ui-color-border-emphasis);
    // width: 100%;
  }
}
</style>
