<template>
  <div class="card rounded py-md px-lg bg-surface-min-contrast">
    <div v-if="hasTitle" class="card__title-wrapper">
      <h3 v-if="$slots.title" class="card__title mb-4" @click="$emit('toggle')">
        <slot name="title"></slot>
      </h3>
      <h3 v-if="title" class="card__title mb-4" @click="$emit('toggle')">{{ title }}</h3>
      <div v-if="$slots.titleTrailing" class="card__title-trailing">
        <slot name="titleTrailing"></slot>
      </div>
    </div>
    <collapsible v-if="collapsible" :collapsed="!showContent">
      <div class="card__content" :class="{ 'pt-md': hasTitle }">
        <slot></slot>
      </div>
    </collapsible>
    <div v-else class="card__content" :class="{ 'pt-md': hasTitle }">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Card extends Vue {
  @Prop()
  title: string;

  @Prop({ default: false })
  collapsible: boolean;

  @Prop({
    default: true,
  })
  showContent: boolean;

  get hasTitle() {
    return this.title || this.$slots.title;
  }
}
</script>

<style lang="scss" scoped>
.card {
  &__title-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__title {
    flex-grow: 1;
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }

  &__content {
    padding: 0;
  }
}
</style>
