<template>
  <div v-if="!page" class="bg-surface-high-contrast rounded-md">
    <div class="aspect-video"></div>
    <div class="h-14"></div>
  </div>
  <router-link v-else-if="videoId" :to="`/pages/${page.id}/steps`">
    <ui-card>
      <vimeo-player class="pointer-events-none touch-none !h-auto" :video-id="videoId" :toc="false"></vimeo-player>
      <div class="px-4 py-5">
        <div class="text-label-large text-text-emphasis">Video and steps</div>
        <div class="mt-3 text-body-small">{{ $t("home.steps_description") }}</div>
      </div>
    </ui-card>
  </router-link>
</template>

<script lang="ts">
import ContentDocument from "@/model/content-document";
import Page from "@/model/page";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { findNodeOfType } from "@/editor/utils/nodes";

@Component({})
export default class VideoCard extends Vue {
  @Prop()
  page: Page;

  get stepsDocument(): ContentDocument {
    return this.page?.documents?.find((d) => d.document_type === "steps");
  }

  get vimeoNode() {
    if (this.stepsDocument) {
      return findNodeOfType(this.stepsDocument.content.doc, "vimeo");
    }
    return null;
  }

  get videoId() {
    return this.vimeoNode?.attrs?.id || null;
  }
}
</script>
