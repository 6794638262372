<template>
  <div class="input-icon">
    <slot></slot>
    <div class="icon-wrapper">
      <div class="inline-block" @click.stop="onClick">
        <icon-fluent v-if="iconType === 'fluent-icon'" :name="icon" :variant="variant"></icon-fluent>
        <icon v-else :icon="icon" @click.stop="onClick"></icon>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class InputIcon extends Vue {
  @Prop()
  icon: string;

  @Prop({ default: "regular" })
  variant: string;

  @Prop({ default: "font-awesome" })
  iconType: "font-awesome" | "fluent-icon";

  onClick(evt) {
    this.$emit("click", evt);
  }
}
</script>

<style lang="scss">
.input-icon {
  position: relative;
  .icon-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: $input-padding;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      color: var(--ui-color-text-variant);
    }
  }

  input {
    padding-left: calc(1em + 2 * #{$input-padding});

    &:focus ~ .icon-wrapper {
      .icon {
        color: var(--ui-color-text-primary);
      }
    }
  }
}
</style>
