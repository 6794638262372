<template>
  <div v-if="!page" class="flex flex-col gap-y-2">
    <div class="bg-surface-high-contrast rounded-sm h-3"></div>
    <div class="bg-surface-high-contrast rounded-sm h-3"></div>
  </div>
  <div v-else-if="summaryText" class="text-body-small">{{ summaryText }}</div>
</template>

<script lang="ts">
import { findNode, textContent } from "@/editor/utils/nodes";
import Page from "@/model/page";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

const regex = /^(goal of operation|tavoite):\s*/i;

@Component({})
export default class SummaryText extends Vue {
  @Prop()
  page: Page;

  get generalDocument() {
    return this.page?.documents?.find((d) => d.document_type === "general");
  }

  get goalOfOperationParagraph() {
    return (
      this.generalDocument &&
      findNode(this.generalDocument.content.doc, (node) => node.type === "paragraph" && textContent(node).match(regex))
    );
  }

  get summaryText() {
    if (this.goalOfOperationParagraph) {
      const text = textContent(this.goalOfOperationParagraph);
      return text.replace(regex, "");
    }
    return null;
  }
}
</script>
