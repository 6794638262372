<template>
  <div class="flex flex-col rounded border px-2 sm:px-8 py-4 text-center duration-300" :class="cardClasses">
    <div class="text-body-small uppercase">{{ title }}</div>
    <div
      class="text-text-primary sm:text-white flex flex-col flex-1 my-4 p-4 rounded-sm sm:bg-gradient-to-br from-[var(--ui-color-osgenic-blue-400)] to-[var(--ui-color-osgenic-blue-100)]"
      :class="{ 'text-white': selected && !isChangeScheduled }"
    >
      <div class="text-label-small uppercase">{{ $t("plan.period." + planPrice.period) }}</div>
      <div>
        <periodic-price
          class="text-xl mt-2"
          :plan-price="effectivePlanPrice"
          period="month"
          :discount-percentage="subscription.discount_percentage"
        />
        <span v-if="planPrice.currency == 'USD'"> USD</span>/{{ $t("period.month") }}
      </div>
      <div v-if="planPrice.period == 'year'" class="flex flex-col gap-2 justify-center sm:flex-row text-label-medium">
        <div>
          (<periodic-price
            :plan-price="effectivePlanPrice"
            period="year"
            :discount-percentage="subscription.discount_percentage"
          />
          <span v-if="planPrice.currency == 'USD'"> USD</span>/{{ $t("period.year") }})
        </div>
        <div v-if="discountPercentage" class="text-semantic-warning">
          {{ $t("signup.save_percentage", { discount: discountPercentage }) }}
        </div>
      </div>
      <div class="flex-1"></div>
      <div class="text-body-small mt-4">
        {{ recurringBillingText }}
      </div>
    </div>
    <div class="text-body-small uppercase">{{ nextDateLabel }}</div>
    <div
      class="text-label-strong text-text-primary mt-2"
      :class="{ 'text-white': selected, 'sm:text-text-primary': selected }"
    >
      {{ nextDate }}
    </div>
    <div v-if="!isChangeScheduled" class="mt-8 flex flex-row gap-4 justify-center">
      <checkbox type="radio" :checked="selected" />
      {{ checkboxLabel }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { dateFilter } from "@/filters";
import PlanPrice from "@/model/plan-price";
import Subscription from "@/model/subscription";
import { computed } from "vue";
import { useI18n } from "vue-i18n-bridge";

const props = defineProps<{
  planPrice: PlanPrice;
  discountPercentage?: number;
  subscription: Subscription;
  selectedPlanPriceId: string;
}>();

const { t } = useI18n();

const effectivePlanPrice = computed(() => {
  const price = (isCurrentPlan.value && props.subscription.price) || props.planPrice.price;
  return { ...props.planPrice, price };
});

const isChangeScheduled = computed(() => {
  return !!props.subscription.scheduled_plan_price_id;
});

const selected = computed(() => {
  return props.selectedPlanPriceId === props.planPrice.price_id;
});

const isCurrentPlan = computed(() => {
  return props.planPrice.price_id === props.subscription.plan_price_id;
});

const cardClasses = computed(() => {
  if (isChangeScheduled.value) {
    return selected.value ? ["border-border-variant"] : ["border-semantic-success"];
  }

  return selected.value
    ? [
        "bg-gradient-to-br",
        "from-[var(--ui-color-osgenic-blue-400)]",
        "to-[var(--ui-color-osgenic-blue-100)]",
        "sm:from-surface-mid-contrast",
        "sm:to-surface-mid-contrast",
        "border-border-emphasis",
        "text-white",
        "sm:text-text-primary",
      ]
    : "border-border-variant";
});

const title = computed(() => {
  return isCurrentPlan.value
    ? t("plan.your_active_plan")
    : isChangeScheduled.value
    ? t("plan.your_upcoming_plan")
    : t("plan.your_new_plan");
});

const nextDateLabel = computed(() => {
  if (isChangeScheduled.value) {
    return isCurrentPlan.value ? t("plan.ending_on") : t("plan.starting_from");
  }

  return isCurrentPlan.value ? t("plan.next_billing_date") : t("plan.starting_from");
});

const nextDate = computed(() => {
  return dateFilter(props.subscription.next_billing_date);
});

const recurringBillingText = computed(() => {
  if (props.planPrice.period === "year") {
    return t("signup.choose_your_plan.recurring_billing_yearly");
  } else {
    return t("signup.choose_your_plan.recurring_billing_monthly");
  }
});

const checkboxLabel = computed(() => {
  if (isCurrentPlan.value) {
    return t("plan.keep_plan");
  } else {
    return t("plan.choose_plan");
  }
});
</script>
