<template>
  <logbook-entry-card :entry="entry" :label="label" @edit="edit">
    <section class="logbook-entry-card__section">
      <h4>{{ $t("item.details") }}</h4>
      <div v-if="entry.data.duration" class="duration">
        <strong>{{ $t("attributes.duration") }}: </strong>{{ entry.data.duration }}
      </div>
      <div class="role">
        <strong>{{ $t("attributes.role") }}: </strong>{{ $t("procedure_role." + entry.data.role) }}
      </div>
      <div class="urgency">
        <strong>{{ $t("procedure.urgency") }}: </strong>{{ $t("procedure_urgency." + entry.data.urgency) }}
      </div>
      <div v-if="entry.data.comments" class="comments">
        <strong>{{ $t("attributes.comments") }}: </strong>{{ entry.data.comments }}
      </div>
    </section>
    <section v-if="entry.data.disease_codes.length > 0" class="logbook-entry-card__section">
      <h4>{{ $t("procedure.diagnosis") }}</h4>
      <div v-for="diseaseCode in entry.data.disease_codes" :key="diseaseCode.code" class="disease-code">
        {{ diseaseCode.code }} - {{ diseaseCode.name }}
      </div>
    </section>
    <section v-if="entry.data.procedure_types.length > 0" class="logbook-entry-card__section">
      <h4>{{ $t("procedure.procedures_performed") }}</h4>
      <div v-for="procedureType in entry.data.procedure_types" :key="procedureType.code" class="procedure-type">
        {{ procedureType.code }} - {{ procedureType.name }}
      </div>
    </section>
    <section v-if="entry.attachments.length > 0" class="logbook-entry-card__section">
      <h4>{{ $t("attributes.attachments") }}</h4>
      <div v-for="attachment in entry.attachments" :key="attachment.url" class="attachment">
        <a class="link" :href="attachment.url" target="_blank">{{ attachment.filename }}</a>
      </div>
    </section>
  </logbook-entry-card>
</template>

<script lang="ts">
import ProcedureModal from "@/components/logbook-entry/procedure-modal.vue";
import LogbookEntry from "@/model/logbook-entry";
import ProcedureData from "@/model/procedure-data";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LogbookProcedureCard extends Vue {
  @Prop()
  entry: LogbookEntry<ProcedureData>;

  get label() {
    return this.entry.curriculum_item
      ? this.entry.curriculum_item.name
      : this.entry.data.procedure_types
          .map((procedureType) => `${procedureType.code} - ${procedureType.name}`)
          .join(", ");
  }

  edit() {
    showDialog(ProcedureModal, { logbookEntry: this.entry });
  }
}
</script>
