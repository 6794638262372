<template>
  <div class="vimeo-player bg-surface-min-contrast rounded-sm" :class="{ 'has-toc': hasToc }">
    <div :id="elementId" class="player"></div>
    <div v-if="hasToc" class="toc select-none" :class="{ 'toc-open': tocOpen }">
      <div class="toc-handle" @click="tocOpen = !tocOpen">TOC</div>
      <div class="toc-content bg-surface-min-contrast">
        <ol>
          <li
            v-for="chapter in chapters"
            :key="chapter.index"
            class="toc-chapter"
            :class="{ current: currentChapterIndex == chapter.index }"
          >
            <a @click="gotoChapter(chapter)">{{ chapter.title }}</a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Player from "@vimeo/player";
import AnalyticsMediaEvent, { VideoPlaybackEventType } from "@/model/analytics-media-event";
import SnowplowService from "@/services/snowplow-service";

type Chapter = {
  title: string;
  startTime: number;
  index: number;
};

@Component({})
export default class VimeoPlayer extends Vue {
  @Prop()
  videoId: string;

  @Prop({ default: true })
  toc: boolean;

  elementId: string;

  player: Player = null;

  chapters: Chapter[] = null;

  tocOpen = false;

  currentChapterIndex: number = null;

  mediaEvent: AnalyticsMediaEvent = null;

  snowplowService: SnowplowService = new SnowplowService();

  get hasToc() {
    return this.toc && this.chapters && this.chapters.length > 0;
  }

  async gotoChapter(chapter: Chapter) {
    this.tocOpen = false;
    await this.player.getPaused().then((paused) => paused && this.player.play());
    this.player.setCurrentTime(chapter.startTime);
  }

  sendMediaEvent(eventType: VideoPlaybackEventType, videoId: string, pageUrl: string, userId: string, data: any) {
    this.mediaEvent = {
      video_id: videoId,
      page_url: pageUrl,
      event_type: eventType,
      user_id: userId,
      video_duration: data?.duration,
      video_percent: data?.percent,
      video_seconds: data?.seconds,
    };

    try {
      this.snowplowService.trackVideoEvent(this.mediaEvent);
    } catch (error) {
      this.$rollbar.error(error);
      console.error(`Snowplow error: ${error.message}`);
    }
  }

  created() {
    this.elementId = `vimeo-player-${this.videoId}`;
  }

  mounted() {
    this.$nextTick(() => {
      const url = `https://player.vimeo.com/video/${this.videoId}`;
      this.player = new Player(this.elementId, { url, responsive: true });
      if (this.toc) {
        this.player.on("chapterchange", ({ index }) => {
          this.currentChapterIndex = index;
        });
        this.player.on("play", (data) => {
          this.sendMediaEvent("play", this.videoId, this.$route.path.toString(), this.$currentUser.id.toString(), data);
        });
        this.player.on("pause", (data) => {
          this.sendMediaEvent(
            "pause",
            this.videoId,
            this.$route.path.toString(),
            this.$currentUser.id.toString(),
            data
          );
        });
        this.player.on("seeked", (data) => {
          this.sendMediaEvent(
            "seeked",
            this.videoId,
            this.$route.path.toString(),
            this.$currentUser.id.toString(),
            data
          );
        });
        this.player.on("ended", (data) => {
          this.sendMediaEvent(
            "ended",
            this.videoId,
            this.$route.path.toString(),
            this.$currentUser.id.toString(),
            data
          );
        });

        this.player.getChapters().then((chapters) => (this.chapters = chapters));
      }
    });
  }

  beforeDestroy() {
    this.player && this.player.destroy();
  }
}
</script>

<style lang="scss" scoped>
$toc-color-bg: $color-surface-variant;
$toc-color-variant: $color-surface;
$toc-color-fg: $color-on-surface;
$toc-width: 250px;

.vimeo-player {
  display: inline-block;
  position: relative;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;

  @media (min-width: $screen-sm-min) {
    &.has-toc {
      display: inline-flex;
      width: 100%;
      height: auto;
      aspect-ratio: 700 / 253.13;
    }

    .player {
      aspect-ratio: 16 / 9;
      height: 100%;
    }
  }

  .toc {
    flex: 1;
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    flex-direction: row;
    margin-left: -18px;
    height: 100%;
    transition: all 0.3s ease-in-out;

    @media (min-width: $screen-sm-min) {
      margin-left: 0;
      position: static;
    }

    &.toc-open {
      transform: translateX(-$toc-width);
    }

    .toc-handle {
      writing-mode: vertical-rl;
      background-color: var(--ui-color-surface-mid-contrast);
      color: var(--ui-color-text-primary);
      font-weight: bold;
      margin-top: $spacing-xs;
      height: 40px;
      width: 18px;
      text-align: center;
      border-radius: $border-radius-sm;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      cursor: pointer;

      @media (min-width: $screen-sm-min) {
        display: none;
      }
    }

    .toc-content {
      height: 100%;
      overflow-y: scroll;
      padding: var(--spacing-sm);
      width: $toc-width;

      @media (min-width: $screen-sm-min) {
        width: auto;
      }

      .toc-chapter {
        color: var(--ui-color-text-variant);

        &:not(:last-child) {
          margin-bottom: var(--spacing-sm);
        }

        &.current,
        &:hover {
          color: var(--ui-color-text-primary);
        }

        a {
          color: inherit;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
