<template>
  <i class="icon" :class="[styleClass, iconClass]"></i>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Icon extends Vue {
  @Prop()
  icon: string;

  @Prop({ default: "solid" })
  iconStyle: string;

  get iconClass() {
    return `fa-${this.icon}`;
  }

  get styleClass() {
    switch (this.iconStyle) {
      case "brand":
        return "fab";
      case "regular":
        return "far";
      default:
        return "fas";
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  &.success {
    color: $color-success;
  }
}
</style>
