<template>
  <div
    ref="contentEditable"
    class="content-editable"
    contenteditable="true"
    :placeholder="placeholder"
    @input="onInput"
  ></div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

@Component({})
export default class ContentEditable extends Vue {
  @Ref("contentEditable")
  contentEditable: HTMLDivElement;

  @Prop()
  value: string;

  @Prop()
  placeholder: string;

  mounted() {
    this.contentEditable.innerText = this.value;
  }

  onInput(evt) {
    const value = evt.target.innerText;
    this.$emit("input", value);
  }
}
</script>

<style lang="scss" scoped>
.content-editable {
  &:focus {
    outline: none;
  }

  &[placeholder]:empty:before {
    content: attr(placeholder);
    color: var(--color-outline);
  }
}
</style>
