<template>
  <div v-if="image" class="removable-image" :label="$t('attributes.image')">
    <button class="btn-icon btn-secondary absolute top-2 right-2" @click="onRemoveImage">
      <icon icon="times"></icon>
    </button>
    <img :src="url" />
  </div>
</template>

<script lang="ts">
import Attachment from "@/model/attachment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class RemovableImage extends Vue {
  @Prop()
  image: Attachment;

  get url() {
    return this.image.url ?? `/files/${this.image["key"]}`;
  }

  onRemoveImage() {
    this.$emit("remove", this.image);
  }
}
</script>

<style lang="scss" scoped>
.removable-image {
  display: inline-block;
  position: relative;
  max-width: 100%;
  img {
    max-width: 100%;
  }
}
</style>
