<template>
  <div class="flex justify-center">
    <div class="flex items-center gap-4 bg-[#3C4964] h-8 px-4 rounded">
      <span class="text-label-large text-text-primary uppercase cursor-pointer" @click="select('month')">{{
        $t("plan.period.month")
      }}</span>
      <div class="rounded bg-blue-600 w-9 h-4 relative cursor-pointer" @click="toggle()">
        <div
          class="absolute -top-[2px] rounded h-5 w-5 bg-white duration-300"
          :class="period == 'month' ? 'left-0' : 'left-4'"
        ></div>
      </div>
      <span class="text-label-large text-text-primary uppercase cursor-pointer" @click="select('year')">{{
        $t("plan.period.year")
      }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  period: string;
}>();

const emit = defineEmits(["select"]);

const select = (period: string) => {
  emit("select", period);
};

const toggle = () => {
  emit("select", props.period === "month" ? "year" : "month");
};
</script>
