<template>
  <component
    :is="linkComponent"
    class="content-card flex flex-col relative rounded-sm bg-surface-min-contrast overflow-hidden group duration-300 sm:rounded-lg group/main hover:shadow-md hover:bg-surface-low-contrast"
    v-bind="linkAttributes"
  >
    <div class="bg-surface-high-contrast">
      <div v-lazy:background-image="previewImageUrl" class="header opacity-0 h-20 sm:h-40"></div>
    </div>
    <div class="flex flex-col p-2 sm:p-5 sm:pt-4 h-[92px] sm:h-44">
      <div class="text-body-small text-text-emphasis mb-1">{{ authorName }}</div>
      <div class="text-text-primary text-body-small line-clamp-2 sm:text-body-large">{{ contentItem.name }}</div>
      <div class="flex-1"></div>
      <div class="flex-wrap gap-2 max-h-14 overflow-hidden hidden sm:flex">
        <badge
          v-for="specialty in contentItem.specialities"
          :key="specialty.id"
          :label="specialty.name"
          variant="specialties"
        ></badge>
        <badge
          v-if="contentItem.content_type == 'ExternalLink'"
          :label="$t('external_link.title')"
          variant="external-link"
        ></badge>
      </div>
      <div class="text-body-small text-text-variant line-clamp-1 sm:hidden">{{ specialityLabels }}</div>
    </div>
    <badge
      v-if="contentItem.item_state && contentItem.item_state !== 'published'"
      class="absolute left-4 top-4"
      :label="$t('status.' + contentItem.item_state)"
      :variant="contentItem.item_state"
    ></badge>
    <div class="flex gap-2 absolute top-2 right-2">
      <favorite-toggle
        class="sm:top-3 sm:right-3 hover-hover:hidden group-hover:flex"
        :toggled="contentItem.favorited_by_current_user"
        @click="$emit('favoriteToggled', $event)"
      ></favorite-toggle>
      <router-link
        v-if="contentItem.content_type == 'ExternalLink' && canEditExternalLink"
        id="edit"
        class="bg-surface-mid-contrast w-8 h-8 rounded flex justify-center items-center p-0 hover-hover:hidden group-hover:flex"
        :to="{ name: 'EditExternalLink', params: { id: contentItem.content_id.toString() } }"
      >
        <div class="group/edit">
          <icon-fluent
            class="flex-shrink-0 text-text-emphasis hidden"
            name="edit"
            size="16"
            variant="filled"
          ></icon-fluent>
          <icon-fluent class="flex-shrink-0 text-text-emphasis" name="edit" size="16" variant="regular"></icon-fluent>
        </div>
      </router-link>
    </div>
  </component>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ContentItem, { getSpecialityLabels } from "@/model/content-item";
import { getActiveThemePreviewPlaceholder } from "@/theme";
import { routeForContentItem } from "@/utils/routes";
import { filePath } from "@/utils/paths";

@Component({})
export default class ContentCard extends Vue {
  @Prop()
  contentItem: ContentItem;

  get authorName() {
    if (this.contentItem.user) {
      return this.contentItem.user.name;
    } else if (this.contentItem.customer_organization) {
      return this.contentItem.customer_organization.name;
    } else {
      return "Osgenic";
    }
  }

  get canEditExternalLink() {
    return this.contentItem.permissions.edit;
  }

  get previewImageUrl() {
    return filePath(this.contentItem.preview_image, "small") || this.previewPlaceholder;
  }

  get previewPlaceholder() {
    return getActiveThemePreviewPlaceholder();
  }

  get specialityLabels() {
    return getSpecialityLabels(this.contentItem);
  }

  get route() {
    if (this.contentItem.id) {
      return routeForContentItem(this.contentItem);
    } else {
      return "/";
    }
  }

  get linkComponent() {
    if (this.contentItem.content_type == "ExternalLink") {
      return "a";
    } else {
      return "router-link";
    }
  }

  get linkAttributes() {
    if (this.contentItem.content_type == "ExternalLink") {
      return {
        href: `/external_links/${this.contentItem.content_id}`,
        target: "_blank",
        rel: "noopener noreferrer",
      };
    } else {
      return {
        to: this.route,
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  background-size: cover;
  background-position: 50% 50%;

  transition: opacity 0.3s ease;

  &[lazy="loaded"] {
    opacity: 1;
  }
}

#edit {
  &:hover {
    svg.filled {
      display: block;
    }

    svg.regular {
      display: none;
    }
  }
}
</style>
