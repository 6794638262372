<template>
  <div class="progress-overlay" :class="{ processing }">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ProgressOverlay extends Vue {
  @Prop({ default: false })
  processing: boolean;
}
</script>

<style lang="scss" scoped>
.progress-overlay.processing {
  opacity: 0.5;
}
</style>
