<template>
  <sign-up-view
    v-if="!isLoading"
    :step="1"
    :back="false"
    :title="$t('signup.choose_your_plan.title')"
    :subtitle="$t('signup.choose_your_plan.subtitle', { days: trialPeriodDays })"
    v-on="$listeners"
  >
    <plan-period-toggle :period="store.period" @select="store.period = $event" />
    <div class="w-full sm:max-w-xs mt-4">
      <plan-card
        v-if="store.period == 'month'"
        :plan-price="monthlyPlan"
        :discount-percentage="discountPercentage"
        :trial-period-days="trialPeriodDays"
        @select="onSelect"
      />
      <plan-card
        v-else
        :plan-price="yearlyPlan"
        :discount-percentage="discountPercentage"
        :yearly-discount-percentage="yearlyPlanDiscount"
        :trial-period-days="trialPeriodDays"
        @select="onSelect"
      />
    </div>
    <div
      class="w-full max-w-xl flex flex-col gap-2 bg-surface-min-contrast sm:bg-transparent rounded-sm mt-4 p-4 sm:px-8"
    >
      <info-text :text="$t('signup.choose_your_plan.unlimited_access_to_content')" />
      <info-text :text="$t('signup.choose_your_plan.content_updates_regularly')" />
      <info-text :text="$t('signup.choose_your_plan.invivo_anatomy')" />
      <info-text :text="$t('signup.choose_your_plan.upload_content_and_take_notes')" />
    </div>
    <div class="mt-8 label-medium">{{ $t("signup.choose_your_plan.eligible_for_tax_deductions") }}</div>
  </sign-up-view>
</template>

<script setup lang="ts">
import SignUpView from "./sign-up-view.vue";
import PlanCard from "./plan-card.vue";
import PlanPrice, { countDiscountPercentage } from "@/model/plan-price";
import { useSignUpStore } from "./signup-store";
import { useArrayFind } from "@vueuse/core";
import { computed } from "vue";
import { useRouter } from "@/composables/router";
import { usePlanPrices } from "@/api/plan-prices";
import { useSignUpCode } from "@/api/sign-up-codes";
import { storeToRefs } from "pinia";
import PlanPeriodToggle from "./plan-period-toggle.vue";

const router = useRouter();

const store = useSignUpStore();

const storeRefs = storeToRefs(store);

const { data: plans, isLoading: isLoadingPlanPrices } = usePlanPrices();

const { data: signUpCode, isLoading: isLoadingSignUpCode } = useSignUpCode(storeRefs.sign_up_code);

const isLoading = computed(() => isLoadingPlanPrices.value || isLoadingSignUpCode.value);

const plan = computed(() => plans.value?.find(({ period }) => period === store.period));

const discountPercentage = computed(() => signUpCode.value?.discount_percentage);

const monthlyPlan = useArrayFind(plans, (p) => p.period === "month");

const yearlyPlan = useArrayFind(plans, (p) => p.period === "year");

const yearlyPlanDiscount = computed(() => countDiscountPercentage(yearlyPlan.value, monthlyPlan.value));

const trialPeriodDays = computed(() => signUpCode.value?.trial_period_days || plan.value?.trial_period_days || 14);

const onSelect = (plan: PlanPrice) => {
  store.period = plan.period;
  router.push({ name: "SignupCreateAccount" });
};
</script>
