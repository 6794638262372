<template>
  <modal :size="cancelled ? 'small' : 'medium'">
    <div v-if="cancelled" class="sm:mx-4 flex flex-col items-center">
      <icon-fluent name="checkmark" class="text-semantic-success h-8 w-auto my-2" size="20" variant="filled" />
      <h3>{{ $t("plan.membership_cancelled") }}</h3>
      <div class="text-text-primary mt-6 text-left">{{ $t("plan.membership_cancelled_subtitle") }}</div>
      <button class="btn-secondary mt-8" @click="close">{{ $t("actions.close") }}</button>
    </div>
    <div v-else class="sm:mx-4">
      <div class="sm:mx-4">
        <h3>{{ $t("plan.cancel_membership?") }}</h3>
        <div class="label-medium text-text-primary">{{ $t("plan.cancel_membership_subtitle") }}</div>
      </div>
      <div class="mt-8 flex flex-col p-8 rounded border border-border-emphasis text-center">
        <div class="text-label-small text-text-variant uppercase">{{ $t("plan.your_plan") }}</div>
        <div class="mt-2 text-label-small text-text-primary">
          Osgenic {{ $t("plan.period." + subscription.period) }}
        </div>
        <div class="mt-4 text-text-primary">
          {{
            subscription.status == "in_trial"
              ? $t("plan.cancellation_effective_on_trial_end")
              : $t("plan.cancellation_effective_on_end_of_period")
          }}
        </div>
        <div class="mt-4 text-label-strong text-text-primary">{{ dateFilter(subscription.end_date) }}</div>
        <div class="mt-4 text-text-primary">{{ $t("plan.cancellation_no_login_info") }}</div>
      </div>
      <div class="mt-8 text-text-primary">{{ $t("plan.cancellation_come_back_info") }}</div>
      <div class="mt-12 flex justify-end gap-4">
        <button class="btn-secondary" @click="close">{{ $t("actions.close") }}</button>
        <button class="btn-primary" @click="finishCancellation">{{ $t("plan.finish_cancellation") }}</button>
      </div>
    </div>
  </modal>
</template>

<script setup lang="ts">
import { loadProfile } from "@/actions";
import { useActiveSubscription } from "@/composables/session";
import { dateFilter } from "@/filters";
import SubscriptionsService from "@/services/subscriptions-service";
import { showDialog } from "@/utils/dialogs";
import { useI18n } from "vue-i18n-bridge";
import LoadingIndicatorView from "@/pages/loading-indicator-view.vue";
import { ref } from "vue";

const { t } = useI18n();

const emit = defineEmits(["ok"]);

const subscription = useActiveSubscription();

const cancelled = ref(false);

const close = () => emit("ok");

const cancelMembership = async () => {
  await new SubscriptionsService().cancelSubscription(subscription.value);
  await loadProfile();
};

const finishCancellation = async () => {
  const title = t("plan.updating_subscription");
  const action = cancelMembership();
  await showDialog(LoadingIndicatorView, { title, action });
  cancelled.value = true;
};
</script>
