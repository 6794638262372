<template>
  <div
    class="flex-1 bg-surface-min-contrast rounded-sm py-2 px-4 text-text-variant text-label-medium sm:text-label-large border-border-emphasis border-solid"
    :class="{ border: hasDiscount }"
  >
    <div class="flex justify-between">
      <div>
        Osgenic
        <span class="uppercase">{{ $t("plan.period." + plan.period) }}</span>
      </div>
      <div class="flex gap-2">
        <div v-if="hasDiscount" class="text-text-disabled line-through">
          <periodic-price :plan-price="plan" :period="plan.period" /> USD
        </div>
        <div>
          <periodic-price :plan-price="plan" :period="plan.period" :discount-percentage="discountPercentage" /> USD/{{
            plan.period
          }}
        </div>
      </div>
    </div>
    <div v-if="hasDiscount" class="mt-2 text-right text-semantic-info">{{ discountStr }}</div>
  </div>
</template>

<script setup lang="ts">
import PlanPrice from "@/model/plan-price";
import SignUpCode from "@/model/sign-up-code";
import { computed } from "vue";
import { useI18n } from "vue-i18n-bridge";

const props = defineProps<{ plan: PlanPrice; signUpCode?: SignUpCode }>();

const { t } = useI18n();

const discountPercentage = computed(() => props.signUpCode?.discount_percentage || 0);

const hasDiscount = computed(() => discountPercentage.value > 0);

const discountStr = computed(() => {
  return t("signup.discount_with_code", {
    discount: discountPercentage.value,
    code: props.signUpCode.code.toUpperCase(),
  });
});
</script>
