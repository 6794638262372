import PageType from "@/model/page-type";
import ContentItem from "@/model/content-item";
import ContentDocument from "@/model/content-document";

interface Page {
  id?: number;
  page_type: PageType;
  name: string;
  index?: number;
  content_item: ContentItem;
  has_draft_content?: boolean;
  documents?: ContentDocument[];
  available_document_types?: string[];
  video_id?: string;
  lock_version?: number;
  permissions?: Record<string, boolean>;
}

const DOCUMENT_PRESETS = Object.freeze({
  procedure: [
    {
      order: 0,
      document_type: "steps",
      content: {
        version: 3,
      },
    },
    {
      order: 1,
      document_type: "general",
      content: {
        version: 3,
      },
    },
    {
      order: 2,
      document_type: "anatomy",
      content: {
        version: 3,
      },
    },
    {
      order: 3,
      document_type: "training",
      content: {
        version: 3,
      },
    },
    {
      order: 4,
      document_type: "external",
      content: {
        version: 3,
      },
    },
    {
      order: 5,
      document_type: "additional",
      content: {
        version: 3,
      },
    },
  ],
  basic_skill: [
    {
      order: 0,
      document_type: "content",
      content: {
        version: 3,
      },
    },
  ],
});

export { DOCUMENT_PRESETS };

export default Page;
