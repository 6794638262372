<template>
  <div class="dropdown-select-item" :class="{ open: childOptionsOpen }" @click.stop="handleClick(option)">
    <a
      class="option select-none group"
      :class="[option.noIndent ? '!px-0' : '!px-3.5', option.separator ? 'border-b' : 'border-b-0']"
    >
      <icon-fluent
        v-if="multiselect && !option.noCheckbox"
        class="checkbox shrink-0 mr-sm"
        :name="
          isSelected ? 'checkboxChecked' : isPartiallySelected(option) ? 'checkboxIndeterminate' : 'checkboxUnchecked'
        "
        :variant="isSelected || isPartiallySelected(option) ? 'filled' : 'regular'"
      ></icon-fluent>
      <form v-if="editing" class="grow flex" @submit.prevent="onSubmit">
        <input v-model="editableName" v-focus class="grow border-0 text-body-medium" size="2" @blur="onBlur" />
        <icon-fluent class="w-4 ml-xs" name="globe" @click.native.stop="onTranslateClick"></icon-fluent>
        <icon-fluent
          v-if="!hasChildren"
          class="w-4 ml-xs"
          name="delete"
          @click.native.stop="onDeleteClick"
        ></icon-fluent>
      </form>
      <span
        v-if="!editing"
        class="grow text-label-medium"
        :class="isSelected && option.noCheckbox && 'text-text-emphasis'"
      >
        {{ option.label }}
      </span>
      <icon-fluent
        v-if="editable && !editing"
        class="w-4 mr-xs sm:hidden group-hover:block"
        name="edit"
        @click.native.stop="startEditing"
      ></icon-fluent>
      <span
        v-if="!editing && hasChildren && !option.forceOpen"
        class="hover:text-text-emphasis"
        @click.stop="onCaretClick"
      >
        <icon-fluent
          class="caret shrink-0 w-4"
          :name="childOptionsOpen ? 'caretUp' : 'caretDown'"
          variant="filled"
        ></icon-fluent>
      </span>
    </a>
    <div v-if="option.forceOpen || childOptionsOpen" ref="child_options" class="select-options pl-3.5">
      <dropdown-select-item
        v-for="child in option.children"
        :key="child.value"
        :option="child"
        :editable="editable"
        :on-select="onSelect"
        :is-selected-check="isSelectedCheck"
        :is-partially-selected="isPartiallySelected"
        :multiselect="multiselect"
        @update-option="$emit('update-option', $event)"
        @delete="$emit('delete', child)"
      ></dropdown-select-item>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import DropdownSelectOption from "@/model/dropdown-select-option";
import { getLocale } from "@/i18n";
import { showDialog } from "@/utils/dialogs";
import TranslationsModal from "@/components/form/translations-modal.vue";
import _ from "lodash";

@Component({})
export default class DropdownSelectItem extends Vue {
  @Ref("child_options")
  childOptions;

  @Prop()
  option: DropdownSelectOption;

  @Prop()
  multiselect: boolean;

  @Prop()
  isSelectedCheck: (option: DropdownSelectOption) => boolean;

  @Prop({ default: () => false })
  isPartiallySelected: (option: DropdownSelectOption) => boolean;

  @Prop()
  onSelect: (option: DropdownSelectOption) => void;

  childOptionsOpen: boolean = false;

  editing = false;

  editableName = "";

  get hasChildren() {
    return this.option.children && this.option.children.length > 0;
  }

  get editable() {
    return this.option.editable;
  }

  get isSelected() {
    return this.isSelectedCheck(this.option);
  }

  onCaretClick() {
    this.childOptionsOpen = !this.childOptionsOpen;
  }

  onSubmit() {
    this.editing = false;
    const label = this.editableName;
    const label_translations = this.option.label_translations;
    if (label_translations) {
      label_translations[getLocale()] = label;
    }
    const newOption: DropdownSelectOption = { ...this.option, label, label_translations };
    this.$emit("update-option", newOption);
  }

  onBlur() {
    // Wait a bit so that possible translaste/delete clicks are registered
    setTimeout(() => (this.editing = false), 200);
  }

  async onTranslateClick() {
    // Open translate modal
    const label_translations: any = await showDialog(TranslationsModal, {
      title: this.$t("attributes.name"),
      value: this.option.label_translations,
    });

    if (label_translations) {
      // Update option
      const label = label_translations[getLocale()] || _.values(label_translations)[0] || this.option.label;
      const newOption: DropdownSelectOption = { ...this.option, label, label_translations };
      this.$emit("update-option", newOption);
    }
  }

  onDeleteClick() {
    this.$emit("delete", this.option);
  }

  handleClick(option) {
    if (!this.editing) {
      this.onSelect(option);
    }
  }

  startEditing() {
    if (this.editable) {
      this.editableName = this.option.label as string;
      this.editing = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-select-item {
  a.option {
    @apply leading-4;
    color: var(--ui-color-text-primary);
    height: 40px;
  }

  .checkbox {
    &.filled {
      color: var(--ui-color-text-emphasis);
    }
  }
}
</style>
