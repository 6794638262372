<template>
  <div :class="disabled && ['opacity-50', 'pointer-events-none']">
    <div id="paypal-button"></div>
  </div>
</template>

<script setup lang="ts">
import { useChargebee } from "@/composables/chargebee";
import PaymentIntentsService from "@/services/payment-intents-service";
import { onMounted } from "vue";

defineProps<{
  disabled?: boolean;
}>();

const emit = defineEmits(["success", "error"]);

const chargebee = useChargebee();

const createPaymentIntent = () => {
  return new PaymentIntentsService().create({
    amount: 0,
    currency_code: "USD",
    gateway: "paypal",
    payment_method_type: "paypal_express_checkout",
  });
};

const initialize = async () => {
  try {
    const paypalHandler = await chargebee.load("paypal");
    const paymentIntent = await createPaymentIntent();
    paypalHandler.setPaymentIntent(paymentIntent);
    await paypalHandler.mountPaymentButton("#paypal-button", {
      style: {
        shape: "pill",
        color: "gold",
        layout: "horizontal",
        label: "paypal",
      },
    });
    await paypalHandler.handlePayment();
    emit("success", paymentIntent);
  } catch (error) {
    emit("error", error);
  }
};

onMounted(async () => initialize());
</script>
