import { UnauthorizedError } from "@/api/api-error";
import Upload from "@/model/upload";
import { DirectUpload } from "@rails/activestorage";
import isString from "lodash/isString";

export default class UploadService {
  async uploadFile(file: File): Promise<Upload> {
    return new Promise((resolve, reject) => {
      const upload = new DirectUpload(file, "/direct_uploads");
      upload.create((error: any, upload: Upload | undefined) => {
        if (error) {
          if (isString(error) && (error as string).indexOf("401") >= 0) {
            reject(new UnauthorizedError("Unauthorized."));
          } else {
            reject(error);
          }
        } else {
          resolve(upload);
        }
      });
    });
  }
}
