<template>
  <div
    ref="scroller"
    class="grid grid-flow-col gap-x-4 p-4 overflow-x-auto overflow-y-hidden -mx-4"
    @scroll="updateScrollState"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";

export interface ScrollState {
  left: boolean;
  right: boolean;
}

@Component({})
export default class ContentItemsScroller extends Vue {
  @Ref("scroller")
  scroller: HTMLElement;

  resizeObserver: ResizeObserver = null;

  mounted() {
    this.resizeObserver = new ResizeObserver(() => {
      this.updateScrollState();
    });
    this.resizeObserver.observe(this.scroller);
  }

  destroyed() {
    this.resizeObserver?.disconnect();
  }

  updateScrollState() {
    const left = this.scroller.scrollLeft > 0;
    const right = Math.ceil(this.scroller.scrollLeft) + this.scroller.clientWidth < this.scroller.scrollWidth;

    this.$emit("scroll", { left, right });
  }

  scrollLeft() {
    this.scroller.scrollBy({
      left: -this.scroller.clientWidth,
      behavior: "smooth",
    });
  }

  scrollRight() {
    this.scroller.scrollBy({
      left: this.scroller.clientWidth,
      behavior: "smooth",
    });
  }
}
</script>

<style lang="scss" scoped>
.grid {
  // Hide scrollbar
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  grid-auto-columns: 40%;

  @media (min-width: 576px) {
    grid-auto-columns: calc(50% - 0.8rem);
  }

  @media (min-width: 1400px) {
    grid-auto-columns: calc(33.33% - 1rem);
  }
}
</style>
