<template>
  <div v-if="editor" class="editor-menu flex flex-wrap items-center w-full bg-surface-mid-contrast rounded px-sm py-xs">
    <editor-menu-button icon="image" label-key="file_types.image" @click="embedImages()"></editor-menu-button>
    <editor-menu-button icon="videoClip" label-key="file_types.video" @click="embedVideos()"></editor-menu-button>
    <editor-menu-button v-if="$currentUser.isOsgenicUser()" label="Vimeo" :icon-style="'brand'" @click="embedVimeo()">
      <template #icon><i class="fab fa-vimeo-v leading-4"></i></template>
    </editor-menu-button>
    <editor-menu-button label="YouTube" :icon-style="'brand'" @click="embedYouTube()">
      <template #icon><i class="fab fa-youtube leading-4"></i></template>
    </editor-menu-button>
    <editor-menu-button icon="attach" label-key="editor.attachment" @click="embedFiles()"></editor-menu-button>
    <editor-menu-button
      icon="link"
      label-key="editor.link"
      :active="isActive('link')"
      @click="toggleLink()"
    ></editor-menu-button>
    <div class="flex-1"></div>
    <editor-menu-button
      v-if="$currentUser.isOsgenicUser()"
      icon="headsetVR"
      label="VR"
      @click="addLauncherLink()"
    ></editor-menu-button>
    <editor-menu-button
      v-if="$currentUser.isOsgenicUser()"
      icon="eye"
      label="3D"
      @click="addViewerLink()"
    ></editor-menu-button>
    <editor-menu-button
      v-if="!ownContent && $currentUser.isEditorUser()"
      icon="question"
      label-key="quiz.title"
      @click="addQuizLink()"
    ></editor-menu-button>
    <div class="flex-1"></div>
    <editor-menu-button
      v-if="$currentUser.isOsgenicUser()"
      icon="textNumberList"
      label-key="editor.step"
      :active="isActive('step')"
      @click="insertStep()"
    ></editor-menu-button>
    <editor-text-style-dropdown :editor="editor"></editor-text-style-dropdown>
    <editor-menu-button
      icon="textBold"
      label-key="editor.bold"
      :active="isActive('bold')"
      @click="toggleMark('bold')"
    ></editor-menu-button>
    <editor-menu-button
      icon="textItalic"
      label-key="editor.italic"
      :active="isActive('italic')"
      @click="toggleMark('italic')"
    ></editor-menu-button>
    <editor-menu-button
      icon="textNumberList"
      label-key="editor.numbering"
      @click="toggleOrderedList()"
    ></editor-menu-button>
    <editor-menu-button
      icon="textBulletList"
      label-key="editor.bulletpoint"
      @click="toggleBulletList()"
    ></editor-menu-button>
    <editor-menu-button
      icon="textIndentIncrease"
      label-key="editor.increase"
      @click="increaseIndent()"
    ></editor-menu-button>
    <editor-menu-button
      icon="textIndentDecrease"
      label-key="editor.decrease"
      @click="decreaseIndent()"
    ></editor-menu-button>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { showDialog } from "@/utils/dialogs";
import { Prop } from "vue-property-decorator";
import Organization from "@/model/organization";
import MediaFile from "@/model/media-file";
import MediaLibraryModal from "@/components/media-library/media-library-modal.vue";
import { Editor } from "@tiptap/vue-2";
import EmbedVimeoModal from "@/editor/extensions/vimeo/embed-vimeo-modal.vue";
import LauncherLinkModal from "@/editor/extensions/launcher-link/launcher-link-modal.vue";
import LauncherLink from "@/editor/extensions/launcher-link/launcher-link";
import ViewerLinkModal from "@/editor/extensions/viewer-link/viewer-link-modal.vue";
import QuizLinkModal from "@/editor/extensions/quiz-link/quiz-link-modal.vue";
import EditorMenuButton from "@/editor/components/editor-menu-button.vue";
import EditorTextStyleDropdown from "@/editor/components/editor-text-style-dropdown.vue";
import PromptDialog from "@/components/prompt-dialog.vue";
import { sanitizeUrl } from "@/utils/urls";
import ViewerLink from "@/editor/extensions/viewer-link/viewer-link";
import QuizLink from "@/editor/extensions/quiz-link/quiz-link";
import FileModal from "@/editor/extensions/file/file-modal.vue";
import FileAttrs from "@/editor/extensions/file/file-attrs";
import { VimeoNodeAttributes } from "../extensions/vimeo/vimeo-node";
import EmbedYouTubeModal from "@/editor/extensions/youtube/embed-youtube-modal.vue";
import { YouTubeNodeAttributes } from "../extensions/youtube/youtube-node";

@Component({
  components: {
    EditorMenuButton,
    EditorTextStyleDropdown,
  },
})
export default class TipTapEditorMenu extends Vue {
  @Prop()
  customerOrganization: Organization;

  @Prop()
  ownContent: boolean;

  @Prop()
  editor: Editor;

  active = {};

  isActive(type) {
    return this.editor.isActive(type);
  }

  async embedImages() {
    const mediaFiles = await this.selectMediaToAdd("image");
    for (const mediaFile of mediaFiles) {
      this.addImage(mediaFile);
    }
  }

  addImage(mediaFile: MediaFile) {
    this.editor.chain().addImage(mediaFile).focus().run();
    this.$emit("imageAdded", mediaFile);
  }

  async embedVideos() {
    const mediaFiles = await this.selectMediaToAdd("video");
    for (const mediaFile of mediaFiles) {
      this.addVideo(mediaFile);
    }
  }

  addVideo(mediaFile: MediaFile) {
    this.editor.chain().addVideo(mediaFile).focus().run();
  }

  async embedFiles() {
    const mediaFiles = await this.selectMediaToAdd("file");
    for (const mediaFile of mediaFiles) {
      await this.addFile(mediaFile);
    }
  }

  async addFile(mediaFile: MediaFile) {
    const { key, filename, content_type, metadata } = mediaFile.file;
    const attrs: FileAttrs = { title: mediaFile.file_name, blob: { key, filename, content_type, metadata } };
    const fileAttrs = await showDialog<FileAttrs>(FileModal, { attrs });
    if (fileAttrs) {
      this.editor.chain().addFile(fileAttrs).focus().run();
    }
  }

  async embedVimeo() {
    const attrs = await showDialog<VimeoNodeAttributes>(EmbedVimeoModal);
    if (attrs) {
      this.editor.chain().addVimeo(attrs).focus().run();
    }
  }

  async embedYouTube() {
    const attrs = await showDialog<YouTubeNodeAttributes>(EmbedYouTubeModal);
    if (attrs) {
      this.editor.chain().addYouTube(attrs).focus().run();
    }
  }

  toggleLink() {
    const isActiveLink = this.editor.isActive("link");
    if (isActiveLink) {
      this.unsetLink();
    } else {
      return this.setLink();
    }
  }

  unsetLink() {
    const chain: any = this.editor.chain();
    chain.extendMarkRange("link").unsetLink().focus().run();
  }

  async setLink() {
    const href = await showDialog(PromptDialog, { label: this.$t("editor.link") }).then(sanitizeUrl);
    if (href) {
      const chain: any = this.editor.chain();
      chain.setLink({ href }).focus().run();
    }
  }

  async addLauncherLink() {
    const launcherLink = await showDialog<LauncherLink>(LauncherLinkModal);
    if (launcherLink) {
      this.editor.chain().addLauncherLink(launcherLink).focus().run();
    }
  }

  async addViewerLink() {
    const viewerLink = await showDialog<ViewerLink>(ViewerLinkModal);
    if (viewerLink) {
      this.editor.chain().addViewerLink(viewerLink).focus().run();
    }
  }

  async addQuizLink() {
    const quizLink = await showDialog<QuizLink>(QuizLinkModal, { customerOrganization: this.customerOrganization });
    if (quizLink) {
      this.editor.chain().addQuizLink(quizLink).focus().run();
    }
  }

  toggleMark(name) {
    this.editor.chain().toggleMark(name).focus().run();
  }

  toggleOrderedList() {
    this.editor.chain().toggleOrderedList().focus().run();
  }

  toggleBulletList() {
    this.editor.chain().toggleBulletList().focus().run();
  }

  increaseIndent() {
    if (this.editor.isActive("listItem")) {
      this.editor.chain().sinkListItem("listItem").focus().run();
    } else {
      this.editor.chain().toggleIndentList().focus().run();
    }
  }

  decreaseIndent() {
    this.editor.chain().liftListItem("listItem").focus().run();
  }

  async insertStep(): Promise<any> {
    this.editor.chain().insertStep().focus().run();
  }

  async selectMediaToAdd(fileType: string): Promise<MediaFile[]> {
    const mediaFiles = await showDialog<MediaFile[]>(MediaLibraryModal, { fileType, allowMultiselect: true });
    return mediaFiles || [];
  }
}
</script>

<style lang="scss" scoped>
.editor-menu {
  > *:not(:last-child) {
    margin-right: var(--spacing-xs);
  }
}
</style>
