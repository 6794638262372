import Subscription from "@/model/subscription";
import ApiService from "@/services/api-service";

export default class SubscriptionsService extends ApiService<Subscription> {
  get baseUrl() {
    return "/api/v1/subscriptions";
  }

  cancelSubscription(subscription: Subscription): Promise<Subscription> {
    const url = this.buildUrl(subscription.id, { action: "cancel" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  uncancelSubscription(subscription: Subscription): Promise<Subscription> {
    const url = this.buildUrl(subscription.id, { action: "uncancel" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }
}
