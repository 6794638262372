import { RootState } from "@/store/store";
import { Module } from "vuex";
import User from "@/model/user";
import { SET_CURRENT_USER, SET_IMPERSONATING, UPDATE_SUBSCRIPTION } from "@/store/mutations";
import { isActiveSubscription } from "@/model/subscription";

export type SessionState = {
  currentUser: User;
  impersonating?: boolean;
};

export const mutations = {
  [SET_CURRENT_USER]: (state, currentUser: User) => Object.assign(state, { currentUser }),
  [SET_IMPERSONATING]: (state, impersonating: boolean) => Object.assign(state, { impersonating }),
  [UPDATE_SUBSCRIPTION]: (state, subscription) => {
    const subscriptions = state.currentUser.subscriptions.map((s) => {
      if (s.id === subscription.id) {
        return subscription;
      }
      return s;
    });
    Object.assign(state, { currentUser: { ...state.currentUser, subscriptions } });
  },
};

export const getters = {
  currentUser: ({ currentUser }) => {
    return currentUser ? new User(currentUser) : null;
  },
  activeSubscription: ({ currentUser }) => {
    if (!currentUser) {
      return null;
    }
    return currentUser.subscriptions.find((subscription) => isActiveSubscription(subscription));
  },
};

const sessionModule: Module<SessionState, RootState> = {
  state: () => ({
    currentUser: null,
  }),
  mutations,
  getters,
};

export default sessionModule;
