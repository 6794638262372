<template>
  <button class="btn-primary" @click="onClick">
    <input ref="fileInput" type="file" :disabled="disabled" accept="image/*" @input="onInput" />
    <icon icon="image"></icon>
  </button>
</template>

<script lang="ts">
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import UploadService from "@/services/upload-service";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

@Component({})
export default class ImageUploadButton extends Vue {
  @Ref("fileInput")
  fileInput: HTMLInputElement;

  @Prop({ default: false })
  disabled: boolean;

  get uploadService() {
    return new UploadService();
  }

  onClick() {
    this.fileInput.value = null;
    this.fileInput.click();
  }

  async onInput() {
    if (this.fileInput.files.length > 0) {
      await this.upload(this.fileInput.files[0]);
    }
  }

  async upload(file: File) {
    try {
      const action = this.uploadService.uploadFile(file);
      const upload = await showDialog(LoadingIndicatorDialog, { action });
      this.$emit("upload", upload);
    } catch (err) {
      this.$rollbar.error(err);
      const message = this.$t("errors.image_upload_failed") as string;
      this.$toast.error(message, { message, position: "top-right" });
    }
  }
}
</script>
<style lang="scss" scoped>
button {
  border-radius: var(--border-radius-sm);

  input[type="file"] {
    display: none;
    width: 0px;
    height: 0px;
    opacity: 0;
  }
}
</style>
