<template>
  <dropdown ref="dropdown" :to-right="right" :right="right" :up="up" @show="onShow">
    <template #dropdownButton>
      <slot name="button">
        <div class="dropdown-button">
          <icon icon="ellipsis-v"></icon>
        </div>
      </slot>
    </template>
    <slot></slot>
  </dropdown>
</template>

<script lang="ts">
import Dropdown from "@/components/dropdown/dropdown.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";

@Component({})
export default class ContextMenu extends Vue {
  @Ref("dropdown")
  dropdown: Dropdown;

  right = true;
  up = false;

  onShow() {
    this.up = false;
    this.$nextTick(() => {
      const el = this.dropdown.$el.querySelector(".dropdown__content");
      if (el) {
        const rect = el.getBoundingClientRect();
        const bottom = rect.y + rect.height;
        const win = el.ownerDocument.defaultView;
        const winHeight = win.innerHeight;
        this.up = bottom > winHeight;
      } else {
        this.up = false;
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.dropdown-button {
  padding: 0 var(--spacing-sm);
  margin-right: calc(-1 * var(--spacing-sm));
}
</style>
