<template>
  <button @click="toggle">
    <icon icon="moon" :icon-style="dark ? 'regular' : 'solid'"></icon>
  </button>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class ThemeSelector extends Vue {
  dark = false;

  created() {
    this.dark = document.documentElement.classList.contains("dark");
  }

  toggle() {
    this.dark = !this.dark;
    document.documentElement.classList.toggle("dark");
  }
}
</script>
