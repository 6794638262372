<template>
  <div class="input-list-item" :class="{ invalid: !!error }" @click="$emit('click', $event)">
    <icon v-if="icon" :icon="icon"></icon>
    <slot>
      <span v-if="!label && placeholder" class="placeholder">{{ placeholder }}</span>
      <span v-if="label" class="text-text-primary">{{ label }}</span>
    </slot>
    <div v-if="removable" class="remove-button" @click="$emit('remove')">
      <icon icon="times-circle"></icon>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class InputListItem extends Vue {
  @Prop()
  icon: string;

  @Prop()
  label: string;

  @Prop()
  placeholder: string;

  @Prop({ default: true })
  removable: boolean;

  @Prop()
  error: any;
}
</script>

<style lang="scss" scoped>
.input-list-item {
  position: relative;
  border-radius: var(--border-radius-xs);
  border: 1px solid var(--color-outline);
  padding: $input-padding;
  padding-right: calc(1em + 2 * #{$input-padding});
  margin-bottom: var(--spacing-sm);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > .icon {
    color: var(--ui-color-text-primary);
    margin-right: $input-padding;
  }

  .placeholder {
    color: $input-color-placeholder;
  }

  .remove-button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon {
      padding: $input-padding;
      margin: 0;

      &:hover {
        color: var(--ui-color-text-primary);
      }
    }
  }

  @media (hover: none), (pointer: coarse) {
    .remove-button {
      display: flex;
    }
  }

  &.invalid {
    border-color: $color-error;
  }
}
</style>
