<template>
  <form @submit.prevent="onSubmit">
    <div v-if="loaded" class="flex flex-col sm:h-[100vh]">
      <div class="flex flex-col flex-1 overflow-hidden p-md">
        <div class="items-center mb-xs sm:flex">
          <div class="flex flex-1 items-center">
            <title-input
              ref="nameInput"
              v-model.trim="$v.page.name.$model"
              class="flex-1"
              :error="$v.page.name.$error"
              maxlength="70"
            ></title-input>
            <preview-image-input
              v-model="page.content_item.preview_image"
              class="ml-4"
              :content-item="previewContentItem"
            ></preview-image-input>
          </div>
          <div class="flex my-sm basis-[283px] sm:justify-end">
            <radio-select
              v-if="userContentOptions"
              ref="userContentSelect"
              v-model="userContent"
              class="user-content-select text-xs"
              :options="userContentOptions"
              :horizontal="true"
              @input="onUserContentChanged"
            ></radio-select>
            <span v-else-if="!ownContent">{{ $t("attributes.owner") + ": " + ownerName }}</span>
          </div>
        </div>
        <div>
          <tabs class="page-tabs ml-4 my-4">
            <tab-link
              v-for="(document, index) in documentsByOrder"
              v-show="
                (!document.document_name && !document.document_type) ||
                (page.documents.length > 1 && !document['_destroy'])
              "
              ref="tab_links"
              :key="document.id"
              :active="document.id === selectedDocumentId"
              :class="index == 0 ? 'mr-xs' : ''"
              :removable="isDocumentRemovable(document)"
              :resetable="isDocumentNameResetable(document)"
              :editable="!isPresetDocument(document)"
              :value="document.document_name"
              :max-length="35"
              @click="selectDocument(document)"
              @input="(value) => updateDocumentName(document, value)"
              @reset="resetDocumentName(document)"
              @remove="removeDocument(document)"
            >
              {{ getDocumentLabel(document) }}
            </tab-link>
            <tab-button v-if="!usePresetDocumentTypes" @click="addDocument()">
              <icon-fluent class="w-4 h-4 mr-xs inline-block align-sub" name="add" variant="filled"></icon-fluent>
              <span v-if="page.documents.length &lt; 2" class="text-on-disabled inline-block hover:text-text-primary">{{
                $t("actions.add_page")
              }}</span>
            </tab-button>
          </tabs>
        </div>
        <div class="flex flex-col gap-md relative flex-1 overflow-hidden sm:flex-row">
          <div class="flex flex-1 flex-col h-full overflow-hidden">
            <document-editor
              v-for="document in $v.page.documents.$each.$iter"
              v-show="selectedDocumentId == document.$model.id"
              :key="document.$model.id"
              ref="document_editors"
              v-model="document.$model"
              :customer-organization="currentCustomerOrganization"
              :own-content="userContent"
              @change="document.$touch"
              @imageAdded="onImageAdded"
            ></document-editor>
          </div>
          <div v-if="showFilters" class="flex flex-col gap-md justify-between sm:basis-[283px] lg:basis-[320px]">
            <div class="filters-bar flex flex-col bg-surface-min-contrast rounded-lg p-md">
              <h3 class="mb-4 text-on-surface-variant">{{ $t("actions.add_filters") }}</h3>
              <speciality-select
                v-model="page.content_item.speciality_ids"
                class="mb-sm"
                :allow-null="true"
                :multiselect="true"
                :width-by-content="false"
                @input="$v.page.content_item.$touch"
              ></speciality-select>
              <location-select
                v-model="page.content_item.location_ids"
                class="mb-sm"
                :editable="true"
                :allow-null="true"
                :multiselect="true"
                :width-by-content="false"
                :user="userContent ? $currentUser : null"
                :customer-organization="currentCustomerOrganization"
                @input="$v.page.content_item.$touch"
              ></location-select>
              <language-select
                v-model="page.content_item.language"
                class="mb-sm"
                :available-languages="contentLanguages"
                :placeholder="$t('attributes.language')"
                :allow-null="true"
                :width-by-content="false"
              ></language-select>
            </div>
            <div class="flex justify-end gap-sm flex-wrap">
              <button class="btn-secondary" type="button" @click="onCancel">{{ $t("actions.cancel") }}</button>
              <button class="btn-primary" type="submit" :disabled="$v.$invalid">{{ $t("actions.save") }}</button>
              <button
                v-if="!userContent && canPublish"
                class="btn-primary-variant"
                type="button"
                :disabled="$v.$invalid"
                @click="onPublish"
              >
                {{ $t("actions.publish") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page v-else>
      <page-content>
        <loader-big></loader-big>
      </page-content>
    </page>
  </form>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import i18n, { availableLanguages, availableContentLanguages } from "@/i18n";
import { showDialog } from "@/utils/dialogs";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import { routeForPage } from "@/utils/routes";
import _ from "lodash";
import PagesService from "@/services/pages-service";
import PageType from "@/model/page-type";
import Page, { DOCUMENT_PRESETS } from "@/model/page";
import Organization from "@/model/organization";
import UploadService from "@/services/upload-service";
import DocumentEditor from "@/components/content/document-editor.vue";
import ContentDocument, { documentLabel } from "@/model/content-document";
import ContentDocumentPreset from "@/model/content-document-preset";
import ContentItem from "@/model/content-item";
import MediaFile from "@/model/media-file";
import TitleInput from "@/components/form/title-input.vue";
import RadioSelect from "@/components/form/radio-select.vue";
import { loadContentItems } from "@/actions";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";

@Component({
  components: {
    DocumentEditor,
  },
})
export default class PageEditPage extends Vue {
  @Ref("nameInput")
  nameInput: TitleInput;

  @Ref("userContentSelect")
  userContentSelect: RadioSelect<boolean>;

  @Prop()
  id: string;

  @Prop()
  copyId: string;

  @Prop()
  document: string;

  @Prop({ default: "general" })
  pageType: PageType;

  @Prop()
  mediaFiles: string;

  @Prop()
  mediaFilesType: string;

  @Ref()
  readonly fileInput: HTMLInputElement;

  @Ref("document_editors")
  documentEditors: DocumentEditor[];

  page: Partial<Page> = null;

  selectedDocumentId: number = null;

  userContent: boolean = true;

  showFilters: boolean = true;

  saving: boolean = false;

  checkPageLockVersionIntervalId = null;

  @Validations()
  validations = {
    page: {
      name: {
        required,
        maxLength: maxLength(70),
      },
      content_item: { required },
      documents: {
        $each: {
          document_type: {
            required: requiredIf((doc) => !doc.document_name),
          },
          document_name: {
            maxLength: maxLength(35),
          },
        },
      },
    },
  };

  get isNew() {
    return !this.id;
  }

  get loaded() {
    return !!this.page;
  }

  get contentLanguages(): string[] {
    return Object.keys(availableContentLanguages);
  }

  get title() {
    return !this.isNew ? this.$t("actions.edit_page") : this.$t("actions.add_page");
  }

  get previewContentItem() {
    return {
      ...this.page.content_item,
      name: this.page.name,
      content_type: "Page",
    };
  }

  get pagesService() {
    return new PagesService();
  }

  get uploadService() {
    return new UploadService();
  }

  get usePresetDocumentTypes() {
    return (this.page?.page_type || this.pageType) in DOCUMENT_PRESETS;
  }

  get documentsByOrder(): ContentDocument[] {
    return _.orderBy(this.page.documents, "order");
  }

  get canPublish(): boolean {
    return (
      (!this.userContent && this.$currentUser.isHospitalAdmin()) ||
      this.$currentUser.isAdmin() ||
      this.$currentUser.isContentAdmin()
    );
  }

  get currentCustomerOrganization(): Organization {
    return this.userContent ? null : this.organizationAvailableForPage;
  }

  get organizationAvailableForPage(): Organization {
    if (this.$currentUser.isResidentWithEditPermission()) {
      return this.$currentUser.currentResidency?.customer_organization ?? null;
    } else if (this.$currentUser.isHospitalAdmin()) {
      return this.$currentUser.customer_organization;
    } else if (this.$currentUser.isOsgenicUser()) {
      if (this.page?.content_item?.customer_organization_id) {
        const hasCreatorRole = this.$currentUser.roles.some(role => 
          role.name === "content_creator" && 
          role.resource_id === this.page.content_item.customer_organization_id
        );
        if (hasCreatorRole) {
          return this.page.content_item.customer_organization;
        }
      }
      return this.page.content_item?.customer_organization ?? null;
    }
    return null;
  }

  get isSharedPage() {
    const ownerId = this.page.content_item.user_id;
    return ownerId && ownerId !== this.$currentUser.id;
  }

  get userContentOptions() {
    if (this.isSharedPage) {
      return null;
    }

    if (this.$currentUser.isOsgenicUser()) {
      const organization = this.organizationAvailableForPage;
      return [
        {
          value: true,
          label: this.$t("contents.my_content"),
        },
        {
          value: false,
          label: organization ? organization.name : this.$t("contents.osgenic_content"),
        },
      ];
    }

    const organization = this.organizationAvailableForPage;
    if (organization) {
      return [
        {
          value: true,
          label: this.$t("contents.my_content"),
        },
        {
          value: false,
          label: organization.name,
        },
      ];
    }

    return null;
  }

  get ownContent() {
    return this.page.content_item.user_id === this.$currentUser.id;
  }

  get ownerName() {
    if (this.page.content_item.user_id) {
      return this.ownContent ? this.$t("contents.my_content") : this.page.content_item.user.name;
    } else if (this.page.content_item.customer_organization) {
      return this.page.content_item.customer_organization.name;
    } else {
      return this.$t("contents.osgenic_content");
    }
  }

  getDocumentLabel(document: ContentDocument): string {
    return documentLabel(document);
  }

  addDocument(): void {
    const document = this.buildDocument({ order: this.page.documents.length });
    const newLenght = this.page.documents.push(document);

    this.selectDocument(document);
    Vue.nextTick(() => {
      this.$refs["tab_links"][newLenght - 1].setEditMode(true);
    });

    this.$v.page.documents.$touch();
  }

  resetDocumentName(document: ContentDocument): void {
    document.document_name = null;
    this.$v.page.documents.$touch();
  }

  removeDocument(document: ContentDocument): void {
    const index = this.page.documents.indexOf(document);
    if (index >= 0) {
      Vue.set(this.page.documents, index, { ...document, _destroy: true });
      this.selectDocument(this.documentsByOrder[0]);
      this.$v.page.documents.$touch();
    }
  }

  selectDocument(document: ContentDocument): void {
    this.selectedDocumentId = document.id;
  }

  updateDocumentName(document: ContentDocument, value: any): void {
    const index = this.page.documents.indexOf(document);

    if (index === -1) {
      console.error("Document index not found.");
      return;
    }

    if (!value) {
      const docIsNew = !document.id || document.id < 0;
      if (docIsNew && !document.document_name) {
        // Tried to add new document but name was not given: can be removed from the array
        this.page.documents.splice(index, 1);
        this.selectDocument(this.documentsByOrder[0]);
      } else {
        // Do not update blank name if name has already been inputted
        return;
      }
    } else {
      // Check for duplicate document_type
      const existingNameIndex = this.page.documents.findIndex((doc) => !doc._destroy && doc.document_name === value);
      if (existingNameIndex > -1 && existingNameIndex !== index) {
        this.$refs["tab_links"][index].setEditMode(true);
      } else {
        Vue.set(this.page.documents, index, { ...document, document_name: value });
        this.$v.page.documents.$each.$iter[index].$touch();
      }
    }
  }

  isDocumentDuplicate(document) {
    return _.without(this.page.documents, document).some(
      (otherDoc) => otherDoc.document_type === document.document_type && !otherDoc._destroy
    );
  }

  isPresetDocument(document) {
    return DOCUMENT_PRESETS[this.page?.page_type || this.pageType]?.some(
      (presetDoc) => presetDoc.document_type === document.document_type
    );
  }

  isDocumentNameResetable(document) {
    if (this.saving) return false;
    if (this.usePresetDocumentTypes) {
      return this.isPresetDocument(document) && document.document_name;
    }
    return false;
  }

  isDocumentRemovable(document) {
    if (this.saving) return false;
    if (this.usePresetDocumentTypes) {
      return !this.isPresetDocument(document) || this.isDocumentDuplicate(document);
    }
    return document.order > 0;
  }

  async created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);

    if (this.id) {
      await this.fetchPage();
      this.checkPageLockVersionIntervalId = setInterval(async () => {
        if (this.$store.getters.currentUser) {
          const lock_version = await this.pagesService.getLockVersion(this.id);
          if (lock_version != this.page.lock_version) {
            const message = i18n.t("errors.conflict_poll_error") as string;
            this.$toast.error(message, {
              message,
              position: "top-right",
              duration: 60000,
              dismissible: true,
            });
          }
        }
      }, 30 * 1000);
    } else if (this.copyId) {
      await this.copyPage();
    } else {
      this.initPage();
    }

    if (this.mediaFiles) {
      this.addFiles();
    }

    if (this.document) {
      const document = this.page.documents.find((document) => document.document_type === this.document);
      if (document) {
        this.selectDocument(document);
      }
    } else {
      this.selectDocument(this.documentsByOrder[0]);
    }
  }

  mounted() {
    if (!this.id && !this.copyId) {
      this.$nextTick(() => {
        const nameInputEl = this.nameInput.$el as HTMLElement;
        nameInputEl.focus();
      });
    }
  }

  @Watch("page")
  onPageLoaded() {
    // Focus on editor if existing page (for new pages focus is set to title input)
    if (this.id || this.copyId) {
      this.$nextTick(() => {
        const documentEditor = this.documentEditors.find((editor) => editor.value.id === this.selectedDocumentId);
        documentEditor?.focus();
      });
    }
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  }

  destroyed() {
    if (this.checkPageLockVersionIntervalId) {
      clearInterval(this.checkPageLockVersionIntervalId);
    }
  }

  addFiles() {
    this.$nextTick(async () => {
      const parsedFiles: MediaFile[] = JSON.parse(this.mediaFiles);

      if (parsedFiles.length > 0) {
        this.page.name = parsedFiles[0].file_name;
        for (let file of parsedFiles) {
          if (this.mediaFilesType === "image") {
            this.documentEditors[0].editorMenu.addImage(file);
          } else if (this.mediaFilesType === "video") {
            this.documentEditors[0].editorMenu.addVideo(file);
          } else {
            this.documentEditors[0].editorMenu.addFile(file);
          }
        }
      }
    });
  }

  onImageAdded(mediaFile: MediaFile) {
    if (this.isNew && !this.page.content_item.preview_image) {
      Vue.set(this.page.content_item, "preview_image", mediaFile.file);
    }
  }

  initPage(page: Page = null) {
    this.page = {
      name: page?.name ?? "",
      page_type: this.pageType,
      lock_version: page?.lock_version,
      content_item: {
        speciality_ids: page?.content_item?.speciality_ids ?? [],
        location_ids: page?.content_item?.location_ids ?? [],
        customer_organization_id: page?.content_item?.customer_organization_id ?? null,
        user_id: page?.content_item?.user_id ?? null,
        language: page?.content_item?.language,
        preview_image: page?.content_item.preview_image,
      } as ContentItem,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      documents: page?.documents?.map(({ id, ...rest }) => ({ ...rest, id: this.generateTemporaryId() })) || [],
    };

    // Set owner to current user if copying shared page
    if (page?.content_item?.user_id && page.content_item.user_id !== this.$currentUser.id) {
      this.page.content_item.user_id = this.$currentUser.id;
    }

    // Set owner as current user if the page parameter does not exist
    if (!page) {
      this.page.content_item.user_id = this.$currentUser.id;
    }

    this.ensureDocumentData();
  }

  async fetchPage() {
    this.page = await this.pagesService.get(this.id);
    this.userContent = !!this.page.content_item.user_id;
    this.ensureDocumentData();
  }

  async copyPage() {
    const page = await this.pagesService.get(this.copyId);
    const name = `${page.name} (${i18n.t("terms.copy")})`;
    this.initPage({ ...page, name });
    this.userContent = !!this.page.content_item.user_id;
  }

  get languageOptions() {
    const langs = availableLanguages;
    return Object.keys(langs).map((key) => {
      return { value: key, label: langs[key] };
    });
  }

  private buildDocument(params): ContentDocument {
    const document: ContentDocument = {
      id: this.generateTemporaryId(), // Create a placeholder ID when not yet saved (negative number)
      documentable_type: "Page",
      documentable_id: parseInt(this.id) || null,
      document_type: params.document_type, // Type is slugified from name in controller if no type given on save
      document_name: params.document_name,
      content: {
        version: 3,
        ...params.content,
      },
      html: "",
      order: params.order ?? 0,
    };

    return document;
  }

  private ensureDocumentData(): void {
    // If page uses preset documents, make sure that required preset data exist, or...
    if (this.usePresetDocumentTypes) {
      this.ensurePresetDocumentData();
    }
    // ...even if not using presets there should be at least one document
    else if (this.page.documents.length == 0) {
      this.page.documents.push(this.buildDocument({ document_name: i18n.t(`documents.types.content`) }));
    }

    // Ensure that docs have order value
    this.page.documents.forEach((doc, i) => {
      // If document has no order set, use index
      if (doc.order === null) {
        this.page.documents[i].order = i;
      }
    });
  }

  private ensurePresetDocumentData(): void {
    const existingDocumentTypes = this.page.documents.map((doc) => doc.document_type);
    const documentPresets: ContentDocumentPreset[] = DOCUMENT_PRESETS[this.page.page_type];

    // Ensure non-null document types first
    existingDocumentTypes.forEach((docType, index) => {
      if (docType === null) {
        const nullTypeDoc = this.page.documents[index];
        const presetDocType = documentPresets.find((preset) => preset.order === (nullTypeDoc.order ?? index));

        if (presetDocType) {
          this.$set(this.page.documents, index, {
            ...nullTypeDoc,
            document_type: presetDocType.document_type,
          });
          existingDocumentTypes[index] = presetDocType.document_type;
        }
      }
    });

    documentPresets.forEach((preset) => {
      const existingDocumentIndex = existingDocumentTypes.indexOf(preset.document_type);
      if (existingDocumentIndex >= 0) {
        const existingDoc = this.page.documents[existingDocumentIndex];
        // Update preset values for existing document (except content) and reset the name
        this.$set(this.page.documents, existingDocumentIndex, {
          ...existingDoc,
          ...preset,
          content: existingDoc.content,
          document_name: null, // No name needed, preset docs use localized version of type as name
        });
      } else {
        // Build and add missing preset document
        const missingDocument = this.buildDocument({
          ...preset,
          document_name: null,
        });
        this.page.documents.push(missingDocument);
      }
    });
  }

  beforeRouteUpdate(to, from, next) {
    this.handleRouteChange(next);
  }

  beforeRouteLeave(to, from, next) {
    this.handleRouteChange(next);
  }

  async handleRouteChange(next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.$v.$anyDirty) {
      const confirmed = await this.confirmLeave();
      if (confirmed) {
        // Navigate to next view
        next();
      } else {
        // Prevent navigation
        next(false);
      }
    } else {
      next();
    }
  }

  async beforeWindowUnload(e) {
    if (this.$v.$anyDirty) {
      const confirmed = await this.confirmLeave();
      if (!confirmed) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    }
  }

  private confirmLeave() {
    return showDialog(ConfirmationDialog, { title: this.$t("confirmation.discard_changes") });
  }

  onCancel() {
    this.$router.back();
  }

  async onSubmit(redirect: boolean = true) {
    this.$v.$touch();
    this.documentEditors.forEach((editor) => editor.applyEdits());

    if (!this.$v.$error) {
      // Reset dirty state so that user will not be asked to discard changes
      this.$v.$reset();

      const action = !this.page.id ? this.createPage(redirect) : this.updatePage(redirect);

      await showDialog(LoadingIndicatorDialog, { action });

      // Reload content items to ensure page becomes visible/updated in content list
      return loadContentItems(true);
    }
  }

  async onPublish() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.publish_page") });
    if (confirmed) {
      // Reset dirty state so that user will not be asked to discard changes
      this.$v.$reset();

      const action = this.onSubmit(false).then(() => this.publishPage());

      await showDialog(LoadingIndicatorDialog, { action });
    }
  }

  private async createPage(redirect: boolean = true) {
    const attrs: Partial<Page> = {
      ...this.page,
      page_type: this.pageType,
      content_item: {
        ...this.page.content_item,
        ...this.generateOwnershipAttributes(),
      },
    };

    this.saving = true;
    const page = await this.pagesService.create(attrs);
    this.saving = false;

    this.page.id = page.id;

    if (redirect) {
      this.$router.push(routeForPage(page));
    }
  }

  private async updatePage(redirect: boolean = true) {
    this.saving = true;
    await this.pagesService.update(this.page.id, {
      ...this.page,
      content_item: {
        ...this.page.content_item,
        ...this.generateOwnershipAttributes(),
      },
    });
    this.saving = false;

    if (redirect) {
      this.$router.push(routeForPage(this.page as Page));
    }
  }

  private async publishPage() {
    this.saving = true;
    const page = await this.pagesService.publish(this.page.id);
    this.saving = false;
    this.$router.push(routeForPage(page));
  }

  private generateOwnershipAttributes(): Partial<ContentItem> {
    if (this.isSharedPage) {
      return {};
    }

    const attrs: Partial<ContentItem> = {
      user_id: null,
      customer_organization_id: null,
    };

    if (this.$currentUser.isHospitalAdmin()) {
      if (this.userContent) {
        attrs.user_id = this.$currentUser.id;
      } else {
        attrs.customer_organization_id = this.$currentUser.customer_organization_id;
      }
    } else if (this.$currentUser.isResidentWithEditPermission()) {
      if (this.userContent) {
        attrs.user_id = this.$currentUser.id;
      } else {
        const organization = this.$currentUser.activeResidencies
          .filter((residency) => residency.permissions.includes("edit_content"))
          .map((residency) => residency.customer_organization)[0];
        attrs.customer_organization_id = organization.id;
      }
    } else if (this.$currentUser.isOsgenicUser()) {
      if (this.userContent) {
        attrs.user_id = this.$currentUser.id;
      } else if (this.page?.content_item?.customer_organization_id) {
        // Keep the existing organization if user has content_creator role for it
        const hasCreatorRole = this.$currentUser.roles.some(role => 
          role.name === "content_creator" && 
          role.resource_id === this.page.content_item.customer_organization_id
        );
        if (hasCreatorRole) {
          attrs.customer_organization_id = this.page.content_item.customer_organization_id;
        }
      }
    } else {
      attrs.user_id = this.$currentUser.id;
    }

    return attrs;
  }

  private async onUserContentChanged(value, skipCallback = false) {
    if (skipCallback) {
      return;
    }

    if (this.page.content_item.location_ids?.length > 0) {
      const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.change_page_owner") });
      if (confirmed) {
        // Reset selected location(s) if the userContent setting is changed
        this.page.content_item.location_ids.splice(0);
      } else {
        // Revert value
        this.userContentSelect.$emit("input", !value, true);
      }
    }
  }

  private generateTemporaryId(): number {
    return -_.uniqueId();
  }
}
</script>

<style lang="scss" scoped>
.user-content-select::v-deep {
  padding: 0;

  label {
    margin: 0 0 0 1rem;
  }
}

.filters-bar {
  .tag-input {
    @apply px-3.5;
  }

  .dropdown-select::v-deep {
    .dropdown__button-title {
      @apply text-base font-regular;
      letter-spacing: 0.5px;
    }
  }

  .date-input::v-deep {
    & + .date-input__input {
      padding: 0 0.875rem;
    }

    &[value]:not([value=""]) {
      & + .date-input__input {
        @apply border-border-emphasis;
      }
    }
  }
}

.page-footer {
  @media (max-width: $screen-xs-max) {
    padding-top: 0.75rem;

    &::after {
      position: absolute;
      display: block;
      content: "";
      background: linear-gradient(180deg, var(--color-background-0) 0%, var(--ui-color-surface-no-contrast) 100%);
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 8px;
      pointer-events: none;
    }
  }
}
</style>
