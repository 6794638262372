<template>
  <div class="flex gap-4 relative">
    <div class="hidden gap-4 toc:flex">
      <action-button v-if="canEdit" icon="edit" :title="$t('actions.edit')" @click="edit"></action-button>
      <ui-favorite-button type="in-circle" :value="favorited" @input="updateFavorited"></ui-favorite-button>
    </div>
    <ui-button-icon
      class="toc:hidden"
      variant="secondary"
      :icon="open ? 'dismiss' : 'moreHorizontal'"
      :class="{ 'shadow-sm': open, hidden: !showMoreSmallScreen, 'toc:hidden': !showMoreLargeScreen }"
      @click="open = !open"
    ></ui-button-icon>
    <ui-button-icon
      class="hidden toc:inline-flex"
      variant="secondary"
      size="sm"
      :icon-size="open ? 'sm' : 'md'"
      :icon="open ? 'dismiss' : 'moreHorizontal'"
      :class="{ 'shadow-sm': open, hidden: !showMoreSmallScreen, 'toc:hidden': !showMoreLargeScreen }"
      @click="open = !open"
    ></ui-button-icon>
    <div
      class="flex flex-col items-start gap-2 absolute top-11 bg-surface-min-contrast rounded px-2 py-4 shadow-md z-10 duration-300 toc:top-9 toc:left-auto toc:right-0"
      :class="open ? ['opacity-100'] : ['opacity-0', 'pointer-events-none']"
      @click="open = false"
    >
      <div class="flex flex-col toc:hidden">
        <action-button v-if="canEdit" icon="edit" :title="$t('actions.edit')" @click="edit"></action-button>
        <action-button
          icon="star"
          :title="favorited ? $t('actions.remove_from_favorites') : $t('actions.add_to_favorites')"
          @click="updateFavorited(!favorited)"
        ></action-button>
      </div>
      <action-button v-if="canShare" icon="share" :title="$t('actions.share')" @click="share"></action-button>
      <action-button
        v-if="canRemoveSharedWithMe"
        icon="shareScreenStop"
        :title="$t('actions.remove_share')"
        @click="removeSharedWithMe"
      ></action-button>
      <action-button v-if="canEdit" icon="copy" :title="$t('actions.copy')" @click="copy"></action-button>
      <action-button
        v-if="canPublish"
        icon="arrowUpload"
        :title="$t('actions.publish')"
        @click="publish"
      ></action-button>
      <action-button
        v-if="canUnpublish"
        icon="dismiss"
        :title="$t('actions.unpublish')"
        @click="unpublish"
      ></action-button>
      <action-button
        v-if="canShowInLatestReleases"
        icon="news"
        :title="$t('actions.show_in_latest_releases')"
        @click="showInLatestReleases"
      ></action-button>
      <action-button
        v-if="canRemoveFromLatestReleases"
        icon="dismiss"
        :title="$t('actions.remove_from_latest_releases')"
        @click="removeFromLatestReleases"
      ></action-button>
      <action-button
        v-if="canDelete"
        icon="delete"
        type="delete"
        :title="$t('actions.delete')"
        @click="deletePage"
      ></action-button>
    </div>
  </div>
</template>

<script lang="ts">
import { isContentItemDraft, isContentItemPublished } from "@/model/content-item";
import Page from "@/model/page";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import ShareDialog from "../share/share-dialog.vue";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import PagesService from "@/services/pages-service";
import ContentItemsService from "@/services/content-items-service";
import { loadHomePageLatestReleases } from "@/actions";
import PublicShareDialog from "@/pages/share/public-share-dialog.vue";

@Component({})
export default class PageActionsBlock extends Vue {
  @Prop()
  page: Page;

  @Prop()
  documentType: string;

  @Prop()
  documentStatus: string;

  open = false;

  get pagesService() {
    return new PagesService();
  }

  get contentItemsService() {
    return new ContentItemsService();
  }

  get canShare() {
    return (
      this.page.content_item.permissions.share ||
      this.page.content_item.permissions.share_public ||
      this.page.content_item.share_token
    );
  }

  get canRemoveSharedWithMe() {
    return this.page.content_item.permissions.remove_shared_with_me;
  }

  get canEdit() {
    return this.page.content_item.permissions.edit;
  }

  get canCopy() {
    return this.canEdit;
  }

  get canPublish() {
    return (
      !this.page.content_item.user_id &&
      this.page.content_item.permissions.publish &&
      isContentItemDraft(this.page.content_item)
    );
  }

  get canUnpublish() {
    return (
      !this.page.content_item.user_id &&
      this.page.content_item.permissions.publish &&
      isContentItemPublished(this.page.content_item)
    );
  }

  get canShowInLatestReleases() {
    return (
      !this.page.content_item.user_id &&
      this.page.content_item.permissions.show_in_latest_releases &&
      !this.page.content_item.latest_content_item_release &&
      isContentItemPublished(this.page.content_item)
    );
  }

  get canRemoveFromLatestReleases() {
    return (
      !this.page.content_item.user_id &&
      this.page.content_item.permissions.show_in_latest_releases &&
      this.page.content_item.latest_content_item_release
    );
  }

  get canDelete() {
    return this.page.content_item.permissions.delete;
  }

  get showMoreSmallScreen() {
    return (
      this.canCopy ||
      this.canShare ||
      this.canRemoveSharedWithMe ||
      this.canDelete ||
      this.canEdit ||
      this.canPublish ||
      this.canUnpublish ||
      this.canShowInLatestReleases ||
      this.canRemoveFromLatestReleases
    );
  }

  get showMoreLargeScreen() {
    return (
      this.canCopy ||
      this.canShare ||
      this.canRemoveSharedWithMe ||
      this.canDelete ||
      this.canPublish ||
      this.canUnpublish ||
      this.canShowInLatestReleases ||
      this.canRemoveFromLatestReleases
    );
  }

  get favorited() {
    return this.page.content_item.favorited_by_current_user;
  }

  async updateFavorited(value) {
    if (value) {
      await this.contentItemsService.favorite(this.page.content_item);
    } else {
      await this.contentItemsService.unfavorite(this.page.content_item);
    }

    const page = {
      ...this.page,
      content_item: {
        ...this.page.content_item,
        favorited_by_current_user: value,
      },
    };

    this.$emit("page-updated", page);
  }

  edit() {
    let route: RawLocation = { name: "EditPage" };
    if (
      this.page.documents &&
      this.documentStatus !== "missing" &&
      this.page.documents[0].document_type !== this.documentType
    ) {
      route.query = { document: this.documentType };
    }
    this.$router.push(route);
  }

  copy() {
    this.$router.push({
      name: "AddPage",
      params: { pageType: this.page.page_type },
      query: { copyId: this.page.id.toString() },
    });
  }

  share() {
    if (this.page.content_item.permissions.share || this.page.content_item.permissions.share_public) {
      showDialog(ShareDialog, {
        contentItem: this.page.content_item,
        sharesUpdated: (shares) => {
          this.$emit("page-updated", {
            ...this.page,
            content_item: {
              ...this.page.content_item,
              has_shares: shares.length > 0,
            },
          });
        },
        contentItemUpdated: (contentItem) => {
          this.$emit("page-updated", {
            ...this.page,
            content_item: {
              ...this.page.content_item,
              ...contentItem,
            },
          });
        },
      });
    } else if (this.page.content_item.share_token) {
      showDialog(PublicShareDialog, { contentItem: this.page.content_item });
    }
  }

  async removeSharedWithMe() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.remove_share") });
    if (confirmed) {
      await this.contentItemsService.deleteUserShare(this.page.content_item);
      this.$router.push({ name: "Contents" });

      this.$emit("content-items-updated");
    }
  }

  async publish() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.publish_page") });
    if (confirmed) {
      const page = await this.pagesService.publish(this.page.id);
      this.$emit("page-updated", page);
    }
  }

  async unpublish() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.unpublish_page") });
    if (confirmed) {
      const page = await this.pagesService.unpublish(this.page.id);
      this.$emit("page-updated", page);
    }
  }

  async showInLatestReleases() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.show_in_latest_releases") });
    if (confirmed) {
      const page = await this.pagesService.showInLatestReleases(this.page.id);
      this.$emit("page-updated", page);
      return loadHomePageLatestReleases();
    }
  }

  async removeFromLatestReleases() {
    const confirmed = await showDialog(ConfirmationDialog, {
      title: this.$t("confirmation.remove_from_latest_releases"),
    });
    if (confirmed) {
      const page = await this.pagesService.removeFromLatestReleases(this.page.id);
      this.$emit("page-updated", page);
      return loadHomePageLatestReleases();
    }
  }

  async deletePage() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.delete_permanently") });
    if (confirmed) {
      await this.pagesService.delete(this.page.id);
      this.$emit("page-deleted", this.page);
      this.$router.push({ name: "Contents" });

      this.$emit("content-items-updated");
    }
  }
}
</script>
