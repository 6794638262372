import ContentItem from "@/model/content-item";
import { SET_CONTENT_LOADING, SET_CONTENT_ITEMS } from "@/store/mutations";
import { RootState } from "@/store/store";
import { Module } from "vuex";

export type ContentsState = {
  contentItems: ContentItem[];
  loading: boolean;
};

export const buildInitialState: () => ContentsState = () => ({
  contentItems: null,
  loading: false,
});

export const mutations = {
  [SET_CONTENT_LOADING]: (state, loading) => Object.assign(state, { loading }),
  [SET_CONTENT_ITEMS]: (state, contentItems) => Object.assign(state, { contentItems }),
};

const contentsModule: Module<ContentsState, RootState> = {
  state: buildInitialState,
  mutations,
};

export default contentsModule;
