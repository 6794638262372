<template>
  <icon-fluent class="content-type-icon rounded-full bg-surface-variant" :name="iconName" :size="size"></icon-fluent>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

const iconTypes = {
  Page: "document",
};

@Component({})
export default class ContentTypeIcon extends Vue {
  @Prop()
  type: string;

  @Prop({ default: "20" })
  size: "16" | "20" | "24";

  get iconName() {
    return iconTypes[this.type];
  }
}
</script>

<style lang="scss" scoped>
.content-type-icon {
  padding: 0.25rem;
  color: var(--ui-color-text-variant);
}
</style>
