<template>
  <input
    class="text-3xl font-light rounded-xs text-text-emphasis-20 dark:text-text-primary placeholder:text-on-disabled relative"
    type="text"
    :value="value"
    :placeholder="`${$t('actions.add')} ${$t('attributes.name').toLowerCase()}`"
    @input="onInput"
  />
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class TitleInput extends Vue {
  @Prop()
  value: string;

  onInput(event) {
    this.$emit("input", event.target.value);
  }
}
</script>

<style lang="scss" scoped>
input {
  line-height: 100%;
  border-color: transparent;
  padding: 0 var(--spacing-xs);
  display: flex;
  flex: 1 1;
  height: auto;

  &:hover {
    border-color: var(--ui-color-text-variant);
  }

  &:focus {
    border-color: var(--ui-color-text-primary);
  }

  &[error] {
    border-color: var(--color-error);
  }
}
</style>
