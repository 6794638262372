<template>
  <div class="inline-block w-5 h-5 relative">
    <icon-fluent
      v-if="type == 'radio'"
      name="radioButton"
      :variant="checked ? 'filled' : 'regular'"
      :class="{ 'text-text-emphasis': checked, 'text-semantic-error': error }"
    ></icon-fluent>
    <icon-fluent
      v-else
      :name="checked ? 'checkboxChecked' : 'checkboxUnchecked'"
      :variant="checked ? 'filled' : 'regular'"
      :class="{ 'text-text-emphasis': checked, 'text-semantic-error': error }"
    ></icon-fluent>
    <input
      class="opacity-0 absolute inset-0"
      :type="type"
      :name="name"
      :value="value"
      :checked="checked"
      @change="$emit('change', value)"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Checkbox extends Vue {
  @Prop()
  value: any;

  @Prop()
  checked: boolean;

  @Prop()
  error: boolean;

  @Prop({ default: "checkbox" })
  type: "checkbox" | "radio";

  @Prop()
  name: string;
}
</script>
