<template>
  <card :title="$t('residents.title')">
    <loader-big v-if="!specialities"></loader-big>
    <div v-else class="content">
      <div class="top-container">
        <div class="chart-container">
          <doughnut-chart :data="chartData" :colors="chartColors"></doughnut-chart>
          <div class="total-residents">
            <div class="text-4xl font-light text-text-primary mt-8 md:mt-0">{{ totalResidents }}</div>
            <div class="text-body-medium mt-3.5 md:mt-0">{{ $t("residents.title") }}</div>
          </div>
        </div>
        <div class="legend">
          <div v-for="(speciality, index) in specialities" :key="speciality.id" class="legend-item">
            <div class="dot" :style="dotStyles[index]"></div>
            <div class="text-label-large text-text-primary">{{ speciality.resident_count }}</div>
            <div class="text-body-medium">{{ speciality.name }}</div>
          </div>
        </div>
      </div>
      <div class="percentages">
        <div v-for="(speciality, index) in specialities" :key="speciality.id" class="percentage">
          <div class="dot" :style="dotStyles[index]"></div>
          <div class="percentage-value text-xl font-bold text-text-primary">{{ percentages[index] }}%</div>
        </div>
      </div>
    </div>
  </card>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import DashboardService from "@/services/dashboard-service";
import Speciality from "@/model/speciality";
import Style from "@/style";

@Component({
  components: {},
})
export default class ResidentsCard extends Vue {
  specialities: Speciality[] = null;

  get dashboardService(): DashboardService {
    return new DashboardService();
  }

  get chartData(): number[] {
    return this.specialities.map(({ resident_count }) => resident_count);
  }

  get totalResidents(): number {
    return this.specialities.reduce((total, { resident_count }) => total + resident_count, 0);
  }

  get chartColors(): string[] {
    return Style.chartColors;
  }

  get percentages(): number[] {
    return this.chartData.map((n) => Math.round((n / this.totalResidents) * 100));
  }

  get dotStyles(): string[] {
    return this.chartColors.map((color) => `background-color: ${color}`);
  }

  async mounted() {
    return this.fetchSpecialities();
  }

  async fetchSpecialities() {
    this.specialities = await this.dashboardService.getSpecialitiesWithResidentCount();
  }
}
</script>

<style lang="scss" scoped>
$dot-width: 8px;

.content {
  .top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .chart-container {
      width: 200px;
      height: 200px;
      position: relative;

      @media (max-width: $screen-sm-max) {
        width: 100px;
        height: 100px;
      }

      .total-residents {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 190px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-width: $screen-sm-max) {
          height: 90px;
        }
      }
    }

    .legend {
      .legend-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        > *:not(:last-child) {
          margin-right: var(--spacing-sm);
        }
      }
    }
  }

  .percentages {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: var(--spacing);
    flex-wrap: wrap;

    .percentage {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:not(:last-child) {
        .dot {
          margin: 0 var(--spacing);
        }
        .percentage-value {
          padding: 0 var(--spacing);
          border-right: 1px solid var(--color-outline);
        }
      }

      &:not(:first-child) {
        padding-left: var(--spacing);
      }
    }
  }

  .dot {
    width: $dot-width;
    height: $dot-width;
  }
}
</style>
