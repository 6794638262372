<template>
  <typeahead-input
    item-icon="pen"
    :selected-items="selectedItems"
    :matching-items="matchingItems"
    :placeholder="$t('procedure.add_disease_code_placeholder') + '...'"
    @updated="onUpdated"
    @input="fetchDiseaseCodes"
  ></typeahead-input>
</template>

<script lang="ts">
import { TypeaheadItem } from "@/model/typeahead-item";
import DiseaseCode from "@/model/disease-code";
import DiseaseCodesService from "@/services/disease-codes-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import debounce from "lodash/debounce";

@Component({})
export default class DiseaseCodeSelect extends Vue {
  @Prop()
  value: DiseaseCode[];

  allDiseaseCodes: DiseaseCode[] = null;

  get diseaseCodesService() {
    return new DiseaseCodesService();
  }

  get selectedItems(): TypeaheadItem<DiseaseCode>[] {
    return this.typeaheadItems(this.value);
  }

  get matchingItems(): TypeaheadItem<DiseaseCode>[] {
    return this.typeaheadItems(this.allDiseaseCodes);
  }

  typeaheadItems(types: DiseaseCode[]) {
    return (
      types &&
      types.map((type) => ({
        value: type,
        label: `${type.code} - ${type.name}`,
      }))
    );
  }

  onUpdated(items: TypeaheadItem<DiseaseCode>[]) {
    this.$emit(
      "input",
      items.map((item) => item.value)
    );
  }

  fetchDiseaseCodes = debounce(this._fetchDiseaseCodes, 300);

  async _fetchDiseaseCodes(q: string) {
    this.allDiseaseCodes = q && q.length > 0 ? await this.diseaseCodesService.getAll({ params: { q } }) : [];
  }
}
</script>
