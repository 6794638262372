<template>
  <node-view-wrapper>
    <section :id="node.attrs.uid" class="step relative" :data-uid="node.attrs.uid">
      <node-view-content class="step-content"></node-view-content>
    </section>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { nodeViewProps, NodeViewWrapper, NodeViewContent } from "@tiptap/vue-2";

@Component({
  components: { NodeViewWrapper, NodeViewContent },
  props: nodeViewProps,
})
export default class StepNodeView extends Vue {
  @Prop()
  node: any;
}
</script>
