import { logout } from "@/actions";
import i18n from "@/i18n";
import Vue from "vue";
import {
  AbortError,
  ApiError,
  ConflictError,
  NetworkError,
  NotFoundError,
  PaymentRequired,
  UnauthorizedError,
  TimeoutError,
} from "@/api/api-error";

export const errorHandler = (err: Error, vm: Vue = null) => {
  // Handles async errors from api calls. See https://dev.to/f3ltron/vue-266-release-part1-23b4 for info
  // on how to make vue aware of async errors.
  if (err instanceof NotFoundError) {
    return;
  }
  if (err instanceof UnauthorizedError) {
    logout({ tokenExpired: true });
  } else if (err instanceof PaymentRequired) {
    if (location.pathname !== "/sessions/subscription_expired") {
      location.href = "/sessions/subscription_expired";
    }
  } else if (err instanceof NetworkError) {
    if (vm) {
      const message = i18n.t("errors.network_error") as string;
      vm.$toast?.error(message, {
        message,
        position: "top-right",
        duration: 10000,
        dismissible: true,
      });
    }
  } else if (err instanceof TimeoutError) {
    console.error(err);
    if (vm) {
      const message = i18n.t("errors.timeout_error") as string;
      vm.$toast?.error(message, {
        message,
        position: "top-right",
        duration: 10000,
        dismissible: true,
      });
      vm.$rollbar?.error(err, {
        url: err.url,
        message: `Request timeout at ${err.url}`
      });
    }
  } else if (err instanceof ConflictError) {
    if (vm) {
      const message = i18n.t("errors.conflict_error") as string;
      vm.$toast?.error(message, {
        message,
        position: "top-right",
        duration: 10000,
        dismissible: true,
      });
    }
  } else if (err instanceof AbortError) {
    console.debug("Aborted request");
  } else if (err instanceof ApiError) {
    console.error(err);
    if (vm) {
      const message = i18n.t("errors.unexpected_error") as string;
      vm.$toast?.error(message, {
        message,
        position: "top-right",
        duration: 10000,
        dismissible: true,
      });
      vm.$rollbar?.error(err);
    }
    throw err;
  } else {
    console.error(err);
    vm?.$rollbar?.error(err);
    throw err;
  }
};

// Creates a a new function with error handling for given function
export const withErrorHandling = (fn: (...args: any[]) => Promise<any>, vm: Vue) => {
  return (...args) => fn(...args).catch((err) => errorHandler(err, vm));
};
