import ContentType from "@/model/content-type";

export default interface ContentItemFilters {
  owner_user_ids: number[];
  favorited_by_ids: number[];
  organization_ids: number[];
  speciality_ids: string[];
  location_ids: string[];
  content_types: ContentType[];
  page_type?: string;
  disease_code_ids: string[];
  procedure_type_ids: string[];
  shared: boolean;
  sort: string;
  q: string;
}

export function buildEmptyFilters(): ContentItemFilters {
  return {
    owner_user_ids: [],
    favorited_by_ids: [],
    organization_ids: [],
    speciality_ids: [],
    location_ids: [],
    content_types: [],
    disease_code_ids: [],
    procedure_type_ids: [],
    shared: false,
    sort: "name",
    q: null,
  };
}
