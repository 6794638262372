<template>
  <modal>
    <form name="course-modal" @submit.prevent="onSubmit">
      <h3 class="mb-4">{{ $t("course.log_course") }}</h3>
      <form-field :label="$t('attributes.name')" :error="$v.model.data.name.$error">
        <input v-model="model.data.name" type="text" :placeholder="$t('attributes.name') + '...'" />
      </form-field>
      <form-field :label="$t('attributes.hospital')">
        <resident-role-select v-model="model.residency_id"></resident-role-select>
      </form-field>
      <form-field
        v-if="selectedResidency && selectedResidency.curriculums && selectedResidency.curriculums.length > 0"
        :label="$t('logbook.title')"
      >
        <curriculum-select
          :value="curriculum && curriculum.id"
          :curriculums="selectedResidency.curriculums"
          @input="selectCurriculum"
        ></curriculum-select>
      </form-field>
      <form-field v-if="curriculum" :label="$t('course.title')">
        <curriculum-item-select
          v-model="model.curriculum_item_id"
          type="course"
          :curriculum="curriculum"
        ></curriculum-item-select>
      </form-field>
      <form-field :label="$t('attributes.date')" :error="$v.model.date.$error">
        <date-input v-model="model.date"></date-input>
      </form-field>
      <form-field :label="$t('attributes.end_date')">
        <date-input v-model="model.end_date"></date-input>
      </form-field>
      <form-field :label="$t('attributes.comments')">
        <textarea v-model="model.data.comments" rows="4"></textarea>
      </form-field>
      <form-field :label="$t('attributes.attachments')">
        <attachments-input v-model="model.attachments"></attachments-input>
      </form-field>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import LogbookEntriesService from "@/services/logbook-entries-service";
import ProcedureData from "@/model/procedure-data";
import dayjs from "dayjs";
import LogbookEntry from "@/model/logbook-entry";
import CourseData from "@/model/course-data";
import Attachment from "@/model/attachment";
import { addLogbookEntry, updateLogbookEntry } from "@/actions";
import MaybeUploading from "@/model/maybe-uploading";
import { Prop } from "vue-property-decorator";
import cloneDeep from "lodash/cloneDeep";
import Curriculum from "@/model/curriculum";
import { showDialog } from "@/utils/dialogs";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import Residency from "@/model/residency";

@Component({
  components: {},
})
export default class CourseModal extends Vue {
  @Prop()
  logbookEntry: LogbookEntry<CourseData>;

  model: Partial<LogbookEntry<CourseData>> = null;

  curriculum: Curriculum = null;

  @Validations()
  validations = {
    model: {
      date: { required },
      data: {
        name: { required },
      },
      attachments: {
        ready: (attachments: Attachment[]) =>
          attachments.every((attachment: MaybeUploading<Attachment>) => !attachment.uploading),
      },
    },
  };

  get logbookEntriesService() {
    return new LogbookEntriesService<ProcedureData>();
  }

  get selectedResidency(): Residency {
    return this.model && this.model.residency_id
      ? this.$currentUser.residencies.find(({ id }) => id === this.model.residency_id)
      : null;
  }

  created() {
    this.model = this.logbookEntry
      ? cloneDeep(this.logbookEntry)
      : {
          entry_type: "course",
          date: dayjs().format("YYYY-MM-DD"),
          end_date: null,
          attachments: [],
          data: {
            name: null,
            comments: null,
          },
          residency_id: this.$currentUser.currentResidency ? this.$currentUser.currentResidency.id : null,
        };

    this.curriculum = (this.logbookEntry && this.logbookEntry.curriculum) || null;
  }

  async onSubmit() {
    this.$v.$touch();
    if (!this.$v.$error) {
      const action = this.model.id ? updateLogbookEntry(this.model) : addLogbookEntry(this.model);
      await showDialog(LoadingIndicatorDialog, { action });
      this.$emit("ok");
    }
  }

  selectCurriculum(id: number) {
    this.curriculum = (this.selectedResidency && this.selectedResidency.curriculums)?.filter(
      (curriculum) => curriculum.id === id
    )[0];
  }
}
</script>
