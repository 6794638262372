export default interface PlanPrice {
  price_id: string;
  price: number;
  currency: string;
  period: "month" | "year";
  trial_period_days: number;
}

export const monthlyPrice = ({ period, price }: PlanPrice) => {
  return period === "year" ? price / 12 : price;
};

export const annualPrice = ({ period, price }: PlanPrice) => {
  return period === "month" ? price * 12 : price;
};

export const priceToDisplay = (price: PlanPrice) => {
  const priceStr = (price.price / 100).toFixed(2);
  if (price.currency === "USD") {
    return `$${priceStr}`;
  } else {
    return `${priceStr} €`;
  }
};

export const countDiscountPercentage = (price: PlanPrice, comparisonPrice: PlanPrice) => {
  const discountRatio = comparisonPrice ? 1 - monthlyPrice(price) / monthlyPrice(comparisonPrice) : 0;
  return Math.round(discountRatio * 100);
};
