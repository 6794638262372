<template>
  <page>
    <page-header :title="$t('contents.title')" :back-button-route="backButtonRoute">
      <template #right>
        <div class="gap-4 hidden md:flex">
          <content-search class="w-96" type="contextual"></content-search>
          <add-content-button></add-content-button>
        </div>
      </template>
    </page-header>
    <page-toolbar>
      <div class="flex gap-4 md:hidden">
        <content-search class="flex-1" type="contextual"></content-search>
        <add-content-button></add-content-button>
      </div>
      <contents-primary-filters
        class="mt-4 sm:mt-0"
        :value="filters"
        :user="$currentUser"
        :customer-organization="filterOrganization"
        @input="updateFilters"
      ></contents-primary-filters>
    </page-toolbar>
    <page-content :class="`page-content-${viewMode}`">
      <scroll-detector v-if="loaded" @end="loadMore">
        <div class="flex gap-2 items-center mb-4 -mt-3">
          <div class="flex-1">
            <badge v-if="contentItems && filters.q && filters.q.length > 0" class="pr-1" variant="info">
              <template #label>
                <span class="mr-1 hidden sm:inline">
                  {{ contentItems.length > 0 ? $t("search.results_for") : $t("search.no_results_for") }}
                </span>
                <span>"{{ filters.q }}"</span>
              </template>
              <template #trailing>
                <icon-fluent
                  class="h-3 ml-2 cursor-pointer"
                  name="dismiss"
                  size="16"
                  variant="filled"
                  @click="clearSearch()"
                ></icon-fluent>
              </template>
            </badge>
          </div>
          <sort-dropdown :sort="filters.sort" :options="sortOptions" @update="updateSort"></sort-dropdown>
          <view-mode-toggle v-model="viewMode"></view-mode-toggle>
        </div>
        <component
          :is="viewMode"
          :content-items="transformedContentItems"
          @favoriteToggled="onFavoriteToggled"
        ></component>
      </scroll-detector>
      <loader-big v-if="loading" class="mt-lg"></loader-big>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import i18n from "@/i18n";
import Organization from "@/model/organization";
import ContentItemsService from "@/services/content-items-service";
import ContentItem, { getContentItemOwnerName, getSpecialityLabels } from "@/model/content-item";
import ContentsGrid from "@/pages/contents/contents-grid.vue";
import ContentsTable from "@/pages/contents/contents-table.vue";
import ContentSearch from "@/pages/contents/content-search.vue";
import ContentsPrimaryFilters from "@/pages/contents/partials/contents-primary-filters.vue";
import _ from "lodash";
import { Prop, Watch } from "vue-property-decorator";
import { loadContentItems, searchContentItems, setContentsViewMode } from "@/actions";
import { getActiveThemePreviewPlaceholder } from "@/theme";
import { SortOption } from "@/components/sort-dropdown.vue";
import ContentItemFilters, { buildEmptyFilters } from "@/model/content-item-filters";
import { SET_CONTENT_FILTERS } from "@/store/mutations";
import { filePath } from "@/utils/paths";

@Component({
  components: {
    ContentsGrid,
    ContentsTable,
    ContentSearch,
    ContentsPrimaryFilters,
  },
  name: "ContentsPage",
})
export default class ContentsPage extends Vue {
  @Prop()
  back: string;

  contentItemsService = new ContentItemsService();

  get backButtonRoute() {
    console.log(this.back);
    return this.back && this.back.length > 0 && { name: this.back };
  }

  get viewMode() {
    return this.$store.state.contentsView.viewMode;
  }

  get filters(): ContentItemFilters {
    let filters = this.$store.state.contentsView.filters;

    // Check if filters are valid
    if (!filters || typeof this.$store.state.contentsView.filters !== "object") {
      filters = buildEmptyFilters();
    }

    // Check if filters contain owner_user_ids and organization_ids
    if (
      !("owner_user_ids" in this.$store.state.contentsView.filters) ||
      !("organization_ids" in this.$store.state.contentsView.filters)
    ) {
      filters = { ...buildEmptyFilters(), ...filters };
    }

    // Set default filters
    if (filters.owner_user_ids.length === 0 && filters.organization_ids.length === 0 && !filters.shared) {
      filters = {
        ...filters,
        owner_user_ids: [this.$currentUser.id],
        organization_ids: this.$currentUser.organizations.map((org: Organization) => org.id),
        shared: true,
      };
    }
    if (filters !== this.$store.state.contentsView.filters) {
      this.updateFilters(filters);
    }
    return filters;
  }

  get contentItems(): ContentItem[] {
    return this.$store.state.contents.contentItems;
  }

  get loading(): boolean {
    return this.$store.state.contents.loading;
  }

  get loaded() {
    return !!this.contentItems;
  }

  get sortOptions(): SortOption[] {
    const options = [
      { value: "name", label: this.$t("attributes.name") },
      this.filters.q?.length > 0
        ? {
            value: "rank",
            label: this.$t("attributes.relevance"),
          }
        : null,
      { value: "created_at", label: this.$t("attributes.date_added_oldest") },
      { value: "-created_at", label: this.$t("attributes.date_added_newest") },
      { value: "-updated_at", label: this.$t("attributes.last_updated") },
    ];

    return _.compact(options);
  }

  set viewMode(value) {
    setContentsViewMode(value);
  }

  get filterOrganization(): Organization {
    if (this.$currentUser.isResident()) {
      return this.$currentUser.currentResidency?.customer_organization ?? null;
    } else if (this.$currentUser.isHospitalAdmin()) {
      return this.$currentUser.customer_organization;
    }
    return null;
  }

  get transformedContentItems() {
    return this.contentItems.map((contentItem) => ({
      ...contentItem,
      preview_image_url: this.previewImage(contentItem),
      speciality_labels: getSpecialityLabels(contentItem),
      owner: getContentItemOwnerName(contentItem),
      language_name: contentItem.language ? this.$t(`languages.${contentItem.language}`) : "-",
    }));
  }

  previewImage(contentItem: ContentItem) {
    return contentItem.preview_image ? filePath(contentItem.preview_image, "small") : this.previewImagePlaceholder;
  }

  get previewImagePlaceholder() {
    return getActiveThemePreviewPlaceholder();
  }

  updateFilters(filters: ContentItemFilters) {
    this.$store.commit(SET_CONTENT_FILTERS, filters);
  }

  updateSort(sort) {
    const filters: ContentItemFilters = {
      ...this.filters,
      sort,
    };
    this.updateFilters(filters);
  }

  contentStatusLabel(status: string) {
    return i18n.t("status." + status);
  }

  async created() {
    return this.queryContents();
  }

  onFavoriteToggled(contentItem: ContentItem) {
    // Call value update on backend
    if (contentItem.favorited_by_current_user) {
      this.contentItemsService.unfavorite(contentItem);
    } else {
      this.contentItemsService.favorite(contentItem);
    }

    // Update value in currently loaded ContentItem
    const existingIndex = this.contentItems.findIndex((ci) => ci.id === contentItem.id);
    Vue.set(this.contentItems, existingIndex, {
      ...contentItem,
      favorited_by_current_user: !contentItem.favorited_by_current_user,
    });
  }

  @Watch("$currentUser.content_languages")
  async onContentLanguagesChange() {
    return this.queryContents();
  }

  @Watch("filters", { deep: true })
  queryContents() {
    return loadContentItems(true);
  }

  loadMore() {
    if (!this.loading) {
      return loadContentItems();
    }
  }

  clearSearch() {
    searchContentItems(null);
  }
}
</script>

<style lang="scss" scoped>
.page-content::v-deep {
  .content {
    display: flex;
    flex-direction: column;
  }

  &[class*="page-content-contents-table"] {
    @media (max-width: $screen-xs-max) {
      overflow-y: visible;
    }
  }
}

.page-content-contents-table {
  @media (min-width: $screen-sm-min) {
    .header {
      &::after {
        display: none;
      }
    }
  }
}

.page-content-contents-table::v-deep {
  padding-bottom: 0;

  .content {
    min-height: calc(100% - 45px);
  }
}

.list-toolbar {
  display: flex;
  flex-direction: row;
  padding: var(--spacing);

  &:not(:last-child) {
    padding-bottom: 0;
  }

  > *:not(:first-child) {
    margin-left: var(--spacing-sm);
  }

  > *:not(:last-child) {
    margin-right: var(--spacing-sm);
  }
}

.filters-bar {
  flex-basis: calc(25% - 1.3em);

  @media (min-width: $screen-sm-min) {
    flex-basis: calc(33.33333333% - 1rem);
  }

  @media (min-width: $screen-xl-min) {
    flex-basis: calc(25% - 1.3em);
  }
}
</style>
