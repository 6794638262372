import Organization from "@/model/organization";
import Page from "@/model/page";
import PageType from "@/model/page-type";
import Residency from "@/model/residency";
import Subscription from "@/model/subscription";
import Role from "@/model/role";
import UserRole from "@/model/user-role";
import Attachment from "@/model/attachment";
import Quiz from "@/model/quiz";
import { Theme } from "@/theme";
import { compact, uniq } from "lodash";
import Speciality from "@/model/speciality";

export default class User {
  id: number;
  name: string;
  shortname: string;
  email: string;
  sign_up_code: string;
  handedness: any;
  language: string;
  content_languages: string[];
  theme: Theme;
  phone_number?: string;
  profile_picture?: Attachment;
  role: Role;
  roles: UserRole[];
  residencies: Residency[];
  subscriptions: Subscription[];
  customer_organization_id?: number;
  customer_organization?: Organization;
  speciality_id?: number;
  speciality?: Speciality;
  speciality_interests?: number[];
  position?: string;
  organization?: string;
  email_verified?: boolean;
  features?: Record<string, boolean>;
  password?: string;
  password_confirmation?: string;
  country?: string;
  verified_medical_professional?: boolean;
  authentication_token?: string;
  entry_count?: number;

  constructor(attrs = {}) {
    Object.assign(this, attrs);
  }

  get activeResidencies(): Residency[] {
    return this.residencies.filter(({ end_date }) => !end_date);
  }

  get currentResidency(): Residency {
    return this.activeResidencies[0];
  }

  get organizations(): Organization[] {
    const orgsFromResidencies = this.activeResidencies.map((residency) => residency.customer_organization);

    const orgsFromRoles = this.roles.filter((role) => role.name === "content_viewer").map((role) => role.resource);

    const organizations = [this.customer_organization, ...orgsFromResidencies, ...orgsFromRoles];

    return uniq(compact(organizations));
  }

  currentResidencyForOrganization(organization: Organization): Residency {
    return this.activeResidencies.find((residency) => residency.customer_organization_id === organization.id);
  }

  isResident() {
    return this.hasRole("resident");
  }

  isResidentWithEditPermission() {
    return (
      this.isResident() && this.activeResidencies.some((residency) => residency.permissions.includes("edit_content"))
    );
  }

  isTerminatedResident() {
    return this.residencies.length > 0 && this.activeResidencies.length == 0;
  }

  isAdmin() {
    return this.hasRole("osgenic_admin");
  }

  isContentAdmin() {
    return this.hasRole("content_admin");
  }

  isContentCreator() {
    return this.hasRole("content_creator");
  }

  isHospitalAdmin() {
    return this.hasRole("hospital_admin");
  }

  isSolo() {
    return this.hasRole("solo");
  }

  isOsgenicUser() {
    return this.isAdmin() || this.isContentAdmin() || this.isContentCreator();
  }

  isEditorUser() {
    return (
      this.isAdmin() ||
      this.isContentAdmin() ||
      this.isContentCreator() ||
      this.isHospitalAdmin() ||
      this.isResidentWithEditPermission()
    );
  }

  showEducation() {
    // At the moment there are no quizzes in English and solo users doesn't have
    // logbook, so we don't want to show the menu item at all.
    return !this.hasRole("solo") || this.content_languages.includes("fi");
  }

  canCreatePage(pageType: PageType) {
    return (
      this.isAdmin() ||
      this.isContentAdmin() ||
      this.isContentCreator() ||
      ((pageType == "general" || pageType == "basic_skill") &&
        (this.isHospitalAdmin() ||
          (this.isResident() &&
            this.activeResidencies.some((residency) => residency.permissions.includes("edit_content")))))
    );
  }

  canEditPageContent(page: Page) {
    return (
      this.isAdmin() ||
      this.isContentAdmin() ||
      this.isContentCreator() ||
      this.isHospitalAdmin() ||
      ((page.page_type == "general" || page.page_type == "basic_skill") &&
        this.isResident() &&
        this.activeResidencies.some((residency) => residency.permissions.includes("edit_content")))
    );
  }

  canCreateQuiz() {
    return (
      this.isAdmin() ||
      this.isContentAdmin() ||
      this.isContentCreator() ||
      this.isHospitalAdmin() ||
      (this.isResident() && this.activeResidencies.some((residency) => residency.permissions.includes("edit_content")))
    );
  }

  canEditQuiz(quiz: Quiz) {
    return (
      this.isAdmin() ||
      this.isContentAdmin() ||
      this.isContentCreator() ||
      (quiz.customer_organization &&
        this.isHospitalAdmin() &&
        this.customer_organization.id === quiz.customer_organization.id) ||
      (this.isResident() &&
        this.activeResidencies.some(
          (residency) =>
            residency.customer_organization_id === quiz.customer_organization_id &&
            residency.permissions.includes("edit_content")
        ))
    );
  }

  canPublishQuiz(quiz: Quiz) {
    return (
      this.isAdmin() ||
      this.isContentAdmin() ||
      (this.isHospitalAdmin() &&
        ((quiz.customer_organization && quiz.customer_organization.id === this.customer_organization.id) ||
          (quiz.customer_organization_id && quiz.customer_organization_id == this.customer_organization.id)))
    );
  }

  hasRole(role: Role) {
    return this.role == role;
  }
}
