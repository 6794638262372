<template>
  <modal>
    <form @submit.prevent="onSubmit">
      <modal-header :title="$t('actions.change_password')"></modal-header>
      <modal-body>
        <form-field :label="$t('attributes.password')" :error="$v.password.$error || old_password_error">
          <input v-model="password" type="password" />
          <div v-if="old_password_error" class="text-xs text-semantic-error">
            {{ $t("passwords.change.error_check_old") }}
          </div>
        </form-field>
        <password-checker ref="new_password_form"></password-checker>
      </modal-body>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import Users from "@/services/users";
import Vue from "vue";
import Component from "vue-class-component";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { ForbiddenError } from "@/api/api-error";
import PasswordChecker from "./form/password-checker.vue";
import { Ref } from "vue-property-decorator";
import { showDialog } from "@/utils/dialogs";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";

@Component({})
export default class ChangePasswordModal extends Vue {
  @Ref("new_password_form")
  newPasswordForm: PasswordChecker;

  password: String = "";
  old_password_error: Boolean = false;

  @Validations()
  validations = {
    password: { required },
  };

  get userService() {
    return new Users();
  }

  get title() {
    return "Change password";
  }

  async onSubmit() {
    this.$v.$touch();
    this.newPasswordForm.$v.$touch();
    if (!this.$v.$error && !this.newPasswordForm.$v.$error) {
      const action = this.userService
        .changePassword(
          this.$store.getters.currentUser.id,
          this.password,
          this.newPasswordForm.newPassword,
          this.newPasswordForm.confirmNewPassword
        )
        .then((response) => {
          // if (response === 200) // response gets handled by ApiService, so no error === response OK
          this.$emit("ok", response);
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((e: ForbiddenError) => {
          this.old_password_error = true;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((e: Error) => {
          this.old_password_error = true;
        });

      showDialog(LoadingIndicatorDialog, { action });
    }
  }
}
</script>
