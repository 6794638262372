<template>
  <dropdown-select
    :value="value"
    :options="options"
    :placeholder="$t('attributes.your_role')"
    :width-by-content="false"
    button-class="rounded-xs"
    :error="error"
    :floating-label="floatingLabel"
    @input="onInput"
  ></dropdown-select>
</template>

<script lang="ts">
import { getLocale } from "@/i18n";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DropdownSelectOption from "@/model/dropdown-select-option";

@Component({})
export default class PositionSelect extends Vue {
  @Prop()
  value: string;

  @Prop({ default: false })
  error: boolean;

  @Prop({ default: false })
  floatingLabel: boolean;

  get options(): DropdownSelectOption[] {
    // For finnish language don't show Fellow
    const allowedPositions =
      getLocale() === "fi"
        ? ["student", "resident", "attending", "other"]
        : ["student", "resident", "fellow", "attending", "other"];

    return allowedPositions.map((value) => ({
      value,
      label: this.$t("positions." + (value === "other" ? "other_position" : value)) as string,
    }));
  }

  onInput(value) {
    this.$emit("input", value);
  }
}
</script>
