<template>
  <div class="image-input">
    <div v-if="value && value.length > 0" class="images">
      <div v-for="attachment in value" :key="attachment.id" class="image" :class="{ invalid: error }">
        <img :src="attachment.url" />
        <div class="remove-button" @click="remove(attachment)">
          <icon icon="times-circle"></icon>
        </div>
        <div v-if="attachment.uploading" class="opacity-overlay">
          <loader-big></loader-big>
        </div>
      </div>
    </div>
    <div v-if="!disabled" class="add-image">
      <input-list-item icon="plus-circle" label="Add image..." :error="error" :removable="false"></input-list-item>
      <input ref="fileInput" type="file" :disabled="disabled" @input="onInput" />
      <div v-if="disabled" class="opacity-overlay"></div>
    </div>
  </div>
</template>

<script lang="ts">
import UploadService from "@/services/upload-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import Attachment from "@/model/attachment";
import MaybeUploading from "@/model/maybe-uploading";
import { v4 as uuidv4 } from "uuid";

@Component({})
export default class ImageInput extends Vue {
  @Ref() readonly fileInput: HTMLInputElement;

  @Prop()
  value: MaybeUploading<Attachment>[];

  @Prop()
  maxCount: number;

  @Prop()
  error: any;

  get uploadService() {
    return new UploadService();
  }

  get disabled() {
    return this.maxCount && this.maxCount <= this.value.length;
  }

  previewImageStyle(image: Attachment) {
    return `background: url(${image.url}) center center; background-size: cover;`;
  }

  remove(attachment) {
    const attachments = this.value.filter((each) => each !== attachment);
    this.$emit("input", attachments);
  }

  async onInput() {
    if (this.fileInput.files.length > 0) {
      await this.upload(this.fileInput.files[0]);
    }
  }

  async upload(file: File) {
    const tempId = uuidv4();
    const tempUrl = await this.toDataURL(file);
    const tempAttachment: MaybeUploading<Attachment> = { filename: file.name, url: tempUrl, tempId, uploading: true };
    this.$emit("input", [...this.value, tempAttachment]);

    let attachments;
    try {
      const upload = await this.uploadService.uploadFile(file);
      attachments = this.value.map((attachment) =>
        (attachment as MaybeUploading<Attachment>).tempId === tempId ? upload : attachment
      );
    } catch (err) {
      console.error(err);
      attachments = this.value.filter((attachment) => (attachment as any).tempId !== tempId);
    }
    if (this.fileInput) {
      this.fileInput.value = null;
    }

    this.$emit("input", attachments);
  }

  toDataURL(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
</script>

<style lang="scss" scoped>
.images {
  width: $input-width;
  display: flex;
  margin-bottom: $spacing-sm;

  .image {
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: $border-radius-sm;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ui-color-surface-mid-contrast);

    &.invalid {
      border: 1px solid var(--color-error);
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .remove-button {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .icon {
        color: $color-error;
        padding: $spacing-xs;
        margin: 0;
      }
    }

    @media (hover: none), (pointer: coarse) {
      .remove-button {
        display: flex;
      }
    }

    &:hover .remove-button {
      display: flex;
    }
  }
}
.add-image {
  position: relative;
  cursor: pointer;

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.opacity-overlay {
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
