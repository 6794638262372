<template>
  <div class="radio-select pl-xs flex" :class="horizontal ? 'flex-row' : 'flex-col'">
    <label
      v-for="option in options"
      :key="option.label"
      class="flex items-center cursor-pointer hover:text-text-primary"
      :class="`${horizontal ? 'mr-md' : 'mb-xs'}${option.value == value ? ' active' : ''}`"
    >
      <checkbox
        type="radio"
        :name="name"
        :value="option.value"
        :checked="option.value == value"
        @change="$emit('input', option.value)"
      ></checkbox>
      <div class="ml-sm">{{ option.label }}</div>
    </label>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";
import { TranslateResult } from "vue-i18n";

export type RadioSelectOption<T extends string | number | symbol | boolean> = {
  value: T;
  label: string | TranslateResult;
};

@Component({})
export default class RadioSelect<T extends string | number | symbol | boolean> extends Vue {
  @Prop()
  options: RadioSelectOption<T>[];

  @Prop()
  value: T;

  @Prop({ default: false })
  horizontal: boolean;

  name: string = null;

  created() {
    this.name = uuidv4();
  }
}
</script>
