import { textContent } from "@/editor/utils/nodes";
import { Content, JSONContent } from "@tiptap/core";


export interface TableOfContentsNode {
  uid: string;
  title: string;
  children: TableOfContentsNode[];
  numberedTitle: boolean;
}

export const buildTableOfContents = (contentDoc: JSONContent): TableOfContentsNode[] => {
  const nodes: TableOfContentsNode[] = [];

  let currentNode: TableOfContentsNode = null;

  contentDoc.content.forEach((contentNode: JSONContent) => {
    if(!contentNode.attrs?.uid) {
      return;
    }
    
    if (contentNode.type === 'heading') {
      if(contentNode.attrs.level === 1) {
        const node = {
          uid: contentNode.attrs.uid,
          title: textContent(contentNode),
          children: [],
          numberedTitle: false
        };
        nodes.push(node);
        currentNode = node;
      }
      else if(contentNode.attrs.level === 2) {
        const node = {
          uid: contentNode.attrs.uid,
          title: textContent(contentNode),
          children: [],
          numberedTitle: false
        }
        if(currentNode) {
          currentNode.children.push(node);
        }
      }
    }
    else if(contentNode.type === 'step'){
      const node = {
        uid: contentNode.attrs.uid,
        title: textContent(contentNode.content[0]),
        children: [],
        numberedTitle: true
      }
      if(currentNode) {
        currentNode.children.push(node);
      }
    }
  });
  return nodes;
};
