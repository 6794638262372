<template>
  <node-view-wrapper as="span">
    <span data-drag-handle="">
      <link-card v-if="node.attrs.card" :link="node.attrs"></link-card>
      <a v-else class="link" :href="node.attrs.url" target="_blank">{{ node.attrs.title }}</a>
    </span>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";

@Component({
  components: { NodeViewWrapper },
  props: nodeViewProps,
})
export default class LinkNodeView extends Vue {
  @Prop()
  node: any;
}
</script>
