import LinkData from "@/model/link-data";
import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import LinkNodeView from "@/editor/extensions/external-link/external-link-node-view.vue";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    externalLink: {
      addLink: (link: LinkData) => ReturnType;
    };
  }
}

const ExternalLinkNode = Node.create({
  name: "externalLink",

  inline: true,

  group: "inline",

  draggable: true,

  addAttributes() {
    return {
      url: {},
      title: {},
      card: { default: false },
      image: { default: null },
      description: { default: null },
      site_name: { default: null },
    };
  },

  addCommands() {
    return {
      addLink: (link: LinkData) => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: link,
        });
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "external-link-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["external-link-node", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(LinkNodeView);
  },
});

export default ExternalLinkNode;
