<template>
  <div v-outside-click="close" class="sm:relative">
    <search-input :value="search" v-bind="$attrs" @input="updateSearch" @reset="clear" @enter="showMore"></search-input>
    <div v-if="open" class="absolute left-0 right-0 px-4 mt-xs z-10 sm:right-[unset] sm:px-0 sm:top-full">
      <div class="flex flex-col gap-4 search-results bg-surface-min-contrast rounded p-md w-full sm:w-96">
        <content-search-item
          v-for="item in results"
          :key="item.id"
          :item="item"
          :search-query="search"
          @click.native="clear"
        ></content-search-item>
        <button class="btn-tertiary btn-sm" @click="showMore">{{ $t("actions.show_more") }}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ContentItemsService from "@/services/content-items-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ContentSearchItem from "@/pages/contents/content-search-item.vue";
import { searchContentItems } from "@/actions";
import { SET_CONTENT_SEARCH_QUERY } from "@/store/mutations";
import { AbortError } from "@/api/api-error";
import ContentItem from "@/model/content-item";

@Component({
  components: {
    ContentSearchItem,
  },
})
export default class ContentSearch extends Vue {
  @Prop({ default: 2 })
  minSearchLength: number;

  open = false;

  abortController: AbortController = null;

  results: ContentItem[] = [];

  get contentItemsService() {
    return new ContentItemsService();
  }

  get search() {
    return this.$store.state.contentSearch.query;
  }

  updateSearch(value) {
    this.$store.commit(SET_CONTENT_SEARCH_QUERY, value);
    this.onSearch();
  }

  onSearch() {
    if (this.search.length >= this.minSearchLength) {
      return this.loadContentItems();
    } else {
      this.results = [];
      this.open = false;
    }
  }

  async loadContentItems() {
    // Abort previous request
    this.abortController?.abort();

    try {
      this.abortController = new AbortController();
      const params: any = {
        q: this.search,
        limit: 5,
      };
      const languages = this.$store.getters.currentUser.content_languages;
      if (languages) {
        params.languages = languages;
      }
      this.results = await this.contentItemsService.getAll({
        params,
        signal: this.abortController.signal,
      });

      this.open = this.search.length >= this.minSearchLength && this.results.length > 0;
    } catch (err) {
      if (!(err instanceof AbortError)) {
        throw err;
      }
    }
  }

  close() {
    this.open = false;
  }

  clear() {
    this.updateSearch("");
    this.close();
  }

  showMore() {
    // Abort pending request
    this.abortController?.abort();

    // Trigger search for contents page
    searchContentItems(this.search);

    this.close();
    this.$emit("search", this.search);
  }
}
</script>

<style lang="scss" scoped>
.search-results {
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
}
</style>
