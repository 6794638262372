import AnalyticsMediaEvent from "@/model/analytics-media-event";

export default class SnowplowService {
  // Snowplow analytics tool is loaded via Google Tag manager, hence it's not referred in source code
  // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/javascript-tracker/google-tag-manager-custom-template/

  // data schemas are defined and managed in Osgenic's Snowplow Iglu schema repository https://docs.snowplow.io/docs/pipeline-components-and-applications/iglu/
  getMediaSchema() {
    return "iglu:com.osgenic/media_event/jsonschema/1-0-1";
  }

  setUserId(userId: string) {
    if (window.snowplow) {
      return snowplow('setUserId', userId);
    }
  }

  trackPageView() {
    if (window.snowplow) {
      return snowplow("trackPageView");
    }
  }

  trackVideoEvent(mediaEvent: AnalyticsMediaEvent) {
    if (window.snowplow) {
      return snowplow("trackSelfDescribingEvent", {
        event: {
          schema: this.getMediaSchema(),
          data: mediaEvent,
        },
      });
    }
  }
}
