<template>
  <div class="media-library h-full">
    <input ref="fileInput" class="hidden" type="file" multiple @input="onFileInput" />
    <div class="flex flex-col h-full" @dragover.prevent @dragenter.prevent @drop.prevent="onDrop">
      <div class="flex flex-row items-center">
        <button class="btn-primary" @click="uploadMedia">
          <icon-fluent class="h-4 w-4 mr-xs" name="add" variant="filled"></icon-fluent>{{ $t("actions.upload") }}
        </button>
        <search-input
          v-model="search"
          class="max-w-md ml-md hidden sm:inline-block"
          @reset="search = ''"
        ></search-input>
        <dropdown-select
          v-if="showFileTypes"
          v-model="type"
          class="ml-md"
          :default-title="$t('attributes.type')"
          :options="typeOptions"
        ></dropdown-select>
        <div class="flex-1"></div>
        <div class="hidden sm:flex">
          <toggle-button icon="gridKanban" :value="view === 'media-grid'" @input="view = 'media-grid'"></toggle-button>
          <toggle-button icon="appsList" :value="view === 'media-list'" @input="view = 'media-list'"></toggle-button>
        </div>
      </div>
      <div class="my-md flex-1 overflow-hidden">
        <div v-if="mediaFiles && mediaFiles.length == 0">{{ $t("media_library.empty_library_text") }}</div>
        <component
          :is="view"
          v-if="mediaFiles && mediaFiles.length > 0"
          :media-files="mediaFiles"
          :selected-files="value"
          :sort="sort"
          :sort-direction="sortDirection"
          @select="onSelect"
          @select-one="onSelectOne"
          @update-sort="sort = $event"
          @update-sort-direction="sortDirection = $event"
          @delete="onDelete"
          @load-more="loadMore"
        ></component>
        <loader-big v-if="loading"></loader-big>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import MediaFilesService from "@/services/media-files-service";
import MediaFile from "@/model/media-file";
import MaybeUploading, { makeMaybeUploading } from "@/model/maybe-uploading";
import MediaGrid from "@/components/media-library/media-grid.vue";
import MediaList from "@/components/media-library/media-list.vue";
import { isoDateTime } from "@/utils/formatting";
import { uploadFileToMediaLibrary } from "@/actions";
import DropdownSelectOption from "@/model/dropdown-select-option";
import { showDialog } from "@/utils/dialogs";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import { isTouchDevice } from "@/utils/device";

export type MediaLibrarySort = "name" | "type" | "date";

export type MediaLibrarySortDirection = "asc" | "desc";

@Component({
  components: {
    MediaGrid,
    MediaList,
  },
})
export default class MediaLibrary extends Vue {
  @Ref() readonly fileInput: HTMLInputElement;

  @Prop()
  value: MediaFile[];

  @Prop({ default: true })
  showFileTypes: boolean;

  @Prop()
  fileType: string;

  @Prop({ default: false })
  allowMultiselect: boolean;

  mediaFiles: MaybeUploading<MediaFile>[] = null;

  type: string = null;

  search = "";

  view: "media-grid" | "media-list" = "media-grid";

  sort: MediaLibrarySort = "date";

  sortDirection: MediaLibrarySortDirection = "desc";

  loading = false;

  readonly limit = 20;

  get typeDropdownTitle() {
    return this.$t("attributes.type");
  }

  get typeOptions(): DropdownSelectOption[] {
    return [{ value: null, label: this.$t("terms.all") as string }].concat(
      ["image", "video", "file"].map((value) => ({ value, label: this.$t("file_types." + value) as string }))
    );
  }

  get searchParams() {
    const params: any = {};

    if (this.type) {
      params.file_type = this.type;
    }

    let sort = {
      date: "created_at",
      name: "file_name",
      type: "file_type",
    }[this.sort];
    if (this.sortDirection === "desc") {
      sort = `-${sort}`;
    }
    params.sort = sort;

    const search = this.search.trim().toLowerCase();
    if (search.length >= 2) {
      params.q = search;
    }

    return params;
  }

  async created() {
    this.type = this.fileType;
  }

  onSelect(mediaFile: MediaFile, event: PointerEvent) {
    event.preventDefault();

    if (this.allowMultiselect && (event.ctrlKey || event.metaKey || isTouchDevice())) {
      // Select/deselect file
      if (this.value.includes(mediaFile)) {
        this.removeFromSelection(mediaFile);
      } else {
        this.addToSelection(mediaFile);
      }
    } else if (this.allowMultiselect && event.shiftKey) {
      this.addRangeToSelection(this.value[0], mediaFile);
    } else {
      this.$emit("input", [mediaFile]);
    }

    // Prevents user (text) selection
    window.getSelection().empty();
  }

  onSelectOne(mediaFile: MediaFile) {
    this.$emit("input", [mediaFile]);
    this.$emit("select");

    // Prevents user (text) selection
    window.getSelection().empty();
  }

  addToSelection(mediaFile: MediaFile) {
    this.$emit("input", [...this.value, mediaFile]);
  }

  removeFromSelection(mediaFile: MediaFile) {
    this.$emit(
      "input",
      this.value.filter((f) => f !== mediaFile)
    );
  }

  addRangeToSelection(fromFile: MediaFile, toFile: MediaFile) {
    const startIndex = this.mediaFiles.findIndex((f) => f === fromFile);
    const endIndex = this.mediaFiles.findIndex((f) => f === toFile);
    const selectedFiles =
      startIndex > endIndex
        ? this.mediaFiles.slice(endIndex, startIndex + 1).reverse()
        : this.mediaFiles.slice(startIndex, endIndex + 1);

    this.$emit("input", selectedFiles);
  }

  uploadMedia() {
    this.fileInput.click();
  }

  onDrop(evt: DragEvent) {
    this.fileInput.files = evt.dataTransfer.files;
    return this.onFileInput();
  }

  async onDelete(mediaFile: MediaFile) {
    const sure = await showDialog<boolean>(ConfirmationDialog, { title: this.$t("confirmation.delete_permanently") });

    if (!sure) {
      return;
    }

    await new MediaFilesService().delete(mediaFile.id);

    this.mediaFiles = this.mediaFiles.filter((it) => it !== mediaFile);
  }

  async onFileInput() {
    try {
      return await Promise.all(Array.from(this.fileInput.files).map((file) => this.handleFileUpload(file)));
    } catch (error) {
      const message = this.$t("errors.upload_error") as string;
      this.$toast.error(message, { message, position: "top-right", duration: 10000, dismissible: true });
    }
  }

  private async handleFileUpload(file: File) {
    const file_name = file.name;
    let file_type = file.type;

    // Push temp media file to file list to show uploading indicator
    const tempMediaFile = makeMaybeUploading<MediaFile>({
      file_name,
      file_type,
      created_at: isoDateTime(),
    } as MediaFile);
    this.mediaFiles = [tempMediaFile, ...this.mediaFiles];

    const mediaFile = await uploadFileToMediaLibrary(file);

    // Replace temp file in list
    this.mediaFiles = this.mediaFiles.map((it) => (it.tempId === tempMediaFile.tempId ? mediaFile : it));
  }

  loadMore() {
    if (!this.loading && this.mediaFiles?.length >= this.limit) {
      return this.loadMediaFiles();
    }
  }

  async loadMediaFiles() {
    this.loading = true;

    const params = { ...this.searchParams, limit: this.limit, offset: this.mediaFiles?.length || 0 };

    const mediaFiles = await new MediaFilesService().getAll({
      params,
    });

    this.mediaFiles = [...(this.mediaFiles || []), ...mediaFiles];

    this.loading = false;
  }

  @Watch("searchParams")
  onSearchParamsChanged() {
    this.mediaFiles = null;
    return this.loadMediaFiles();
  }
}
</script>
