<template>
  <div
    v-if="nodes && nodes.length > 0"
    class="bg-interactive-bg-neutral-low-contrast shadow-md px-6 rounded shrink-0 absolute top-0 w-full flex flex-col toc:px-4 toc:pt-6 toc:w-auto toc:min-w-[276px] toc:max-w-sm toc:bg-surface-min-contrast toc:shadow-none toc:sticky"
    :style="style"
  >
    <div class="flex items-center py-3 toc:py-0" @click="toggleOpen">
      <icon-fluent class="h-4 w-auto text-text-variant mr-2 toc:hidden" name="appsList"></icon-fluent>
      <h3 class="flex-1 text-button-small text-text-variant mb-0 toc:px-2 toc:text-xl toc:text-text-variant">
        {{ $t("table_of_contents.title") }}
      </h3>
      <icon-fluent
        class="h-4 w-auto text-text-variant ml-2 toc:hidden"
        :name="open ? 'chevronUp' : 'chevronDown'"
        size="16"
        variant="filled"
      ></icon-fluent>
    </div>
    <div
      class="pt-4 pb-6 max-h-96 overflow-y-auto flex-1 toc:block toc:max-h-[9999px]"
      :class="open ? 'block' : 'hidden'"
    >
      <div v-for="node in nodes" :key="node.uid" class="flex flex-col px-2 toc:px-4">
        <a
          class="py-1 text-text-primary text-body-medium"
          :href="`#${node.uid}`"
          :class="{ active: activeNodeId == node.uid }"
          @click.prevent="scrollTo(node)"
        >
          {{ node.title }}
        </a>
        <div v-if="node.children && node.children.length > 0" class="flex flex-col py-2">
          <a
            v-for="(child, idx) in node.children"
            :key="child.uid"
            class="py-2 ml-1 border-l px-2 text-text-primary text-body-small first:pt-1 last:pb-1"
            :href="`#${child.uid}`"
            :class="{ active: activeNodeId == child.uid }"
            @click.prevent="scrollTo(child)"
          >
            <div class="flex">
              <div v-if="child.numberedTitle" class="w-5 shrink-0">{{ idx + 1 }}.</div>
              {{ child.title }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { JSONContent } from "@tiptap/core";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TableOfContentsNode, buildTableOfContents } from "@/pages/page/table-of-contents";

@Component({})
export default class TableOfContentsBlock extends Vue {
  @Prop()
  doc: JSONContent;

  open: boolean = false;

  // eslint-disable-next-line no-undef
  tocElements: NodeListOf<Element> = null;

  activeNodeId = null;

  style = null;

  get nodes(): TableOfContentsNode[] {
    return buildTableOfContents(this.doc);
  }

  toggleOpen() {
    this.open = !this.open;
  }

  scrollTo(node: TableOfContentsNode) {
    this.open = false;
    const el = document.getElementById(node.uid);
    if (el) {
      this.scrollToElement(el);
    }
  }

  mounted() {
    this.setStyle();

    // Wait that the editor is added to the DOM
    this.$nextTick(() => {
      const container = document.querySelector(".document .ProseMirror");
      this.tocElements = container?.querySelectorAll("h1[id], h2[id], section.step[id]");
      this.updateActiveNodeId();
      window.addEventListener("scroll", this.updateActiveNodeId);
    });
  }

  destroyed() {
    window.removeEventListener("scroll", this.updateActiveNodeId);
  }

  setStyle() {
    if (window.innerWidth >= 992 && this.$el.getBoundingClientRect) {
      // Set top to initial position when sticky
      const rect = this.$el.getBoundingClientRect();
      const top = rect.top;
      const maxHeight = window.innerHeight - top - 20;

      this.style = {
        top: `${top}px`,
        maxHeight: `${maxHeight}px`,
        overflow: "hidden",
      };
    }
  }

  updateActiveNodeId() {
    if (this.tocElements) {
      const scrollTop = window.scrollY;
      for (let i = this.tocElements.length - 1; i >= 0; i--) {
        const el = this.tocElements[i] as HTMLElement;
        if (el.offsetTop <= scrollTop) {
          this.activeNodeId = el.id;
          break;
        }
      }
    }
  }

  scrollToElement(el: HTMLElement) {
    window.removeEventListener("scroll", this.updateActiveNodeId);

    window.scroll({ behavior: "smooth", top: el.offsetTop, left: 0 });

    this.activeNodeId = el.id;

    this.waitForScrollToEnd().then(() => {
      window.addEventListener("scroll", this.updateActiveNodeId);
    });
  }

  waitForScrollToEnd(): Promise<void> {
    return new Promise((resolve) => {
      const scrollY = window.scrollY;
      setTimeout(() => {
        if (scrollY == window.scrollY) {
          resolve();
        } else {
          this.waitForScrollToEnd().then(resolve);
        }
      }, 100);
    });
  }
}
</script>

<style lang="scss" scoped>
a.active {
  color: var(--ui-color-text-emphasis);
  font-weight: 700;
}

@media (hover: hover) {
  a:hover {
    color: var(--ui-color-text-emphasis);
    font-weight: 700;
  }
}
</style>
