import { schema } from "normalizr";

export const attachment = new schema.Entity("attachments");

export const speciality = new schema.Entity("specialities");

export const curriculumItem = new schema.Entity("curriculumItems");

export const clinicalBlock = new schema.Entity("clinicalBlocks", {
  curriculumItems: [curriculumItem],
  speciality,
});

export const curriculum = new schema.Entity("curriculums", {
  speciality,
  clinical_blocks: [clinicalBlock],
});

export const organization = new schema.Entity("organizations", {
  curriculums: [curriculum],
});

export const residency = new schema.Entity("residencies", {
  customer_organization: organization,
  curriculums: [curriculum],
});

export const user = new schema.Entity("users", {
  profile_picture: attachment,
  residencies: [residency],
  customer_organization: organization,
});

export const comment = new schema.Entity("comments", {
  author: user,
});

export const logbookEntry = new schema.Entity("logbookEntries", {
  attachments: [attachment],
  curriculum_item: curriculumItem,
});

export default {
  users: [user],
  residencies: [residency],
  attachments: [attachment],
  organizations: [organization],
  curriculums: [curriculum],
  specialities: [speciality],
  clinicalBlocks: [clinicalBlock],
  curriculumItems: [curriculumItem],
  logbookEntries: [logbookEntry],
  comments: [comment],
};
