<template>
  <span>{{ priceStr }}</span>
</template>

<script setup lang="ts">
import PlanPrice, { annualPrice, monthlyPrice } from "@/model/plan-price";
import { PropType } from "vue";
import { computed } from "vue";
import Decimal from "decimal.js";

const props = defineProps({
  tag: {
    type: String,
    default: "div",
  },
  period: {
    type: String,
    default: "month",
  },
  planPrice: {
    type: Object as PropType<PlanPrice>,
    required: true,
  },
  discountPercentage: {
    type: Number,
    default: 0,
  },
});

const price = computed(() => {
  const price = props.period === "year" ? annualPrice(props.planPrice) : monthlyPrice(props.planPrice);
  const discountedRatio = new Decimal(100).minus(props.discountPercentage || 0).div(100);
  return new Decimal(price).div(100).mul(discountedRatio).toNumber();
});

const priceStr = computed(() => {
  const fractions = price.value == Math.round(price.value) ? 0 : 2;
  const priceStr = price.value.toFixed(fractions);
  if (props.planPrice.currency === "USD") {
    return `$${priceStr}`;
  } else {
    return `€${priceStr}`;
  }
});
</script>

<style scoped></style>
