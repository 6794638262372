import ContentItem from "@/model/content-item";
import { SET_HOME_PAGE_LATEST_RELEASES, SET_HOME_PAGE_LATEST_RELEASES_LOADING } from "@/store/mutations";
import { RootState } from "@/store/store";
import { Module } from "vuex";

export type HomePageState = {
  latestReleases: ContentItem[];
  loadingLatestReleases: boolean;
};

export const buildInitialState: () => HomePageState = () => ({
  latestReleases: null,
  loadingLatestReleases: false,
});

export const mutations = {
  [SET_HOME_PAGE_LATEST_RELEASES_LOADING]: (state, loading) => Object.assign(state, { loadingLatestReleases: loading }),
  [SET_HOME_PAGE_LATEST_RELEASES]: (state, latestReleases) => Object.assign(state, { latestReleases }),
};

const homePageModule: Module<HomePageState, RootState> = {
  state: buildInitialState,
  mutations,
};

export default homePageModule;
