<template>
  <div class="loader-big">
    <div class="update-hack">X</div>
    <inline-svg
      class="svg-icon"
      :src="require('@/assets/images/loading-dots.svg')"
      :stroke="strokeColor"
      :fill="fillColor"
    ></inline-svg>
    <div class="update-hack">X</div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LoaderBig extends Vue {
  @Prop()
  fillColor: string;

  @Prop()
  outline: boolean;

  get strokeColor() {
    return this.outline ? "white" : undefined;
  }
}
</script>

<style lang="scss" scoped>
.loader-big {
  color: white;
  .update-hack {
    visibility: hidden;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
