<template>
  <select v-if="options" :value="value" @change="onChange">
    <option :value="null">{{ $t("attributes.not_selected") }}</option>
    <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
  </select>
  <input-skeleton v-else></input-skeleton>
</template>

<script lang="ts">
import Curriculum from "@/model/curriculum";
import CurriculumItem from "@/model/curriculum-item";
import CurriculumItemsService from "@/services/curriculum-items-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class CurriculumItemSelect extends Vue {
  @Prop()
  value: number;

  @Prop()
  type: string;

  @Prop()
  curriculum: Curriculum;

  curriculumItems: CurriculumItem<object>[] = null;

  get curriculumItemsService(): CurriculumItemsService {
    return new CurriculumItemsService();
  }

  get options() {
    return (
      this.curriculumItems &&
      this.curriculumItems.map((curriculumItem) => {
        return {
          value: curriculumItem.id,
          label: curriculumItem.name,
        };
      })
    );
  }

  onChange(event) {
    const value: number = Number.parseInt(event.target.value) || null;
    this.$emit("input", value);
  }

  created() {
    this.fetchCurriculumItems();
  }

  get curriculumItemsUpdatedAt(): number {
    return this.type, this.curriculum, Date.now();
  }

  @Watch("curriculumItemsUpdatedAt")
  private async fetchCurriculumItems() {
    if (this.type && this.curriculum) {
      this.curriculumItems = await this.curriculumItemsService.getAll({
        params: { item_type: this.type, curriculum_id: this.curriculum.id.toString() },
      });
      if (this.value && !this.curriculumItems.find(({ id }) => id === this.value)) {
        this.$emit("input", null);
      }
    }
  }
}
</script>
