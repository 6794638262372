import Vue from "vue";
import dayjs from "@/dayjs";

export function dateFilter(date) {
  if (date) {
    return dayjs(date).format("LL");
  }
}

export function dateTimeFilter(date) {
  if (date) {
    return dayjs(date).format("L LT");
  }
}

export function relativeDateFilter(date) {
  if (date) {
    return (dayjs(date) as any).from(dayjs());
  }
}

export function uppercase(str: string) {
  return str?.toUpperCase();
}

export function defaultFilter(value, defaultValue) {
  return value ?? defaultValue;
}

Vue.filter("date", dateFilter);
Vue.filter("dateTime", dateTimeFilter);
Vue.filter("ago", relativeDateFilter);
Vue.filter("uppercase", uppercase);
Vue.filter("default", defaultFilter);
