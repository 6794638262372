import Quiz from "@/model/quiz";
import QuizAnswer from "@/model/quiz-answer";
import ApiService from "@/services/api-service";

export default class QuizzesService extends ApiService<Quiz> {
  get baseUrl() {
    return "/api/v1/quizzes";
  }

  publish(id) {
    const url = this.buildUrl(id, { action: "publish" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  answer(id, answer) {
    const url = this.buildUrl(id, { action: "answer" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
      body: JSON.stringify(answer),
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  getAnswers(id, user_id): Promise<QuizAnswer[]> {
    const url = this.buildUrl(id, { action: "answers", params: { user_id } });
    const headers = this.buildHeaders();
    return fetch(url, {
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }
}
