<template>
  <page>
    <page-header :title="$t('user_management.title')"></page-header>
    <page-content>
      <tabs>
        <tab-link :to="{ name: 'CustomerOrganizationUsers' }">{{ $t("users.title") }}</tab-link>
        <tab-link :to="{ name: 'CustomerOrganizationInvitations' }">{{ $t("invitations.title") }}</tab-link>
      </tabs>
      <router-view></router-view>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class CustomerOrganizationPage extends Vue {}
</script>
