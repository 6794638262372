import dayjs from "@/dayjs";

export const isoDate = (date = Date()) => dayjs(date).format("YYYY-MM-DD");
export const isoDateTime = (date = Date()) => dayjs(date).toISOString();
export const localizedDate = (date = Date()) => dayjs(date).format("L");
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
