<template>
  <modal>
    <form @submit.prevent="onSubmit">
      <h3 v-if="title" class="mb-4">{{ title }}</h3>
      <form-field :label="label">
        <input v-model="value" v-focus type="text" />
      </form-field>
      <modal-footer>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
        <button class="btn-primary" type="submit">{{ $t("actions.ok") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ProptDialog extends Vue {
  @Prop()
  title: string;

  @Prop()
  label: string;

  @Prop()
  initialValue: string;

  value: string = null;

  created() {
    this.value = this.initialValue || "";
  }

  onSubmit() {
    this.$emit("ok", this.value);
  }
}
</script>
