<template>
  <typeahead-input
    item-icon="pen"
    :selected-items="selectedItems"
    :matching-items="matchingItems"
    :placeholder="$t('procedure.add_procedure_placeholder') + '...'"
    @updated="onUpdated"
    @input="fetchProcedureTypes"
  ></typeahead-input>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { TypeaheadItem } from "@/model/typeahead-item";
import debounce from "lodash/debounce";
import ProcedureType from "@/model/procedure-type";
import ProcedureTypesService from "@/services/procedure-types-service";
import { withErrorHandling } from "@/error-handler";

@Component({})
export default class ProsedureTypeSelect extends Vue {
  @Prop()
  value: ProcedureType[];

  allProcedureTypes: ProcedureType[] = null;

  get procedureTypesService() {
    return new ProcedureTypesService();
  }

  get selectedItems(): TypeaheadItem<ProcedureType>[] {
    return this.typeaheadItems(this.value);
  }

  get matchingItems(): TypeaheadItem<ProcedureType>[] {
    return this.typeaheadItems(this.allProcedureTypes);
  }

  typeaheadItems(types: ProcedureType[]) {
    return (
      types &&
      types.map((type) => ({
        value: type,
        label: `${type.code} - ${type.name}`,
      }))
    );
  }

  onUpdated(items: TypeaheadItem<ProcedureType>[]) {
    this.$emit(
      "input",
      items.map((item) => item.value)
    );
  }

  fetchProcedureTypes = debounce(
    withErrorHandling(async (q: string) => {
      this.allProcedureTypes = q && q.length > 0 ? await this.procedureTypesService.getAll({ params: { q } }) : [];
    }, this),
    300
  );
}
</script>
