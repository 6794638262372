const AuthPlugin = {
  install(Vue) {
    Vue.prototype.$hasRole = function (roles: string | string[]) {
      const rolesArr = Array.isArray(roles) ? roles : roles.split(",");

      return rolesArr.some((role) => this.$store.getters.currentUser.hasRole(role));
    };

    Vue.prototype.$hasFeature = function (features: string | string[]) {
      features = Array.isArray(features) ? features : features.split(",");

      return features.every((feature) => this.$store.getters.currentUser.features?.[feature]);
    };

    Vue.mixin({
      computed: {
        $currentUser() {
          return this.$store.getters.currentUser;
        },
      },
    });
  },
};

export default AuthPlugin;
