<template>
  <item-card class="logbook-entry-card" :class="{ 'logbook-entry-card--uploading': entry.uploading }">
    <template #title>
      <div class="flex flex-col">
        <div class="inline overflow-hidden whitespace-nowrap text-ellipsis">{{ label }}</div>
        <div class="flex items-center text-text-variant">
          <icon class="mr-sm" icon="calendar"></icon><span>{{ dateFilter(entry.date) }}</span>
        </div>
        <notification-indicator commentable-type="LogbookEntry" :commentable-id="entry.id"></notification-indicator>
      </div>
    </template>
    <template #titleTrailing>
      <context-menu v-if="$hasRole('resident,solo')" class="ml-sm">
        <action-link
          v-if="$listeners.edit"
          icon="edit"
          :align="true"
          :title="$t('actions.edit')"
          @click="$emit('edit')"
        ></action-link>
        <action-link icon="trash" :align="true" :title="$t('actions.delete')" @click="deleteEntry()"></action-link>
      </context-menu>
    </template>
    <slot></slot>
    <section class="logbook-entry-card__section">
      <h4>{{ $t("attributes.comments") }}</h4>
      <comments-section commentable-type="LogbookEntry" :commentable-id="entry.id"></comments-section>
    </section>
  </item-card>
</template>

<script lang="ts">
import { deleteLogbookEntry } from "@/actions";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import { dateFilter } from "@/filters";
import LogbookEntry from "@/model/logbook-entry";
import MaybeUploading from "@/model/maybe-uploading";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LogbookEntryCard<Data extends object> extends Vue {
  @Prop()
  entry: MaybeUploading<LogbookEntry<Data>>;

  @Prop()
  label: string;

  open = false;

  toggleOpen() {
    this.open = !this.open;
  }

  async deleteEntry() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.delete_entry") });
    if (confirmed) {
      return showDialog(LoadingIndicatorDialog, { action: deleteLogbookEntry(this.entry) });
    }
  }

  dateFilter(value: string) {
    return dateFilter(value);
  }
}
</script>

<style lang="scss" scoped>
.logbook-entry-card {
  position: relative;
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-sm);
  cursor: pointer;

  &--uploading {
    opacity: 0.5;
  }
}
</style>

<style lang="scss">
.logbook-entry-card {
  &__section {
    &:not(:last-child) {
      margin-bottom: var(--spacing);
    }
    > * {
      margin-left: var(--spacing);
    }
  }
}
</style>
