<template>
  <div class="item-card bg-surface-mid-contrast">
    <div class="item-card__title-bar" @click="toggleOpen()">
      <div class="item-card__chevron mr-md">
        <icon :icon="open ? 'chevron-up' : 'chevron-down'"></icon>
      </div>
      <div class="item-card__title text-label-strong text-text-primary">
        <slot name="title"></slot>
      </div>
      <div v-if="$slots.titleTrailing" class="item-card__title-traling">
        <slot name="titleTrailing"></slot>
      </div>
    </div>
    <collapsible :collapsed="!open">
      <div class="item-card__content">
        <slot></slot>
      </div>
    </collapsible>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class ItemCard extends Vue {
  open = false;

  toggleOpen() {
    this.open = !this.open;
  }
}
</script>

<style lang="scss" scoped>
.item-card {
  position: relative;
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--spacing-sm);
  cursor: pointer;

  &__title-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__title {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 0;
  }

  &__content {
    padding-top: var(--spacing);
  }
}
</style>
