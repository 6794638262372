import { RootState } from "@/store/store";
import { Module } from "vuex";
import Vue from "vue";
import schema from "@/schema";
import { denormalize, normalize } from "normalizr";
import {
  FETCH_USERS_SUCCESS,
  STORE_ENTITIES,
  TERMINATE_RESIDENCY_SUCCESS,
  UPDATE_RESIDENCY_SUCCESS,
} from "@/store/mutations";
import { isoDate } from "@/utils/formatting";

const storeEntities = (state, entities) => {
  const { entities: normalizedEntities } = normalize(entities, schema);

  Object.keys(normalizedEntities).forEach((type) => {
    Object.keys(normalizedEntities[type]).forEach((id) => {
      // Ensure we have database for this type
      Vue.set(state, type, state[type] || {});

      // Get existing entity or create new
      const entity = state[type][id] || {};

      // Update entity state
      Object.assign(entity, normalizedEntities[type][id]);

      // Ensure entity is in database and it's reactive
      Vue.set(state[type], id, entity);
    });
  });
};

export const mutations = {
  [UPDATE_RESIDENCY_SUCCESS]: (state, residency) => storeEntities(state, { residencies: [residency] }),
  [FETCH_USERS_SUCCESS]: (state, users) => storeEntities(state, { users }),
  [TERMINATE_RESIDENCY_SUCCESS]: (state, residencyId) => {
    const residency = state.residencies && state.residencies[residencyId];
    if (residency) {
      Vue.set(residency, "end_date", isoDate());
    }
  },
  [STORE_ENTITIES]: storeEntities,
};

export const getters = {
  getEntities: (state) => (query) => denormalize(query, schema, state),
};

const entitiesModule: Module<any, RootState> = {
  state: () => ({}),
  mutations,
  getters,
};

export default entitiesModule;
