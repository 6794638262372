<template>
  <div class="text-input">
    <div class="relative">
      <input
        v-bind="$attrs"
        ref="input"
        v-focus="autoFocus"
        class="peer focus:!border-[1.5px] dark:focus:!border-high-em"
        :type="inputType"
        :value="value"
        :placeholder="inputPlaceholder"
        :class="{ invalid: error, 'focus:!border-semantic-error': error, '!border-semantic-success': success }"
        @input="$emit('input', $event.target.value)"
        @focus="onFocus"
        @blur="onBlur"
      />
      <floating-label v-if="floatingLabel" :error="error" :success="success">{{ placeholder }}</floating-label>
      <div
        v-if="isPassword"
        class="text-text-variant absolute top-0 right-0 p-3 cursor-pointer"
        @click.prevent="toggleClearText"
      >
        <icon-fluent :name="eyeIcon" size="16" variant="filled"></icon-fluent>
      </div>
    </div>
    <transition name="show-validation-message">
      <div v-if="showValidationMessage" class="text-xs" :class="validationMessageClass">
        <div class="flex items-center pt-2 px-2">
          <icon-fluent class="mr-xs" :name="validationIcon" size="16" variant="filled"></icon-fluent>
          {{ validationMessage }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

@Component({})
export default class TextInput extends Vue {
  @Ref("input")
  input: HTMLInputElement;

  @Prop({ default: "text" })
  type: string;

  @Prop()
  value: any;

  @Prop()
  placeholder: string;

  @Prop({ default: false })
  floatingLabel: boolean;

  @Prop()
  validationMessage: string;

  @Prop({ default: false })
  invalid: boolean;

  @Prop({ default: false })
  error: boolean;

  @Prop({ default: false })
  success: boolean;

  @Prop({ default: false })
  autoFocus: boolean;

  showClearText = false;
  focused = false;

  get inputPlaceholder() {
    return this.floatingLabel ? " " : this.placeholder;
  }

  get isPassword() {
    return this.type === "password";
  }

  get eyeIcon() {
    return this.showClearText ? "eye" : "eyeOff";
  }

  get inputType() {
    return this.showClearText ? "text" : this.type;
  }

  get showValidationMessage() {
    return (this.error || this.focused) && this.validationMessage;
  }

  get validationMessageClass() {
    return this.error ? "text-semantic-error" : this.invalid ? "text-text-variant" : "text-success";
  }

  get validationIcon() {
    return this.error ? "errorCircle" : this.invalid ? "dismiss" : "checkmark";
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    setTimeout(() => {
      // Wait for the next tick to check if the input is still focused
      // This is needed because input can be focused again when toggling clear text visibility
      if (this.input !== document.activeElement) {
        this.focused = false;
      }
    }, 200);
  }

  toggleClearText() {
    this.input.focus();
    this.showClearText = !this.showClearText;
  }
}
</script>

<style lang="scss" scoped>
.show-validation-message-enter-active,
.show-validation-message-leave-active {
  transition: all 0.3s;
}
.show-validation-message-enter,
.show-validation-message-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>
