<template>
  <dropdown-select
    :value="value"
    :options="options"
    :allow-null="allowNull"
    :multiselect="multiselect"
    :placeholder="$t('attributes.content_type')"
    :width-by-content="false"
    button-class="rounded-xs"
    @input="onInput"
  ></dropdown-select>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ContentTypeSelect extends Vue {
  @Prop()
  value: any;

  @Prop({ default: false })
  allowNull: boolean;

  @Prop({ default: false })
  multiselect: boolean;

  get options() {
    return [{ value: "Page", label: this.$t("page.title") }];
  }

  onInput(value) {
    this.$emit("input", value);
  }
}
</script>

<style lang="scss" scoped></style>
