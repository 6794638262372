<template>
  <modal>
    <h3 class="mb-4">{{ $t("actions.edit") }}</h3>
    <form v-if="model" @submit.prevent="onSubmit">
      <form-field :label="$t('logbook.title')">
        <select v-model="model.curriculum_ids" multiple>
          <option v-for="curriculum in curriculums" :key="curriculum.id" :value="curriculum.id">
            {{ curriculum.name }},&nbsp;
            <date-range :start-date="curriculum.start_date" :end-date="curriculum.end_date"></date-range>
          </option>
        </select>
      </form-field>
      <form-field :label="$t('attributes.permissions')">
        <checkbox-select v-model="model.permissions" :options="permissionOptions"></checkbox-select>
      </form-field>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.save") }}</button>
        <button class="btn-secondary" @click="onCancel">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import { updateResidency } from "@/actions";
import { CheckboxSelectOption } from "@/components/form/checkbox-select.vue";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import Organization from "@/model/organization";
import Residency from "@/model/residency";
import User from "@/model/user";
import Organizations from "@/services/organizations";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CustomerOrganizationEditResidentModal extends Vue {
  @Prop()
  user: User;

  model: Partial<Residency> = null;

  organization: Organization = null;

  get curriculums() {
    return this.organization.curriculums;
  }

  get userResidency(): Residency {
    return this.user.currentResidencyForOrganization(this.organization);
  }

  get permissionOptions(): CheckboxSelectOption<string>[] {
    return [{ value: "edit_content", label: this.$t("permissions.edit_content") }];
  }

  async onSubmit() {
    await showDialog<Residency>(LoadingIndicatorDialog, {
      action: updateResidency(this.userResidency.id, this.model),
    });
    this.$emit("ok");
  }

  onCancel() {
    this.$emit("cancel");
  }

  async created() {
    this.organization = this.$currentUser.customer_organization || this.user.currentResidency.customer_organization;

    // Reload organization, populate curriculums
    this.organization = await new Organizations().get(this.organization.id.toString());

    this.model = {
      curriculum_ids: this.userResidency.curriculums?.map(({ id }) => id),
      permissions: this.userResidency.permissions,
    };
  }
}
</script>
