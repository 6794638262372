<template>
  <node-view-wrapper as="span">
    <span v-if="editor.isEditable" data-drag-handle="">
      <a class="link file" @click="edit">{{ title }}</a>
    </span>
    <a v-else class="link file" :href="url" target="_blank">{{ title }}</a>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";
import { filePath } from "@/utils/paths";
import { showDialog } from "@/utils/dialogs";
import FileModal from "@/editor/extensions/file/file-modal.vue";

@Component({
  components: { NodeViewWrapper },
  props: nodeViewProps,
})
export default class ImageNodeView extends Vue {
  @Prop()
  editor: Editor;

  @Prop()
  node: any;

  get title() {
    return this.node.attrs.title;
  }

  get url() {
    return filePath(this.node.attrs.blob);
  }

  async edit() {
    const attrs: any = await showDialog(FileModal, { attrs: this.node.attrs });
    if (attrs) {
      this.editor
        .chain()
        .updateFile({ ...this.node.attrs, ...attrs })
        .focus()
        .run();
    }
  }
}
</script>

<style scoped></style>
