import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import YouTubeNodeView from "@/editor/extensions/youtube/youtube-node-view.vue";

export type YouTubeNodeAttributes = {
  id: string;
  url: string;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    youtube: {
      addYouTube: (attrs: YouTubeNodeAttributes) => ReturnType;
      updateYouTube: (attrs: YouTubeNodeAttributes) => ReturnType;
    };
  }
}

const YouTubeNode = Node.create({
  name: "youtube",

  inline: true,

  group: "inline",

  draggable: true,

  addAttributes() {
    return {
      id: {},
      url: {},
    };
  },

  addCommands() {
    return {
      addYouTube:
        (attrs: YouTubeNodeAttributes) =>
        ({ commands }) => {
          console.log('addYouTube command called with attrs:', attrs);
          return commands.insertContent({
            type: this.name,
            attrs,
          });
        },
      updateYouTube:
        (attrs: YouTubeNodeAttributes) =>
        ({ commands }) => {
          console.log('updateYouTube command called with attrs:', attrs);
          return commands.updateAttributes(this.name, attrs);
        },
    };
  },
  
  parseHTML() {
    return [
      {
        tag: "youtube-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["youtube-node", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(YouTubeNodeView);
  },
});

export default YouTubeNode;
