<template>
  <modal>
    <h3 class="mb-4">{{ $t("editor.embed_video") }}</h3>
    <form name="linkForm" @submit.prevent="onSubmit">
      <form-field :label="$t('attributes.youtube_url')">
        <input
          ref="urlInput"
          v-model="model.url"
          type="text"
          required
          :placeholder="$t('attributes.youtube_url') + '...'"
          @blur="updateVideoId"
        />
        <div v-if="urlError" class="text-error">{{ $t('errors.invalid_youtube_url') }}</div>
      </form-field>
      <form-field :label="$t('attributes.youtube_video_id')">
        <input
          ref="idInput"
          v-model="model.id"
          type="text"
          required
          :placeholder="$t('attributes.youtube_video_id') + '...'"
          readonly
        />
      </form-field>
      <modal-footer>
        <button class="btn-primary" type="submit" :disabled="!isValid">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import { YouTubeNodeAttributes } from "./youtube-node";

@Component({})
export default class EmbedYouTubeModal extends Vue {
  @Ref()
  readonly urlInput: HTMLInputElement;

  @Prop({ required: false })
  attrs: YouTubeNodeAttributes;

  model: YouTubeNodeAttributes = null;
  urlError: boolean = false;

  get isValid() {
    return this.model.url.length > 0 && !this.urlError;
  }

  created() {
    this.model = {
      id: this.attrs?.id || "",
      url: this.attrs?.url || "",
    };
  }

  mounted() {
    this.$nextTick(() => this.urlInput.focus());
  }

  updateVideoIdFromUrl(url: string) {
    const videoIdRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const match = url.match(videoIdRegex);
    const id = match && match[1] ? match[1] : "";
    this.model.id = id;
    this.urlError = !id;
  }

  updateVideoId() {
    this.updateVideoIdFromUrl(this.model.url);
  }

  onSubmit() {
    this.$emit("ok", this.model);
  }
}
</script>
