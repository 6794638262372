import Vue from "vue";
import Vuex, { createLogger } from "vuex";
import VuexPersistence from "vuex-persist";
import session, { SessionState } from "@/store/session";
import entities from "@/store/entities";
import logbookEntries, { LogbookEntriesState } from "@/store/logbook-entries";
import contents, { ContentsState } from "@/store/contents";
import contentsView, { ContentsViewState } from "@/store/contents-view";
import users, { UsersState } from "@/store/users";
import commentNotifications, { CommentNotificationsState } from "@/store/comment-notifications";
import { SET_CURRENT_USER, SET_IMPERSONATING } from "@/store/mutations";
import contentSearch, { ContentSearchState } from "@/store/content-search";
import homePage, { HomePageState } from "./home-page";

Vue.use(Vuex);

export type RootState = {
  session: SessionState;
  entities: any;
  logbookEntries: LogbookEntriesState;
  contents: ContentsState;
  contentsView: ContentsViewState;
  contentSearch: ContentSearchState;
  users: UsersState;
  commentNotifications: CommentNotificationsState;
  homePage: HomePageState;
};

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  modules: ["contentsView"],
});

const store = new Vuex.Store<RootState>({
  modules: {
    session,
    users,
    entities,
    logbookEntries,
    contents,
    contentsView,
    contentSearch,
    commentNotifications,
    homePage,
  },
  plugins: [vuexLocal.plugin, createLogger()],
});

export const loadStoreInitialState = () => {
  if (window.osgenicSession) {
    console.log("Setting osgenicSession");
    const { currentUser, impersonating } = window.osgenicSession;
    store.commit(SET_CURRENT_USER, currentUser);
    store.commit(SET_IMPERSONATING, impersonating);
  }
};

export default store;
