<template>
  <div class="flex flex-1 flex-col items-center">
    <h3 class="text-text-primary my-lg">{{ $t("errors.page_not_found") }}</h3>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class NotFound extends Vue {}
</script>

<style lang="scss" scoped></style>
