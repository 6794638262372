<template>
  <span class="relative inline-block align-bottom group" :style="selected && style">
    <img class="image inline rounded-sm" :src="url" :style="!selected && style" />
    <div
      class="absolute inset-0 opacity-0 pointer-events-none bg-white rounded-sm duration-300 group-hover:opacity-80"
    ></div>
    <div
      class="absolute inset-0 flex flex-col justify-center items-center gap-2 opacity-0 z-10 pointer-events-none duration-300 group-hover:opacity-100 group-hover:pointer-events-auto"
    >
      <button class="btn-primary" type="button" @click="changeImage">{{ $t("actions.change_image") }}</button>
      <button class="btn-tertiary" type="button" @click.stop="addImageToSide">
        {{ $t("actions.add_image_to_side") }}
      </button>
      <button class="btn-tertiary" type="button" @click.stop="removeImage">
        <icon-fluent name="dismiss" size="16" variant="filled"></icon-fluent>
        <div class="ml-2">{{ $t("actions.remove_image") }}</div>
      </button>
      <template v-if="!hasSideImages">
        <button class="btn-tertiary" type="button" @click.stop="toggleHoverImage">
          <div v-if="hasHoverImage">{{ $t("actions.remove_hover_image") }}</div>
          <div v-else>{{ $t("actions.add_hover_image") }}</div>
        </button>
      </template>
    </div>
  </span>
</template>

<script lang="ts">
import MediaLibraryModal from "@/components/media-library/media-library-modal.vue";
import ContentBlob from "@/model/content-blob";
import MediaFile from "@/model/media-file";
import { showDialog } from "@/utils/dialogs";
import { filePath } from "@/utils/paths";
import { Editor } from "@tiptap/core";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class EditorImageView extends Vue {
  @Prop()
  editor: Editor;

  @Prop()
  blob: ContentBlob;

  @Prop()
  width: number;

  @Prop()
  selected: boolean;

  @Prop()
  getPos: () => number;

  style = null;

  get url() {
    return filePath(this.blob);
  }

  updateStyle() {
    if (!this.width) {
      this.style = null;
    } else if (this.selected) {
      this.style = { width: this.width + "px" };
    } else {
      this.style = { maxWidth: this.width + "px" };
    }
  }

  created() {
    this.updateStyle();
  }

  async changeImage() {
    const mediaFiles = await showDialog<MediaFile[]>(MediaLibraryModal, {
      fileType: "image",
      showFileTypes: false,
      allowMultiselect: false,
    });
    if (mediaFiles && mediaFiles.length > 0) {
      const mediaFile = mediaFiles[0];
      this.editor
        .chain()
        .setTextSelection({ from: this.getPos(), to: this.getPos() + 1 })
        .updateImage(mediaFile)
        .run();
    }
  }

  async addImageToSide() {
    const mediaFiles = await showDialog<MediaFile[]>(MediaLibraryModal, {
      fileType: "image",
      showFileTypes: false,
      allowMultiselect: false,
    });
    if (mediaFiles && mediaFiles.length > 0) {
      const mediaFile = mediaFiles[0];

      // Select current image
      this.editor.commands.setTextSelection({ from: this.getPos(), to: this.getPos() + 1 });

      if (this.editor.state.selection.$from.parent.type.name === "imageRow") {
        // Already inside row

        this.editor
          .chain()

          // Move cursor after image
          .setTextSelection(this.getPos() + 1)

          // Add new image
          .addImage(mediaFile)

          .run();
      } else {
        // Not inside row yet

        let currentNode = null;
        this.editor
          .chain()

          // Make a copy of image and remove it from document
          .command(({ tr }) => {
            const node = tr.selection.$from.nodeAfter;
            if (node.type.name === "image") {
              currentNode = node.copy(node.content);
              delete currentNode.attrs.image_width;
              tr.deleteSelection();
              return true;
            } else {
              return false;
            }
          })

          // Add image row
          .addImageRow()

          // Add previous image to row
          .command(({ tr }) => {
            tr.insert(tr.selection.from, currentNode);
            return true;
          })

          // Add new image to row
          .addImage(mediaFile)

          .run();
      }
    }
  }

  removeImage() {
    this.editor
      .chain()
      .setTextSelection({ from: this.getPos(), to: this.getPos() + 1 })
      .deleteSelection()
      .command(({ tr }) => {
        // Remove empty image row
        const parent = tr.selection.$from.parent;
        if (parent.type.name === "imageRow" && parent.childCount === 0) {
          tr.deleteRange(tr.selection.$from.before(), tr.selection.$to.after());
        }
        return true;
      })
      .run();
  }

  get hasHoverImage() {
    const { state } = this.editor;
    const pos = this.getPos();
    const node = state.doc.nodeAt(pos);

    return node && node.attrs.hoverImage;
  }

  async toggleHoverImage() {
    if (this.hasHoverImage) {
      // Remove hover image
      this.editor.commands.updateAttributes("image", { hoverImage: null });
    } else {
      // Add hover image
      const mediaFiles = await showDialog<MediaFile[]>(MediaLibraryModal, {
        fileType: "image",
        showFileTypes: false,
        allowMultiselect: false,
      });

      if (mediaFiles && mediaFiles.length > 0) {
        const mediaFile = mediaFiles[0];
        this.editor.commands.updateAttributes("image", {
          hoverImage: {
            key: mediaFile.file.key,
            filename: mediaFile.file.filename,
            content_type: mediaFile.file.content_type,
            metadata: mediaFile.file.metadata,
          },
        });
      }
    }
  }

  get hasSideImages() {
    const { state } = this.editor;
    const pos = this.getPos();
    const node = state.doc.nodeAt(pos);

    if (node && node.type.name === "image") {
      const parent = state.doc.resolve(pos).parent;
      return parent.type.name === "imageRow" && parent.childCount > 1;
    }

    return false;
  }
}
</script>

<style lang="scss" scoped>
img {
  // Max width = content width - 1px (so that it will not automatically break to new line in editor)
  max-width: calc(100% - 1px);
}

.resize-x {
  // Set max width for resize container also so it cannot overflow the content container
  max-width: calc(100% - 1px);
}
</style>
