<template>
  <div class="logbook-number-of-completed" :class="{ completed }">
    <icon v-if="completed" icon="check-square"></icon>
    <icon v-else icon="square" icon-style="regular"></icon>
    {{ entryCount.done }}/{{ entryCount.required }}
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

export type EntryCount = {
  done: number;
  required: number;
};

@Component({})
export default class LogbookNumberOfCompleted extends Vue {
  @Prop()
  entryCount: EntryCount;

  get completed() {
    return this.entryCount.done >= this.entryCount.required;
  }
}
</script>

<style lang="scss" scoped>
.logbook-number-of-completed {
  width: 80px;
}

.icon {
  color: var(--ui-color-text-variant);
}
.completed {
  .icon {
    color: var(--ui-color-text-emphasis);
  }
}
</style>
