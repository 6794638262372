<template>
  <div class="flex-1 flex flex-col">
    <loading-indicator-view v-if="sending" :title="$t('signup.add_payment_method.adding_payment_method')" />

    <sign-up-view
      v-show="!sending"
      :step="2"
      :total-steps="2"
      :title="$t('signup.add_payment_method.title')"
      :subtitle="$t('membership_expired.add_payment_method_subtitle')"
      v-on="$listeners"
    >
      <div class="w-full sm:w-[520px]">
        <selected-plan-row :plan="plan" :sign-up-code="signUpCode" />
        <div class="mt-12 sm:px-6 flex flex-col gap-4">
          <payment-method-card
            :title="$t('signup.add_payment_method.card.title')"
            :open="paymentMethod == 'card'"
            @click="togglePaymentMethod('card')"
          >
            <div class="flex gap-1">
              <credit-card-image brand="visa" />
              <credit-card-image brand="amex" />
              <credit-card-image brand="mastercard" />
              <credit-card-image brand="discover" />
            </div>
            <credit-card-form ref="creditCardForm" class="mt-4" />
            <div class="my-12 px-2 py-4 border-y border-border-primary flex flex-col gap-3 text-text-variant text-left">
              <div>{{ $t("signup.add_payment_method.subscription_auto_renew_info") }}</div>
              <div>{{ $t("plan.cancel_membership_info_text") }}</div>
              <div>{{ $t("membership_expired.info_text_click_to_confirm") }}</div>
            </div>
            <button class="btn-primary" :disabled="sending" @click="confirmAndPay()">
              {{ $t("membership_expired.confirm_and_pay") }}
            </button>
          </payment-method-card>
          <payment-method-card :open="paymentMethod == 'paypal'" @click="togglePaymentMethod('paypal')">
            <template #title><img :src="require('@/assets/images/paypal.png')" /></template>
            <div
              class="mt-6 mb-8 px-2 py-4 border-y border-border-primary flex flex-col gap-3 text-text-variant text-left"
            >
              <div>{{ $t("signup.add_payment_method.subscription_auto_renew_info") }}</div>
              <div>{{ $t("plan.cancel_membership_info_text") }}</div>
              <div>{{ $t("signup.add_payment_method.check_out_with_paypal_info") }}</div>
            </div>
            <paypal-button @success="onPayPalSuccess" />
          </payment-method-card>
        </div>
      </div>
    </sign-up-view>
  </div>
</template>

<script setup lang="ts">
import Vue from "vue";
import SignUpView from "@/pages/sign-up/sign-up-view.vue";
import SelectedPlanRow from "@/pages/sign-up/selected-plan-row.vue";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n-bridge";
import PaymentIntentsService from "@/services/payment-intents-service";
import LoadingIndicatorView from "@/pages/loading-indicator-view.vue";
import CreditCardForm from "@/components/credit-card/credit-card-form.vue";
import { loadProfile } from "@/actions";
import SubscriptionsService from "@/services/subscriptions-service";
import PaymentMethodCard from "@/pages/sign-up/payment-method-card.vue";
import PaypalButton from "@/pages/sign-up/paypal-button.vue";
import PlanPrice from "@/model/plan-price";
import SignUpCode from "@/model/sign-up-code";
import { useRollbar } from "@/composables/rollbar";
import { PaymentRequired } from "@/api/api-error";

const props = defineProps<{ period: string; plans: PlanPrice[]; signUpCode: SignUpCode }>();

const emit = defineEmits(["done"]);

const { t } = useI18n();

const creditCardForm = ref<typeof CreditCardForm>(null);

const paymentMethod = ref("card");

const sending = ref(false);

const rollbar = useRollbar();

const paymentIntentId = ref(null);

const plan = computed(() => props.plans?.find(({ period }) => period === props.period));

const confirmAndPay = async () => {
  if (sending.value) {
    return;
  }
  try {
    sending.value = true;
    if (paymentMethod.value === "card") {
      await authorizeWith3ds();
    }
    await createSubscription();
    await loadProfile();
    emit("done");
  } catch (error) {
    if (error instanceof PaymentRequired) {
      const message = error.message;
      Vue.$toast.error(message, { message, position: "top-right" });
    } else {
      const message = t("payment.adding_payment_method_failed");
      rollbar.error(error);
      Vue.$toast.error(message, { message, position: "top-right" });
    }
  } finally {
    sending.value = false;
  }
};

const authorizeWith3ds = async () => {
  const paymentIntent = await createPaymentIntent();
  await creditCardForm.value.authorizeWith3ds(paymentIntent);
  paymentIntentId.value = paymentIntent.id;
};

const createPaymentIntent = () => {
  return new PaymentIntentsService().create({
    amount: 0,
    currency_code: "USD",
  });
};

const onPayPalSuccess = (paymentIntent) => {
  paymentIntentId.value = paymentIntent.id;
  confirmAndPay();
};

const createSubscription = async () => {
  const service = new SubscriptionsService();
  await service.create({
    plan_price_id: plan.value.price_id,
    payment_intent_id: paymentIntentId.value,
  });
};

const togglePaymentMethod = (method: string) => {
  if (paymentMethod.value === method) {
    paymentMethod.value = null;
  } else {
    paymentMethod.value = method;
  }
};
</script>
