<template>
  <dropdown-select
    v-bind="$attrs"
    :value="value"
    :options="options"
    :placeholder="$t('attributes.location')"
    :add-label="editable ? $t('actions.add') : null"
    button-class="rounded-xs"
    @input="onInput"
    @update-option="onUpdateOption"
    @delete="onDeleteOption"
    @show="fetchLocations"
  >
    <template #add="slotProps">
      <location-form
        ref="form"
        :parent-options="options"
        :user="user"
        :customer-organization="customerOrganization"
        @submit="onLocationSubmit"
        @locationCreated="onLocationCreated($event), slotProps.addCallback($event)"
      ></location-form>
      <div v-if="showAddLoader" class="loader absolute inset-0 flex flex-1 items-center justify-center">
        <div class="bg-background absolute inset-0 opacity-80"></div>
        <loader-big class="relative z-10"></loader-big>
      </div>
    </template>
  </dropdown-select>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import LocationsService from "@/services/locations-service";
import Location from "@/model/location";
import User from "@/model/user";
import Organization from "@/model/organization";
import LocationForm from "@/components/locations/location-form.vue";
import DropdownSelectOption, { BuildDropdownSelectOptionHierarchy } from "@/model/dropdown-select-option";
import { showDialog } from "@/utils/dialogs";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";

@Component({})
export default class LocationSelect extends Vue {
  @Ref()
  form: LocationForm;

  @Prop()
  user: User;

  @Prop()
  customerOrganization: Organization;

  @Prop({ default: false })
  usedValuesOnly: boolean;

  @Prop({ default: false })
  editable: boolean;

  @Prop()
  value: number[];

  locations: Location[] = null;

  showAddLoader = false;

  get locationService() {
    return new LocationsService();
  }

  get loaded() {
    return !!this.locations;
  }

  get options() {
    if (!this.loaded) return null;

    const locations = new Map([
      [
        "",
        BuildDropdownSelectOptionHierarchy(this.globalLocations, {
          editable: this.editable && this.$currentUser.isOsgenicUser(),
        }),
      ],
    ]);

    if (this.user) {
      locations.set(
        this.$t("locations.own_locations").toString(),
        BuildDropdownSelectOptionHierarchy(this.userLocations, { editable: this.editable })
      );
    }

    if (this.customerOrganization) {
      locations.set(
        this.customerOrganization.name,
        BuildDropdownSelectOptionHierarchy(this.organizationLocations, {
          editable:
            this.editable &&
            this.$currentUser.isHospitalAdmin() &&
            this.$currentUser.customer_organization?.id === this.customerOrganization.id,
        })
      );
    }

    return locations;
  }

  get globalLocations() {
    return this.locations?.filter((location) => !location.user_id && !location.customer_organization_id);
  }

  get userLocations() {
    if (!this.user) return [];
    return this.locations?.filter((location) => location.user_id === this.$currentUser.id);
  }

  get organizationLocations() {
    if (!this.customerOrganization) return [];
    return this.locations?.filter((location) => location.customer_organization_id === this.customerOrganization.id);
  }

  get systemLanguage() {
    return this.$currentUser?.language;
  }

  onInput(value) {
    this.$emit("input", value);
  }

  async onUpdateOption(option: DropdownSelectOption) {
    const location = this.locations.find(({ id }) => id === option.value);
    const updatedLocation = this.updateLocationFromOption(location, option);

    // update location on server
    await this.locationService.update(location.id, updatedLocation);

    // update location object in list
    this.locations = this.locations.map((it) => (it === location ? updatedLocation : it));
  }

  updateLocationFromOption(location: Location, option: DropdownSelectOption): Location {
    return {
      ...location,
      name: option.label as string,
      name_translations: option.label_translations,
    };
  }

  async onDeleteOption(option: DropdownSelectOption) {
    const sure = await showDialog<boolean>(ConfirmationDialog, { title: this.$t("confirmation.delete_location") });

    if (!sure) {
      return;
    }

    const location = this.locations.find(({ id }) => id === option.value);

    // Delete location from server
    await this.locationService.delete(location.id);

    // Remove location from list
    this.locations = this.locations.filter((it) => it !== location);

    // Remove location for selected locations
    const ids = this.value.filter((id) => id !== location.id);
    this.onInput(ids);
  }

  async created() {
    return this.fetchLocations();
  }

  @Watch("systemLanguage")
  async fetchLocations() {
    const params = {};
    if (this.usedValuesOnly) {
      params["used_values_only"] = "1";
    }
    return this.locationService.getAll({ params }).then((locations) => (this.locations = locations));
  }

  onLocationSubmit(): void {
    this.showAddLoader = true;
  }

  onLocationCreated(location: Location) {
    this.fetchLocations().then(() => {
      this.showAddLoader = false;
      this.$parent.$emit("add", location);
    });
  }
}
</script>

<style lang="scss" scoped></style>
