<template>
  <div v-if="value" class="flex flex-1 flex-col bg-surface-min-contrast rounded-lg overflow-hidden">
    <div class="flex items-center m-2">
      <editor-menu
        ref="editorMenu"
        :editor="tiptapEditor"
        :customer-organization="customerOrganization"
        :own-content="ownContent"
        @imageAdded="onImageAdded"
      ></editor-menu>
    </div>
    <div class="flex-1 overflow-y-auto">
      <editor
        ref="editor"
        class="min-h-full p-md flex flex-col"
        :doc="value.content.doc"
        @change="onChange"
        @save="onSave"
      ></editor>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import ContentDocument from "@/model/content-document";
import Organization from "@/model/organization";
import MediaFile from "@/model/media-file";
import Editor from "@/editor/components/editor.vue";
import EditorMenu from "@/editor/components/editor-menu.vue";
import { Editor as TiptapEditor } from "@tiptap/vue-2";

@Component({
  components: {
    Editor,
    EditorMenu,
  },
})
export default class DocumentEditor extends Vue {
  @Ref("editorMenu")
  readonly editorMenu: EditorMenu;

  @Ref("editor")
  readonly editor: Editor;

  @Prop()
  value: ContentDocument;

  @Prop()
  customerOrganization: Organization;

  @Prop()
  ownContent: boolean;

  tiptapEditor: TiptapEditor = null;

  applyEdits() {
    this.editor.save();
  }

  onChange(view) {
    this.$emit("change", view);
  }

  onImageAdded(mediaFile: MediaFile) {
    this.$emit("imageAdded", mediaFile);
  }

  onSave({ doc, html }) {
    this.$emit("input", {
      ...this.value,
      content: { ...this.value.content, doc },
      html,
    });
  }

  mounted() {
    this.tiptapEditor = this.editor.editor;
  }

  focus() {
    this.editor.focus();
  }
}
</script>

<style lang="scss" scoped>
.toolbar {
  display: flex;
  align-items: center;
  margin-top: var(--topbar-height);

  padding: var(--spacing-sm) var(--spacing);

  &__content {
    flex: 1;
    padding: 0 var(--spacing-lg);
  }
}
</style>
