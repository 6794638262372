<template>
  <div class="password-checker">
    <form-field :label="$t('attributes.new_password')" :required="true" :error="$v.newPassword.$error">
      <input v-model="newPassword" type="password" />
      <div v-if="$v.newPassword.$error" class="text-xs text-semantic-error">
        {{ $t("passwords.change.error_more_complex") }}
      </div>
    </form-field>
    <form-field :label="$t('attributes.confirm_new_password')" :required="true" :error="$v.confirmNewPassword.$error">
      <input v-model="confirmNewPassword" type="password" />
      <div v-if="!$v.newPassword.$error && $v.confirmNewPassword.$error" class="text-xs text-semantic-error">
        {{ $t("passwords.change.error_confirmation_must_match_new") }}
      </div>
    </form-field>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Validations } from "vuelidate-property-decorators";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

@Component({})
export default class PasswordChecker extends Vue {
  newPassword: String = "";
  confirmNewPassword: String = "";

  @Validations()
  validations = {
    newPassword: {
      required,
      minLength: minLength(8),
      hasNumberAndLetter: (password) => /\d/.test(password) && /\D/.test(password),
    },
    confirmNewPassword: {
      required,
      minLength: minLength(8),
      sameAsNewPassword: sameAs("newPassword"),
    },
  };
}
</script>
