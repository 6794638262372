<template>
  <modal>
    <h3 class="mb-4">{{ $t("invite_user.title") }}</h3>
    <form @submit.prevent="onSubmit">
      <form-field :label="$t('attributes.email')">
        <text-input
          key="email"
          v-model="email"
          class="mb-4"
          type="email"
          :floating-label="false"
          :error="$v.email.$error"
          :validation-message="vuelidateExternalResults.email"
          @input="vuelidateExternalResults.email = null"
        ></text-input>
      </form-field>
      <form-field :label="$t('attributes.role_type')">
        <select v-model="role_type">
          <option v-for="option in roleOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
        </select>
      </form-field>
      <form-field
        v-if="isCustomerOrganizationRole && !organizationId"
        :label="$t('organization.title')"
        :error="$v.customer_organization_id.$error"
      >
        <select v-model="customer_organization_id">
          <option :value="null">{{ $t("attributes.not_selected") }}</option>
          <option v-for="organization in organizations" :key="organization.id" :value="organization.id">
            {{ organization.name }}
          </option>
        </select>
      </form-field>
      <form-field
        v-if="role_type == 'resident' && selectedOrganization && selectedOrganization.curriculums"
        :label="$t('logbook.title')"
      >
        <select v-model="curriculum_id">
          <option :value="null">{{ $t("attributes.not_selected") }}</option>
          <option v-for="curriculum in selectedOrganization.curriculums" :key="curriculum.id" :value="curriculum.id">
            {{ curriculum.name }},&nbsp;
            <date-range :start-date="curriculum.start_date" :end-date="curriculum.end_date"></date-range>
          </option>
        </select>
      </form-field>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.invite") }}</button>
        <button class="btn-secondary" @click="onCancel">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import { UnprocessableEntityError } from "@/api/api-error";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import Invitation from "@/model/invitation";
import Organization from "@/model/organization";
import Invitations from "@/services/invitations";
import Organizations from "@/services/organizations";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({})
export default class InviteUserModal extends Vue {
  @Prop()
  organizationId: number;

  email: string = null;
  role_type: string = "resident";
  customer_organization_id: number = null;
  curriculum_id: number = null;

  organizations: Organization[] = [];
  vuelidateExternalResults: Record<string, string> = {
    email: null,
  };

  @Validations()
  validations = {
    email: {
      required,
      validFormat: () => !this.vuelidateExternalResults.email,
    },
    customer_organization_id: {
      required(value) {
        return this.isCustomerOrganizationRole ? !!value : true;
      },
    },
  };

  get organizationService(): Organizations {
    return new Organizations();
  }

  get roleOptions() {
    const organizationRoles = [
      { value: "resident", label: this.$t("user_roles.resident") },
      { value: "hospital_admin", label: this.$t("user_roles.hospital_admin") },
    ];
    const osgenicRoles = [
      { value: "content_admin", label: this.$t("user_roles.content_admin") },
      { value: "content_creator", label: this.$t("user_roles.content_creator") },
      { value: "osgenic_admin", label: this.$t("user_roles.osgenic_admin") },
    ];

    if (this.customer_organization_id) {
      return organizationRoles;
    } else {
      return organizationRoles.concat(osgenicRoles);
    }
  }

  get isCustomerOrganizationRole() {
    return this.role_type === "resident" || this.role_type === "hospital_admin";
  }

  get selectedOrganization() {
    return this.customer_organization_id && this.organizations?.find(({ id }) => id === this.customer_organization_id);
  }

  async created() {
    this.organizations = await new Organizations().getAll();
    if (this.organizationId) {
      this.customer_organization_id = this.organizationId;
    }
  }

  async onSubmit() {
    this.$v.$touch();
    if (!this.$v.$error) {
      const model: Partial<Invitation> = { email: this.email, role_type: this.role_type };
      if (this.isCustomerOrganizationRole) {
        model.customer_organization_id = this.customer_organization_id;
        if (this.curriculum_id) {
          model.curriculum_id = this.curriculum_id;
        }
      }
      const action = new Invitations().create(model).catch((e: UnprocessableEntityError) => {
        for (let field in this.vuelidateExternalResults) {
          this.vuelidateExternalResults[field] = e.details[field] || null;
        }
        this.$v.$touch();
      });
      const invitation = await showDialog(LoadingIndicatorDialog, { action });
      if (invitation) {
        this.$emit("ok", invitation);
      }
    }
  }

  onCancel() {
    this.$emit("cancel");
  }

  @Watch("customer_organization_id")
  onChangeOrganization() {
    this.curriculum_id = null;
  }
}
</script>
