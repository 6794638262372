<template>
  <input-group>
    <input
      ref="questionInput"
      v-focus="autoFocus"
      class="text-text-primary"
      type="text"
      :value="value"
      :class="{ invalid }"
      :placeholder="placeholder"
      @input="onInput"
    />
    <image-upload-button
      class="hidden"
      :title="imageButtonTitle"
      @upload="$emit('upload', $event)"
    ></image-upload-button>
  </input-group>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Ref } from "vue-property-decorator";

@Component({})
export default class ImageTextInput extends Vue {
  @Ref()
  questionInput: HTMLInputElement;

  @Prop()
  value: string;

  @Prop()
  invalid: boolean;

  @Prop()
  autoFocus: boolean;

  @Prop()
  placeholder: string;

  @Prop()
  imageButtonTitle: string;

  onInput(event) {
    this.$emit("input", event.target.value);
  }

  focus() {
    this.questionInput.focus();
  }
}
</script>

<style lang="scss" scoped>
.input-group:not(:hover):not(:focus-within) {
  // hide input borders and remove side paddings when not focused or hovered
  input {
    border: none;
    padding: var(--spacing-sm) 0;

    &.invalid {
      border-bottom: 1px solid var(--color-error);
      border-radius: 0;
    }
  }
}
.input-group:hover,
.input-group:focus-within {
  // show image button when hovered or focused
  button {
    display: inline-block;
  }
}

input.invalid + button {
  // set error color for image button also
  background-color: var(--color-error);
}
</style>
