<template>
  <modal size="medium" :closable="true" v-on="$listeners">
    <div class="sm:mx-4">
      <div class="sm:mx-4">
        <div v-if="updated" class="flex flex-col items-center">
          <icon-fluent name="checkmark" class="text-semantic-success h-8 w-auto my-2" size="20" variant="filled" />
          <h3>{{ $t("payment.payment_method_updated") }}</h3>
        </div>
        <h3 v-else>{{ $t("plan.review_your_payment_method") }}</h3>
      </div>
      <div v-if="editing" class="flex flex-col gap-4 mt-14">
        <div class="sm:mx-4 text-body-small text-text-variant flex justify-between items-center">
          <div>{{ $t("plan.add_payment_method") }}</div>
        </div>
        <payment-method-card
          class="bg-surface-mid-contrast border"
          :class="paymentMethod == 'card' ? 'border-border-emphasis' : 'border-border-variant'"
          :title="$t('signup.add_payment_method.card.title')"
          :open="paymentMethod == 'card'"
          @click="togglePaymentMethod('card')"
        >
          <div class="flex gap-1">
            <credit-card-image brand="visa" />
            <credit-card-image brand="amex" />
            <credit-card-image brand="mastercard" />
            <credit-card-image brand="discover" />
          </div>
          <credit-card-form ref="creditCardForm" class="mt-4" />
          <div class="text-center">
            <button class="btn-primary mt-6" @click="addCard()">{{ $t("plan.add_card") }}</button>
          </div>
        </payment-method-card>
        <payment-method-card
          class="bg-surface-mid-contrast border text-center"
          :class="paymentMethod == 'paypal' ? 'border-border-emphasis' : 'border-border-variant'"
          :open="paymentMethod == 'paypal'"
          @click="togglePaymentMethod('paypal')"
        >
          <template #title><img :src="require('@/assets/images/paypal.png')" /></template>
          <paypal-button :currency="subscription.currency" @success="onPayPalSuccess" />
        </payment-method-card>
      </div>
      <div v-else>
        <div class="mt-12 sm:mx-4 text-body-small text-text-variant flex justify-between items-center">
          <div>{{ $t("plan.current_payment_method") }}</div>
          <action-button :title="$t('actions.change')" @click="onChangeClicked()" />
        </div>
        <div class="bg-surface-mid-contrast rounded-sm border border-border-variant p-4">
          <div v-if="subscription.payment_method == 'card'">
            <div class="text-label-small text-text-primary">
              {{ $t("plan.payment_methods.card") }}
            </div>
            <div
              v-if="subscription.payment_method_details"
              class="mt-4 flex justify-between items-center px-4 h-10 border border-border-variant rounded-xs"
            >
              <div>
                •••• •••• ••••
                {{ subscription.payment_method_details.last4 }}
              </div>
            </div>
          </div>
          <div
            v-if="subscription.payment_method == 'paypal_express_checkout'"
            class="flex gap-2 justify-between items-center"
          >
            <img :src="require('@/assets/images/paypal.png')" />
            <div class="flex-1 text-label-small text-text-primary overflow-hidden whitespace-nowrap text-ellipsis">
              {{ subscription.payment_method_details.email }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-16 flex gap-2 justify-center">
        <button class="btn-secondary" @click="close">{{ $t("actions.close") }}</button>
      </div>
    </div>
  </modal>
</template>

<script setup lang="ts">
import Vue from "vue";
import { useActiveSubscription } from "@/composables/session";
import PaymentIntentsService from "@/services/payment-intents-service";
import CreditCardForm from "@/components/credit-card/credit-card-form.vue";
import LoadingIndicatorView from "@/pages/loading-indicator-view.vue";
import { ref } from "vue";
import { showDialog } from "@/utils/dialogs";
import { useI18n } from "vue-i18n-bridge";
import SubscriptionsService from "@/services/subscriptions-service";
import { loadProfile } from "@/actions";
import PaymentMethodCard from "@/pages/sign-up/payment-method-card.vue";
import PaypalButton from "@/pages/sign-up/paypal-button.vue";
import { useRollbar } from "@/composables/rollbar";

const { t } = useI18n();

const rollbar = useRollbar();

const emit = defineEmits(["ok"]);

const creditCardForm = ref<typeof CreditCardForm>(null);

const editing = ref(false);

const subscription = useActiveSubscription();

const paymentMethod = ref(null);

const updated = ref(false);

const close = () => emit("ok");

const onChangeClicked = () => {
  editing.value = true;
  updated.value = false;
};

const addCard = async () => {
  try {
    updated.value = false;
    const action = updateCardPaymentSource();
    await showDialog(LoadingIndicatorView, { action, title: t("plan.updating_payment_method") });
    updated.value = true;
  } catch (e) {
    const message = t("plan.updating_payment_method_failed");
    Vue.$toast.error(message, { message, position: "top-right", duration: 10000 });
    window.rollbar.error(e);
  } finally {
    editing.value = false;
  }
};

const updateCardPaymentSource = async () => {
  await updatePaymentSourceWith3ds();
  await loadProfile();
};

const updatePaymentSourceWith3ds = async () => {
  const paymentIntent = await createPaymentIntent();
  await creditCardForm.value.authorizeWith3ds(paymentIntent);
  await new SubscriptionsService().update(subscription.value.id, {
    payment_intent_id: paymentIntent.id,
  });
};

const updatePaymentSourceWithPaymentIntent = async (paymentIntent) => {
  await new SubscriptionsService().update(subscription.value.id, {
    payment_intent_id: paymentIntent.id,
  });
  await loadProfile();
};

const onPayPalSuccess = async (paymentIntent) => {
  try {
    updated.value = false;
    const action = updatePaymentSourceWithPaymentIntent(paymentIntent);
    await showDialog(LoadingIndicatorView, { action, title: t("plan.updating_payment_method") });
    const message = t("plan.payment_method_updated");
    Vue.$toast.success(message, { message, position: "top-right" });
  } catch (e) {
    rollbar.error(e);
    const message = t("plan.updating_payment_method_failed");
    Vue.$toast.error(message, { message, position: "top-right", duration: 10000 });
  } finally {
    editing.value = false;
  }
};

const createPaymentIntent = () => {
  return new PaymentIntentsService().create({
    amount: 0,
    currency_code: subscription.value.currency,
  });
};

const togglePaymentMethod = (method: string) => {
  if (paymentMethod.value === method) {
    paymentMethod.value = null;
  } else {
    paymentMethod.value = method;
  }
};
</script>
