<template>
  <modal size="medium">
    <h3>{{ $t("actions.share") }}</h3>
    <p>{{ $t("share.public_share_dialog_subtitle") }}</p>
    <div class="mt-8 flex justify-center gap-4">
      <button class="btn-secondary" :disabled="!contentItem.share_token" @click="copyLink">
        <icon-fluent name="link" class="mr-xs" />
        {{ $t("share.copy_link") }}
      </button>
      <button class="btn-primary" @click="close">{{ $t("actions.close") }}</button>
    </div>
  </modal>
</template>

<script setup lang="ts">
import { useToaster } from "@/composables/toaster";
import ContentItem from "@/model/content-item";
import { useI18n } from "vue-i18n-bridge";

const props = defineProps<{ contentItem: ContentItem }>();

const emit = defineEmits(["ok"]);

const { t } = useI18n();

const toast = useToaster();

const copyLink = () => {
  const publicLink = `${location.origin}/public/content_items/${props.contentItem.share_token}`;
  navigator.clipboard.writeText(publicLink);
  const message = t("share.link_copied_to_clipboard") as string;
  toast.info(message, { message, position: "bottom" });
};

const close = () => {
  emit("ok");
};
</script>
