<template>
  <sign-up-view
    v-if="subscription"
    :title="$t('membership_expired.welcome_back', { name: currentUser.name })"
    subtitle=""
    :back="false"
  >
    <div class="border border-semantic-success rounded py-8 px-12 flex flex-col gap-8 text-left">
      <div class="flex flex-col gap-1">
        <div class="text-body-small text-text-variant uppercase">{{ $t("attributes.username") }}</div>
        <div class="text-label-small text-text-primary">{{ currentUser.email }}</div>
      </div>
      <div class="flex flex-col gap-1">
        <div class="text-body-small text-text-variant uppercase">{{ $t("plan.your_plan") }}</div>
        <div class="text-label-small text-text-primary">{{ $t("plan.period." + plan.period) }}</div>
      </div>
      <div class="flex flex-col gap-1">
        <div class="text-body-small text-text-variant uppercase">{{ $t("plan.payment_method") }}</div>
        <div class="text-label-small text-text-primary">
          {{ $t("plan.payment_methods." + subscription.payment_method) }}
        </div>
      </div>
      <div class="flex flex-col gap-1">
        <div class="text-body-small text-text-variant uppercase">{{ $t("plan.next_billing_date") }}</div>
        <div class="text-label-small text-text-primary">{{ dateFilter(subscription.next_billing_date) }}</div>
      </div>
    </div>
    <div class="mt-8">
      <a href="/" class="btn btn-secondary">{{ $t("actions.done") }}</a>
    </div>
  </sign-up-view>
</template>

<script setup lang="ts">
import SignUpView from "@/pages/sign-up/sign-up-view.vue";
import PlanPrice from "@/model/plan-price";
import { useActiveSubscription, useCurrentUser } from "@/composables/session";
import { dateFilter } from "@/filters";
import { computed } from "vue";

const props = defineProps<{ plans: PlanPrice[]; period: string }>();

const currentUser = useCurrentUser();

const subscription = useActiveSubscription();

const plan = computed(() => props.plans?.find(({ period }) => period === props.period));
</script>
