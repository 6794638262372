<template>
  <modal v-if="plans" size="large" :closable="true" v-on="$listeners">
    <component
      :is="currentView"
      :selected-plan-id="selectedPlanId"
      :selected-plan="selectedPlan"
      :plans="plans"
      @close="close"
      @back="back"
      @checkout="checkout"
      @select-plan="selectPlan"
      @update-subscription="updateSubscription"
      @discard-scheduled-change="discardScheduledChange"
    />
  </modal>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import LoadingIndicatorView from "@/pages/loading-indicator-view.vue";
import { showDialog } from "@/utils/dialogs";
import SubscriptionsService from "@/services/subscriptions-service";
import ChangePlanView from "@/pages/profile/change-plan-view.vue";
import CheckoutPlanView from "@/pages/profile/checkout-plan-view.vue";
import { useActiveSubscription } from "@/composables/session";
import PlanPrice from "@/model/plan-price";
import { useI18n } from "vue-i18n-bridge";
import MembershipUpdatedView from "@/pages/profile/membership-updated-view.vue";
import store from "@/store";
import { UPDATE_SUBSCRIPTION } from "@/store/mutations";
import { usePlanPrices } from "@/api/plan-prices";

const { t } = useI18n();

const emit = defineEmits(["cancel", "ok"]);

const viewStack = ref<any>([ChangePlanView]);

const currentView = computed(() => viewStack.value[viewStack.value.length - 1]);

const subscription = useActiveSubscription();

const { data: plans } = usePlanPrices();

const selectedPlanId = ref<string>(subscription.value.plan_price_id ?? null);

const selectedPlan = computed(() => {
  return plans.value.find((p) => p.price_id === selectedPlanId.value);
});

const close = () => emit("ok");

const back = () => {
  viewStack.value = viewStack.value.slice(0, viewStack.value.length - 1);
};

const checkout = () => {
  viewStack.value = [...viewStack.value, CheckoutPlanView];
};

const selectPlan = (plan: PlanPrice) => {
  selectedPlanId.value = plan.price_id;
};

const updateSubscription = async () => {
  const action = new SubscriptionsService()
    .update(subscription.value.id, { plan_price_id: selectedPlanId.value })
    .then((subscription) => {
      store.commit(UPDATE_SUBSCRIPTION, subscription);
    });

  await showDialog(LoadingIndicatorView, { title: t("plan.updating_subscription"), action });

  viewStack.value = [MembershipUpdatedView];
};

const discardScheduledChange = async () => {
  const action = new SubscriptionsService()
    .update(subscription.value.id, { plan_price_id: subscription.value.plan_price_id })
    .then((subscription) => {
      store.commit(UPDATE_SUBSCRIPTION, subscription);
    });

  await showDialog(LoadingIndicatorView, { title: t("plan.updating_subscription"), action });

  close();
};
</script>
