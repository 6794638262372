<template>
  <div class="badge inline-flex items-center h-6 px-2" :class="variant">
    <icon-fluent v-if="variant == 'external-link'" class="mr-1" name="open" size="16" variant="filled"></icon-fluent>
    <div class="text-label-strong-small leading-none">
      <slot name="label">{{ label }}</slot>
    </div>
    <slot name="trailing"></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Badge extends Vue {
  @Prop()
  label: string;

  @Prop({ default: "info" })
  variant: string;
}
</script>

<style lang="scss" scoped>
.badge {
  border-radius: var(--border-radius-xs);

  .label {
    height: 20px;
  }

  &.info {
    background-color: var(--ui-color-semantic-info-container);
    color: var(--ui-color-semantic-info);
  }

  &.edited {
    background-color: var(--color-custom-1-container);
    color: var(--color-on-custom-1-container);
  }

  &.draft {
    background-color: var(--color-custom-0-container);
    color: var(--color-on-custom-0-container);
  }

  &.specialties {
    background-color: var(--color-custom-2-container);
    color: var(--color-on-custom-2-container);
  }

  &.external-link {
    background-color: var(--color-custom-3-container);
    color: var(--color-on-custom-3-container);
  }

  &.error,
  &.missing {
    background-color: var(--color-error-container);
    color: var(--color-on-error-container);
  }

  &.success {
    background-color: var(--ui-color-semantic-success-container);
    color: var(--ui-color-semantic-success);
  }

  &.warning {
    background-color: var(--ui-color-semantic-warning-container);
    color: var(--ui-color-semantic-warning-on-surface);
  }
}
</style>
