<template>
  <div v-lazy:background-image="backgroundImage" class="flex flex-1 flex-col overflow-y-auto relative bg-cover">
    <inline-svg
      class="text-white h-5 w-auto fixed top-8 left-12 hidden dark:text-text-primary sm:block"
      :src="require('@/assets/images/osgenic-logo.svg')"
    ></inline-svg>
    <form class="flex flex-col flex-center flex-1 p-md" @submit.prevent="onSubmit()">
      <card class="w-full max-w-[24rem]">
        <div class="flex flow-root">
          <language-selection class="float-right"></language-selection>
        </div>
        <h3 class="mt-16 text-text-emphasis text-center">
          {{ $t("login.title") }}
          <div v-if="error" class="text-error text-body-small mb-md">{{ error }}</div>
        </h3>
        <div class="mt-12 grid grid-cols-1 gap-4">
          <text-input
            key="email"
            v-model="email"
            v-focus
            type="email"
            :placeholder="$t('attributes.email')"
            :floating-label="true"
            :error="$v.email.$error"
          ></text-input>
          <text-input
            key="password"
            v-model="password"
            type="password"
            :placeholder="$t('attributes.password')"
            :floating-label="true"
            :error="$v.password.$error"
          ></text-input>
        </div>
        <div class="mt-2 flex flex-row justify-end text-body-small">
          <router-link class="link" to="/sessions/reset_password">{{ $t("login.forgot_password") }}</router-link>
        </div>
        <div class="mt-16">
          <button class="btn-primary w-full justify-center" type="submit">{{ $t("actions.login") }}</button>
        </div>
        <div class="mt-4 flex justify-center">
          {{ $t("login.dont_have_an_account_yet") }}
          <router-link class="link ml-xs" to="/signup">{{ $t("actions.signup") }}</router-link>
        </div>
        <div class="mt-16">
          <a class="group flex items-center text-body-small hover:text-text-emphasis" @click="showFeedbackModal">
            <icon-fluent class="group-hover:hidden" name="mail" size="16"></icon-fluent>
            <icon-fluent class="hidden group-hover:block" name="mail" size="16" variant="filled"></icon-fluent>
            <div class="ml-1">{{ $t("feedback_view.title") }}</div>
          </a>
        </div>
        <div class="mt-4 flex flex-row justify-between text-body-small text-outline">
          <router-link class="group flex items-center hover:text-text-emphasis" to="/eula" target="_blank">
            <icon-fluent class="group-hover:hidden" name="document" size="16"></icon-fluent>
            <icon-fluent class="hidden group-hover:block" name="document" size="16" variant="filled"></icon-fluent>
            <div class="ml-1">{{ $t("pages.terms_of_service") }}</div>
          </router-link>
          <router-link class="group flex items-center hover:text-text-emphasis" to="/privacy_statement" target="_blank">
            <icon-fluent class="group-hover:hidden" name="document" size="16"></icon-fluent>
            <icon-fluent class="hidden group-hover:block" name="document" size="16" variant="filled"></icon-fluent>
            <div class="ml-1">{{ $t("pages.privacy_policy") }}</div>
          </router-link>
        </div>
      </card>
    </form>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import i18n from "@/i18n";
import { TranslateResult } from "vue-i18n";
import { showDialog } from "@/utils/dialogs";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import { loadCommentNotifications, login, updateUserSettings } from "@/actions";
import AlertDialog from "@/components/alert-dialog.vue";
import FeedbackModal from "@/components/feedback/feedback-modal.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import backgroundImage from "@/assets/images/background.png";
import { PaymentRequired } from "@/api/api-error";

@Component({})
export default class LoginPage extends Vue {
  email: string = "";
  password: string = "";
  error: TranslateResult = null;

  @Validations()
  validations = {
    email: {
      required,
    },
    password: {
      required,
    },
  };

  get backgroundImage() {
    return backgroundImage;
  }

  mounted() {
    this.email = this.$route.params.email;

    const tokenExpired = localStorage.getItem("tokenExpired");
    localStorage.removeItem("tokenExpired");
    if (tokenExpired === "true") {
      showDialog(AlertDialog, {
        title: this.$t("session.session_expired_title"),
        message: this.$t("session.session_expired_message"),
      });
    }
  }

  async onSubmit() {
    this.$v.$touch();

    if (!this.$v.$error) {
      const action = login({
        email: this.email,
        password: this.password,
      })
        .then(() => loadCommentNotifications())
        .then(() => updateUserSettings())
        .then(() => {
          let path = "/";
          // Push redirect path to router if it is defined in url query
          if (this.$route.query !== undefined && this.$route.query.redirect !== undefined) {
            path = this.$route.query.redirect.toString();
          }
          this.$router.push({ path: path });
        })
        .catch((error) => {
          if (error instanceof PaymentRequired) {
            location.href = "/sessions/subscription_expired";
          } else {
            this.error = i18n.t("login.check_password");
          }
        });

      return showDialog(LoadingIndicatorDialog, { action });
    }
  }

  signup() {
    this.$router.push({ name: "SignUp" });
  }

  showFeedbackModal() {
    showDialog(FeedbackModal);
  }
}
</script>
