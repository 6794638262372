<template>
  <button
    class="group preview-image-input p-px flex align-center border rounded-xs overflow-hidden w-[60px] h-[60px]"
    type="button"
    :class="{ 'hover:border-high-em': !value }"
    @click="showModal"
  >
    <img v-if="value" class="rounded-[2px]" :src="value.url" />
    <div v-else class="placeholder text-center items-center">{{ $t("actions.add_cover_image") }}</div>
    <div
      v-if="value"
      class="change-label text-center items-center flex absolute inset-px invisible group-hover:visible rounded-[2px]"
    >
      <div class="relative">{{ $t("actions.change_cover_image") }}</div>
    </div>
  </button>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import MediaFile from "@/model/media-file";
import ContentItem from "@/model/content-item";
import { showDialog } from "@/utils/dialogs";
import PreviewImageModal from "./preview-image-modal.vue";
import Attachment from "@/model/attachment";

@Component({})
export default class PreviewImageInput extends Vue {
  @Prop()
  value: Attachment;

  @Prop()
  contentItem: ContentItem;

  async showModal() {
    const mediaFile = await showDialog<MediaFile>(PreviewImageModal, {
      contentItem: {
        specialities: [],
        ...this.contentItem,
      },
    });

    // Type is 'undefined' is cancelled
    if (typeof mediaFile !== "undefined") {
      this.$emit("input", mediaFile?.file);
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-image-input {
  position: relative;

  .placeholder,
  .change-label {
    font-size: 0.55rem;
  }

  .change-label {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--ui-color-surface-no-contrast);
      opacity: 0.78;
    }
  }
}
</style>
