<template>
  <dropdown-select
    v-bind="$attrs"
    :value="value"
    :options="options"
    :multiselect="true"
    :placeholder="$t('contents.title')"
    :floating-label="floatingLabel"
    button-class="rounded-xs"
    @input="onInput"
  ></dropdown-select>
</template>

<script lang="ts">
import ContentItemFilters from "@/model/content-item-filters";
import DropdownSelectOption from "@/model/dropdown-select-option";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ContentBySelect extends Vue {
  @Prop()
  filters: ContentItemFilters;

  @Prop({ default: false })
  floatingLabel: boolean;

  get options(): DropdownSelectOption[] | Map<string, DropdownSelectOption[]> {
    return new Map([
      [
        "",
        [
          {
            value: "all",
            label: this.$t("terms.all"),
            noCheckbox: true,
            excludeOthers: true,
          },
        ],
      ],
      [this.$t("components.content_by_select.show_only") as string, [...this.myOptions, ...this.organizationOptions]],
    ]);
  }

  get myOptions(): DropdownSelectOption[] {
    return [
      {
        value: "my_content",
        label: this.$t("contents.my_content"),
      },
      {
        value: "shared_with_me",
        label: this.$t("contents.shared_with_me"),
      },
    ];
  }

  get organizationOptions(): DropdownSelectOption[] {
    return this.$currentUser.organizations.map((organization) => {
      return {
        value: "org:" + organization.id,
        label: organization.name,
      };
    });
  }

  get value(): string[] {
    const value = [];
    if (this.myContentSelected && this.sharedWithMeSelected && this.allOrganizationsSelected) {
      value.push("all");
    } else {
      if (this.myContentSelected) {
        value.push("my_content");
      }
      if (this.sharedWithMeSelected) {
        value.push("shared_with_me");
      }
      if (this.filters.organization_ids) {
        value.push(...this.filters.organization_ids.map((id) => "org:" + id));
      }
    }
    return value;
  }

  get myContentSelected() {
    return this.filters.owner_user_ids?.includes(this.$currentUser.id);
  }

  get sharedWithMeSelected() {
    return this.filters.shared;
  }

  get allOrganizationsSelected() {
    return this.$currentUser.organizations.every((o) => this.filters.organization_ids?.includes(o.id));
  }

  onInput(value: string[]) {
    const allSelected = value.includes("all") && value.length === 1;
    const organization_ids = allSelected
      ? this.$currentUser.organizations.map((o) => o.id)
      : value.filter((v) => v.startsWith("org:")).map((v) => parseInt(v.replace("org:", "")));

    const filters = {
      ...(this.filters || {}),
      owner_user_ids: value.includes("my_content") || allSelected ? [this.$currentUser.id] : [],
      shared: value.includes("shared_with_me") || allSelected,
      organization_ids,
    };

    this.$emit("change", filters);
  }
}
</script>
