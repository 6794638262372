<template>
  <page>
    <page-header
      :title="page.name"
      :back-button-route="backButtonRoute"
      :status="page.content_item.item_state"
    ></page-header>
    <page-toolbar>
      <div class="flex flex-col gap-4 md:flex-row">
        <div class="flex-1">
          <tabs v-if="availableDocuments.length > 1">
            <tab-link
              v-for="document in availableDocuments"
              :key="document.id"
              :to="{ name: 'PageContent', params: { documentType: document.document_type } }"
            >
              {{ getDocumentLabel(document) }}
            </tab-link>
          </tabs>
        </div>
        <div class="flex gap-2 toc:flex-col">
          <page-actions-block
            :page="page"
            :document-type="documentType"
            :document-status="documentStatus"
            @page-updated="$emit('page-updated', $event)"
            @content-items-updated="onContentItemsUpdated"
          ></page-actions-block>
          <div class="flex-1 relative toc:hidden">
            <table-of-contents-block v-if="document" :doc="document.content.doc"></table-of-contents-block>
          </div>
        </div>
      </div>
    </page-toolbar>
    <page-content>
      <not-found v-if="documentStatus === 'missing'" class="mt-64"></not-found>
      <loader-big v-else-if="!document" class="mt-72"></loader-big>
      <div
        v-else
        class="flex flex-col-reverse items-start justify-between gap-6 toc:px-4 toc:flex-row xl:justify-start"
      >
        <div class="document w-full toc:w-auto toc:basis-[700px]">
          <document-view v-if="document" :doc="document.content.doc"></document-view>
        </div>
        <div class="flex-shrink basis-6 hidden xl:block"></div>
        <table-of-contents-block class="hidden toc:flex" :doc="document.content.doc"></table-of-contents-block>
      </div>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import _ from "lodash";
import Page from "@/model/page";
import Documents from "@/services/documents";
import ContentDocument, { documentStatus, documentLabel } from "@/model/content-document";
import { NotFoundError } from "@/api/api-error";
import { availableLanguages } from "@/i18n";
import { loadContentItems } from "@/actions";
import TableOfContentsBlock from "@/pages/page/table-of-contents-block.vue";
import PageActionsBlock from "@/pages/page/page-actions-block.vue";
import { RawLocation } from "vue-router";

@Component({
  beforeRouteEnter(to, from, next) {
    next((vm: PageContentPage) => {
      if (!from?.name) {
        // If we have no previous route (eg. coming from outside the app),
        // add contents route to history so we can go back to it from title back button
        vm.$router.replace({ name: "Contents" });
        vm.$router.push(to);
      }
    });
  },
  components: {
    TableOfContentsBlock,
    PageActionsBlock,
  },
})
export default class PageContentPage extends Vue {
  @Prop()
  page: Page;

  @Prop()
  documentType: string;

  document: ContentDocument = null;

  documentStatus: string = null;

  abortController: AbortController = null;

  get html() {
    return this.document ? this.document.html : "";
  }

  get availableDocuments() {
    return _.orderBy(
      this.page.documents.filter((doc) => this.page.available_document_types?.includes(doc.document_type)),
      "order"
    );
  }

  get params() {
    const p: any = {
      documentable_type: "Page",
      documentable_id: this.page.id.toString(),
    };
    if (this.documentType) {
      p.document_type = this.documentType;
    }
    return p;
  }

  get languages() {
    return Object.keys(availableLanguages).reduce((languages, key) => {
      return {
        ...languages,
        [key]: this.$t(`languages.${key}`),
      };
    }, {});
  }

  get canEdit() {
    return this.page.content_item.permissions.edit;
  }

  get backButtonRoute(): RawLocation {
    return { name: "Contents" };
  }

  async mounted() {
    return this.fetchDocument().then(() => {
      this.$trackPageView(this.page.name + " - " + (this.document.document_name || this.document.document_type));
    });
  }

  @Watch("params")
  onParamsChanged(newParams, oldParams) {
    if (!_.isEqual(newParams, oldParams)) {
      return this.fetchDocument();
    }
  }

  async fetchDocument() {
    // Abort any previous request
    this.abortController?.abort();

    this.document = null;

    if (!this.documentType) {
      this.documentStatus = "missing";
      return;
    }

    try {
      this.abortController = new AbortController();
      this.document = await new Documents().getDocument(this.params, { signal: this.abortController.signal });
      this.documentStatus = documentStatus(this.document);
    } catch (error) {
      if (error instanceof NotFoundError) {
        this.document = null;
        this.documentStatus = "missing";
      } else {
        throw error;
      }
    }
  }

  getDocumentLabel(document: ContentDocument): string {
    return documentLabel(document);
  }

  onContentItemsUpdated() {
    loadContentItems(true);
  }
}
</script>
