import LogbookEntry from "@/model/logbook-entry";
import LogbookEntriesService from "@/services/logbook-entries-service";
import UserSessions from "@/services/user_sessions";
import store from "@/store/store";
import Users from "@/services/users";
import {
  SET_CURRENT_USER,
  SET_LOADING_LOGBOOK_ENTRIES,
  SET_LOGBOOK_ENTRY_IDS,
  STORE_ENTITIES,
  UPDATE_RESIDENCY_SUCCESS,
  TERMINATE_RESIDENCY_SUCCESS,
  SET_CONTENTS_VIEW_MODE,
  SET_COMMENT_NOTIFICATIONS,
  SET_CONTENT_LOADING,
  SET_CONTENT_ITEMS,
  SET_CONTENT_FILTERS,
  SET_HOME_PAGE_LATEST_RELEASES,
  SET_HOME_PAGE_LATEST_RELEASES_LOADING,
} from "@/store/mutations";
import ResidenciesService from "@/services/residencies-service";
import Residency from "@/model/residency";
import User from "@/model/user";
import { getLocale, setLocale } from "@/i18n";
import { setTheme } from "@/theme";
import UploadService from "@/services/upload-service";
import MediaFilesService from "@/services/media-files-service";
import CommentNotificationsService from "@/services/comment-notifications-service";
import _ from "lodash";
import ContentItemsService from "@/services/content-items-service";
import ContentItemFilters from "./model/content-item-filters";
import { fetchHomePageLatestReleases } from "./services/home-page";

export async function login(credentials) {
  const user = await new UserSessions().create(credentials);
  store.commit(SET_CURRENT_USER, user);
  return user;
}

export async function logout({
  tokenExpired = false,
  subscriptionExpired = false,
}: { tokenExpired?: boolean; subscriptionExpired?: boolean } = {}): Promise<any> {
  await new UserSessions().delete();
  localStorage.clear();

  if (subscriptionExpired) {
    location.href = "/sessions/subscription_expired";
    return;
  }

  if (tokenExpired) {
    localStorage.setItem("tokenExpired", "true");
  }

  location.href = "/";
}

export async function impersonate(user: User) {
  await new UserSessions().impersonate(user.id);
  localStorage.clear();
  location.href = "/";
}

export async function stopImpersonating() {
  await new UserSessions().stopImpersonating();
  localStorage.clear();
  location.href = "/";
}

export async function loadProfile() {
  const user = await new UserSessions().getProfile();

  store.commit(SET_CURRENT_USER, user);
}

export async function updateProfile(profile: Partial<User>) {
  const user = await new Users().update(store.getters.currentUser.id, profile);

  store.commit(SET_CURRENT_USER, user);
}

export async function updateUserSettings() {
  const user = store.getters.currentUser;
  if (user) {
    if (user.language) {
      setLocale(user.language);
    } else {
      // Save language setting to ensure that it's set to same as current
      await updateProfile({ language: getLocale() });
    }

    setTheme(user.theme);
  }
}

export async function loadCommentNotifications() {
  const notifications = await new CommentNotificationsService().getAll();

  store.commit(SET_COMMENT_NOTIFICATIONS, notifications);
}

export async function loadLogbookEntries(params = null) {
  store.commit(SET_LOADING_LOGBOOK_ENTRIES, true);
  if (params) {
    // Fetching for other user (as admin). Clear previously fetched entries.
    store.commit(SET_LOGBOOK_ENTRY_IDS, []);
  }
  return new LogbookEntriesService()
    .getAll({ params })
    .then((logbookEntries) => {
      store.commit(STORE_ENTITIES, { logbookEntries });
      store.commit(
        SET_LOGBOOK_ENTRY_IDS,
        logbookEntries.map(({ id }) => id)
      );
    })
    .finally(() => store.commit(SET_LOADING_LOGBOOK_ENTRIES, false));
}

export async function addLogbookEntry(entry: Partial<LogbookEntry<object>>) {
  const logbookEntry = await new LogbookEntriesService().create(entry);

  store.commit(STORE_ENTITIES, { logbookEntries: [logbookEntry] });

  store.commit(SET_LOGBOOK_ENTRY_IDS, [...store.state.logbookEntries.ids, logbookEntry.id]);

  return logbookEntry;
}

export async function updateLogbookEntry(entry: Partial<LogbookEntry<object>>) {
  const logbookEntry = await new LogbookEntriesService().update(entry.id, entry);

  store.commit(STORE_ENTITIES, { logbookEntries: [logbookEntry] });
}

export async function deleteLogbookEntry(entry: Partial<LogbookEntry<object>>) {
  await new LogbookEntriesService().delete(entry.id);
  store.commit(
    SET_LOGBOOK_ENTRY_IDS,
    store.state.logbookEntries.ids.filter((id) => id !== entry.id)
  );
}

export async function updateResidency(id: number, params: Partial<Residency>) {
  const residency = await new ResidenciesService().update(id, params);
  store.commit(UPDATE_RESIDENCY_SUCCESS, residency);
}

export async function terminateResidency(id: number) {
  await new ResidenciesService().delete(id);
  store.commit(TERMINATE_RESIDENCY_SUCCESS, id);
}

export async function uploadFileToMediaLibrary(file: File) {
  const upload = await new UploadService().uploadFile(file);
  return new MediaFilesService().create({
    file_name: file.name,
    file_type: file.type,
    file: upload,
  });
}

export async function setContentsViewMode(viewMode: "contents-grid" | "contents-table") {
  store.commit(SET_CONTENTS_VIEW_MODE, viewMode);
}

export async function searchContentItems(search: string) {
  const filters: ContentItemFilters = {
    ...store.state.contentsView.filters,
    q: search,
    sort: search?.length > 0 ? "rank" : "name",
  };
  store.commit(SET_CONTENT_FILTERS, filters);
}

export async function loadContentItems(reload = false) {
  if (reload) {
    // Clear content items if reload is required
    store.commit(SET_CONTENT_ITEMS, null);
  }

  try {
    const { contentItems } = store.state.contents;
    const { filters } = store.state.contentsView;

    store.commit(SET_CONTENT_LOADING, true);

    const params = _.pickBy(
      {
        ...filters,
        languages: store.getters.currentUser.content_languages,
      },
      (v) => v && (v === true || !_.isEmpty(v))
    );

    // Fetch next content items
    const newItems = await new ContentItemsService().getAll({
      params: { ...params, limit: 20, offset: contentItems?.length || 0 },
    });

    // Append fetched items to list
    store.commit(SET_CONTENT_ITEMS, [...(contentItems || []), ...newItems]);
  } finally {
    store.commit(SET_CONTENT_LOADING, false);
  }
}

export async function loadHomePageLatestReleases() {
  if (store.state.homePage.loadingLatestReleases) {
    return;
  }
  store.commit(SET_HOME_PAGE_LATEST_RELEASES_LOADING, true);
  try {
    const latestReleases = await fetchHomePageLatestReleases();
    store.commit(SET_HOME_PAGE_LATEST_RELEASES, latestReleases);
  } finally {
    store.commit(SET_HOME_PAGE_LATEST_RELEASES_LOADING, false);
  }
}
