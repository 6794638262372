<template>
  <component
    :is="component"
    class="nav-link h-14 w-60 px-5 rounded-full flex items-center cursor-pointer text-label-strong text-text-primary group dark:text-text-variant hover:text-text-emphasis dark:hover:text-text-emphasis"
    :to="to"
  >
    <div class="w-5 h-5 flex items-center justify-center mr-2">
      <slot name="icon">
        <icon-fluent class="group-hover:hidden" :name="icon"></icon-fluent>
        <icon-fluent class="hidden group-hover:inline-block" :name="icon" variant="filled"></icon-fluent>
      </slot>
    </div>
    <div class="title">{{ title }}</div>
  </component>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class NavLink extends Vue {
  @Prop()
  to: string;

  @Prop()
  title: string;

  @Prop()
  icon: string;

  get component() {
    return this.to ? "router-link" : "a";
  }
}
</script>

<style lang="scss" scoped>
a.nav-link {
  @media (hover: hover) {
    &:hover {
      // Set normal brightness for non-active links and exact links
      // (non-exact links have modified brightness declared with css class)
      &:not(.router-link-active),
      &.router-link-exact-active {
        filter: brightness(1);
      }
    }
  }

  &.router-link-active {
    background-color: var(--ui-color-surface-low-contrast);
    color: var(--ui-color-text-emphasis);

    @media (hover: hover) {
      // Specify hover color to be the same as non-hover for active links
      // Non active links get hover color from css class
      &:hover {
        color: var(--ui-color-text-emphasis);
      }
    }

    svg.regular {
      display: none;
    }
    svg.filled {
      display: inline-block;
    }
  }
}
</style>
