<template>
  <div v-lazy:background-image="backgroundImage" class="flex-1 flex justify-center items-center p-4 bg-cover">
    <card class="w-full max-w-[24rem]">
      <div class="flex justify-center mt-8">
        <div class="flex justify-center items-center w-48 h-48 bg-surface-mid-contrast rounded-full">
          <inline-svg class="svg-icon" :src="require('@/assets/images/lock.svg')"></inline-svg>
        </div>
      </div>
      <h3 class="mt-8 text-text-emphasis text-center">{{ $t("recover_password.title") }}</h3>
      <form class="mt-12 mb-8" @submit.prevent="onSubmit()">
        <text-input v-model="email" type="email" placeholder="Email" :floating-label="true"></text-input>
        <button class="btn-primary w-full justify-center mt-8" type="submit">
          {{ $t("recover_password.recover_password") }}
        </button>
      </form>
    </card>
  </div>
</template>

<script lang="ts">
import ResultModal from "@/components/result-modal.vue";
import Users from "@/services/users";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import backgroundImage from "@/assets/images/background.png";

@Component({})
export default class RequestPasswordResetPage extends Vue {
  email: String = "";

  @Validations()
  validations = {
    email: { required },
  };

  get userService() {
    return new Users();
  }

  get backgroundImage() {
    return backgroundImage;
  }

  async onSubmit() {
    this.$v.$touch();

    if (!this.$v.$error) {
      await this.userService.requestPasswordReset(this.email);

      await showDialog(ResultModal, {
        title: this.$t("recover_password.recover_password"),
        content: this.$t("recover_password.instructions"),
      });

      this.$router.back();
    }
  }

  onCancel() {
    this.$router.back();
  }
}
</script>
