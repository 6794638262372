<template>
  <div v-if="!contentItems" class="flex flex-col">
    <div class="bg-surface-mid-contrast rounded h-5 w-40"></div>
    <div class="grid grid-cols-2 gap-4 mt-4 sm:grid-cols-3 lg:grid-cols-1">
      <div v-for="i in 2" :key="'skeleton-' + i" class="rounded-sm bg-surface-mid-contrast h-16 xs:h-14"></div>
    </div>
  </div>
  <div v-else-if="contentItems.length > 0 || showAddButton" class="flex flex-col">
    <div class="flex justify-between">
      <div class="text-label-strong text-text-primary">{{ title }}</div>
      <a class="link text-button-small" @click="goToContentsPage()">{{ $t("actions.show_more") }}</a>
    </div>
    <div class="flex gap-4 mt-4 overflow-hidden lg:flex-col">
      <router-link
        v-for="contentItem in contentItems"
        :key="contentItem.id"
        class="shrink-0 w-[calc(50%-0.5rem)] sm:w-[calc(33.33%-0.66rem)] md:w-full"
        :to="`/pages/${contentItem.content_id}`"
      >
        <content-item-card :content-item="contentItem"></content-item-card>
      </router-link>
      <router-link
        v-if="showAddButton"
        class="border-dashed border-border-variant border-2 rounded-sm h-16 justify-center text-button-small text-text-emphasis duration-300 xs:h-14 hover:border-solid hover:border-border-emphasis active:bg-interactive-bg-neutral-low-contrast w-[calc(50%-0.5rem)] sm:w-[calc(33.33%-0.66rem)] md:w-full"
        :to="addButtonRoute"
        tag="button"
      >
        <icon-fluent name="add" variant="filled" size="16"></icon-fluent>
        <div class="ml-2">{{ $t("actions.add") }}</div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import ContentItem from "@/model/content-item";
import ContentItemFilters from "@/model/content-item-filters";
import ContentItemsService from "@/services/content-items-service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ContentItemCard from "@/pages/home/highlighted-contents-block/content-item-card.vue";
import { SET_CONTENT_FILTERS } from "@/store/mutations";

@Component({
  components: {
    ContentItemCard,
  },
})
export default class HighlightedContentsBlock extends Vue {
  @Prop()
  title: string;

  @Prop()
  filters: Partial<ContentItemFilters>;

  @Prop()
  action?: string;

  @Prop()
  type: string;

  @Prop()
  fetchItems?: () => Promise<ContentItem[]>;

  contentItems: ContentItem[] = null;

  get showAddButton() {
    return this.type === "my_content" && this.contentItems && this.contentItems.length < 3;
  }

  get addButtonRoute() {
    return {
      name: "AddPage",
      params: {
        pageType: "general",
      },
    };
  }
  get params() {
    return {
      ...this.filters,
      languages: this.$store.getters.currentUser.content_languages,
      limit: 3,
    };
  }

  goToContentsPage() {
    if (this.filters) {
      this.$store.commit(SET_CONTENT_FILTERS, this.filters);
    }
    this.$router.push({ name: "Contents" });
  }

  async created() {
    if (this.fetchItems) {
      this.contentItems = await this.fetchItems();
    } else {
      this.contentItems = await new ContentItemsService().getAll({ params: this.params, action: this.action });
    }
  }
}
</script>
