<template>
  <modal :closable="true" :centered="false" :size="size" @close="$emit('cancel')">
    <loader-big v-if="!quiz"></loader-big>
    <div v-else class="quiz">
      <div v-if="state == 'not_started'" class="quiz-start">
        <h3 class="mb-4">{{ quiz.name }}</h3>
        <p class="description">{{ quiz.description }}</p>
        <div class="flex flex-row flex-center mt-md">
          <button class="btn-primary" @click="start()">{{ $t("actions.start") }}!</button>
        </div>
      </div>
      <quiz-question-view
        v-if="state == 'active'"
        :quiz="quiz"
        :question-number="answers.length + 1"
        :question="currentQuestion"
        @answer="answer"
      ></quiz-question-view>
      <div v-if="state == 'finished'" class="quiz-summary">
        <h3 class="mb-4">{{ $t("quiz.summary_title") }}</h3>
        <p v-if="allCorrect" class="result">{{ $t("quiz.all_correct_message") }}</p>
        <p v-else class="result">
          {{ $t("quiz.number_correct_message", { correct: correctAnswers, questions: quiz.questions.length }) }}
        </p>
        <div class="flex flex-row justify-end mt-md">
          <button class="btn-primary" @click="finish()">{{ $t("actions.ok") }}</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script lang="ts">
import { addLogbookEntry } from "@/actions";
import LogbookEntry from "@/model/logbook-entry";
import Quiz from "@/model/quiz";
import QuizAnswer from "@/model/quiz-answer";
import QuizQuestionAnswer from "@/model/quiz-question-answer";
import QuizzesService from "@/services/quizzes-service";
import dayjs from "dayjs";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import QuizQuestionView from "./quiz-question-view.vue";
import QuizData from "@/model/quiz-data";

@Component({
  components: {
    QuizQuestionView,
  },
})
export default class QuizModal extends Vue {
  @Prop()
  id: number;

  @Prop({ default: "large" })
  size: string;

  @Prop()
  preview: Quiz;

  quiz: Quiz = null;
  answers: QuizQuestionAnswer[] = null;
  logbookEntry: LogbookEntry<QuizData> = null;

  get state(): string {
    if (!this.started) {
      return "not_started";
    } else if (!this.finished) {
      return "active";
    } else {
      return "finished";
    }
  }

  get started() {
    return !!this.answers;
  }

  get questionNumber() {
    return this.answers.length + 1;
  }

  get finished() {
    return this.answers.length == this.quiz.questions.length;
  }

  get currentQuestion() {
    return this.quiz.questions[this.answers.length];
  }

  get correctAnswers(): number {
    return this.answers.filter((answer, index) => {
      const option = this.quiz.questions[index]?.options.find(({ id }) => id === answer.option_id);
      return option?.is_correct_answer;
    }).length;
  }

  get allCorrect(): boolean {
    return this.correctAnswers === this.quiz.questions.length;
  }

  start() {
    this.answers = [];
  }

  finish() {
    if (this.logbookEntry?.curriculum_item) {
      this.$toast.info(this.$t("logbook.requirement_completed_message") as string);
    }

    this.$emit("ok");
  }

  async answer(answer: QuizQuestionAnswer) {
    this.answers.push(answer);

    if (this.finished && !this.preview) {
      const answer = await this.saveAnswer();
      this.logbookEntry = await this.saveLogbookEntry(answer);
    }
  }

  async created() {
    if (this.preview) {
      this.quiz = this.preview;
    } else {
      await this.fetchQuiz();
    }

    if (_.isEmpty(this.quiz.description)) {
      // skip start page if description is empty
      this.start();
    }
  }

  async fetchQuiz() {
    return new QuizzesService().get(this.id.toString()).then((quiz) => (this.quiz = quiz));
  }

  saveAnswer(): Promise<QuizAnswer> {
    const answer = { answers: this.answers };
    return new QuizzesService().answer(this.quiz.id, answer);
  }

  saveLogbookEntry(answer: QuizAnswer): Promise<LogbookEntry<QuizData>> {
    const entry: Partial<LogbookEntry<QuizData>> = {
      entry_type: "quiz",
      date: dayjs().format("YYYY-MM-DD"),
      data: {
        quiz_answer_id: answer.id,
      },
    };
    return addLogbookEntry(entry) as Promise<LogbookEntry<QuizData>>;
  }
}
</script>

<style lang="scss" scoped>
.description {
  white-space: pre-line;
}
.quiz {
  margin-top: var(--spacing);
}
</style>
