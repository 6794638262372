<template>
  <dropdown-select
    v-bind="$attrs"
    :options="options"
    :allow-null="allowNull"
    :placeholder="$t('attributes.speciality')"
    button-class="rounded-xs"
    :allow-text-search="true"
    :floating-label="floatingLabel"
    @input="onInput"
    @show="fetchSpecialities"
  ></dropdown-select>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import SpecialitiesService from "@/services/specialities-service";
import Speciality, { SOFT_LAUNCH_SPECIALITY_CODES } from "@/model/speciality";
import { BuildDropdownSelectOptionHierarchy } from "@/model/dropdown-select-option";

@Component({})
export default class SpecialitySelect extends Vue {
  @Prop({ default: false })
  usedValuesOnly: boolean;

  @Prop({ default: true })
  allowNull: boolean;

  @Prop({ default: false })
  softLaunch: boolean;

  @Prop({ default: false })
  floatingLabel: boolean;

  specialities: Speciality[] = null;

  get loaded() {
    return !!this.specialities;
  }

  get options() {
    if (!this.loaded) return null;
    return BuildDropdownSelectOptionHierarchy(this.softLaunch ? this.softLaunchSpecialities : this.specialities);
  }

  get softLaunchSpecialities() {
    return this.specialities.filter((s) => SOFT_LAUNCH_SPECIALITY_CODES.includes(s.code));
  }

  get systemLanguage() {
    return this.$currentUser?.language;
  }

  onInput(value) {
    this.$emit("input", value);
  }

  async created() {
    return this.fetchSpecialities();
  }

  @Watch("systemLanguage")
  async fetchSpecialities() {
    const params = {};
    if (this.usedValuesOnly) {
      params["used_values_only"] = "1";
    }
    return new SpecialitiesService().getAll({ params }).then((specialities) => (this.specialities = specialities));
  }
}
</script>

<style lang="scss" scoped></style>
