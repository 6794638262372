<template>
  <div class="flex flex-col h-full">
    <div class="h-8 flex items-center">
      <div class="flex-1 flex items-center" @click="updateSort('name')">
        {{ $t("attributes.name") }}
        <icon-fluent
          v-if="sort === 'name'"
          class="h-4 ml-xs"
          :name="sortDirection === 'asc' ? 'arrowUp' : 'arrowDown'"
          variant="filled"
        ></icon-fluent>
      </div>
      <div class="w-32 flex items-center" @click="updateSort('type')">
        {{ $t("attributes.type") }}
        <icon-fluent
          v-if="sort === 'type'"
          class="h-4 ml-xs"
          :name="sortDirection === 'asc' ? 'arrowUp' : 'arrowDown'"
          variant="filled"
        ></icon-fluent>
      </div>
      <div class="w-32 flex items-center" @click="updateSort('date')">
        {{ $t("attributes.date") }}
        <icon-fluent
          v-if="sort === 'date'"
          class="h-4 ml-xs"
          :name="sortDirection === 'asc' ? 'arrowUp' : 'arrowDown'"
          variant="filled"
        ></icon-fluent>
      </div>
    </div>
    <scroll-container class="flex-1" @scroll-end="$emit('load-more')">
      <media-list-row
        v-for="mediaFile in mediaFiles"
        :key="mediaFile.id || mediaFile.tempId"
        :media-file="mediaFile"
        :selected="selectedFiles && selectedFiles.includes(mediaFile)"
        @click="$emit('select', mediaFile, $event)"
        @dblclick="$emit('select-one', mediaFile)"
      ></media-list-row>
    </scroll-container>
  </div>
</template>

<script lang="ts">
import MaybeUploading from "@/model/maybe-uploading";
import MediaFile from "@/model/media-file";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MediaListRow from "@/components/media-library/media-list-row.vue";
import { MediaLibrarySort, MediaLibrarySortDirection } from "@/components/media-library/media-library.vue";

@Component({
  components: {
    MediaListRow,
  },
})
export default class MediaList extends Vue {
  @Prop()
  mediaFiles: MaybeUploading<MediaFile>[];

  @Prop()
  selectedFiles: MediaFile[];

  @Prop()
  sort: MediaLibrarySort;

  @Prop()
  sortDirection: MediaLibrarySortDirection;

  updateSort(sort) {
    if (this.sort === sort) {
      this.$emit("update-sort-direction", this.sortDirection === "asc" ? "desc" : "asc");
    } else {
      this.$emit("update-sort", sort);
    }
  }
}
</script>
