<template>
  <theme-provider
    v-lazy:background-image="backgroundImage"
    theme="dark"
    class="fixed inset-0 z-50 opacity-0 lazy-loaded:opacity-100 transition-opacity duration-300"
  >
    <div class="h-full w-full flex flex-col items-center justify-center">
      <inline-svg class="animate-scale" :src="require('@/assets/images/osgenic-brand.svg')" />
      <h2 class="mt-4 animate-pulse">{{ props.title }}</h2>
    </div>
  </theme-provider>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { TranslateResult } from "vue-i18n";
import backgroundImage from "@/assets/images/signup-background.png";

const props = defineProps<{ title: string | TranslateResult; action?: Promise<any> }>();

const emit = defineEmits(["error", "ok"]);

onMounted(() => {
  if (props.action) {
    props.action.then((result) => emit("ok", result)).catch((err) => emit("error", err));
  }
});
</script>
