<template>
  <page>
    <page-header :title="'Invivo Anatomy'">
      <template #right>
        <div class="hidden sm:block">
          <search-input
            v-model="search"
            type="contextual"
            :placeholder="$t('actions.search')"
            class="w-96"
            @reset="onReset"
          ></search-input>
        </div>
      </template>
    </page-header>
    <page-toolbar class="sm:hidden">
      <search-input
        v-model="search"
        type="contextual"
        :placeholder="$t('actions.search')"
        @reset="onReset"
      ></search-input>
    </page-toolbar>
    <page-content>
      <scroll-detector v-if="isFetched" @end="loadMore">
        <div class="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-5">
          <invivo-card v-for="dynamicScene in dynamicScenes" :key="dynamicScene.id" :dynamic-scene="dynamicScene" />
        </div>
      </scroll-detector>
    </page-content>
  </page>
</template>

<script setup lang="ts">
import { useDynamicScenesInfinitely } from "@/api/dynamic-scenes";
import { computed, ref } from "vue";
import InvivoCard from "./invivo-card.vue";

const search = ref<string>("");

const { data, isFetched, isFetching, hasNextPage, fetchNextPage } = useDynamicScenesInfinitely(search);

const dynamicScenes = computed(() => {
  const pages = data.value?.pages;

  if (!pages) {
    return [];
  }

  return pages.flatMap((page) => page);
});

const onReset = () => {
  search.value = "";
};

const loadMore = () => {
  if (hasNextPage.value && !isFetching.value) {
    fetchNextPage();
  }
};
</script>
