<template>
  <router-link
    class="nav-sublink px-10 text-label-strong group mb-md text-text-primary dark:text-text-variant hover:text-text-emphasis dark:hover:text-text-emphasis"
    :to="to"
  >
    {{ title }}
  </router-link>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class NavSublink extends Vue {
  @Prop()
  to: string;

  @Prop()
  title: string;
}
</script>

<style lang="scss" scoped>
a.nav-sublink {
  @media (hover: hover) {
    &:hover {
      // Set normal brightness for non-active links and exact links
      // (non-exact links have modified brightness declared with css class)
      &:not(.router-link-active),
      &.router-link-exact-active {
        filter: brightness(1);
      }
    }
  }

  &.router-link-active {
    color: var(--ui-color-text-emphasis);

    @media (hover: hover) {
      // Specify hover color to be the same as non-hover for active links
      // Non active links get hover color from css class
      &:hover {
        color: var(--ui-color-text-emphasis);
      }
    }
  }
}
</style>
