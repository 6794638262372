<template>
  <div class="flex flex-1 flex-col">
    <div v-if="loading" class="flex-1 flex-col flex-center">
      <loader-big></loader-big>
    </div>
    <router-view v-else-if="page" :page="page" @page-updated="onPageUpdated"></router-view>
    <not-found v-else class="mb-lg flex-center"></not-found>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Page from "@/model/page";
import PagesService from "@/services/pages-service";
import { routeForPage } from "@/utils/routes";
import { Route } from "vue-router";
import { loadContentItems } from "@/actions";

@Component({
  beforeRouteEnter(to, from, next) {
    next((vm: PageShowPage) => {
      vm.from = from;
    });
  },
})
export default class PageShowPage extends Vue {
  from: Route = null;

  page: Page = null;

  loading: boolean = false;

  onPageUpdated(page: Page) {
    this.page = page;
    return loadContentItems(true);
  }

  async mounted() {
    this.loading = true;

    return new PagesService()
      .get(this.$route.params.id)
      .then((page) => {
        this.page = page;

        // Redirect to first document url if documentType not given
        if (!this.$route.params.documentType) {
          const route = routeForPage(page);
          if (route) {
            this.$router.replace(route);
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
</script>
