<template>
  <button class="dropdown-button" :disabled="disabled" :title="tooltip" @click="$emit('click')">
    <icon-fluent v-if="icon" class="w-4 h-4 mr-sm" :name="icon"></icon-fluent>{{ title }}
  </button>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class DropdownButton extends Vue {
  @Prop()
  icon: string;

  @Prop()
  title: string;

  @Prop()
  tooltip: string;

  @Prop()
  disabled: boolean;
}
</script>

<style lang="scss" scoped>
button.dropdown-button {
  height: 40px;
  width: 100%;
  padding: 0 var(--spacing);
  background-color: transparent;
  color: var(--ui-color-text-variant);

  &:hover:not(:disabled) {
    color: var(--ui-color-text-primary);
  }
  &:disabled {
    background-color: transparent;
    color: var(--color-on-disabled);
  }
}
</style>
