<template>
  <card :title="$t('dashboard.recent_resident_activity')">
    <loader-big v-if="!recentEntries"></loader-big>
    <div v-else class="entries">
      <div v-for="entry in recentEntries" :key="entry.id" class="entry">
        <div class="flex flex-col mr-sm">
          <avatar :user="entry.user"></avatar>
          <div class="centerline"></div>
        </div>
        <div class="flex flex-col">
          <div class="text-body-medium">{{ entry.user.name }}</div>
          <div class="text-label-strong text-text-primary">{{ entryTitle(entry) }}</div>
          <div class="text-body-small">{{ relativeDateFilter(entry.created_at) }}</div>
        </div>
      </div>
    </div>
  </card>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import DashboardService from "@/services/dashboard-service";
import LogbookEntry from "@/model/logbook-entry";
import { relativeDateFilter } from "@/filters";

@Component({})
export default class RecentActivityCard extends Vue {
  recentEntries: LogbookEntry<any>[] = null;

  get dashboardService(): DashboardService {
    return new DashboardService();
  }

  entryTitle(entry: LogbookEntry<any>) {
    switch (entry.entry_type) {
      case "procedure":
        return this.$t("dashboard.added_procedure");
      case "course":
        return this.$t("dashboard.added_course");
      default:
        return this.$t("dashboard.added_other");
    }
  }

  async mounted() {
    return this.fetchRecentActivity();
  }

  async fetchRecentActivity() {
    this.recentEntries = await this.dashboardService.getRecentActivity();
  }

  relativeDateFilter(value: string) {
    return relativeDateFilter(value);
  }
}
</script>

<style lang="scss" scoped>
.entry {
  display: flex;
  flex-direction: row;
  min-height: 70px;
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .centerline {
    margin-top: 3px;
    flex: 1;
    border-right: 0.5px solid var(--color-outline);
    align-self: center;
  }
}
</style>
