import ApiService from "@/services/api-service";
import ContentItem from "@/model/content-item";

export default class ContentItemsService extends ApiService<ContentItem> {
  get baseUrl() {
    return "/api/v1/content_items";
  }

  getPublic(token: string): Promise<ContentItem> {
    const url = this.buildUrl(`public/${token}`, {});
    const headers = this.baseHeaders;
    return fetch(url, {
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  createShareToken(contentItem: ContentItem): Promise<ContentItem> {
    const url = this.buildUrl(contentItem.id, { action: "share_token" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  deleteShareToken(contentItem: ContentItem): Promise<ContentItem> {
    const url = this.buildUrl(contentItem.id, { action: "share_token" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "delete",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  deleteUserShare(contentItem: ContentItem): Promise<ContentItem> {
    const url = this.buildUrl(contentItem.id, { action: "user_share" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "delete",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  favorite(contentItem: ContentItem): Promise<ContentItem> {
    const url = this.buildUrl(contentItem.id, { action: "favorite" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  unfavorite(contentItem: ContentItem): Promise<ContentItem> {
    const url = this.buildUrl(contentItem.id, { action: "favorite" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "delete",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }
}
