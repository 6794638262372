import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import ImageRowNodeView from "@/editor/extensions/image-row/image-row-node-view.vue";
import { TextSelection } from "@tiptap/pm/state";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    imageRow: {
      addImageRow: () => ReturnType;
    };
  }
}

const ImageRowNode = Node.create({
  name: "imageRow",

  group: "inline",

  inline: true,

  content: "image*",

  addCommands() {
    return {
      addImageRow:
        () =>
        ({ dispatch, tr, editor }) => {
          if (dispatch) {
            const offset = tr.selection.anchor + 1;
            const imageRow = editor.schema.nodes.imageRow.createChecked({});
            tr.replaceSelectionWith(imageRow)
              .scrollIntoView()
              .setSelection(TextSelection.near(tr.doc.resolve(offset)));
          }

          return true;
        },
    };
  },

  parseHTML() {
    return [
      {
        tag: "image-row-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["image-row-node", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageRowNodeView);
  },
});

export default ImageRowNode;
