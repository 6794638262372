<template>
  <page>
    <page-header :title="$t('pubmed_search.title')">
    </page-header>
    <page-content>
      <div class="flex flex-col gap-4 w-full max-w-2xl mx-auto">
        <!-- Chat messages -->
        <div 
          ref="chatContainer"
          class="space-y-4"
        >
          <div v-for="(message, index) in chatHistory" :key="index" class="flex">
            <div :class="[
              'p-3 rounded-sm min-w-[200px] max-w-[75%]',
              message.type === 'user' ? 'bg-blue-100 text-blue-800 ml-auto' : 'bg-gray-100 text-gray-800'
            ]">
              <div class="font-bold mb-1">{{ message.type === 'user' ? 'Query:' : 'Response:' }}</div>
              <div class="prose prose-sm prose-tight max-w-none" v-html="renderMarkdown(message.content)"></div>
            </div>
          </div>
        </div>

        <!-- New Query button (only shown after a completed search) -->
        <div v-if="searchStatus === 'completed'" class="flex justify-end">
          <button 
            class="btn-primary px-4"
            @click="startNewQuery"
          >
            New Query
          </button>
        </div>

        <!-- Loading indicator -->
        <loader-big v-if="isLoading" class="my-4"></loader-big>

        <!-- Input field with search button (only shown when search status allows) -->
        <div v-if="searchStatus === 'idle'" class="flex space-x-2">
          <input
            type="text"
            class="flex-grow p-2 rounded-lg border border-gray-200 bg-white"
            :placeholder="$t('pubmed_search.placeholder')"
            v-model="query"
            @keyup.enter="onSearch"
            :disabled="isLoading"
          />
          <button 
            class="btn-primary px-4 py-2"
            @click="onSearch"
            :disabled="isLoading || !query.trim()"
          >
            Search
          </button>
        </div>
      </div>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import AiSearchService from "@/services/ai-search-service";
import { createConsumer } from "@rails/actioncable";
import { useMarkdown } from "@/composables/use-markdown";

interface ChatMessage {
  type: "user" | "assistant";
  content: string;
  refinedQuery?: string;
}

@Component({})
export default class PubmedSearchPage extends Vue {
  query = "";
  chatHistory: ChatMessage[] = [];
  aiSearchService = new AiSearchService();
  isLoading = false;
  jobId: string | null = null;
  searchStatus: 'idle' | 'queued' | 'completed' | 'failed' = 'idle';
  
  // Create cable consumer as a class property
  private cable = createConsumer();
  private searchSubscription: any = null;

  // Establish WebSocket connection when component is created
  created() {
    this.setupPubmedSearchSubscription();
  }

  setupPubmedSearchSubscription() {
    this.searchSubscription = this.cable.subscriptions.create(
      { 
        channel: 'PubmedSearchChannel',
        user_id: this.$currentUser.id
      },
      {
        connected: () => {
          console.log('Connected to PubmedSearchChannel');
        },
        disconnected: () => {
          console.log('Disconnected from PubmedSearchChannel');
        },
        rejected: () => {
          console.log('Subscription to PubmedSearchChannel was rejected');
        },
        received: (data) => {
          
          if (data.status === 'refining' && data.refined_query) {
            this.chatHistory.push({ 
              type: "assistant", 
              content: `Refined search query: ${data.refined_query}`,
              refinedQuery: data.refined_query
            });
          }
          
          if (data.status === 'articles_list' && data.articles_message) {
            this.chatHistory.push({ 
              type: "assistant", 
              content: data.articles_message
            });
          }
          
          if (data.status === 'analyzing') {
            this.chatHistory.push({ 
              type: "assistant", 
              content: data.status_message
            });
          }

          if (data.status === 'completed') {
            this.handleSearchResult(data);
          } else if (data.status === 'failed') {
            this.handleSearchError(data.error);
          }
        }
      }
    );
  }

  async onSearch() {
    // Prevent multiple searches
    if (!this.query.trim() || this.isLoading || this.searchStatus !== 'idle') return;

    this.chatHistory.push({ type: "user", content: this.query });
    this.isLoading = true;
    this.searchStatus = 'queued';

    try {
      const chatHistoryForApi = this.chatHistory.map((message) => ({
        role: message.type,
        content: message.content,
      }));

      // Initiate background search
      const response = await this.aiSearchService.pubmedSearch({
        chat_history: chatHistoryForApi,
      });

      this.jobId = response.job_id;
    } catch (error) {
      console.error("Error initiating PubMed search:", error);
      this.isLoading = false;
      this.searchStatus = 'failed';
    }
  }

  handleSearchResult(data) {
    this.chatHistory.push({ 
      type: "assistant", 
      content: data.result,
      refinedQuery: data.refined_query
    });
    this.isLoading = false;
    this.searchStatus = 'completed';
    this.query = ""; // Clear the input
  }

  handleSearchError(error) {
    console.error("PubMed Search Error:", error);
    this.chatHistory.push({ 
      type: "assistant", 
      content: "An error occurred while fetching results." 
    });
    this.isLoading = false;
    this.searchStatus = 'failed';
  }

  startNewQuery() {
    // Reset to initial state for a new query
    this.chatHistory = [];
    this.query = "";
    this.searchStatus = 'idle';
    this.jobId = null;
  }

  // Optional: Cleanup subscription when component is destroyed
  beforeDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  renderMarkdown(content: string): string {
    const { render } = useMarkdown();
    return render(content);
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/_prose.scss";

</style>