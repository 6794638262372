<template>
  <editor :doc="doc" :editable="false"></editor>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Content } from "@tiptap/core";
import Editor from "@/editor/components/editor.vue";

@Component({
  components: {
    Editor,
  },
})
export default class DocumentView extends Vue {
  @Prop()
  doc: Content;
}
</script>
