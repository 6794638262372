<template>
  <router-link
    class="nav-link text-button-small group text-text-variant px-lg py-2 dark:text-text-variant hover:text-text-emphasis dark:hover:text-text-primary hover:brightness-95 dark:hover:brightness-120"
    :to="to"
  >
    <div class="icon-container mr-2">
      <icon-fluent class="group-hover:hidden" :name="icon" :size="16"></icon-fluent>
      <icon-fluent class="hidden group-hover:inline-block" :name="icon" :size="16" variant="filled"></icon-fluent>
    </div>
    <div class="title">{{ title }}</div>
  </router-link>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class NavBottomLink extends Vue {
  @Prop()
  to: string;

  @Prop()
  title: string;

  @Prop()
  icon: string;
}
</script>

<style lang="scss" scoped>
a.nav-link {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  align-items: center;

  @media (hover: hover) {
    &:hover {
      // Set normal brightness for non-active links and exact links
      // (non-exact links have modified brightness declared with css class)
      &:not(.router-link-active),
      &.router-link-exact-active {
        filter: brightness(1);
      }
    }
  }

  &.router-link-active {
    color: var(--ui-color-text-emphasis);

    @media (hover: hover) {
      // Specify hover color to be the same as non-hover for active links
      // Non active links get hover color from css class
      &:hover {
        color: var(--ui-color-text-emphasis);
      }
    }

    svg.regular {
      display: none;
    }
    svg.filled {
      display: inline-block;
    }
  }

  .icon-container {
    display: flex;
    justify-content: center;
    height: 14px;
    width: 20px;

    svg {
      height: 14px;
    }
  }
}
</style>
