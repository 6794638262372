<template>
  <div class="confirmable-input">
    <div v-show="!editing">
      <div class="h-10 flex flex-col justify-end pb-2">
        <slot name="value"></slot>
      </div>
      <a class="link text-xs" @click="setEditState(true)">{{ editLabel }}</a>
    </div>
    <div v-show="editing" class="confirmable-input__input-wrapper">
      <slot :save="save"></slot>
      <div class="confirmable-input__actions flex mt-1">
        <a class="link text-xs mr-sm cancel" :disabled="saving" @click="cancel">{{ $t("actions.cancel") }}</a>
        <a class="link text-xs mr-sm" :disabled="saving" @click="save">{{ $t("actions.save") }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import i18n from "@/i18n";

@Component({})
export default class ConfirmableInput extends Vue {
  @Prop()
  value: any;

  // Text shown as edit link
  @Prop({ default: () => i18n.t("actions.edit") })
  editLabel: string;

  // Save logic passed as prop from parent that uses the component
  @Prop()
  onSave: (value: any) => Promise<any>;

  // Value to reset to on cancel
  originalValue: any = null;

  editing: boolean = false;

  saving: boolean = false;

  setEditState(state: boolean) {
    this.originalValue = state ? this.value : null;
    this.editing = state;
    if (this.editing) {
      this.$emit("edit", this);
      this.$nextTick(() => {
        this.$el.querySelector("input")?.focus();
      });
    }
  }

  cancel() {
    this.$emit("input", this.originalValue);
    this.setEditState(false);
    this.$emit("cancel", this);
  }

  save() {
    if (this.saving) {
      return;
    }

    this.editing = false;
    this.saving = true;
    this.onSave(this.value)
      .catch(() => this.$emit("input", this.originalValue))
      .finally(() => {
        this.saving = false;
      });
    this.$emit("save", this);
  }
}
</script>

<style lang="scss" scoped>
a.link.cancel {
  color: var(--ui-color-text-variant);
}
</style>
