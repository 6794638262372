import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import { v4 as uuidv4 } from "uuid";
import StepNodeView from "@/editor/extensions/step/step-node-view.vue";
import { TextSelection } from "prosemirror-state";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    step: {
      insertStep: () => ReturnType;
      removeStep: () => ReturnType;
    };
  }
}

const StepNode = Node.create({
  name: "step",

  content: "(heading | paragraph) block*",

  addCommands() {
    return {
      insertStep: () => ({ tr, editor, dispatch }) => {
        // Create step node
        const heading = editor.schema.nodes.heading.createChecked({ level: 1 });
        const step = editor.schema.nodes.step.createChecked({}, [heading]);

        if (dispatch) {
          // Insert step into current position, and move selection to step title
          const offset = tr.selection.anchor + 1;
          tr.replaceSelectionWith(step)
            .scrollIntoView()
            .setSelection(TextSelection.near(tr.doc.resolve(offset)));
        }

        return true;
      },

      removeStep: () => ({ tr, dispatch, state }) => {
        const { $head } = state.selection;
        if ($head.node(1).type.name === "step") {
          if (dispatch) {
            tr.delete($head.before(1), $head.after(1)).scrollIntoView();
          }
        }

        return true;
      },
    };
  },

  addKeyboardShortcuts() {
    return {
      // Custom backspace handler for step
      // This was needed because default would not remove step at the beginning of document
      Backspace: () => {
        const { head, $head } = this.editor.state.selection;
        // Are we at start of step?
        if ($head.node(1).type.name === "step" && $head.start(1) === head - 1) {
          return this.editor.commands.deleteNode("step");
        }

        // Return null to pass to through to default handler
        return null;
      },

      Enter: () => {
        const { head, anchor, $head } = this.editor.state.selection;

        // Are we at end of step?
        if ($head.node(1).type.name === "step" && $head.end(1) === anchor + 1 && $head.parent.childCount == 0) {
          const isStart = $head.start(1) === head - 1;

          if (isStart) {
            // If we are at beginning of step, then insert paragraph
            // outside and remove step
            return this.editor
              .chain()
              .insertContentAt(anchor + 2, { type: "paragraph" }, { updateSelection: false })
              .deleteNode("step")
              .focus()
              .run();
          } else {
            // Otherwise add new step after this step.
            const content = { type: "step", content: [{ type: "heading", attrs: { level: 1 } }] };
            return this.editor
              .chain()
              .insertContentAt(anchor + 2, content, { updateSelection: true })
              .focus()
              .run();
          }
        }

        // Return null to pass to through to default handler
        return null;
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "step-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["step-node", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(StepNodeView);
  },
});

export default StepNode;
