import { Node } from "@tiptap/vue-2";
import { Plugin } from "prosemirror-state";
import { v4 as uuidv4 } from "uuid";

const types =  ["heading", "step"];

 const Uid = Node.create({
  name: 'uid',

  addGlobalAttributes() {
    return [
      {
        types: types,
        attributes: {
          uid: {
            default: null,
            keepOnSplit: false,
            parseHTML: element => element.getAttribute('data-uid'),
            // … and customize the HTML rendering.
            renderHTML: attributes => {
              return {
                'id': attributes.uid,
                'data-uid': attributes.uid,
              }
            },
          },
        },
      },
    ]
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (_transactions, oldState, newState) => {
          const tr = newState.tr

          newState.doc.descendants((node, pos, _parent) => {
            if (!node.attrs.uid && types.includes(node.type.name)) {
              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                uid: uuidv4(),
              })
            }

            // Don't descend below top level
            return false;
          })

          return tr;
        },
      }),
    ]
  },
});

export default Uid;