<template>
  <div class="loading-indicator-dialog z-50">
    <loader-big :outline="true"></loader-big>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LoadingIndicatorDialog extends Vue {
  @Prop()
  action: Promise<any>;

  async mounted() {
    return this.action
      .then((result) => this.$emit("ok", result))
      .catch((err) => {
        this.$emit("error", err);
      });
  }
}
</script>

<style lang="scss" scoped>
.loading-indicator-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
