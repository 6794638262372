<template>
  <modal>
    <h3 v-if="title" class="mb-4">{{ title }}</h3>
    <div v-if="message" class="text">{{ message }}</div>
    <modal-footer>
      <button class="button-primary" type="button" @click="$emit('ok', true)">{{ $t("actions.ok") }}</button>
    </modal-footer>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class AlertDialog extends Vue {
  @Prop()
  title: string;

  @Prop()
  message: string;
}
</script>
