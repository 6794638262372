<template>
  <page>
    <page-content>
      <not-found></not-found>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class NotFoundPage extends Vue {}
</script>
