<template>
  <node-view-wrapper class="image-node-view" as="span" :style="styles">
    <span v-if="editor.isEditable" data-drag-handle="">
      <editor-image-view
        :editor="editor"
        :get-pos="getPos"
        :selected="selected"
        :blob="blob"
        :width="node.attrs.image_width"
      ></editor-image-view>
    </span>
    <image-view
      v-else
      :blob="blob"
      :width="node.attrs.image_width"
      :hover-blob="node.attrs.hoverImage"
    ></image-view>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";
import EditorImageView from "@/editor/extensions/image/editor-image-view.vue";
import ImageView from "@/editor/extensions/image/image-view.vue";

@Component({
  components: { NodeViewWrapper, EditorImageView, ImageView },
  props: nodeViewProps,
})
export default class ImageNodeView extends Vue {
  @Prop()
  editor: Editor;

  @Prop()
  node: any;

  @Prop()
  selected: boolean;

  @Prop()
  getPos: () => number;

  @Prop()
  updateAttributes: (attrs: any) => void;

  get blob() {
    return this.node.attrs.blob;
  }

  get metadata() {
    return this.blob.metadata;
  }

  get styles() {
    const styles: any = {};
    if (this.metadata?.width && this.metadata?.height) {
      // CSS trick that makes imakes with different aspect ratios
      // have the same height in flex row
      styles.flexGrow = this.metadata.width / this.metadata.height;
    }
    return styles;
  }
}
</script>

<style scoped></style>
