<template>
  <modal>
    <form name="translationsModal" @submit.prevent="onSubmit">
      <modal-header :title="title"></modal-header>
      <modal-body>
        <form-field v-for="language in languages" :key="language" :label="languageLabel(language)">
          <input v-model="translations[language]" type="text" />
        </form-field>
      </modal-body>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import { availableLanguages } from "@/i18n";
import Translations from "@/model/translations";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class TranslationsModal extends Vue {
  @Prop()
  title: string;

  @Prop()
  value: Translations;

  translations: Translations = {};

  get languages() {
    return Object.keys(availableLanguages);
  }

  languageLabel(lang) {
    return this.$t(`languages.${lang}`);
  }
  onSubmit() {
    this.$emit("ok", this.translations);
  }

  mounted() {
    this.translations = { ...this.value };
  }
}
</script>
