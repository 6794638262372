<template>
  <modal>
    <h3 class="mb-4">{{ title }}</h3>
    <form @submit.prevent="onSubmit">
      <form-field :label="$t('attributes.name')" :error="$v.model.name.$error">
        <input v-model="model.name" type="text" :placeholder="$t('attributes.name') + '...'" />
      </form-field>
      <form-field v-if="isProcedure" :label="$t('procedure.procedures_performed')">
        <procedure-type-select v-model="model.data.procedure_types"></procedure-type-select>
      </form-field>
      <form-field v-if="isQuiz" :label="$t('quiz.title')" :error="$v.model.data.quiz_id.$error">
        <quiz-select v-model="model.data.quiz_id"></quiz-select>
      </form-field>
      <form-field v-if="!isCourse && !isQuiz" :label="$t('attributes.required_count')">
        <input v-model="model.min_count" type="number" :placeholder="$t('attributes.required_count') + '...'" />
      </form-field>
      <form-field :label="$t('attributes.description')">
        <textarea v-model="model.description" :placeholder="$t('attributes.description') + '...'" rows="3"></textarea>
      </form-field>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.save") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { Prop } from "vue-property-decorator";
import cloneDeep from "lodash/cloneDeep";
import CurriculumItem from "@/model/curriculum-item";

@Component({
  components: {},
})
export default class LogbookItemModal extends Vue {
  @Prop()
  curriculumItem: CurriculumItem<any>;

  model: Partial<CurriculumItem<any>> = null;

  @Validations()
  validations = {
    model: {
      name: { required },
      data: {
        quiz_id: {
          required(value) {
            return this.isQuiz ? !!value : true;
          },
        },
      },
    },
  };

  get title() {
    switch (this.curriculumItem.item_type) {
      case "procedure":
        return this.$t("procedure.title");
      case "course":
        return this.$t("course.title");
      case "other":
        return this.$t("other_requirement.title");
      default:
        return "";
    }
  }

  get isProcedure() {
    return this.curriculumItem.item_type === "procedure";
  }

  get isCourse() {
    return this.curriculumItem.item_type === "course";
  }

  get isQuiz() {
    return this.curriculumItem.item_type === "quiz";
  }

  created() {
    this.model = cloneDeep(this.curriculumItem);
  }

  async onSubmit() {
    this.$v.$touch();
    if (!this.$v.$error) {
      this.$emit("ok", this.model);
    }
  }
}
</script>
