<template>
  <card-component
    ref="card"
    :classes="cardInputClasses"
    :styles="styles"
    :fonts="fonts"
    @ready="onReady"
    @change="onChange"
  >
    <div class="flex flex-col gap-4">
      <card-number :class="inputWrapperStyle" :placeholder="$t('signup.add_payment_method.card.card_number')" />
      <div class="w-full flex gap-4">
        <card-expiry class="flex-1" :class="inputWrapperStyle" />
        <card-cvv class="flex-1" :class="inputWrapperStyle" />
      </div>
    </div>
  </card-component>
</template>

<script setup lang="ts">
import { useTheme } from "@/theme";
import { CardComponent, CardNumber, CardExpiry, CardCvv } from "@chargebee/chargebee-js-vue-wrapper";
import { computed, ref } from "vue";

const emit = defineEmits(["valid"]);

const card = ref<CardComponent>(null);

const statuses = ref({
  number: false,
  expiry: false,
  cvv: false,
});

const isValid = computed(() => Object.values(statuses.value).every((v) => v));

const theme = useTheme();

const styles = computed(() => {
  return {
    base: {
      color: theme.value === "dark" ? "#FFFFFF" : "#000000",
      fontSize: "12px",
      fontFamily: "sans-serif",
    },
  };
});

const fonts = [
  {
    src: "https://fonts.googleapis.com/css2?family=Nexa:wght@400;700&display=swap",
  },
];

const cardInputClasses = {
  focus: "border-border-primary",
  invalid: "border-semantic-error",
};

const inputWrapperStyle = "border rounded-xs px-3 py-3 text-text-variant text-xs font-[sans-serif]";

const onReady = (el) => {
  el.focus();
};

const authorizeWith3ds = async (paymentIntent) => {
  await card.value.authorizeWith3ds(paymentIntent);
};

const onChange = ({ field, complete, error }) => {
  statuses.value = {
    ...statuses.value,
    [field]: complete && !error,
  };

  emit("valid", isValid.value);
};

defineExpose({
  authorizeWith3ds,
});
</script>
