<template>
  <node-view-wrapper as="span">
    <span data-drag-handle="">
      <vr-button v-if="url" :url="url" :title="node.attrs.title" @click.native="onClick"> </vr-button>
    </span>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Editor, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2";
import LauncherLink, { buildLauncherLinkUrlFromNode } from "@/editor/extensions/launcher-link/launcher-link";
import { showDialog } from "@/utils/dialogs";
import LauncherLinkModal from "@/editor/extensions/launcher-link/launcher-link-modal.vue";
import VrButton from "@/editor/extensions/launcher-link/vr-button.vue";

@Component({
  components: { NodeViewWrapper, VrButton },
  props: nodeViewProps,
})
export default class LauncherLinkNodeView extends Vue {
  @Prop()
  editor: Editor;

  @Prop()
  node: any;

  url = null;

  async created() {
    this.url = await buildLauncherLinkUrlFromNode(this.node);
  }

  async onClick(evt) {
    if (this.editor.isEditable) {
      evt.preventDefault();

      const launcherLink = await showDialog<LauncherLink>(LauncherLinkModal, { launcherLink: this.node.attrs });
      if (launcherLink) {
        this.editor
          .chain()
          .updateLauncherLink({ ...this.node.attrs, ...launcherLink })
          .focus()
          .run();
      }
    }
  }
}
</script>
