<template>
  <button class="p-sm" @click="toggle">
    <icon-fluent
      v-if="icon"
      class="h-4 w-4"
      :name="icon"
      :class="value ? 'text-text-emphasis' : 'text-text-variant'"
      :variant="value ? 'filled' : 'regular'"
    ></icon-fluent>
  </button>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ToggleButton extends Vue {
  @Prop()
  icon: string;

  @Prop()
  value: boolean;

  toggle() {
    this.$emit("input", !this.value);
  }
}
</script>
