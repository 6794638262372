<template>
  <modal>
    <form name="launcherLinkForm" novalidate @submit.prevent="$emit('ok', launcher_link)">
      <modal-header :title="$t('editor.add_vr_launcher_link')"></modal-header>
      <modal-body>
        <form-field :label="$t('attributes.title')">
          <input v-model="title" class="title-input" type="text" :placeholder="$t('attributes.title') + '...'" />
        </form-field>
        <form-field :label="$t('attributes.module_name')">
          <input
            v-model="module_name"
            class="title-input"
            type="text"
            :placeholder="$t('attributes.module_name') + '...'"
          />
        </form-field>
        <form-field :label="'Debug link?'">
          <input v-model="debug_link" class="title-input" type="checkbox" />
        </form-field>
      </modal-body>
      <modal-footer>
        <button class="btn-primary" type="submit" :disabled="!isValid">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import LauncherLink from "@/editor/extensions/launcher-link/launcher-link";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LauncherLinkModal extends Vue {
  @Prop()
  launcherLink: LauncherLink;

  title: string = "";
  url: string = "";
  command: string = "";
  module_name: string = "";
  vr: boolean = true;
  debug_link: boolean = false;
  additional_flags: string = "";

  get isValid() {
    if (this.url.length > 0) {
      return this.url.match(/^osgenic:\/\//);
    } else {
      return this.launcher_link_title.length > 0 && this.module_name.length > 0;
    }
  }

  get launcher_link_title() {
    if (this.title.length > 0) {
      return this.title;
    } else if (this.url.length > 0) {
      return this.url;
    } else if (this.module_name.length > 0) {
      return this.module_name;
    } else return "";
  }

  get launcher_link() {
    return {
      title: this.title.length > 0 ? this.title : this.url,
      url: this.url,
      command: "launch",
      module_name: this.module_name,
      vr: true,
      debug_link: this.debug_link,
      additional_flags: this.additional_flags,
    };
  }

  created() {
    if (this.launcherLink) {
      Object.assign(this, this.launcherLink);
    }
  }
}
</script>

<style lang="scss" scoped>
.url-input {
  margin-bottom: $spacing;
}
</style>
