import { Editor } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";

interface CreateDropHandlerProps {
  key: string;
  editor: Editor;
  filter: (file: File) => boolean;
  callback: (file: File[]) => void;
}

export const createDropHandler = (props: CreateDropHandlerProps) => {
  return new Plugin({
    key: new PluginKey(props.key),
    props: {
      handleDrop: (view, event) => {
        const { editor, filter, callback } = props;

        // Filter files with filter callback
        let files = event?.dataTransfer?.files || ([] as File[]);
        files = Array.from(files).filter(filter);

        // Return false if no matching files found (let other handlers take this)
        if (files.length === 0) {
          return false;
        }

        // OK got files. Prevent browser default drop action
        event.preventDefault();

        // Focus editor to drop position
        const { pos } = view.posAtCoords({ left: event.clientX, top: event.clientY });
        editor.commands.setTextSelection(pos);

        // Invoke callback
        callback(files);

        // Return true indicating that we handled this
        return true;
      },
    },
  });
};
