<template>
  <div v-if="user" class="flex-1 rounded sm:bg-transparent" :class="{ 'bg-surface-min-contrast': !open }">
    <div
      class="flex flex-row items-center p-4 sm:hidden text-label-medium text-text-variant cursor-pointer"
      @click="open = !open"
    >
      <div class="flex-1">
        {{ $t("profile.system_preferences") }}
      </div>
      <icon-fluent :name="open ? 'chevronUp' : 'chevronDown'" size="16" variant="filled" />
    </div>
    <div
      class="sm:flex flex-col gap-12 sm:gap-6 rounded p-6 sm:p-0 bg-surface-min-contrast sm:bg-transparent"
      :class="open ? 'flex' : 'hidden'"
    >
      <div>
        <div class="text-label-small uppercase">{{ $t("attributes.system_language") }}</div>
        <radio-select
          v-model="user.language"
          class="radio-select mt-2"
          :options="languageOptions"
          @input="saveLanguage"
        />
      </div>
      <div>
        <div class="text-label-small uppercase">{{ $t("attributes.content_languages") }}</div>
        <checkbox-select
          v-model="user.content_languages"
          class="radio-select mt-2"
          :options="languageOptions"
          @input="saveContentLanguages"
        />
      </div>
      <div>
        <div class="text-label-small uppercase">{{ $t("theme.title") }}</div>
        <radio-select v-model="user.theme" class="radio-select mt-2" :options="themeOptions" @input="saveTheme" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { availableLanguages, setLocale } from "@/i18n";
import { Theme, availableThemes, setTheme } from "@/theme";
import { RadioSelectOption } from "@/components/form/radio-select.vue";
import { updateProfile } from "@/actions";
import _ from "lodash";
import User from "@/model/user";
import store from "@/store";

const user = ref<User>(null);
const isMounted = ref(false);
const open = ref(false);

onMounted(() => {
  user.value = _.cloneDeep(store.getters.currentUser);
  console.log(user.value);
  isMounted.value = true;
});

const languageOptions = computed(() => {
  return Object.keys(availableLanguages).map((value) => ({ value, label: availableLanguages[value] }));
});

const themeOptions = computed(() => {
  return availableThemes().map(
    (entry) =>
      ({
        value: entry[0],
        label: entry[1],
      } as RadioSelectOption<Theme>)
  );
});

const saveLanguage = async (language) => {
  setLocale(language);
  return updateProfile({
    language,
  });
};

const saveContentLanguages = async (content_languages) => {
  return updateProfile({
    content_languages,
  });
};

const saveTheme = (theme) => {
  setTheme(theme);
  return updateProfile({
    theme,
  });
};
</script>
