<template>
  <modal>
    <form name="viewerLinkForm" novalidate @submit.prevent="submit()">
      <modal-header :title="$t('editor.add_3d_viewer_link')"></modal-header>
      <modal-body>
        <form-field :label="$t('attributes.title')">
          <input v-model="title" class="title-input" type="text" :placeholder="$t('attributes.title') + '...'" />
        </form-field>
        <form-field :label="$t('attributes.module_name')" :error="$v.slug.$error">
          <input v-model="slug" class="title-input" type="text" :placeholder="$t('attributes.module_name') + '...'" />
        </form-field>
        <form-field :label="$t('attributes.image')">
          <removable-image
            v-if="imageMediaFile"
            :image="imageMediaFile.file"
            @remove="imageMediaFile = null"
          ></removable-image>
          <a v-else class="link" @click="addImage">{{ $t("actions.add_image") }}</a>
        </form-field>
      </modal-body>
      <modal-footer>
        <button class="btn-primary" type="submit" :disabled="!isValid">{{ $t("actions.ok") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import MediaLibraryModal from "@/components/media-library/media-library-modal.vue";
import ViewerLink from "@/editor/extensions/viewer-link/viewer-link";
import Attachment from "@/model/attachment";
import MediaFile from "@/model/media-file";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";

@Component({})
export default class ViewerLinkModal extends Vue {
  @Prop()
  viewerLink: ViewerLink;

  title: string = "";
  slug: string = "";
  imageMediaFile: MediaFile = null;

  @Validations()
  validations = {
    slug: {
      isValid: (slug) => {
        try {
          return slug.startsWith("http://") || slug.startsWith("https://")
            ? new URL(slug).pathname.startsWith("/viewer/")
            : true;
        } catch {
          return false;
        }
      },
    },
  };

  get isValid() {
    return this.title.length > 0 && this.slug.length > 0;
  }

  get relativeSlug() {
    if (this.slug.startsWith("http://") || this.slug.startsWith("https://")) {
      try {
        const url = new URL(this.slug);
        return url.pathname.replace("/viewer/", "") + url.search;
      } catch {
        return this.slug;
      }
    } else {
      return this.slug;
    }
  }

  created() {
    if (this.viewerLink) {
      this.title = this.viewerLink.title;
      this.slug = this.viewerLink.slug;
      if (this.viewerLink.blob) {
        this.imageMediaFile = {
          file: this.viewerLink.blob as Attachment,
        } as unknown as MediaFile;
      }
    }
  }

  submit() {
    this.$v.$touch();
    if (!this.$v.$error) {
      const attrs: ViewerLink = {
        title: this.title,
        slug: this.relativeSlug,
      };
      if (this.imageMediaFile) {
        const { key, filename, content_type, metadata } = this.imageMediaFile.file;
        attrs.blob = { key, filename, content_type, metadata };
      }
      this.$emit("ok", attrs);
    }
  }

  async addImage() {
    const mediaFiles = await showDialog<MediaFile[]>(MediaLibraryModal, {
      fileType: "image",
      allowMultiselect: false,
    });
    this.imageMediaFile = mediaFiles?.length > 0 ? mediaFiles[0] : null;
  }
}
</script>

<style lang="scss" scoped>
.url-input {
  margin-bottom: $spacing;
}
</style>
