<template>
  <dropdown class="sm">
    <template #dropdownButtonTitle>
      <icon-fluent v-if="activeStyle" :name="activeStyle.icon" variant="filled"></icon-fluent>
    </template>
    <div class="dropdown-content">
      <button v-for="style in styles" class="btn-tertiary rounded-none" type="button" @click="update(style)">
        <icon-fluent
          class="mr-sm"
          :name="style.icon"
          :variant="activeStyle == style ? 'filled' : 'regular'"
        ></icon-fluent>
        <span :class="activeStyle == style ? 'font-bold' : 'font-regular'">{{ $t(style.name) }}</span>
      </button>
    </div>
  </dropdown>
</template>

<script lang="ts">
import { Editor } from "@tiptap/vue-2";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class TipTapEditorTextStyleDropdown extends Vue {
  @Prop()
  editor: Editor;

  get styles() {
    return [
      {
        type: "heading",
        level: 1,
        name: "editor.text_heading_1",
        icon: "textHeader1",
      },
      {
        type: "heading",
        level: 2,
        name: "editor.text_heading_2",
        icon: "textHeader2",
      },
      {
        type: "heading",
        level: 3,
        name: "editor.text_heading_3",
        icon: "textHeader3",
      },
      {
        type: "paragraph",
        name: "editor.text_body",
        icon: "textParagraph",
      },
    ];
  }

  get activeStyle() {
    return this.styles.find((style) => {
      if (style.type === "heading") {
        return this.editor.isActive("heading", { level: style.level });
      } else if (style.type === "paragraph") {
        return this.editor.isActive("paragraph");
      }
    });
  }

  update(style) {
    if (style.type === "heading") {
      this.editor.chain().setHeading({ level: style.level }).focus().run();
    } else if (style.type === "paragraph") {
      this.editor.chain().setParagraph().focus().run();
    }
  }
}
</script>
