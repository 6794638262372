<template>
  <dropdown :title="title" button-style="tertiary" button-size="sm">
    <a v-for="option in options" :key="option.value" class="option h-10" @click="updateSort(option.value)">{{
      option.label
    }}</a>
  </dropdown>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { TranslateResult } from "vue-i18n";
import { Prop } from "vue-property-decorator";

export interface SortOption {
  value: string;
  label: string | TranslateResult;
}

@Component({})
export default class SortDropdown extends Vue {
  @Prop({ default: "name" })
  sort: string;

  @Prop()
  options: SortOption[];

  get title() {
    if (this.sort && typeof this.sort === "string") {
      return this.options.find((option) => option.value === this.sort)?.label;
    } else {
      return this.$t("contents.sort_by");
    }
  }

  updateSort(value: string): void {
    this.$emit("update", value);
  }
}
</script>

<style lang="scss" scoped></style>
