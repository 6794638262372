import ApiService from "@/services/api-service";
import Share from "@/model/share";

export default class SharesService extends ApiService<Share> {
  get baseUrl() {
    return "/api/v1/shares";
  }

  accept(token: string): Promise<Share> {
    const url = this.buildUrl(`accept/${token}`, {});
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }
}
