<template>
  <div class="drop-area">
    {{ $t("drop_area.message") }}
    <input ref="fileInput" type="file" @input="onInput" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";

@Component({})
export default class DropArea extends Vue {
  @Ref() readonly fileInput: HTMLInputElement;

  onInput() {
    if (this.fileInput.files.length > 0) {
      this.$emit("select", this.fileInput.files[0]);
    }
  }
}
</script>
<style lang="scss" scoped>
.drop-area {
  width: 100%;
  height: 150px;
  border: 1px dashed white;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: $spacing;
  border-radius: $border-radius;

  &.invalid {
    border-color: $color-error;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
  }
}
</style>
