<template>
  <list-item>
    <div class="item-name">{{ item.name }}</div>
    <div class="actions">
      <context-menu>
        <a @click="$emit('edit', item)">
          <icon icon="pen"></icon><span class="text">{{ $t("actions.edit") }}</span>
        </a>
        <a @click="$emit('delete', item)">
          <icon icon="trash"></icon><span class="text">{{ $t("actions.delete") }}</span>
        </a>
      </context-menu>
    </div>
  </list-item>
</template>

<script lang="ts">
import CurriculumItem from "@/model/curriculum-item";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CurriculumItemView extends Vue {
  @Prop()
  item: CurriculumItem<any>;

  editItem() {}
}
</script>

<style lang="scss" scoped>
.item-name {
  flex-grow: 1;
  color: var(--ui-color-text-primary);
}
</style>
