<template>
  <div class="bg-surface-min-contrast rounded-sm p-2 sm:p-0">
    <div
      class="bg-gradient-to-br from-[var(--ui-color-osgenic-blue-400)] to-[var(--ui-color-osgenic-blue-100)] flex flex-col p-2 sm:p-6 rounded-sm text-text-primary"
    >
      <div class="text-label-medium uppercase mb-2">{{ $t("plan.for_individuals") }}</div>
      <div v-if="discountPercentage" class="sm:mt-2 text-xl line-through">
        <periodic-price :plan-price="props.planPrice" period="month" />
      </div>
      <div class="flex justify-center items-center gap-1">
        <periodic-price
          class="text-2xl sm:text-3xl"
          :plan-price="props.planPrice"
          :discount-percentage="discountPercentage"
          period="month"
        />
        <div class="text-body-small sm:text-body-medium text-left leading-none">
          <span v-if="planPrice.currency == 'USD'"> USD<br /></span>/month
        </div>
      </div>
      <div v-if="planPrice.period == 'year'" class="flex flex-row gap-2 justify-center text-body-medium">
        <div>
          (<periodic-price :plan-price="props.planPrice" :discount-percentage="discountPercentage" period="year" />
          <span v-if="planPrice.currency == 'USD'"> USD</span>/year)
        </div>
        <div v-if="yearlyDiscountPercentage" class="text-semantic-warning">
          {{ $t("signup.save_percentage", { discount: yearlyDiscountPercentage }) }}
        </div>
      </div>
      <div class="hidden sm:block my-4 px-6">
        {{ recurringBillingText }}
      </div>
      <div v-if="trialPeriodDays" class="text-label-medium sm:text-label-large text-semantic-success-variant">
        {{ $t("signup.free_trial_days", { days: trialPeriodDays }) }}
      </div>
      <button class="btn-primary rounded-xs w-full hidden sm:inline-flex mt-4" @click="selectAndContinue">
        {{ $t("actions.select_and_continue") }}
      </button>
    </div>
    <div class="flex justify-center my-4 text-body-medium text-text-variant sm:hidden">
      {{ recurringBillingText }}
    </div>
    <button class="btn-primary rounded-xs w-full sm:hidden" @click="selectAndContinue">
      {{ $t("actions.select_and_continue") }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import PlanPrice from "@/model/plan-price";
import { useI18n } from "vue-i18n-bridge";

const { t } = useI18n();

const props = defineProps<{
  planPrice: PlanPrice;
  discountPercentage?: number;
  yearlyDiscountPercentage?: number;
  trialPeriodDays?: number;
}>();

const emit = defineEmits(["select"]);

const recurringBillingText = computed(() => {
  if (props.planPrice.period === "year") {
    return t("signup.choose_your_plan.recurring_billing_yearly");
  } else {
    return t("signup.choose_your_plan.recurring_billing_monthly");
  }
});

const selectAndContinue = () => {
  emit("select", props.planPrice);
};
</script>
