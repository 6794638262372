<template>
  <span class="date-range">
    <span class="start_date">{{ dateFilter(startDate) }} </span>&nbsp;-&nbsp;<span v-if="endDate" class="end_date">{{
      dateFilter(endDate)
    }}</span>
  </span>
</template>

<script lang="ts">
import { dateFilter } from "@/filters";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class DateRange extends Vue {
  @Prop()
  startDate: string;

  @Prop()
  endDate: string;

  dateFilter(value: string) {
    return dateFilter(value);
  }
}
</script>
