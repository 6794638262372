<template>
  <select v-if="options" :value="value" @change="onChange">
    <option :value="null">{{ $t("attributes.not_selected") }}</option>
    <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
  </select>
  <input-skeleton v-else></input-skeleton>
</template>

<script lang="ts">
import Curriculum from "@/model/curriculum";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CurriculumSelect extends Vue {
  @Prop()
  value: number;

  @Prop()
  curriculums: Curriculum[];

  get options() {
    return (
      this.curriculums &&
      this.curriculums.map((curriculum) => {
        return {
          value: curriculum.id,
          label: curriculum.name,
        };
      })
    );
  }

  onChange(event) {
    const value: number = Number.parseInt(event.target.value) || null;
    this.$emit("input", value);
  }
}
</script>
