import ApiService from "@/services/api-service";
import ExternalLink from "@/model/external-link";

export default class ExternalLinksService extends ApiService<ExternalLink> {
  get baseUrl() {
    return "/api/v1/external_links";
  }

  publish(id) {
    const url = this.buildUrl(id, { action: "publish" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  unpublish(id) {
    const url = this.buildUrl(id, { action: "unpublish" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  showInLatestReleases(id) {
    const url = this.buildUrl(id, { action: "show_in_latest_releases" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  removeFromLatestReleases(id) {
    const url = this.buildUrl(id, { action: "remove_from_latest_releases" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "post",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }
}
