import User from "@/model/user";
import { FETCH_USERS_FAILURE, FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS } from "@/store/mutations";
import { RootState } from "@/store/store";
import { Module } from "vuex";

export type UsersState = {
  loading: boolean;
  ids: number[];
};

const usersModule: Module<UsersState, RootState> = {
  state: () => ({
    loading: false,
    ids: [],
  }),
  mutations: {
    [FETCH_USERS_REQUEST]: (state) => (state.loading = true),
    [FETCH_USERS_SUCCESS]: (state, users) =>
      Object.assign(state, {
        loading: false,
        ids: users.map(({ id }) => id),
      }),
    [FETCH_USERS_FAILURE]: (state) => (state.loading = false),
  },
  getters: {
    users: ({ ids }, getters) => getters.getEntities({ users: ids }).users.map((user) => new User(user)),
  },
};

export default usersModule;
