import ApiService, { ApiServiceOpts } from "@/services/api-service";
import User from "@/model/user";

export default class UserSessions extends ApiService<User> {
  get baseUrl() {
    return "/api/v1/user_sessions";
  }

  getProfile(): Promise<User> {
    return this.get("profile");
  }

  getAuthenticationToken(): Promise<string> {
    const url = this.buildUrl(null, { action: "authentication_token" });
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "get",
      headers,
    })
      .then((response) => this.handleResponse(response, true))
      .catch((error) => this.handleError(error));
  }

  impersonate(userId: number): Promise<User> {
    return this.create({}, { action: `impersonate/${userId}` });
  }

  stopImpersonating() {
    return this.delete({ action: "impersonate" });
  }

  delete(opts: ApiServiceOpts = {}): Promise<User> {
    const url = this.buildUrl(null, opts);
    const headers = this.buildHeaders();
    return fetch(url, {
      method: "delete",
      headers,
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => this.handleError(error));
  }

  protected unserialize(data: any): User {
    return new User(data);
  }
}
