import { kebabCase } from "lodash";
import Vue from "vue";

const components = (import.meta as any).glob("./**/*.vue", { eager: true });

for (const path in components) {
  const component = components[path];
  // Get name of component
  const componentName = kebabCase(
    // Gets the file name
    path
      .split("/")
      .pop()
      .replace(/\.\w+$/, "")
  );

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    component.default || component
  );
}
