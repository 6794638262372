import Vue from "vue";
import Cookies from "js-cookie";
import dayjs from "@/dayjs";
import { castToVueI18n, createI18n } from "vue-i18n-bridge";
import VueI18n from "vue-i18n";

Vue.use(VueI18n, { bridge: true });

const messages = (window as any).translations;

const availableLanguages = {
  en: "English",
  fi: "Suomi",
};

const availableContentLanguages = {
  en: "English",
  fi: "Suomi",
};

const i18n = castToVueI18n(
  createI18n(
    {
      legacy: false,
      locale: "en",
      messages,
    },
    VueI18n
  )
);

const getLocale = () => i18n.locale;

const setLocale = (locale) => {
  if (availableLanguages[locale]) {
    i18n.locale = locale;
    Cookies.set("locale", locale);
    dayjs.locale(locale);
    return true;
  } else {
    return false;
  }
};

const setTemporaryLocale = (locale) => {
  if (availableLanguages[locale]) {
    i18n.locale = locale;
    dayjs.locale(locale);
    return true;
  } else {
    return false;
  }
};

const setInitialLanguage = () => {
  // Set correct language from cookies / browser settings
  const cookieLanguage = Cookies.get("locale");
  const userLanguage = (navigator.language || (navigator as any).userLanguage || "").split("-")[0];
  setLocale(cookieLanguage) || setLocale(userLanguage);
};

export { availableLanguages, availableContentLanguages, setLocale, getLocale, setInitialLanguage, setTemporaryLocale };
export default i18n;
