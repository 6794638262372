<template>
  <modal>
    <form name="course-modal" @submit.prevent="onSubmit">
      <h3 class="mb-4">{{ $t("logbook.title") }}</h3>
      <form-field :label="$t('attributes.name')" :error="$v.model.name.$error">
        <input v-model="model.name" type="text" />
      </form-field>
      <form-field :label="$t('attributes.speciality')">
        <speciality-select v-model="model.speciality_id"></speciality-select>
      </form-field>
      <form-field :label="$t('attributes.start_date')" :error="$v.model.start_date.$error">
        <date-input v-model="model.start_date"></date-input>
      </form-field>
      <form-field :label="$t('attributes.end_date')">
        <date-input v-model="model.end_date"></date-input>
      </form-field>
      <modal-footer>
        <button class="btn-primary" type="submit">{{ $t("actions.create") }}</button>
        <button class="btn-secondary" type="button" @click="$emit('cancel')">{{ $t("actions.cancel") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import { Prop } from "vue-property-decorator";
import Curriculum from "@/model/curriculum";
import cloneDeep from "lodash/cloneDeep";
import CurriculumsService from "@/services/curriculums-service";

@Component({
  components: {},
})
export default class LogbookModal extends Vue {
  @Prop()
  curriculum: Curriculum;

  model: Partial<Curriculum> = null;

  @Validations()
  validations = {
    model: {
      name: { required },
      start_date: { required },
    },
  };

  get curriculumsService(): CurriculumsService {
    return new CurriculumsService();
  }

  async created() {
    this.model = this.curriculum
      ? cloneDeep(this.curriculum)
      : {
          start_date: dayjs().format("YYYY-MM-DD"),
          end_date: null,
        };
  }

  async onSubmit() {
    this.$v.$touch();
    if (!this.$v.$error) {
      const curriculum = !this.model.id
        ? this.curriculumsService.create(this.model)
        : this.curriculumsService.update(this.curriculum.id, this.model);
      this.$emit("ok", curriculum);
    }
  }
}
</script>
