<template>
  <div class="question-card" @click="$emit('click')">
    <div class="flex flex-row">
      <div class="text-text-primary pt-sm mr-md">{{ questionNumber }}.</div>
      <div class="flex-1">
        <image-text-input
          v-model="question.question"
          class="mb-sm"
          :auto-focus="true"
          :invalid="$v.question.question.$error"
          :placeholder="$t('question.enter_question')"
          :image-button-title="$t('question.add_image')"
          @upload="onImageUploaded"
        ></image-text-input>
        <div v-if="question.image" class="mb-sm">
          <removable-image :image="question.image" @remove="onRemoveImage"></removable-image>
        </div>
        <div v-if="$v.question.options.$error" class="form-error-message">
          {{ $t("question.invalid_options_message") }}
        </div>
        <option-card
          v-for="(option, index) in question.options"
          v-if="!option._destroy"
          :key="index"
          :ref="'option-' + index"
          :option="option"
          :option-index="index"
          :only-content-editable="onlyContentEditable"
          @remove="onRemoveOption(option)"
        ></option-card>
        <div class="mt-sm">
          <button v-if="!onlyContentEditable" class="btn-secondary" @click="addOption">
            {{ $t("question.add_option") }}
          </button>
        </div>
      </div>
      <div class="ml-2">
        <button
          v-if="!onlyContentEditable"
          class="btn-icon btn-tertiary"
          :title="$t('question.remove_question')"
          @click.stop="$emit('remove')"
        >
          <icon icon="times"></icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import QuizQuestion from "@/model/quiz-question";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import OptionCard from "@/pages/quiz-edit/option-card.vue";
import Attachment from "@/model/attachment";
import QuizOption from "@/model/quiz-option";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({ components: { OptionCard } })
export default class QuestionCard extends Vue {
  @Prop()
  question: QuizQuestion;

  @Prop()
  questionNumber: number;

  @Prop({ default: false })
  onlyContentEditable: boolean;

  @Validations()
  validations = {
    question: {
      question: { required },
      options: {
        required,
        hasCorrectOption: (options: QuizOption[]) =>
          options.filter(({ is_correct_answer }) => is_correct_answer).length === 1,
      },
    },
  };

  optionCards() {
    return Object.keys(this.$refs)
      .filter((key) => key.startsWith("option-"))
      .map((key) => this.$refs[key][0] as OptionCard)
      .filter((obj) => obj);
  }

  validateOptions() {
    return this.optionCards()
      .map((el) => {
        el.$v.$touch();
        return el.validate();
      })
      .every((valid) => valid);
  }

  validate(): boolean {
    this.$v.$touch();
    return this.validateOptions() && !this.$v.$error;
  }

  addOption() {
    const newOption: Partial<QuizOption> = {
      text: "",
      image: null,
      explanation: "",
      explanation_image: null,
      is_correct_answer: false,
    };
    this.question.options.push(newOption as QuizOption);
  }

  onImageUploaded(image: Attachment) {
    this.question.image = image;
  }

  onRemoveImage() {
    this.question.image = null;
  }

  onRemoveOption(option: QuizOption) {
    const removeIndex = this.question.options.indexOf(option);
    if (removeIndex >= 0) {
      Vue.set(this.question.options, removeIndex, { ...option, _destroy: true });
    }
  }
}
</script>

<style lang="scss" scoped>
.question-card {
  border-bottom: 1px solid var(--color-outline);
  padding-bottom: var(--spacing);
  margin-bottom: var(--spacing);

  .remove {
    &:hover {
      cursor: pointer;
      color: var(--ui-color-text-primary);
    }
  }
}
</style>
