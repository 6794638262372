<template>
  <dropdown-select
    v-bind="$attrs"
    :options="options"
    :allow-null="allowNull"
    :placeholder="label"
    button-class="rounded-xs"
    :allow-text-search="true"
    :floating-label="floatingLabel"
    @input="onInput"
  ></dropdown-select>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { BuildDropdownSelectOptionHierarchy, TreeNode } from "@/model/dropdown-select-option";
import { getAllCountries } from "@/countries";
import i18n from "@/i18n";
import _ from "lodash";

@Component({})
export default class CountrySelect extends Vue {
  @Prop({ default: true })
  allowNull: boolean;

  @Prop({ default: false })
  floatingLabel: boolean;

  @Prop({ default: () => i18n.t("attributes.country") })
  label: string;

  get countries(): TreeNode[] {
    return _.map(getAllCountries(), ({ code, name }) => ({
      id: code,
      name,
      parent_id: null,
    }));
  }

  get options() {
    return BuildDropdownSelectOptionHierarchy(this.countries);
  }

  get systemLanguage() {
    return this.$currentUser?.language;
  }

  onInput(value) {
    this.$emit("input", value);
  }
}
</script>
