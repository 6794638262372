<template>
  <page>
    <page-header class="flex-1">
      <page-header-title v-if="resident" :title="resident.name">
        <template #left>
          <avatar class="mr-4" :user="resident"></avatar>
        </template>
      </page-header-title>
    </page-header>
    <page-toolbar v-if="loaded">
      <div class="flex justify-between items-start">
        <div class="flex gap-2">
          <tab-link :to="{ name: 'ResidentLogbookEntries', params: { id, residency_id } }">
            {{ $t("entries.title") }}
          </tab-link>
          <tab-link
            v-for="curriculum in curriculums"
            :key="curriculum.id"
            :to="{ name: 'ResidentLogbookCurriculum', params: { id, residency_id, curriculum_id: curriculum.id } }"
          >
            {{ curriculum.name }}
          </tab-link>
        </div>
        <dropdown class="accent" :title="dropdownTitle" :to-right="true" :right="true">
          <router-link :to="{ path: `/residents/${id}/logbook` }">{{ $t("logbook.all_entries") }}</router-link>
          <router-link
            v-for="residency in resident.residencies"
            :key="residency.id"
            :to="{ path: `/residents/${id}/${residency.id}/logbook` }"
          >
            {{ residencyTitle(residency) }}
          </router-link>
        </dropdown>
      </div>
    </page-toolbar>
    <page-content v-if="!loaded">
      <card>
        <loader-big></loader-big>
      </card>
    </page-content>
    <page-content v-else>
      <div class="details">
        <div v-if="resident.speciality" class="item">
          <strong>{{ $t("attributes.speciality") }}:</strong><span>{{ resident.speciality.name }}</span>
        </div>
        <div v-if="selectedResidency" class="hospital-details">
          <div class="item">
            <strong>{{ $t("attributes.hospital") }}:</strong>{{ selectedResidency.customer_organization.name }}
          </div>
        </div>
      </div>
      <router-view
        :entries="entries"
        :usage-records="usageRecords"
        :curriculum="curriculum"
        :user="resident"
      ></router-view>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import User from "@/model/user";
import LogbookEntry from "@/model/logbook-entry";
import CurriculumsService from "@/services/curriculums-service";
import Curriculum from "@/model/curriculum";
import Users from "@/services/users";
import Residency from "@/model/residency";
import { localizedDate } from "@/utils/formatting";
import UsageRecordsService from "@/services/usage-records-service";
import UsageRecord from "@/model/usage-record";
import { loadLogbookEntries } from "@/actions";

@Component({})
export default class ResidentLogbookPage extends Vue {
  @Prop()
  id: string;

  @Prop()
  residency_id: string;

  @Prop()
  curriculum_id: string;

  resident: User = null;
  usageRecords: UsageRecord[] = null;
  curriculum: Curriculum = null;
  loadingResident = false;
  loadingUsageRecords = false;
  loadingCurriculum = false;

  get loaded() {
    return !(this.loadingResident || this.loadingEntries || this.loadingCurriculum || this.loadingUsageRecords);
  }

  get selectedResidency(): Residency {
    return this.residency_id && this.resident
      ? this.resident.residencies.find(({ id }) => id.toString() === this.residency_id.toString())
      : null;
  }

  get curriculums(): Curriculum[] {
    return this.selectedResidency && this.selectedResidency.curriculums;
  }

  get entries(): LogbookEntry<any>[] {
    let entries = this.$store.getters.logbookEntries as LogbookEntry<any>[];
    if (this.residency_id) {
      const id = Number.parseInt(this.residency_id);
      entries = entries.filter(({ residency_id }) => residency_id === id);
    }
    return entries;
  }

  get loadingEntries() {
    return this.$store.state.logbookEntries.loading;
  }

  get usersService() {
    return new Users();
  }

  get usageRecordsService() {
    return new UsageRecordsService();
  }

  get curriculumsService(): CurriculumsService {
    return new CurriculumsService();
  }

  get dropdownTitle() {
    return this.selectedResidency ? this.residencyTitle(this.selectedResidency) : this.$t("logbook.all_entries");
  }

  get userAndResidencyIds() {
    const params: any = { user_id: this.id };
    if (this.residency_id) {
      params.residency_id = this.residency_id;
    }
    return params;
  }

  residencyTitle(residency: Residency): string {
    let title = residency.customer_organization.name;

    if (residency.end_date) {
      title += ` (${localizedDate(residency.start_date)} - ${localizedDate(residency.end_date)})`;
    }

    return title;
  }

  async created() {
    return Promise.all([
      this.fetchResident(),
      this.fetchLogbookEntries(),
      this.fetchCurriculum(),
      this.fetchUsageRecords(),
    ]);
  }

  @Watch("id")
  async fetchResident() {
    this.loadingResident = true;
    return this.usersService
      .get(this.id)
      .then((resident) => (this.resident = resident))
      .finally(() => (this.loadingResident = false));
  }

  @Watch("userAndResidencyIds")
  async fetchLogbookEntries() {
    return loadLogbookEntries(this.userAndResidencyIds);
  }

  @Watch("userAndResidencyIds")
  async fetchUsageRecords() {
    this.loadingUsageRecords = true;
    this.usageRecords = null;
    return this.usageRecordsService
      .getAll({ params: this.userAndResidencyIds })
      .then((usageRecords) => (this.usageRecords = usageRecords))
      .finally(() => (this.loadingUsageRecords = false));
  }

  @Watch("curriculum_id")
  async fetchCurriculum() {
    this.curriculum = null;
    if (this.curriculum_id) {
      this.loadingCurriculum = true;
      return this.curriculumsService
        .get(this.curriculum_id)
        .then((curriculum) => (this.curriculum = curriculum))
        .finally(() => (this.loadingCurriculum = false));
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  h1 {
    display: flex;
    flex-direction: row;
    align-items: center;

    .avatar {
      margin-left: var(--spacing);

      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }
}
.logbook-entry-card {
  margin-left: -$spacing;
  margin-right: -$spacing;
}

.tabs {
  margin-bottom: $spacing;
}

.details {
  margin-bottom: var(--spacing);
}
</style>
