<template>
  <page v-if="organization">
    <page-header :title="$t('organizations.title') + ' > ' + organization.name"></page-header>
    <page-content>
      <form @submit.prevent="onSubmit">
        <div class="field">
          <label>{{ $t("attributes.name") }}</label>
          <input v-model="name" type="text" required maxlength="70" />
        </div>
        <div class="actions">
          <button class="btn-primary-variant" type="submit" :disabled="!isValid">{{ $t("actions.save") }}</button>
          <button @click="onCancel">{{ $t("actions.cancel") }}</button>
        </div>
      </form>
      <div class="organization__details">
        <strong>Details</strong>
        <dl>
          <dt>{{ $t("organization.title") }} {{ $t("attributes.name").toLowerCase() }}</dt>
          <dd>{{ organization.name }}</dd>
          <dt>{{ $t("organization.title") }} ID</dt>
          <dd>{{ organization.id }}</dd>
          <dt>{{ $t("organization.title") }} {{ $t("attributes.administrator").toLowerCase() }}</dt>
          <dd>
            <div v-if="admins.length == 0">{{ $t("attributes.no_administrator") }}</div>
            <ul>
              <li v-for="user in admins" :key="user.name">
                <span>{{ user.name }}</span>
              </li>
            </ul>
          </dd>
        </dl>
      </div>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Organization from "@/model/organization";
import Organizations from "@/services/organizations";
import Users from "@/services/users";

@Component({
  beforeRouteEnter(to, from, next) {
    Promise.all([
      new Organizations().get(to.params.id),
      new Users().getAll({ params: { customer_organization_id: to.params.id, role: "hospital_admin" } }),
    ]).then(([organization, admins]) => {
      next((vm) => {
        (vm as OrganizationEditPage).setOrganization(organization);
        (vm as OrganizationEditPage).admins = admins;
      });
    });
  },
})
export default class OrganizationEditPage extends Vue {
  organization: Organization = null;

  name = "";

  admins = [];

  setOrganization(organization: Organization) {
    this.organization = organization;
    this.name = organization.name;
  }

  get organizationService() {
    return new Organizations();
  }

  get isValid() {
    return this.name.length > 0;
  }

  async onSubmit() {
    const { id } = await this.organizationService.update(this.organization.id, {
      name: this.name,
    });

    this.$router.replace({ name: "Organization", params: { id: String(id) } });
  }

  onCancel() {
    this.$router.back();
  }
}
</script>

<style lang="scss" scoped></style>
