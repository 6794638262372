import LauncherLink from "@/editor/extensions/launcher-link/launcher-link";
import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import LauncherLinkNodeView from "@/editor/extensions/launcher-link/launcher-link-node-view.vue";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    launcherLink: {
      addLauncherLink: (launcherLink: LauncherLink) => ReturnType;
      updateLauncherLink: (launcherLink: LauncherLink) => ReturnType;
    };
  }
}

const LauncherLinkNode = Node.create({
  name: "launcherLink",

  inline: true,

  group: "inline",

  draggable: true,

  atom: true,

  addAttributes() {
    return {
      title: {},
      url: { default: undefined },
      command: { default: undefined },
      module_name: { default: undefined },
      vr: { default: undefined },
      debug_link: { default: undefined },
      additional_flags: { default: undefined },
    };
  },

  addCommands() {
    return {
      addLauncherLink: (launcherLink: LauncherLink) => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: launcherLink,
        });
      },
      updateLauncherLink: (launcherLink: LauncherLink) => ({ commands }) => {
        return commands.updateAttributes(this.name, launcherLink);
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "launcher-link-node",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["launcher-link-node", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(LauncherLinkNodeView);
  },
});

export default LauncherLinkNode;
