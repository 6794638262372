<template>
  <modal>
    <div class="feedback-modal">
      <div class="header">
        <h3 class="mb-4">{{ $t("feedback_view.title") }}</h3>
        <p class="text-text-variant mb-4">{{ $t("feedback_view.description") }}</p>
      </div>
      <form @submit.prevent="send">
        <form-field v-if="!$currentUser" :label="$t('attributes.name')">
          <input v-model="model.name" v-focus type="text" />
        </form-field>
        <form-field v-if="!$currentUser" :label="$t('attributes.email')" :error="$v.model.email.$error">
          <input v-model="model.email" type="email" />
        </form-field>
        <form-field :label="$t('attributes.message')" :error="$v.model.message.$error">
          <textarea v-model="model.message" v-focus="$currentUser" rows="10"></textarea>
        </form-field>
        <form-field :label="$t('attributes.attachments')" :error="$v.model.attachments.$error">
          <input ref="fileInput" class="w-full" type="file" multiple @change="onAttachmentsChanged" />
          <div class="text-xs text-text-variant mt-xs">
            {{ $t("file_types.allowed_file_types") }}: {{ allowedTypesLabel }}
          </div>
          <div class="text-xs text-text-variant mt-xs">
            {{ $t("file.max_allowed_file_size") }}: {{ formatBytes(maxAttachmentSize) }}
          </div>
        </form-field>
        <div class="actions">
          <button class="btn-primary" type="submit">{{ $t("actions.send") }}</button>
          <button class="btn-secondary" @click="cancel">{{ $t("actions.cancel") }}</button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import SupportMessagesService from "@/services/support-messages-service";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { Ref } from "vue-property-decorator";
import { formatBytes } from "@/utils/formatting";

@Component({})
export default class FeedbackModal extends Vue {
  @Ref("fileInput")
  fileInput: HTMLInputElement;

  model = {
    email: null,
    name: null,
    message: "",
    attachments: [],
  };

  @Validations()
  validations = {
    model: {
      email: {
        required(email) {
          return this.$currentUser ? true : !!(email && email.length > 0);
        },
      },
      message: { required },
      attachments: {
        $each: {
          fileSize(file) {
            return file.size <= this.maxAttachmentSize;
          },
          fileType(file) {
            return this.fileTypeWhiteList.includes(file.type);
          },
        },
      },
    },
  };

  get supportMessagesService(): SupportMessagesService {
    return new SupportMessagesService();
  }

  get megabyte(): number {
    return 1048576;
  }

  get maxAttachmentSize(): number {
    return this.megabyte * 20;
  }

  get fileTypeWhiteList(): string[] {
    return [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png",
      "application/pdf",
      "text/plain",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "video/webm",
    ];
  }

  get allowedTypesLabel() {
    return "jpeg, jpg, gif, png, pdf, txt, doc, docx, webm";
  }

  formatBytes(bytes: number): string {
    return formatBytes(bytes, 0);
  }

  send() {
    this.$v.$touch();

    if (!this.$v.$error) {
      const position = "top-right";
      const sendingMessage = this.$t("feedback_view.sending_feedback") as string;
      this.$toast.info(sendingMessage, { message: sendingMessage, position });

      this.supportMessagesService
        .create({ ...this.model, attachments: [...(this.fileInput.files as any)] })
        .then(() => {
          const message = this.$t("feedback_view.feedback_sent") as string;
          this.$toast.success(message, { message, position });
        })
        .catch(() => {
          const errorMessage = this.$t("feedback_view.feedback_send_error") as string;
          this.$toast.error(errorMessage, { message: errorMessage, position });
        });

      this.$emit("ok");
    }
  }

  cancel() {
    this.$emit("cancel");
  }

  onAttachmentsChanged(event): void {
    this.model.attachments = event.target.files;
    this.$v.model.attachments.$touch();
  }
}
</script>

<style lang="scss" scoped>
.feedback-modal {
  margin-bottom: var(--spacing-sm);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  transition: opacity 0.2s;

  &.open {
    opacity: 1;
  }

  form {
    .actions {
      display: flex;
      justify-content: flex-end;
      > button:not(:last-child) {
        margin-right: var(--spacing-sm);
      }
    }
  }
}
</style>
