<template>
  <div class="logbook-node mt-xs pt-xs border-t border-background">
    <div class="logbook-node__title-wrapper">
      <div class="logbook-node__title" @click="toggleDetails">
        <div v-if="$slots.details" class="icon-wrapper">
          <icon :icon="showDetails ? 'caret-up' : 'caret-down'"></icon>
        </div>
        <div v-if="$slots.title" class="title">
          <slot name="title"></slot>
        </div>
        <div v-else class="title">{{ title }}</div>
      </div>
      <div v-if="$slots.titleTrailing" class="logbook-node__title-trailing">
        <slot name="titleTrailing"></slot>
      </div>
    </div>
    <div v-if="$slots.default" class="logbook-node__content">
      <slot></slot>
    </div>
    <div v-if="$slots.details && showDetails" class="logbook-node__details">
      <slot name="details"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class LogbookNode extends Vue {
  @Prop()
  title: string;

  showDetails = false;

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }
}
</script>

<style lang="scss" scoped>
.logbook-node {
  --indent: 15px;

  &__title-wrapper {
    display: flex;
    margin-bottom: var(--spacing-sm);
  }

  &__title {
    flex-grow: 1;
    display: flex;

    .icon-wrapper {
      width: var(--indent);
    }
  }

  &__content {
    margin-bottom: var(--spacing-sm);
    padding-left: var(--indent);
  }

  &__details {
    margin-bottom: var(--spacing-sm);
    padding-left: var(--indent);
  }
}
</style>
