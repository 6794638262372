<template>
  <span
    class="overflow-hidden relative align-bottom"
    :class="enabled && ['inline-block', 'resize-x']"
    :style="enabled && style"
  >
    <slot></slot>
    <div v-if="enabled" class="absolute top-0 left-0 w-full h-full border-border-emphasis border-dashed border-2"></div>
    <div v-if="enabled" class="absolute bottom-0 right-0 w-4 h-5 bg-interactive-bg-emphasis">
      <icon-fluent class="text-on-primary" name="arrowFit" variant="filled"></icon-fluent>
    </div>
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class Resizeable extends Vue {
  @Prop()
  value: number;

  @Prop()
  enabled: boolean;

  observer: ResizeObserver = null;

  get style() {
    if (this.value) {
      return { width: this.value + "px" };
    } else {
      return null;
    }
  }

  @Watch("enabled")
  onEnabledChanged() {
    if (this.enabled) {
      const callback = (entries) => {
        for (const entry of entries) {
          const { width } = entry.contentRect;
          this.$emit("input", width);
        }
      };
      // Invoke callback on resize, ignoring initial call
      this.observer = new ResizeObserver(callback);
      this.observer.observe(this.$el);
    } else {
      this.observer?.disconnect();
    }
  }

  destroyed() {
    this.observer?.disconnect();
  }
}
</script>

<style lang="scss" scoped>
.resize-x {
  // Set max width for resize container also so it cannot overflow the content container
  max-width: calc(100% - 1px);
}
</style>
