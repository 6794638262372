<template>
  <button
    v-tooltip.bottom-end="tooltip"
    class="btn-icon btn-sm text-text-emphasis group"
    :class="{
      'bg-interactive-bg-neutral-low-contrast': inCircle,
      'hover:bg-interactive-bg-neutral-high-contrast': inCircle,
    }"
    @click="onClick"
  >
    <icon-fluent name="star" size="16" :variant="iconVariant"></icon-fluent>
  </button>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({})
export default class UiButtonIcon extends Vue {
  @Prop()
  value: boolean;

  @Prop({ default: "default" })
  type: "default" | "in-circle";

  tooltip = null;

  get iconVariant() {
    return this.value ? "filled" : "regular";
  }

  get inCircle() {
    return this.type == "in-circle";
  }

  onClick() {
    this.$emit("input", !this.value);
  }

  @Watch("value", { immediate: true })
  onValueChange() {
    this.tooltip = null;

    setTimeout(() => {
      this.tooltip = this.value ? this.$t("actions.remove_from_favorites") : this.$t("actions.add_to_favorites");
    }, 100);
  }
}
</script>
