<template>
  <div
    class="flex rounded-sm overflow-hidden bg-surface-low-contrast h-16 duration-300 hover:bg-surface-mid-contrast active:bg-surface-low-contrast xs:h-14"
  >
    <div class="shrink-0 h-full bg-interactive-bg-emphasis">
      <div
        v-lazy:background-image="previewImage"
        class="h-full w-14 bg-cover opacity-0 duration-300 ease-in lazy-loaded:opacity-100"
      ></div>
    </div>
    <div class="p-2 flex flex-col min-w-0 justify-between xs:px-4">
      <div class="text-body-small text-text-primary line-clamp-1">{{ contentItem.name }}</div>
      <div v-if="speciality" class="text-body-extra-small text-text-variant line-clamp-1 xs:text-body-small">
        {{ speciality.name }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ContentItem from "@/model/content-item";
import { filePath } from "@/utils/paths";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ContentItemCard extends Vue {
  @Prop()
  contentItem: ContentItem;

  get previewImage() {
    return filePath(this.contentItem.preview_image, "thumb");
  }

  get speciality() {
    return this.contentItem.specialities?.[0];
  }
}
</script>
