<template>
  <span class="date"> <icon class="mr-sm" icon="calendar"></icon>{{ dateFilter(date) }} </span>
</template>

<script lang="ts">
import { dateFilter } from "@/filters";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Date extends Vue {
  @Prop()
  date: string;

  dateFilter(value: string) {
    return dateFilter(value);
  }
}
</script>
