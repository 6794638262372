<template>
  <page>
    <page-content>
      <div v-html="cookielist"></div>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class CookieListPage extends Vue {
  cookielist: string = "";
  intervalId: any = null;

  created() {
    this.intervalId = setInterval(() => {
      this.cookielist = document.querySelector("#ot-sdk-cookie-policy").innerHTML;
      if (this.cookielist.length > 0) {
        clearInterval(this.intervalId);
      }
    }, 100);
  }

  destroyed() {
    clearInterval(this.intervalId);
  }
}
</script>
