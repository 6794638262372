<template>
  <modal>
    <div class="text-center">
      <dialog-steps :step="step" :total="2" />
      <div v-if="step == 1">
        <h2 class="mt-6">{{ $t("invitation.welcome_to_osgenic") }}</h2>
        <div class="mt-4 text-label-medium">
          {{ $t("welcome_dialog.subtitle_1") }}
        </div>
        <div class="mt-12 flex flex-col gap-4">
          <speciality-select v-model="attrs.speciality_id" :floating-label="true" />
          <position-select v-model="attrs.position" :floating-label="true" />
          <text-input
            v-model="attrs.organization"
            :placeholder="$t('attributes.hospital_or_organization')"
            :floating-label="true"
          />
        </div>
        <button class="btn-primary mt-12" :disabled="!stepOneValid" @click="step++">
          <div class="px-12">
            {{ $t("actions.next") }}
          </div>
        </button>
      </div>
      <div v-if="step == 2">
        <h2 class="mt-6">{{ $t("welcome_dialog.content_language") }}</h2>
        <div class="mt-4 text-label-medium">
          {{ $t("welcome_dialog.subtitle_2") }}
        </div>
        <div class="w-48 mt-12 mx-auto flex flex-col gap-2">
          <checkbox-select v-model="attrs.content_languages" :options="contentLanguageOptions" :multiselect="true" />
        </div>
        <button class="btn-primary mt-12" :disabled="!stepTwoValid" @click="submit">
          <div class="px-12">
            {{ $t("actions.lets_go") }}
          </div>
        </button>
      </div>
      <div class="text-body-small sm:text-body-large mt-12">
        <div>{{ $t("signup.have_a_question") }}</div>
        <div>support@osgenic.com</div>
      </div>
    </div>
  </modal>
</template>

<script setup lang="ts">
import { updateProfile } from "@/actions";
import { CheckboxSelectOption } from "@/components/form/checkbox-select.vue";
import { getLocale } from "@/i18n";
import { computed } from "vue";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n-bridge";

const { t } = useI18n();

const step = ref(1);

const attrs = reactive({
  speciality_id: null,
  position: null,
  organization: null,
  content_languages: [getLocale()],
});

const contentLanguageOptions: CheckboxSelectOption<string>[] = [
  {
    value: "en",
    label: t("languages.en"),
  },
  {
    value: "fi",
    label: t("languages.fi"),
  },
];

const stepOneValid = computed(() => {
  return attrs.speciality_id && attrs.position && attrs.organization;
});

const stepTwoValid = computed(() => {
  return attrs.content_languages.length > 0;
});

const emit = defineEmits(["ok"]);

const submit = () => {
  updateProfile(attrs);
  emit("ok");
};
</script>
