<template>
  <div class="flex w-full">
    <div class="mr-sm flex-1 text-ellipsis overflow-hidden">{{ name }}</div>
    <div>
      <badge v-if="state !== 'published'" :label="$t(`status.${state}`)" :variant="state"></badge>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ContentNameAndState extends Vue {
  @Prop()
  name: string;

  @Prop()
  state: "published" | "draft";
}
</script>

<style lang="scss" scoped></style>
