import { getLocale } from "@/i18n";
import UserSessions from "@/services/user_sessions";
import store from "@/store";

export default interface LauncherLink {
  title: string;
  url: string;
  command: string;
  module_name: string;
  vr: boolean;
  debug_link: boolean;
  additional_flags: string;
}

export async function buildLauncherLinkUrlFromNode(node): Promise<string> {
  const authenticationToken = await new UserSessions().getAuthenticationToken();

  // Deprecated version
  if (node.attrs.url && node.attrs.url.length > 0) {
    return addParamsToLauncherLinkUrl(node.attrs.url, authenticationToken);
  }

  let protocol = "osgenic://";
  let command = node.attrs.command; // should always exist, see launcher-link-modal
  let token = "&token=" + authenticationToken;
  let base = "&base=" + window.location.host;
  let locale = "&locale=" + getLocale();

  let targetModule = "operation=" + node.attrs.module_name; // should always exist

  let orgName = "";
  const customerOrganization =
    store.getters.currentUser.customer_organization ||
    store.getters.currentUser.currentResidency?.customer_organization;
  if (customerOrganization) {
    let tempName = "org=" + customerOrganization.name;
    let nameAsEncodedString = new URLSearchParams(tempName).toString();
    orgName = "&" + nameAsEncodedString;
  }

  let debugFlag = "";
  if (node.attrs.debug_link) {
    debugFlag = "&debug=1";
  }

  let vrFlag = "";
  if (node.attrs.vr) {
    vrFlag = "&vr=1";
  }

  let urlBase = protocol + command + "/" + "Incision";

  let queryString = "?" + targetModule + orgName + debugFlag + vrFlag + token + base + locale;
  return urlBase + queryString;
}

// Deprecated, handles links with manually set URLs. Should be removed when links have been updated on Production server.
function addParamsToLauncherLinkUrl(originalHref: string, authenticationToken: string): string {
  let token = "token=" + authenticationToken;
  let base = "base=" + window.location.host;
  let locale = "&locale=" + getLocale();
  let orgName = "";
  const customerOrganization =
    store.getters.currentUser.customer_organization ||
    store.getters.currentUser.currentResidency?.customer_organization;
  if (customerOrganization) {
    let tempName = "org=" + customerOrganization.name;
    let nameAsEncodedString = new URLSearchParams(tempName).toString();
    orgName = "&" + nameAsEncodedString;
  }
  return originalHref + "&" + token + "&" + base + orgName + locale;
}
