import AnalyticsEvent from "@/model/analytics-event";
import store from "@/store";

export default class AnalyticsEventsService {
  trackPageView(path: string, target: string) {
    const analyticsEvent: AnalyticsEvent = {
      event_type: "page_view",
      page_path: path,
      target,
    };
    return this.saveEvent(analyticsEvent);
  }

  trackClick(path: string, target: string) {
    const analyticsEvent: AnalyticsEvent = {
      event_type: "click",
      page_path: path,
      target,
    };
    return this.saveEvent(analyticsEvent);
  }

  private saveEvent(analyticsEvent: AnalyticsEvent) {
    if (!store.getters.currentUser) {
      return;
    }
    const url = "/api/v1/analytics_events";
    const headers = {
      "Content-Type": "application/json",
    };
    return fetch(url, {
      method: "post",
      headers,
      body: JSON.stringify(analyticsEvent),
    }).catch((error) => {
      console.error(error);
      window.rollbar.error(error);
    });
  }
}
