import Subscription from "@/model/subscription";
import User from "@/model/user";
import store from "@/store";
import { computed } from "vue";

export const useCurrentUser = () => {
  return computed(() => store.getters.currentUser as User);
};

export const useActiveSubscription = () => {
  return computed(() => store.getters.activeSubscription as Subscription);
};
