<template>
  <div class="checkbox-select pl-xs">
    <label v-for="option in options" :key="option.value" class="flex items-center mb-xs hover:text-text-primary">
      <checkbox type="checkbox" :value="option.value" :checked="checked(option)" @change="toggle(option)"></checkbox>
      <div class="ml-sm">{{ option.label }}</div>
    </label>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";

export type CheckboxSelectOption<T extends string | number | symbol> = {
  value: T;
  label: string | TranslateResult;
};

@Component({})
export default class CheckboxSelect<T extends string | number | symbol> extends Vue {
  @Prop()
  options: CheckboxSelectOption<T>[];

  @Prop()
  value: T[];

  model: T[] = null;

  checked(option: CheckboxSelectOption<T>) {
    return this.value.includes(option.value);
  }

  toggle(option: CheckboxSelectOption<T>) {
    if (this.value.includes(option.value)) {
      const value = this.value.filter((item) => item !== option.value);
      this.$emit("input", value);
    } else {
      this.$emit("input", [...this.value, option.value]);
    }
  }

  created() {
    this.updateModel();
  }

  @Watch("value")
  private updateModel() {
    this.model = this.value;
  }
}
</script>
