<template>
  <div class="app shrink-0 flex flex-col" :class="{ impersonating }">
    <keep-alive include="ContentsPage,HomePage">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script lang="ts">
import "@/class-component-hooks";
import Vue from "vue";
import Component from "vue-class-component";
import router from "@/router";
import { loadCommentNotifications, updateUserSettings } from "@/actions";
import { errorHandler } from "./error-handler";

@Component({
  router,
})
export default class App extends Vue {
  reloadIntervalId = null;

  get impersonating() {
    return !!this.$store.state.session.impersonating;
  }

  async created() {
    this.reloadIntervalId = setInterval(() => {
      if (this.$store.getters.currentUser) {
        this.reloadCommentNotifications();
      }
    }, 60 * 1000);

    if (this.$store.getters.currentUser) {
      return Promise.all([loadCommentNotifications(), updateUserSettings()]);
    }
  }

  destroyed() {
    if (this.reloadIntervalId) {
      clearInterval(this.reloadIntervalId);
    }
  }

  reloadCommentNotifications(): Promise<any> {
    if (!document.hidden) {
      return loadCommentNotifications().catch(errorHandler);
    }
  }
}
</script>

<style lang="scss" scoped>
.app {
  // Overflows body (width 100%) when scrollbar is visible, so that content width is not changed when scrollbar appears.
  width: 100vw;
  background-color: var(--ui-color-surface-no-contrast);
  color: var(--ui-color-text-variant);

  &.impersonating {
    border: 5px solid red;
    font-weight: bold;
  }
}
</style>
