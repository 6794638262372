import { getAttributes } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { showDialog } from "@/utils/dialogs";
import LinkEditModal from "@/editor/extensions/custom-link/link-edit-modal.vue";

export const createLinkEditClickHandler = ({ type, editor }) => {
  return new Plugin({
    key: new PluginKey("linkEditClickHandler"),
    props: {
      handleClick: (view, pos, event) => {
        const link = (event.target as HTMLElement)?.closest("a");
        const attrs = getAttributes(view.state, type);

        if (link && attrs.href) {
          // Prompt for link url
          showDialog(LinkEditModal, { deleted: false, href: attrs.href, title: link.text }).then(
            ({ deleted, href, title }) => {
              if (deleted) {
                editor
                  .chain()
                  .focus()
                  .extendMarkRange("link")
                  .unsetLink()
                  .run();
              } else if (href && title) {
                // Update node's href and title attributes
                editor
                  .chain()
                  .focus()
                  .extendMarkRange("link")
                  .setLink({ href: href })
                  .command(({ tr }) => tr.insertText(title))
                  .run();
              }
            }
          );
          return true;
        }

        return false;
      },
    },
  });
};
