<template>
  <label :class="classes">
    <slot></slot>
  </label>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class FloatingLabel extends Vue {
  @Prop({ default: false })
  error: boolean;

  @Prop({ default: false })
  success: boolean;

  @Prop()
  floating: boolean;

  @Prop()
  primary: boolean;

  get classes() {
    let classes = "flex flex-col justify-center absolute duration-200 touch-none pointer-events-none";

    if (this.floating === true) {
      classes += " top-0 text-xs h-[1.5px] bg-current-background px-1 left-2";
    } else if (this.floating === false) {
      classes += " text-base top-2 h-6";
    } else {
      // floating is undefined
      // then we want to floating the label when the input is focused or has a value
      classes += " top-0 text-xs h-[1.5px] bg-current-background px-1 left-2";
      classes +=
        " peer-placeholder-shown:text-base peer-placeholder-shown:top-2 peer-placeholder-shown:h-6 peer-placeholder-shown:bg-transparent peer-placeholder-shown:px-3 peer-placeholder-shown:left-0";
      classes +=
        " peer-focus:top-0 peer-focus:text-xs peer-focus:h-[1.5px] peer-focus:bg-current-background peer-focus:px-1 peer-focus:left-2";
    }

    if (this.primary === true) {
      classes += " text-text-primary";
    } else if (this.error) {
      classes += " text-semantic-error";
    } else if (this.success) {
      classes += " text-semantic-success";
    } else {
      // primary is undefined
      // then we want to use primary color when the input is focused
      classes += " text-text-variant";
    }

    return classes;
  }
}
</script>
