<template>
  <modal size="medium">
    <div class="flex flex-col">
      <h3 class="mb-4">{{ $t("actions.share") }}</h3>
      <div class="flex-1">
        <div v-if="contentItem.permissions.share" class="flex flex-col gap-4 sm:flex-row">
          <div class="flex flex-col flex-1 border border-solid border-outline rounded-xs relative">
            <div v-if="newShares.length > 0" class="flex-1 flex flex-col items-start gap-2 mx-4 my-3">
              <div
                v-for="newShare in newShares"
                :key="shareName(newShare)"
                class="rounded-xs bg-interactive-bg-emphasis text-on-primary px-2 py-1 flex items-center"
              >
                <div class="mr-xs">{{ shareName(newShare) }}</div>
                <icon-fluent class="w-4 h-4" name="dismiss" @click="removeNewShare(newShare)"></icon-fluent>
              </div>
            </div>
            <input
              ref="nameOrEmailField"
              v-model="nameOrEmail"
              class="border-0"
              type="text"
              :placeholder="inputPlaceholder"
              maxlength="254"
              @keyup.enter="onEnterPressed"
            />
            <div v-if="matchingUsers" class="absolute z-10 top-full w-full mt-1 rounded-xs bg-surface-high-contrast">
              <div
                v-for="user in matchingUsers"
                :key="user.email"
                class="px-md py-sm cursor-pointer hover:text-text-primary"
                @click="selectUser(user)"
              >
                <div class="flex items-center">
                  <avatar class="mr-sm" :user="user"></avatar>
                  <div class="flex flex-col hover:text-text-primary">
                    <div class="text-label-strong">{{ user.name }}</div>
                    <div class="text-label-small">{{ user.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="btn-primary justify-center" :disabled="newShares.length == 0" @click="share">
            {{ $t("actions.share") }}
          </button>
        </div>
        <div class="mt-md">
          <loader-big v-if="!loaded"></loader-big>
          <div v-else class="shares">
            <div v-if="contentItem.permissions.share" class="mt-4">
              <div class="text-label-medium mb-1">{{ $t("share.people_with_access") }}</div>
              <share-dialog-row v-if="ownerShare" :share="ownerShare">
                <template #trailing>
                  <div class="mr-4">{{ $t("attributes.owner") }}</div>
                </template>
              </share-dialog-row>
              <share-dialog-row
                v-for="share in userShares"
                :key="share.id"
                :share="share"
                @set-permission="onSetPermission(share, $event)"
                @remove="onRemove(share)"
              ></share-dialog-row>
              <share-dialog-row
                v-for="share in emailShares"
                :key="share.id"
                :share="share"
                @set-permission="onSetPermission(share, $event)"
                @remove="onRemove(share)"
              ></share-dialog-row>
            </div>
            <div v-if="contentItem.permissions.share && organizationShares.length > 0" class="mt-4">
              <div class="text-label-medium mb-1">{{ $t("share.organization_access") }}</div>
              <share-dialog-row
                v-for="share in organizationShares"
                :key="share.id"
                :share="share"
                @set-permission="onSetPermission(share, $event)"
                @remove="onRemove(share)"
              ></share-dialog-row>
            </div>
            <div v-if="contentItem.permissions.share_public" class="mt-4">
              <div class="text-label-medium mb-1">{{ $t("share.general_access") }}</div>
              <div v-if="contentItem.share_token" class="flex items-center justify-between">
                <div class="flex items-center">
                  <div class="flex items-center justify-center h-10 w-10 mr-sm">
                    <icon-fluent name="peopleTeam"></icon-fluent>
                  </div>
                  <div class="text-label-strong">{{ $t("share.anyone_with_the_link") }}</div>
                </div>
                <dropdown :title="$t('share.permission.view')" :button-style="'clear'">
                  <a @click="removeLink()">{{ $t("share.remove_link") }}</a>
                </dropdown>
              </div>
              <div v-else class="flex items-center justify-between">
                <div class="flex items-center">
                  <div class="flex items-center justify-center h-10 w-10 mr-sm">
                    <icon-fluent name="peopleTeam"></icon-fluent>
                  </div>
                  <div class="text-label-strong">{{ $t("share.no_general_access") }}</div>
                </div>
              </div>
              <div>
                <a
                  v-if="contentItem.share_token"
                  class="cursor-pointer flex items-center text-text-emphasis"
                  @click="copyLink"
                >
                  <div class="flex items-center justify-center h-10 w-10 mr-sm">
                    <icon-fluent name="link"></icon-fluent>
                  </div>
                  <div class="text-label-strong">{{ $t("share.copy_link") }}</div>
                </a>
                <a v-else class="cursor-pointer flex items-center text-text-emphasis" @click="generateLink">
                  <div class="flex items-center justify-center h-10 w-10 mr-sm">
                    <icon-fluent name="link"></icon-fluent>
                  </div>
                  <div class="text-label-strong">{{ $t("share.generate_link") }}</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 flex justify-center gap-6">
        <button class="btn-primary" @click="close">{{ $t("actions.done") }}</button>
      </div>
    </div>
  </modal>
</template>

<script lang="ts">
import DropdownSelectOption from "@/model/dropdown-select-option";
import User from "@/model/user";
import Users from "@/services/users";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import Share, { SharePermission } from "@/model/share";
import ContentItem from "@/model/content-item";
import SharesService from "@/services/shares-service";
import { showDialog } from "@/utils/dialogs";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import ShareDialogRow from "@/pages/share/share-dialog-row.vue";
import ContentItemsService from "@/services/content-items-service";

@Component({
  components: { ShareDialogRow },
})
export default class ShareDialog extends Vue {
  @Ref("nameOrEmailField")
  nameOrEmailField: HTMLInputElement;

  @Prop()
  contentItem: ContentItem;

  @Prop()
  contentItemUpdated: (contentItem: ContentItem) => void;

  @Prop()
  sharesUpdated: (shares: Share[]) => Share[];

  nameOrEmail: string = "";
  permission: SharePermission = "view";
  matchingUsers: User[] = null;
  newShares: (User | string)[] = [];
  shares: Share[] = null;
  loaded = false;

  get sharesService() {
    return new SharesService();
  }

  get contentItemsService() {
    return new ContentItemsService();
  }

  get shareOptions(): DropdownSelectOption[] {
    return ["view", "edit"].map((value) => ({
      value,
      label: this.$t("share.permission." + value) as string,
    }));
  }

  get userShares(): Share[] {
    return this.shares?.filter(({ user }) => user);
  }

  get organizationShares(): Share[] {
    const shares = this.shares?.filter(({ customer_organization }) => customer_organization);
    const currentOrganization =
      this.$currentUser.customer_organization || this.$currentUser.currentResidency?.customer_organization;
    if (currentOrganization && !shares.some(({ customer_organization: { id } }) => id === currentOrganization.id)) {
      const share: Partial<Share> = {
        content_item: this.contentItem,
        customer_organization: currentOrganization,
        permission: null,
      };
      shares.push(share as Share);
    }
    return shares;
  }

  get emailShares(): Share[] {
    return this.shares?.filter(({ email }) => email);
  }

  get isEmail(): boolean {
    return /^\S+@\S+\.\S+$/.test(this.nameOrEmail);
  }

  get publicLink() {
    if (this.contentItem.share_token) {
      return `${location.origin}/public/content_items/${this.contentItem.share_token}`;
    } else {
      return null;
    }
  }

  get ownerShare(): Partial<Share> {
    return { user: this.contentItem.user, content_item: this.contentItem };
  }

  get inputPlaceholder() {
    return this.$t("share.input_placeholder") as string;
  }

  shareName(share: User | string) {
    return share instanceof User ? share.name : share;
  }

  async created() {
    if (this.contentItem.permissions.share) {
      this.shares = await this.sharesService.getAll({ params: { content_item_id: this.contentItem.id.toString() } });
    }
    this.loaded = true;
  }

  async share() {
    const action = Promise.all(
      this.newShares.map(async (newShare) => {
        if (newShare instanceof User) {
          const attrs = {
            user_id: newShare.id,
            permission: this.permission,
            content_item_id: this.contentItem.id,
          };
          return this.sharesService.create(attrs);
        } else {
          const attrs = {
            permission: this.permission,
            content_item_id: this.contentItem.id,
            email: newShare,
          };
          return this.sharesService.create(attrs);
        }
      })
    );
    const shares = await showDialog<Share>(LoadingIndicatorDialog, { action });
    this.shares = this.shares.concat(shares);
    this.sharesUpdated(this.shares);
    this.newShares = [];
  }

  onEnterPressed() {
    if (this.isEmail) {
      this.newShares.push(this.nameOrEmail);
      this.nameOrEmail = "";
      this.$nextTick(() => this.nameOrEmailField.focus());
    }
  }

  async onRemove(share: Share) {
    const action = this.sharesService.delete(share.id);
    await showDialog(LoadingIndicatorDialog, { action });
    this.shares = this.shares.filter((it) => it !== share);
    this.sharesUpdated(this.shares);
  }

  async onSetPermission(share: Share, permission: SharePermission) {
    if (share.id) {
      const action = this.sharesService.update(share.id, { permission });
      const updated = await showDialog<Share>(LoadingIndicatorDialog, { action });
      this.shares = this.shares.map((it) => (it === share ? updated : it));
    } else {
      const attrs: any = {
        permission,
        content_item_id: share.content_item.id,
      };
      if (share.customer_organization) {
        attrs.customer_organization_id = share.customer_organization.id;
      }
      const action = this.sharesService.create(attrs);
      const created = await showDialog<Share>(LoadingIndicatorDialog, { action });
      this.shares = [...this.shares, created];
    }
  }

  async generateLink() {
    const action = this.contentItemsService.createShareToken(this.contentItem);
    const contentItem = await showDialog<ContentItem>(LoadingIndicatorDialog, { action });
    Object.assign(this.contentItem, contentItem);
    this.contentItemUpdated(contentItem);
  }

  async removeLink() {
    const action = this.contentItemsService.deleteShareToken(this.contentItem);
    const contentItem = await showDialog<ContentItem>(LoadingIndicatorDialog, { action });
    Object.assign(this.contentItem, contentItem);
    this.contentItemUpdated(contentItem);
  }

  copyLink() {
    navigator.clipboard.writeText(this.publicLink);
    const message = this.$t("share.link_copied_to_clipboard") as string;
    this.$toast.info(message, { message, position: "bottom" });
  }

  selectUser(user: User) {
    this.newShares.push(user);
    this.nameOrEmail = "";
    this.$nextTick(() => this.nameOrEmailField.focus());
  }

  removeNewShare(newShare) {
    this.newShares = this.newShares.filter((it) => it !== newShare);
  }

  close() {
    this.$emit("cancel");
  }

  @Watch("nameOrEmail")
  async onNameOrEmailChanged() {
    this.matchingUsers = null;
    if (this.nameOrEmail && this.nameOrEmail.length > 0) {
      this.matchingUsers = await new Users().search(this.nameOrEmail);
    }
  }
}
</script>
