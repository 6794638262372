<template>
  <button
    class="h-10 rounded-xs flex flex-col px-sm justify-around lg:h-14 hover:text-text-primary"
    type="button"
    :class="{ active, 'text-text-primary': active }"
    @click="$emit('click')"
  >
    <slot name="icon">
      <icon-fluent class="h-4 w-4" :name="icon" :variant="active ? 'filled' : 'regular'"></icon-fluent>
    </slot>
    <div class="hidden h-3 lg:block text-[9px]">{{ labelText }}</div>
  </button>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class EditorMenuButton extends Vue {
  @Prop()
  icon: string;

  @Prop()
  labelKey: string;

  @Prop()
  label: string;

  @Prop()
  active: boolean;

  get labelText() {
    if (this.label) {
      return this.label;
    } else if (this.labelKey) {
      return this.$t(this.labelKey);
    } else {
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  &.active {
    background-color: var(--ui-color-surface-high-contrast);
    border-radius: var(--border-radius-sm);
  }
}
</style>
