<template>
  <div class="flex flex-col items-center">
    <icon-fluent name="checkmark" class="text-semantic-success h-8 w-auto my-2" size="20" variant="filled" />
    <h3>{{ $t("plan.membership_updated") }}</h3>
    <div class="text-label-medium text-text-primary mt-2">{{ $t("plan.your_new_membership_starts_on") }}</div>
    <div class="text-label-strong text-text-primary mt-2">{{ dateFilter(newPlanStartingDate) }}</div>
    <div class="border border-border-emphasis rounded py-8 px-12 flex flex-col gap-6 text-left mt-8">
      <div class="flex flex-col gap-4">
        <div class="text-body-small text-text-variant uppercase">{{ $t("plan.your_plan") }}</div>
        <div class="text-label-small text-text-primary">{{ $t("plan.period." + selectedPlan.period) }}</div>
      </div>
      <div class="flex flex-col gap-4">
        <div class="text-body-small text-text-variant uppercase">{{ $t("plan.starting_date") }}</div>
        <div class="text-label-small text-text-primary">{{ dateFilter(newPlanStartingDate) }}</div>
      </div>
      <div class="flex flex-col gap-4">
        <div class="text-body-small text-text-variant uppercase">{{ $t("plan.payment_method") }}</div>
        <div class="text-label-small text-text-primary">
          {{ $t("plan.payment_methods." + subscription.payment_method) }}
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div class="text-body-small text-text-variant uppercase">{{ $t("plan.next_billing_date") }}</div>
        <div class="text-label-small text-text-primary">{{ dateFilter(newPlanStartingDate) }}</div>
      </div>
    </div>
    <div class="mt-12">
      <button class="btn-secondary" @click="close">{{ $t("actions.close") }}</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useActiveSubscription } from "@/composables/session";
import { computed } from "vue";
import { dateFilter } from "@/filters";
import PlanPrice from "@/model/plan-price";

defineProps<{
  selectedPlan: PlanPrice;
}>();

const emit = defineEmits(["close"]);

const subscription = useActiveSubscription();

const newPlanStartingDate = computed(() => {
  return subscription.value.next_billing_date;
});

const close = () => emit("close");
</script>
