import LogbookEntry from "@/model/logbook-entry";
import Speciality from "@/model/speciality";
import User from "@/model/user";
import ApiService from "@/services/api-service";

export default class DashboardService extends ApiService<any> {
  get baseUrl() {
    return "/api/v1/dashboard";
  }

  getRecentActivity(): Promise<LogbookEntry<any>[]> {
    return this.get("recent_activity");
  }

  getUsersWithMostProceduresPerformed(): Promise<User[]> {
    return this.get("most_active", { params: { entry_type: "procedure" } });
  }

  getUsersWithLeastProceduresPerformed(): Promise<User[]> {
    return this.get("least_active", { params: { entry_type: "procedure" } });
  }

  getSpecialitiesWithResidentCount(): Promise<Speciality[]> {
    return this.get("specialities");
  }
}
