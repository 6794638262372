<template>
  <div class="users-list relative">
    <div>
      <div class="mb-md">
        <div class="flex flex-1">
          <search-input
            v-model="search"
            class="w-64 sm:grow-0"
            :placeholder="$t('actions.search')"
            @reset="onReset"
          ></search-input>
        </div>
      </div>
    </div>
    <scroll-detector v-if="loaded" class="mb-28" @end="loadMoreUsers">
      <div>
        <list-item v-for="user in users" :key="user.id" class="user">
          <div class="picture mr-md">
            <avatar :user="user"></avatar>
          </div>
          <div class="name">
            <div class="text-body-large text-text-primary">{{ user.name }}</div>
            <div class="text-body-small">{{ userRole(user) }}</div>
          </div>
          <div class="actions">
            <context-menu v-if="user.isResident()">
              <a @click="editUser(user)">
                <icon icon="pen"></icon><span>{{ $t("actions.edit") }}</span>
              </a>
              <a @click="endResidency(user)">
                <icon icon="times"></icon><span>{{ $t("actions.end_residency") }}</span>
              </a>
            </context-menu>
          </div>
        </list-item>
      </div>
    </scroll-detector>
    <loader-big v-if="loading" class="absolute top-full inset-x-0 mt-lg"></loader-big>
  </div>
</template>

<script lang="ts">
import { terminateResidency } from "@/actions";
import store from "@/store/store";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import User from "@/model/user";
import CustomerOrganizationEditResidentModal from "@/pages/customer-organization/customer-organization-edit-resident-modal.vue";
import Users from "@/services/users";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import { FETCH_USERS_SUCCESS } from "@/store/mutations";

@Component({})
export default class CustomerOrganizationUsersPage extends Vue {
  loading: boolean = false;

  @Prop({ default: 2 })
  minSearchLength: number;
  search = "";

  get users() {
    return this.$store.getters.users.filter(
      (user: User) =>
        user.customer_organization_id === this.$currentUser.customer_organization.id ||
        !!user.currentResidencyForOrganization(this.$currentUser.customer_organization)
    );
  }

  get usersService(): Users {
    return new Users();
  }

  get loaded() {
    return !!this.users;
  }

  async created() {
    return this.queryUsers();
  }

  queryUsers() {
    return this.fetchUsers();
  }

  async fetchUsers() {
    const users = this.$store.getters.users.filter(
      (user: User) =>
        user.customer_organization_id === this.$currentUser.customer_organization.id ||
        !!user.currentResidencyForOrganization(this.$currentUser.customer_organization)
    );
    const params: any = { limit: 20, offset: this.users?.length || 0 };
    if (this.search.length >= this.minSearchLength) {
      params.q = this.search;
    }

    const newUsers = await new Users().getAll({ params });

    const newOrganizationUsers = newUsers.filter(
      (user: User) =>
        user.customer_organization_id === this.$currentUser.customer_organization.id ||
        !!user.currentResidencyForOrganization(this.$currentUser.customer_organization)
    );

    store.commit(FETCH_USERS_SUCCESS, [...(users || []), ...newOrganizationUsers]);
    this.loading = false;
  }

  loadMoreUsers() {
    if (!this.loading) {
      this.fetchUsers();
    }
  }

  get currentUser() {
    return this.$store.getters.currentUser;
  }

  userRole(user: User) {
    let label = this.$t(`user_roles.${user.role}`);
    if (user.isResident()) {
      const residency = user.currentResidencyForOrganization(this.$currentUser.customer_organization);
      if (residency && residency.curriculums) {
        const curriculumNames = residency.curriculums.map(({ name }) => name).join(", ");
        label += ` (${curriculumNames})`;
      }
    }
    return label;
  }

  async editUser(user: User) {
    await showDialog(CustomerOrganizationEditResidentModal, { user });
  }

  async endResidency(user: User) {
    const confirmed = await showDialog(ConfirmationDialog, {
      title: this.$t("confirmation.end_residency", { user: user.name }),
    });
    if (confirmed) {
      const residency = user.currentResidencyForOrganization(this.$currentUser.customer_organization);
      if (residency) {
        const action = terminateResidency(residency.id);
        return showDialog(LoadingIndicatorDialog, { action });
      }
    }
  }

  @Watch("search")
  onSearch() {
    store.commit(FETCH_USERS_SUCCESS, []);
    this.fetchUsers();
  }

  onReset() {
    this.search = "";
  }
}
</script>

<style lang="scss" scoped>
.user {
  .picture {
    width: 40px;
  }
  .name {
    flex: 1;
  }
}
</style>
