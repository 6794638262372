<template>
  <div class="invitation-list relative">
    <div>
      <div class="mb-md">
        <div class="flex flex-1">
          <search-input
            v-model="search"
            class="w-64 mr-md sm:grow-0"
            :placeholder="$t('actions.search')"
            @reset="onReset"
          ></search-input>
          <button class="btn-primary" @click="inviteUser">{{ $t("invite_user.title") }}</button>
        </div>
      </div>
    </div>
    <scroll-detector v-if="loaded" class="mb-28" @end="loadMoreInvitations">
      <div>
        <list-item v-if="invitations && invitations.length == 0" class="flex-center">
          {{ $t("invitations.no_pending_invitations") }}
        </list-item>
        <list-item v-for="invitation in invitations" :key="invitation.id" class="invitation">
          <div class="flex-1">
            <div class="text-body-large text-text-primary">{{ invitation.email }}</div>
            <div class="text-body-small">{{ roleName(invitation) }}</div>
          </div>
          <div class="actions">
            <context-menu>
              <action-link
                icon="trash"
                :align="true"
                :title="$t('actions.delete')"
                @click="deleteInvitation(invitation)"
              ></action-link>
              <action-link
                icon="envelope"
                :align="true"
                :title="$t('actions.resend')"
                @click="resendInvitation(invitation)"
              ></action-link>
              <action-link
                icon="copy"
                :align="true"
                :title="$t('actions.copy_invitation_link')"
                @click="copyInvite(invitation.token)"
              >
              </action-link>
            </context-menu>
          </div>
        </list-item>
      </div>
    </scroll-detector>
    <loader-big v-if="loading" class="absolute top-full inset-x-0 mt-lg"></loader-big>
  </div>
</template>

<script lang="ts">
import Invitation from "@/model/invitation";
import CustomerOrganizationInviteUserModal from "@/pages/customer-organization/customer-organization-invite-user-modal.vue";
import Invitations from "@/services/invitations";
import { showDialog } from "@/utils/dialogs";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";

@Component({})
export default class CustomerOrganizationInvitationsPage extends Vue {
  invitations: Invitation[] = null;
  loading: boolean = false;

  @Prop({ default: 2 })
  minSearchLength: number;
  search = "";

  get invitationsService(): Invitations {
    return new Invitations();
  }
  get loaded() {
    return !!this.invitations;
  }

  get currentUser() {
    return this.$store.getters.currentUser;
  }

  roleName(invitation: Invitation) {
    let label = this.$t(`user_roles.${invitation.role_type}`);
    if (invitation.curriculum) {
      label += ` (${invitation.curriculum.name})`;
    }
    return label;
  }

  async inviteUser() {
    await showDialog<Invitation>(CustomerOrganizationInviteUserModal);
    this.fetchInvitations();
  }

  async deleteInvitation(invitation: Invitation) {
    await this.invitationsService.delete(invitation.token);
    this.invitations = this.invitations.filter(({ id }) => id !== invitation.id);
  }

  async resendInvitation(invitation: Invitation) {
    await this.invitationsService.resendInvitation(invitation.token);
    this.$toast.info(this.$t("invitation.invitation_sent") as string);
  }

  copyInvite(token: String) {
    navigator.clipboard.writeText(window.location.origin + "/users/new/" + token);
  }

  async created() {
    return this.fetchInvitations();
  }

  async fetchInvitations() {
    this.loading = true;
    const params: any = { limit: 20, offset: this.invitations?.length || 0 };
    if (this.search.length >= this.minSearchLength) {
      params.q = this.search;
    }
    const newInvitations = await new Invitations().getAll({ params });
    this.invitations = [...(this.invitations || []), ...newInvitations];
    this.loading = false;
  }

  loadMoreInvitations() {
    if (!this.loading) {
      this.fetchInvitations();
    }
  }

  @Watch("search")
  async onSearch() {
    this.invitations = null;
    this.fetchInvitations();
  }

  onReset() {
    this.search = "";
  }
}
</script>

<style lang="scss" scoped>
.user {
  .picture {
    width: 40px;
  }
  .name {
    flex: 1;
  }
}
</style>
