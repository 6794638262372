import Organization from "@/model/organization";
import QuizQuestion from "@/model/quiz-question";

const DRAFT = "draft";

interface Quiz {
  id: number;
  name: string;
  description: string;
  status: string;
  language?: string;
  questions: QuizQuestion[];
  customer_organization_id?: number;
  customer_organization?: Organization;
  answers_count: number;
  success_rate: number;
}

const isDraftQuiz = (quiz: Quiz) => quiz.status === DRAFT;

export { isDraftQuiz };

export default Quiz;
