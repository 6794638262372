<template>
  <a
    target="_blank"
    :href="url"
    class="block rounded-sm sm:bg-surface-min-contrast hover:bg-transparent active:sm:bg-surface-low-contrast sm:p-2 sm:pb-4 w-full place-self-start group duration-300"
  >
    <div class="bg-surface-high-contrast rounded-sm aspect-square sm:aspect-[4/3] relative">
      <div
        v-lazy:background-image="previewImageUrl"
        class="h-full w-full bg-cover bg-center opacity-0 lazy-loaded:opacity-80 lazy-loaded:group-hover:opacity-100 lazy-loaded:group-active:opacity-100 lazy-loaded:group-hover:shadow-md lazy-loaded:group-active:shadow-image-inset rounded-sm duration-300"
      ></div>
      <three-d-badge class="absolute top-2 left-2 group-active:opacity-0 group-active:sm:opacity-100 duration-300" />
      <open-link-badge class="absolute top-2 right-2 sm:hidden group-active:opacity-0 duration-300" />
    </div>
    <div class="flex flex-col sm:flex-row items-start sm:items-center sm:gap-4 mt-2 sm:mt-4">
      <div class="text-label-small text-text-emphasis sm:hidden">Invivo</div>
      <badge v-if="!props.dynamicScene.published" label="Draft" variant="draft" class="mr-1" />
      <div class="text-label-small text-text-primary sm:text-text-variant flex-1 line-clamp-2">
        {{ dynamicScene.title }}
      </div>
      <icon-fluent
        name="open"
        size="16"
        variant="filled"
        class="text-text-emphasis opacity-0 hover-hover:group-hover:opacity-100 duration-300"
      />
    </div>
  </a>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
import DynamicScene from "@/model/dynamic-scene";
import { filePath } from "@/utils/paths";

const props = defineProps<{
  dynamicScene: DynamicScene;
}>();

const previewImageUrl = computed(() => {
  return filePath(props.dynamicScene.preview_image, "small");
});

const url = `/viewer/${props.dynamicScene.slug}`;
</script>
