<template>
  <div class="input-group">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class InputGroup extends Vue {}
</script>

<style lang="scss" scoped>
.input-group {
  display: flex;

  input {
    flex: 1;
  }

  > input:not(:first-child),
  > button:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > input:not(:last-child),
  > button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
