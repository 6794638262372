<template>
  <dropdown-select
    :value="value"
    :options="options"
    :placeholder="$t('color_theme.title')"
    :error="error"
    :width-by-content="false"
    button-class="rounded-xs"
    @input="onInput"
  ></dropdown-select>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import DropdownSelectOption from "@/model/dropdown-select-option";
import { availableThemes } from "@/theme";

@Component({})
export default class ThemeSelect extends Vue {
  @Prop()
  value: string;

  @Prop({ default: false })
  error: boolean;

  get options(): DropdownSelectOption[] {
    return availableThemes().map((entry) => ({
      value: entry[0],
      label: entry[1].toString(),
    }));
  }

  onInput(value) {
    this.$emit("input", value);
  }
}
</script>
