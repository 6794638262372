<template>
  <button class="btn-icon" :class="classes" @click="onClick">
    <icon-fluent
      class="w-auto"
      :name="icon"
      size="20"
      :variant="iconVariant"
      :class="smallIcon ? 'h-4' : 'h-6'"
    ></icon-fluent>
  </button>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class UiButtonIcon extends Vue {
  @Prop()
  icon: string;

  @Prop({ default: "md" })
  size: "md" | "sm" | "xs";

  @Prop()
  iconSize: "md" | "sm";

  @Prop({ default: "primary" })
  variant: string;

  @Prop({ default: "filled" })
  iconVariant: string;

  @Prop()
  disabled: boolean;

  get smallIcon() {
    return (this.size === "sm" || this.size === "xs") && this.iconSize !== "md";
  }

  get classes() {
    return [
      `btn-${this.variant}`,
      `btn-${this.size}`,
      {
        "text-text-variant": this.disabled,
      },
    ];
  }

  onClick() {
    if (!this.disabled) {
      this.$emit("click");
    }
  }
}
</script>
