export const UPDATE_ENTITIES = "updateEntities";
export const STORE_ENTITIES = "storeEntities";

export const SET_LOADING_LOGBOOK_ENTRIES = "setLoadingLogbookEntries";
export const SET_LOGBOOK_ENTRY_IDS = "setLogbookEntryIds";

export const SET_CURRENT_USER = "setCurrentUser";
export const SET_IMPERSONATING = "setImpersonating";
export const UPDATE_SUBSCRIPTION = "updateSubscription";

export const FETCH_USERS_REQUEST = "fetchUsersRequest";
export const FETCH_USERS_SUCCESS = "fetchUsersSuccess";
export const FETCH_USERS_FAILURE = "fetchUsersFailure";

export const UPDATE_RESIDENCY_SUCCESS = "updateResidencySuccess";
export const TERMINATE_RESIDENCY_SUCCESS = "terminateResidencySuccess";

export const FETCH_RESIDENTS_REQUEST = "fetchResidentsRequest";
export const FETCH_RESIDENTS_FAILURE = "fetchResidentsFailure";

export const SET_CONTENTS_VIEW_MODE = "setContentsViewMode";
export const SET_CONTENT_LOADING = "setContentLoading";
export const SET_CONTENT_ITEMS = "setContentItems";
export const SET_CONTENT_FILTERS = "setContentFilters";
export const SET_CONTENT_SEARCH_QUERY = "setContentSearchQuery";

export const SET_COMMENT_NOTIFICATIONS = "setCommentNotifications";

export const SET_HOME_PAGE_LATEST_RELEASES_LOADING = "setHomePageLatestReleasesLoading";
export const SET_HOME_PAGE_LATEST_RELEASES = "setHomePageLatestReleases";
