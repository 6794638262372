export const isElementOrAncestorOf = (element: HTMLElement, ofElement: HTMLElement) => {
  return element === ofElement || (ofElement?.parentElement && isElementOrAncestorOf(element, ofElement.parentElement));
};

export const isElementOrAncestorOfClass = (element: HTMLElement, className: string) => {
  return (
    element?.classList?.contains(className) ||
    (element?.parentElement && isElementOrAncestorOfClass(element.parentElement, className))
  );
};
