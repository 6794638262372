<template>
  <page v-if="externalLink">
    <page-toolbar v-if="!isNew">
      <div class="flex justify-end gap-2">
        <action-button
          v-if="canShowInLatestReleases"
          id="showInLatestReleases"
          icon="news"
          :title="$t('actions.show_in_latest_releases')"
          @click="showInLatestReleases"
        ></action-button>
        <action-button
          v-if="canRemoveFromLatestReleases"
          id="removeFromLatestReleases"
          icon="dismiss"
          :title="$t('actions.remove_from_latest_releases')"
          @click="removeFromLatestReleases"
        ></action-button>
        <ui-button-icon icon="delete" variant="secondary" size="sm" @click="deleteLink"></ui-button-icon>
      </div>
    </page-toolbar>
    <page-content>
      <badge
        v-if="externalLink.content_item.id"
        class="mb-4"
        :label="$t('status.' + externalLink.content_item.item_state)"
        :variant="externalLink.content_item.item_state"
      ></badge>
      <div class="flex flex-col gap-8 w-80 xs:w-[500px]">
        <preview-image-input
          v-model="externalLink.content_item.preview_image"
          :content-item="previewContentItem"
        ></preview-image-input>
        <form @submit.prevent="onSubmit">
          <div class="flex flex-col gap-4">
            <text-input
              key="name"
              v-model="externalLink.name"
              type="text"
              :auto-focus="!$v.externalLink.name.$error"
              :placeholder="$t('attributes.name')"
              :floating-label="true"
            ></text-input>
            <text-input
              key="link"
              v-model="externalLink.link"
              type="text"
              :placeholder="$t('editor.link')"
              :floating-label="true"
              :error="!$v.externalLink.link.url"
              :validation-message="$t('errors.url_format')"
            ></text-input>
          </div>
        </form>
        <div class="flex flex-col gap-md">
          <div class="flex flex-col bg-surface-min-contrast rounded-lg p-md">
            <h3 class="mb-4 text-on-surface-variant">{{ $t("actions.add_filters") }}</h3>
            <speciality-select
              v-model="externalLink.content_item.speciality_ids"
              class="mb-sm"
              :allow-null="true"
              :multiselect="true"
              :width-by-content="false"
              @input="$v.externalLink.content_item.$touch"
            ></speciality-select>
            <location-select
              v-model="externalLink.content_item.location_ids"
              class="mb-sm"
              :editable="true"
              :allow-null="true"
              :multiselect="true"
              :width-by-content="false"
              :user="$currentUser"
              :customer-organization="currentCustomerOrganization"
              @input="$v.externalLink.content_item.$touch"
            ></location-select>
            <language-select
              v-model="externalLink.content_item.language"
              class="mb-sm"
              :available-languages="contentLanguages"
              :placeholder="$t('attributes.language')"
              :allow-null="true"
              :width-by-content="false"
            ></language-select>
          </div>
          <div class="flex justify-end gap-sm flex-wrap">
            <button class="btn-secondary" type="button" @click="onCancel">{{ $t("actions.cancel") }}</button>
            <button class="btn-primary" type="submit" :disabled="$v.externalLink.$invalid" @click="onSubmit">
              {{ $t("actions.save") }}
            </button>
            <button
              v-if="canPublish"
              class="btn-primary-variant"
              type="button"
              :disabled="$v.externalLink.$invalid"
              @click="onPublish"
            >
              {{ $t("actions.publish") }}
            </button>
            <button
              v-if="canUnPublish"
              class="btn-primary-variant"
              type="button"
              :disabled="$v.externalLink.$invalid"
              @click="onUnPublish"
            >
              {{ $t("actions.unpublish") }}
            </button>
          </div>
        </div>
      </div>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { availableLanguages, availableContentLanguages } from "@/i18n";
import { showDialog } from "@/utils/dialogs";
import ConfirmationDialog from "@/components/confirmation-dialog.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, url } from "vuelidate/lib/validators";
import Organization from "@/model/organization";
import ContentItem from "@/model/content-item";
import LoadingIndicatorDialog from "@/components/loading-indicator-dialog.vue";
import ExternalLink from "@/model/external-link";
import ExternalLinksService from "@/services/external-links-service";
import { loadContentItems, loadHomePageLatestReleases } from "@/actions";

@Component({})
export default class ExternalLinkEditPage extends Vue {
  @Prop()
  id: string;

  externalLink: Partial<ExternalLink> = null;

  @Validations()
  validations = {
    externalLink: {
      name: {
        required,
      },
      link: {
        required,
        url,
      },
      content_item: { required },
    },
  };

  get isNew() {
    return !this.id;
  }

  get loaded() {
    return !!this.externalLink;
  }

  get canPublish(): boolean {
    return (
      this.externalLink.content_item.id &&
      this.externalLink.content_item.item_state == "draft" &&
      (this.$currentUser.isHospitalAdmin() || this.$currentUser.isOsgenicUser())
    );
  }

  get canUnPublish(): boolean {
    return (
      this.externalLink.content_item.id &&
      this.externalLink.content_item.item_state == "published" &&
      (this.$currentUser.isHospitalAdmin() || this.$currentUser.isOsgenicUser())
    );
  }

  get canShowInLatestReleases() {
    return (
      !this.externalLink.content_item.user_id &&
      this.externalLink.content_item.permissions.show_in_latest_releases &&
      !this.externalLink.content_item.latest_content_item_release &&
      this.externalLink.content_item.item_state === "published"
    );
  }

  get canRemoveFromLatestReleases() {
    return (
      !this.externalLink.content_item.user_id &&
      this.externalLink.content_item.permissions.show_in_latest_releases &&
      this.externalLink.content_item.latest_content_item_release
    );
  }

  get contentLanguages(): string[] {
    return Object.keys(availableContentLanguages);
  }

  get title() {
    return !this.isNew ? this.$t("actions.edit_external_link") : this.$t("actions.add_external_link");
  }

  get previewContentItem() {
    return {
      ...this.externalLink.content_item,
      name: this.externalLink.name,
      content_type: "ExternalLink",
    };
  }

  get externalLinkService() {
    return new ExternalLinksService();
  }

  get currentCustomerOrganization(): Organization {
    return this.organizationAvailableForExternalLink;
  }

  get organizationAvailableForExternalLink(): Organization {
    if (this.$currentUser.isResidentWithEditPermission()) {
      return this.$currentUser.currentResidency?.customer_organization ?? null;
    } else if (this.$currentUser.isHospitalAdmin()) {
      return this.$currentUser.customer_organization;
    } else if (this.$currentUser.isOsgenicUser()) {
      return this.externalLink.content_item?.customer_organization ?? null;
    }
    return null;
  }

  async created() {
    if (this.id) {
      await this.fetchExternalLink();
    } else {
      this.initExternalLink();
    }
  }

  @Watch("$route.params.id")
  async onIdChanged(newId: string, oldId: string) {
    if (newId !== oldId) {
      if (newId) {
        await this.fetchExternalLink();
      } else {
        this.initExternalLink();
      }
    }
  }

  initExternalLink(externalLink: ExternalLink = null) {
    this.externalLink = {
      name: externalLink?.name ?? "",
      link: externalLink?.link ?? "",
      content_item: {
        speciality_ids: externalLink?.content_item?.speciality_ids ?? [],
        location_ids: externalLink?.content_item?.location_ids ?? [],
        customer_organization_id: externalLink?.content_item?.customer_organization_id ?? null,
        language: externalLink?.content_item?.language,
        preview_image: externalLink?.content_item.preview_image,
        permissions: {},
      } as ContentItem,
    };
  }

  async onPublish() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.publish_external_link") });
    if (confirmed) {
      const externalLink = await this.externalLinkService.publish(this.externalLink.id);
      this.externalLink = externalLink;
    }
  }

  async onUnPublish() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.unpublish_external_link") });
    if (confirmed) {
      const externalLink = await this.externalLinkService.unpublish(this.externalLink.id);
      this.externalLink = externalLink;
    }
  }

  async showInLatestReleases() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.show_in_latest_releases") });
    if (confirmed) {
      const externalLink = await this.externalLinkService.showInLatestReleases(this.externalLink.id);
      this.externalLink = externalLink;
      return loadHomePageLatestReleases();
    }
  }

  async removeFromLatestReleases() {
    const confirmed = await showDialog(ConfirmationDialog, {
      title: this.$t("confirmation.remove_from_latest_releases"),
    });
    if (confirmed) {
      const externalLink = await this.externalLinkService.removeFromLatestReleases(this.externalLink.id);
      this.externalLink = externalLink;
      return loadHomePageLatestReleases();
    }
  }

  async fetchExternalLink() {
    this.externalLink = await this.externalLinkService.get(this.id);
  }

  get languageOptions() {
    const langs = availableLanguages;
    return Object.keys(langs).map((key) => {
      return { value: key, label: langs[key] };
    });
  }

  onCancel() {
    this.$router.back();
  }

  async onSubmit() {
    this.$v.$touch();

    if (!this.$v.$error) {
      // Reset dirty state so that user will not be asked to discard changes
      this.$v.$reset();

      const action = !this.externalLink.id ? this.createExternalLink() : this.updateExternalLink();

      await showDialog(LoadingIndicatorDialog, { action });
    }
  }

  private async createExternalLink() {
    const attrs: Partial<ExternalLink> = {
      ...this.externalLink,
      link: this.externalLink?.link,
      content_item: {
        ...this.externalLink.content_item,
        ...this.generateOwnershipAttributes(),
      },
    };

    const externalLink = await this.externalLinkService.create(attrs);

    this.$router.push({ name: "EditExternalLink", params: { id: externalLink.id.toString() } });
  }

  private async updateExternalLink() {
    await this.externalLinkService.update(this.externalLink.id, {
      ...this.externalLink,
      link: this.externalLink?.link,
      content_item: {
        ...this.externalLink.content_item,
        ...this.generateOwnershipAttributes(),
      },
    });
  }

  private generateOwnershipAttributes(): Partial<ContentItem> {
    const attrs: Partial<ContentItem> = {
      user_id: null,
      customer_organization_id: null,
    };

    if (this.$currentUser.isHospitalAdmin()) {
      attrs.customer_organization_id = this.$currentUser.customer_organization_id;
    }

    return attrs;
  }

  async deleteLink() {
    const confirmed = await showDialog(ConfirmationDialog, { title: this.$t("confirmation.delete_permanently") });
    if (confirmed) {
      const action = this.externalLinkService.delete(this.externalLink.id);
      await showDialog(LoadingIndicatorDialog, { action });

      this.$router.replace({ name: "Contents" });
      return loadContentItems(true);
    }
  }
}
</script>
