import ApiService from "@/services/api-service";

export default class SupportMessagesService extends ApiService<any> {
  get baseUrl() {
    return "/api/v1/support_messages";
  }

  create(data) {
    return super.create(data, { format: "multipart" });
  }
}
