<template>
  <page>
    <page-header :title="$t('residents.title')"></page-header>
    <page-content>
      <div>
        <div class="mb-md">
          <div class="flex flex-1">
            <search-input
              v-model="search"
              class="w-64 sm:grow-0"
              :placeholder="$t('actions.search')"
              @reset="onReset"
            ></search-input>
          </div>
        </div>
      </div>
      <div v-if="loaded" class="resident-list">
        <list-subheader class="resident-item">
          <div class="picture"></div>
          <div class="name">{{ $t("attributes.name") }}</div>
          <div class="start-date">{{ $t("attributes.start_date") }}</div>
          <div class="stat-col">{{ $t("procedures.title") }}</div>
          <div class="stat-col">{{ $t("courses.title") }}</div>
          <div class="stat-col">{{ $t("other_entries.title") }}</div>
        </list-subheader>
        <scroll-detector class="mb-28" @end="loadMoreResidents">
          <div>
            <list-item
              v-for="rr in residentsAndRoles"
              :key="rr.resident.id"
              class="resident-item"
              @click="showLogbook(rr)"
            >
              <div class="picture">
                <avatar :user="rr.resident"></avatar>
              </div>
              <div class="name">
                <div class="name-heading">
                  <span class="text-body-large text-text-primary mr-xs">{{ rr.resident.name }}</span>
                  <icon class="text-text-variant" icon="chevron-right"></icon>
                  <notification-indicator :user-id="rr.resident.id"></notification-indicator>
                </div>
                <div v-if="rr.resident.speciality" class="text-body-small">{{ rr.resident.speciality.name }}</div>
              </div>
              <div class="start-date">{{ dateFilter(rr.residency.start_date) }}</div>
              <div class="stat-col">{{ rr.residency.entry_counts.procedure }}</div>
              <div class="stat-col">{{ rr.residency.entry_counts.course }}</div>
              <div class="stat-col">{{ rr.residency.entry_counts.other }}</div>
            </list-item>
          </div>
        </scroll-detector>
      </div>
      <loader-big v-if="loading" class="absolute top-full inset-x-0 mt-lg"></loader-big>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import User from "@/model/user";
import { Prop, Watch } from "vue-property-decorator";
import Users from "@/services/users";
import Residency from "@/model/residency";
import { dateFilter } from "@/filters";

type ResidentAndRole = {
  resident: User;
  residency: Residency;
};

@Component({})
export default class ResidentsPage extends Vue {
  residents: User[] = null;
  loading: boolean = false;

  @Prop({ default: 2 })
  minSearchLength: number;
  search = "";

  get loaded() {
    return !!this.residents;
  }

  async created() {
    return this.queryResidents();
  }

  queryResidents() {
    this.residents = null;
    return this.fetchResidents();
  }

  get residentsAndRoles(): ResidentAndRole[] {
    return (
      this.residents &&
      this.residents.map((resident) => ({
        resident,
        residency: this.residentRole(resident),
      }))
    );
  }

  residentRole(resident: User): Residency {
    // Find "active" residency for this resident
    return resident.residencies.find(
      ({ customer_organization_id }) =>
        customer_organization_id === this.$store.getters.currentUser.customer_organization_id
    );
  }

  loadMoreResidents() {
    if (!this.loading) {
      return this.fetchResidents();
    }
  }

  async fetchResidents() {
    this.loading = true;
    const params: any = { role: "resident", limit: 20, offset: this.residents?.length || 0 };
    if (this.search.length >= this.minSearchLength) {
      params.q = this.search;
    }
    const newResidents = await new Users().getAll({ params });
    this.residents = [...(this.residents || []), ...newResidents];
    this.loading = false;
  }

  showLogbook({ resident, residency }: ResidentAndRole) {
    this.$router.push({
      path: `/residents/${resident.id}/${residency.id}/logbook`,
    });
  }

  @Watch("search")
  async onSearch() {
    this.residents = null;
    await this.fetchResidents();
  }

  onReset() {
    this.search = "";
  }

  dateFilter(value: string) {
    return dateFilter(value);
  }
}
</script>

<style lang="scss" scoped>
section {
  margin-bottom: $spacing;
}
.resident-list {
  .resident-item {
    display: flex;
    .picture {
      width: 60px;
    }
    .name {
      flex: 1;
    }
    .start-date {
      width: 120px;

      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }
    .actions {
      flex: 1;
      text-align: right;
    }
    .stat-col {
      width: 120px;

      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }
}
</style>
