<template>
  <page>
    <page-header :title="$t('home.title')">
      <template #right>
        <div class="gap-4 hidden md:flex">
          <content-search class="w-96" type="contextual" @search="gotoContents()"></content-search>
          <add-content-button></add-content-button>
        </div>
      </template>
    </page-header>
    <page-toolbar class="md:hidden">
      <div class="flex gap-4">
        <content-search class="flex-1" type="contextual" @search="gotoContents()"></content-search>
        <add-content-button></add-content-button>
      </div>
    </page-toolbar>
    <page-content>
      <div class="flex flex-col gap-10 md:flex-row">
        <div class="md:flex-1 md:max-w-[912px]">
          <div class="mb-2">
            <latest-releases-block></latest-releases-block>
          </div>
          <all-content-block class="mb-6"></all-content-block>
          <!-- <upcoming-releases class="mb-6"></upcoming-releases> -->
          <div class="text-label-strong text-text-primary">{{ $t("highlights.title") }}</div>
          <fade-transition>
            <content-item-preview
              v-if="highlightedContentItem"
              :content-item="highlightedContentItem"
            ></content-item-preview>
          </fade-transition>
        </div>
        <div class="md:w-72">
          <div class="flex flex-col gap-5">
            <highlighted-contents-block
              v-for="highlight in highlights"
              :key="highlight.title"
              :title="highlight.title"
              :filters="highlight.filters"
              :action="highlight.action"
              :type="highlight.type"
              :fetch-items="highlight.fetchItems"
              ref="highlightedContentsBlocks"
            ></highlighted-contents-block>
          </div>
        </div>
      </div>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import ContentItemFilters from "@/model/content-item-filters";
import { TranslateResult } from "vue-i18n";
import HighlightedContentsBlock from "@/pages/home/highlighted-contents-block/highlighted-contents-block.vue";
import LatestReleasesBlock from "@/pages/home/latest-releases-block/latest-releases-block.vue";
import ContentItem from "@/model/content-item";
import { fetchHomePageHighlightedContent } from "@/services/home-page";
import ContentItemPreview from "@/pages/home/content-item-preview/content-item-preview.vue";
import AllContentBlock from "@/pages/home/all-content-block.vue";
import ContentSearch from "@/pages/contents/content-search.vue";
// import UpcomingReleases from "@/pages/home/upcoming-releases/upcoming-releases.vue";
import ContentItemsService from "@/services/content-items-service";

interface Highlight {
  title: string | TranslateResult;
  filters?: Partial<ContentItemFilters>;
  action?: string;
  type: string;
  fetchItems?: () => Promise<ContentItem[]>;
}

@Component({
  components: {
    HighlightedContentsBlock,
    LatestReleasesBlock,
    ContentItemPreview,
    AllContentBlock,
    ContentSearch,
    // UpcomingReleases,
  },
})
export default class HomePage extends Vue {
  highlightedContentItem: ContentItem = null;

  get highlights(): Highlight[] {
    return [
      {
        title: this.$t("contents.recently_opened"),
        action: "recently_opened",
        type: "recently_opened",
        fetchItems: async () => {
          const service = new ContentItemsService();
          return await service.getAll({ action: "recently_opened", params: { limit: 3 } });
        },
      },
      {
        title: this.$t("contents.my_content"),
        filters: {
          owner_user_ids: [this.$store.getters.currentUser.id],
        },
        type: "my_content",
      },
      {
        title: this.$t("contents.shared_with_me"),
        filters: {
          shared: true,
        },
        type: "shared_with_me",
      },
    ];
  }

  async created() {
    this.highlightedContentItem = await fetchHomePageHighlightedContent(
      this.$store.getters.currentUser.content_languages
    );
  }

  activated() {
    const recentlyOpenedHighlight = this.highlights.find(h => h.type === "recently_opened");
    if (recentlyOpenedHighlight && recentlyOpenedHighlight.fetchItems) {
      recentlyOpenedHighlight.fetchItems().then(items => {
        const recentlyOpenedBlock = this.$refs.highlightedContentsBlocks.find(
          block => block.type === "recently_opened"
        );
        if (recentlyOpenedBlock) {
          recentlyOpenedBlock.contentItems = items;
        }
      });
    }
  }

  gotoContents() {
    this.$router.push({ name: "Contents", query: { back: "Home" } });
  }
}
</script>
