<template>
  <transition name="fade">
    <div v-if="visible" class="notification-indicator" :title="title"></div>
  </transition>
</template>

<script lang="ts">
import CommentNotification from "@/model/comment-notification";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class NoticationIndicator extends Vue {
  @Prop()
  userId: any;

  @Prop()
  commentableType: string;

  @Prop()
  commentableId: number;

  @Prop()
  commentId: number;

  get title(): string {
    return this.$t("comments.notification_text") as string;
  }

  get visible() {
    let notifications = this.$store.getters.commentNotifications || [];

    if (this.userId) {
      notifications = notifications.filter(
        (notification: CommentNotification) => notification.comment.author_id === this.userId
      );
    }

    if (this.commentableType) {
      notifications = notifications.filter(
        (notification: CommentNotification) => notification.comment.commentable_type === this.commentableType
      );
    }

    if (this.commentableId) {
      notifications = notifications.filter(
        (notification: CommentNotification) => notification.comment.commentable_id === this.commentableId
      );
    }

    return notifications.length > 0;
  }
}
</script>
<style lang="scss" scoped>
.notification-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-notification);
  margin: 0 var(--spacing-sm);
}

.fade-leave-active {
  transition: opacity 1s;
}

.fade-leave-to {
  opacity: 0;
}
</style>
