<template>
  <form @submit.prevent="onSubmit">
    <input-icon class="input-icon" icon-type="fluent-icon" icon="search" variant="filled">
      <input
        ref="input"
        class="rounded-full text-label-strong text-text-variant duration-100 peer hover:!border-border-emphasis focus:!border-border-emphasis hover:bg-transparent focus:bg-transparent hover:md:bg-transparent focus:md:bg-transparent hover:border-[1.5px] focus:border-[1.5px]"
        type="text"
        :value="model"
        :placeholder="placeholder"
        :class="inputClasses"
        @input="onInput"
      />
      <button
        class="absolute right-sm inset-y-0 text-text-variant peer-placeholder-shown:text-transparent peer-focus:peer-placeholder-shown:text-text-disabled peer-focus:text-text-variant p-0.5"
        type="button"
        @click="onReset"
      >
        <icon-fluent name="dismiss" size="16" variant="filled"></icon-fluent>
      </button>
    </input-icon>
  </form>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import i18n from "@/i18n";
import _ from "lodash";

@Component({})
export default class SearchInput extends Vue {
  @Ref("input")
  input: HTMLInputElement;

  @Prop()
  value: string;

  @Prop({ default: () => `${i18n.t("actions.search")}...` })
  placeholder: string;

  @Prop({ default: "default" })
  type: "default" | "contextual";

  model: string = null;

  emitInput = _.debounce(function () {
    this.$emit("input", this.model);
  }, 300);

  get inputClasses() {
    if (this.type === "contextual") {
      return "placeholder-shown:bg-surface-low-contrast placeholder-shown:md:bg-surface-mid-contrast border placeholder-shown:border-0";
    } else {
      return null;
    }
  }

  onInput(event) {
    this.model = event.target.value;
    this.emitInput();
  }

  onSubmit() {
    // Cancel debounced emits and emit immediately
    this.emitInput.cancel();
    this.$emit("input", this.model);

    // Emit enter event on next tick (so that watchers have time to react)
    this.$nextTick(() => {
      this.$emit("enter");
    });
  }

  onReset() {
    this.$emit("reset");
    this.input.focus();
  }

  @Watch("value", { immediate: true })
  onValueChange(value) {
    this.model = value;
  }
}
</script>

<style lang="scss" scoped>
.input-icon::v-deep {
  .icon-wrapper {
    left: 0.75rem;

    svg {
      width: 19.65px;
      height: 19.65px;

      @media (min-width: $screen-sm-min) {
        width: 25.25px;
        height: 25.25px;
      }
    }
  }

  input {
    padding-left: 2.5781rem;
    padding-right: 2rem;
    border-color: var(--ui-color-text-variant);

    &::placeholder {
      color: var(--ui-color-text-variant);
    }
  }
}
</style>
