<template>
  <modal>
    <h3 class="mb-2">{{ title }}</h3>
    <p>{{ content }}</p>
    <modal-footer>
      <button class="button-primary" type="button" @click="$emit('ok')">{{ $t("actions.ok") }}</button>
    </modal-footer>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ResultModal extends Vue {
  @Prop()
  title: String;
  @Prop()
  content: String;
}
</script>
