import { mergeAttributes, Node, wrappingInputRule } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    indentList: {
      toggleIndentList: () => ReturnType;
    };
  }
}

const inputRegex = /^\t$/;

const IndentListNode = Node.create({
  name: "indentList",

  group: "block list",

  content() {
    return "listItem+";
  },

  parseHTML() {
    return [{ tag: "ul", getAttrs: (node: HTMLElement) => node.classList.contains("indent-list") && null }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["ul", mergeAttributes({ class: "indent-list" }, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      toggleIndentList: () => ({ commands }) => {
        return commands.toggleList(this.name, "listItem");
      },
    };
  },

  addInputRules() {
    return [
      wrappingInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },
});

export default IndentListNode;
