<template>
  <div class="w-full aspect-video bg-surface-high-contrast rounded-sm">
    <div
      v-lazy:background-image="url || placeholderImageUrl"
      class="h-full w-full bg-cover opacity-0 duration-300 ease-in rounded-sm lazy-loaded:opacity-100"
    ></div>
  </div>
</template>

<script lang="ts">
import { getActiveThemePreviewPlaceholder } from "@/theme";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class UiCardImage extends Vue {
  @Prop()
  url: string;

  get placeholderImageUrl() {
    return getActiveThemePreviewPlaceholder();
  }
}
</script>
