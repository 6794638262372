<template>
  <page v-if="organization">
    <page-header :title="organization.name"></page-header>
    <page-toolbar>
      <tabs>
        <tab-link :to="{ name: 'OrganizationGeneral' }">Details</tab-link>
        <tab-link :to="{ name: 'OrganizationUserList' }">{{ $t("users.title") }}</tab-link>
        <tab-link :to="{ name: 'OrganizationInvitationList' }">{{ $t("invitations.title") }}</tab-link>
      </tabs>
    </page-toolbar>
    <page-content>
      <router-view></router-view>
    </page-content>
  </page>
</template>

<script lang="ts" setup>
import { computedAsync } from "@vueuse/core";
import { defineProps } from "vue";
import Organizations from "@/services/organizations";

const props = defineProps<{
  id: string;
}>();

const organization = computedAsync(() => new Organizations().get(props.id));
</script>
